import axiosInstance from "./axiosInstance";

const getEquipmentParameters = (filters) => axiosInstance.get('/equipment-parameters', { params: filters });
const getEquipmentParameterById = (id) => axiosInstance.get(`/equipment-parameters/${id}`);
const createEquipmentParameter = (equipmentParameterData) => axiosInstance.post('/equipment-parameters', equipmentParameterData);
const updateEquipmentParameter = (id, equipmentParameterData) => axiosInstance.put(`/equipment-parameters/${id}`, equipmentParameterData);
const deleteEquipmentParameter = (id) => axiosInstance.delete(`/equipment-parameters/${id}`);

export default {
  getEquipmentParameters,
  getEquipmentParameterById,
  createEquipmentParameter,
  updateEquipmentParameter,
  deleteEquipmentParameter,
};
