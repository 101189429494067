// src/pages/UI/MaterialManagement/WarehouseSidebar.js
import React, { useState } from 'react';
import { Layout, Tree, DatePicker, Input, Button, Form, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/locale/vi_VN';
import { ConfigProvider } from 'antd';
import UISidebar from '../components/Sidebar';

dayjs.locale('vi');

const WarehouseSidebar = ({onFilter}) => {

  const formFields = [
    {
      type: 'Input',
      label: 'Mã kho',
      name: 'id',
      placeholder: 'Nhập mã kho',
    },
    {
      type: 'Input',
      label: 'Tên kho',
      name: 'warehouse_name',
      placeholder: 'Nhập tên kho',
    },
  ];  

  return (
    <UISidebar formFields={formFields} onFilter={onFilter}/>
  );
};

export default WarehouseSidebar;
