// src/components/PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { Spin } from 'antd';

const PrivateRoute = ({ children }) => {
    const { user, loading } = useContext(AuthContext);
    if (loading) {
        return <Spin fullscreen />; // Hoặc bạn có thể thêm một spinner
    }

    return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
