import axiosInstance from "./axiosInstance";

const getPermissions = (filters) => axiosInstance.get('/permissions', { params: filters });
const getPermissionById = (id) => axiosInstance.get(`/permissions/${id}`);
const createPermission = (permissionData) => axiosInstance.post('/permissions', permissionData);
const updatePermission = (id, permissionData) => axiosInstance.put(`/permissions/${id}`, permissionData);
const deletePermission = (id) => axiosInstance.delete(`/permissions/${id}`);

export default {
  getPermissions,
  getPermissionById,
  createPermission,
  updatePermission,
  deletePermission,
};
