import React, { useEffect, useRef, useState } from 'react';
import { Layout, Tree, DatePicker, Button, Form, Divider, Select, ConfigProvider, Radio, Input } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/locale/vi_VN';
import UISidebar from '../../components/Sidebar';
import equipmentManagementApi from '../../../../api/equipmentManagementApi';
import { getProductionPlans } from '../../../../api/productionPlanApi';

dayjs.locale('vi');

const { Sider } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;



const TaktTimeCycleTimeSidebar = ({ filters, onFilter }) => {
  const [form] = Form.useForm();
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [startTime, setStartTime] = useState(dayjs());
  const [endTime, setEndTime] = useState(dayjs());
  const [piOptions, setPiOptions] = useState([]);
  const timer = useRef();

  const formFields = [
    {
      type: 'Select',
      label: 'Thiết bị',
      name: 'equipment_id',
      options: equipmentOptions,
      placeholder: 'Chọn thiết bị',
    },
    {
      type: 'DatePicker',
      label: 'Thời gian bắt đầu',
      name: 'start_time',
      placeholder: 'Thời gian bắt đầu',
      value: startTime,
    },
    {
      type: 'DatePicker',
      label: 'Thời gian kết thúc',
      name: 'end_time',
      placeholder: 'Thời gian kết thúc',
      value: endTime,
    },
    {
      type: 'Input',
      label: 'Mã nội bộ',
      name: 'product_id',
      placeholder: 'Nhập mã nội bộ',
    },
    {
      type: 'Input',
      label: 'Lô sản xuất',
      name: 'batch_id',
      placeholder: 'Nhập lô sản xuất',
    },
    {
      type: 'Input',
      label: 'Mã lot',
      name: 'lot_id',
      placeholder: 'Nhập mã lot',
    },
    // {
    //   type: 'AutoComplete',
    //   label: 'Số PI',
    //   name: 'pi',
    //   placeholder: 'Số PI',
    //   options: piOptions,
    //   search: (value) => {
    //     clearTimeout(timer.current);
    //     timer.current = setTimeout(() => {
    //       fetchProductionPlan({pi: value, page: 1, pageSize: 10});
    //     }, 500);
    //   }
    // },
    // Add other fields here
  ];
  const fetchEquipment = async () => {
    try {
      const response = await equipmentManagementApi.getEquipments();
      if (response?.success) {
        setEquipmentOptions(response.data.data.map(e => ({ label: e.equipment_name, value: e.id })));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductionPlan = async (params) => {
    const response = await getProductionPlans(params);
    if (response?.success) {
      setPiOptions((response.data?.data ?? []).map((item) => ({label: item?.pi, value: item?.pi})));
    }
  }

  useEffect(() => {
    fetchEquipment();
  }, []);
  
  return (
    <UISidebar filters={filters} formFields={formFields} onFilter={onFilter} />
  );
};

export default TaktTimeCycleTimeSidebar;
