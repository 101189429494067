import React, { useState } from 'react';
import { Layout } from 'antd';
import MaterialExportTable from './MaterialExportTable';
import MaterialExportSidebar from './MaterialExportSidebar';
import './MaterialExportManagement.css';

const { Content, Sider } = Layout;

const MaterialExportManagement = () => {
    const [filters, setFilters] = useState({});

    const handleFilter = (newFilters) => {
        setFilters(newFilters);
    };

    return (
        <Layout>
            <Sider width={250}>
                <MaterialExportSidebar onFilter={handleFilter} />
            </Sider>
            <Content className="site-layout-content">
                <div style={{height: "100%"}}>
                    <MaterialExportTable filters={filters} />
                </div>
            </Content>
        </Layout>
    );
};

export default MaterialExportManagement;
