import axiosInstance from "./axiosInstance";

const getProductCategories = (filters) => axiosInstance.get('/product-categories', { params: filters });
const getProductCategoryById = (id) => axiosInstance.get(`/product-categories/${id}`);
const createProductCategory = (productCategoryData) => axiosInstance.post('/product-categories', productCategoryData);
const updateProductCategory = (id, productCategoryData) => axiosInstance.put(`/product-ctegories/${id}`, productCategoryData);
const deleteProductCategory = (id) => axiosInstance.delete(`/product-categories/${id}`);

export default {
  getProductCategories,
  getProductCategoryById,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
};
