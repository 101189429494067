import axiosInstance from "./axiosInstance";

const getLotHistoriesIndex = () => axiosInstance.get('/lot-histories');
const getLotHistories = (filters) => axiosInstance.get('/lot-histories-search', { params: filters });
const getLotHistoriesSummary = (filters) => axiosInstance.get('/lot-histories-summary', { params: filters });
const getLotHistoryById = (id) => axiosInstance.get(`/lot-histories/${id}`);
const createLotHistory = (data) => axiosInstance.post('/lot-histories', data);
const updateLotHistory = (id, data) => axiosInstance.put(`/lot-histories/${id}`, data);
const updateNgLotHistory = (id, data) => axiosInstance.put(`/lot-histories/update-ng/${id}`, data);
const deleteLotHistory = (id) => axiosInstance.delete(`/lot-histories/${id}`);
const updateManyNgLotHistory = (id, data) => axiosInstance.put(`/lot-histories/update-many-ng/${id}`, data);

export default {
  getLotHistoriesIndex,
  getLotHistories,
  getLotHistoryById,
  getLotHistoriesSummary,
  createLotHistory,
  updateLotHistory,
  updateNgLotHistory,
  deleteLotHistory,
  updateManyNgLotHistory
};
