// src/pages/UI/TemplateManagement/TemplateForm.js

import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import './TemplateForm.css';
import config from '../../../config/config';

const TemplateForm = ({ form }) => {
    Form.useWatch('content', form)
    const handleEditorChange = (content, editor) => {
        form.setFieldsValue({ content: content });
    };
    return (
        <Form form={form} layout="vertical" name="form_in_modal">
            <div className="modal-form-grid">
                <Form.Item name="name" label="Tên template" rules={[{ required: true, message: 'Vui lòng nhập tên template!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="description" label="Mô tả" rules={[{ required: true, message: 'Vui lòng nhập mô tả!' }]}>
                    <Input />
                </Form.Item>
            </div>
            <Form.Item name="content" hidden >
                <Input />
            </Form.Item>
            <Form.Item label="Nội dung" rules={[{ required: true, message: 'Vui lòng nhập nội dung!' }]}>
                <Editor
                    apiKey={config.tinymceApiKey} // Thay thế bằng API key của bạn từ TinyMCE
                    value={form.getFieldValue('content')}
                    init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                            'pagebreak',
                            'table',
                            'image',
                            'code',
                        ],
                        toolbar:
                            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | table | image | code',

                        file_picker_types: 'image',
                        file_picker_callback: function (cb, value, meta) {
                            console.log('file_picker_callback called'); // Kiểm tra xem callback có được gọi không
                            let input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');

                            input.onchange = function () {
                                let file = this.files[0];
                                let formData = new FormData();
                                formData.append('image', file);

                                fetch(config.apiUrl + '/upload-image', {
                                    method: 'POST',
                                    body: formData,
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        cb(data.path, { title: file.name });
                                    })
                                    .catch(error => {
                                        console.error('Error uploading image:', error);
                                    });
                            };

                            input.click();
                        }
                    }}
                    onEditorChange={handleEditorChange}
                />
            </Form.Item>
        </Form>
    );
};

export default TemplateForm;
