import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Space, message } from 'antd';
import config from '../../../../config/config';
import errorEquipmentLog from '../../../../api/errorEquipmentLog';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import axiosInstance from '../../../../api/axiosInstance';
import { b64toBlob } from '../../../../utils/dataBlob';
import { DownloadOutlined } from '@ant-design/icons';
dayjs.extend(duration);

const EquipmentPerformanceTable = ({ filters }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  const fetchData = async (filters) => {
    setLoading(true);
    try {
      if (filters?.start_time) filters.start_time = dayjs(filters.start_time).format('YYYY-MM-DD');
      if (filters?.stop_time) filters.stop_time = dayjs(filters.stop_time).format('YYYY-MM-DD');
      var res = await errorEquipmentLog.getErrorEquipmentLogs({ ...filters });
      if (res.success) {
        setData(res.data.data);
        res.data.pagination && setPagination({ ...pagination, ...res.data.pagination });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  };

  const calcTotalTime = (stop_time, start_time) => {
    if (!stop_time || !start_time || !dayjs(stop_time).isValid() || !dayjs(start_time).isValid()) {
      return "";
    }
    const duration = dayjs.duration(dayjs(start_time).diff(dayjs(stop_time))).asHours();
    return (Math.round(duration * 10) / 10);
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: 70,
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    // {
    //   title: 'Công đoạn',
    //   dataIndex: 'process_id',
    //   align: 'center',
    //   width: 100,
    //   render: (_, item) => (item.equipment.process.process_name ?? "")
    // },
    {
      title: 'Máy',
      dataIndex: 'equipment_name',
      align: 'center',
      width: 100,
      render: (_, item) => (item.equipment.equipment_name ?? "")
    },
    {
      title: 'Thời gian dừng',
      dataIndex: 'stop_time',
      align: 'center',
      width: 150,
    },
    {
      title: 'Thời gian chạy',
      dataIndex: 'start_time',
      align: 'center',
      width: 150,
    },
    {
      title: 'Tổng thời gian xử lý',
      dataIndex: 'total_time',
      align: 'center',
      width: 150,
      render: (_, item) => calcTotalTime(item?.stop_time, item?.start_time)
    },
    {
      title: 'Thời gian nhập lỗi',
      dataIndex: 'handle_time',
      align: 'center',
      width: 150,
    },
    {
      title: 'Mã lỗi',
      dataIndex: 'error_equipment_id',
      align: 'center',
      width: 100,
    },
    {
      title: 'Tên lỗi',
      dataIndex: 'equipment_error_name',
      align: 'center',
      width: 100,
      render: (_, item) => (item?.equipment_error?.error_equipment_name ?? "")
    },
    {
      title: 'Nguyên nhân',
      dataIndex: 'reason',
      align: 'center',
      width: 100,
      render: (_, item) => (item?.equipment_error?.reason ?? "")
    },
    {
      title: 'Biện pháp khắc phục',
      dataIndex: 'fix',
      align: 'center',
      width: 300,
      render: (_, item) => (item?.equipment_error?.fix ?? "")
    },
    {
      title: 'Biện pháp phòng ngừa',
      dataIndex: 'protection_measures',
      align: 'center',
      width: 300,
      render: (_, item) => (item?.equipment_error?.protection_measures ?? "")
    },
    {
      title: 'Người xử lý',
      dataIndex: 'handler_name',
      align: 'center',
      width: 100,
      render: (_, item) => (item?.handler?.name ?? "")
    },
  ];

  const getTableHeight = () => {
    const layout = document.querySelector('.site-layout-content');
    console.log(layout?.offsetHeight);
    if (layout) {
      const sectionChart = layout?.querySelector('.section-chart');
      const sectionCard = layout?.querySelector('.section-card');
      const cardHead = sectionCard?.querySelector('.ant-card-head');
      const cardBody = sectionCard?.querySelector('.ant-card-body');
      const table = sectionCard?.querySelector('.table-equipment-performance');
      if (table) {
        let paddingTop = 0;
        let paddingBottom = 0;
        if (cardBody) {
          const style = window.getComputedStyle(cardBody);
          paddingTop = parseFloat(style.paddingTop);
          paddingBottom = parseFloat(style.paddingBottom);
        }
        const header = table?.querySelector('.ant-table-thead');
        const pagination = table?.querySelector('.ant-table-pagination');
        const tableBody = table?.querySelector('.ant-table-body');
        const antEmpty = table?.querySelector('.ant-empty-normal');
        const result = (layout?.offsetHeight ?? 0) - (sectionChart?.offsetHeight ?? 0) - (cardHead?.offsetHeight ?? 0) - (header?.offsetHeight ?? 0) - (pagination?.offsetHeight ?? 0) - paddingTop - paddingBottom - 36 - 48 - 8;
        if (Number(result) > 0) {
          if (tableBody) tableBody?.style.setProperty('height', `${result}px`, 'important');
          if (antEmpty) antEmpty?.style.setProperty('height', `${result - 114}px`);
          console.log(result);
          return result;
        }
      }
    }
    return undefined;
  }

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/error-equipment-logs-export', {params: filters});
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      className='section-card'
      style={{ height: '100%' }}
      title="Thông số lỗi"
      extra={
        <Space>
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel} loading={loading}>Xuất file</Button>
        </Space>
      }
    >
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
        <Table
          className='table-equipment-performance'
          loading={loading}
          columns={columns}
          dataSource={data.map((e, i) => ({ ...e, key: i }))}
          bordered
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) => {
              fetchData(page, pageSize);
            },
          }}
          scroll={{ x: 2000, y: getTableHeight() ?? '100%' }}
          size="small"
        />
      </div>
    </Card>
  );
};

export default EquipmentPerformanceTable;
