// src/pages/UI/Manufacturing/ManufacturingHistory.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import FinishedProductWarehouseSidebar from './FinishedProductWarehouseSidebar';
import FinishedProductWarehouseTable from './FinishedProductWarehouseTable';

const { Content } = Layout;

const FinishedProductWarehouse = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  useEffect(()=>{
    console.log(filters);
  }, [filters])
  return (
    <Layout>
      <FinishedProductWarehouseSidebar onFilter={handleFilter}/>
      <Layout>
        <Content className="site-layout-content">
          <FinishedProductWarehouseTable filters={filters} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default FinishedProductWarehouse;
