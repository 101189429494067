import axiosInstance from "./axiosInstance";

const getMaterialReceipts = (filters) => axiosInstance.get('/material-receipts', { params: filters });
const getMaterialReceiptById = (id) => axiosInstance.get(`/material-receipts/${id}`);
const createMaterialReceipt = (materialReceiptData) => axiosInstance.post('/material-receipts', materialReceiptData);
const updateMaterialReceipt = (id, materialReceiptData) => axiosInstance.put(`/material-receipts/${id}`, materialReceiptData);
const deleteMaterialReceipt = (id) => axiosInstance.delete(`/material-receipts/${id}`);

const getMaterialReceiptItems = (filters) => axiosInstance.get('/material-receipt-items', { params: filters });
const deleteMaterialReceiptItem = (id) => axiosInstance.delete(`/material-receipt-items/${id}`);

export default {
  getMaterialReceiptItems,
  getMaterialReceipts,
  getMaterialReceiptById,
  createMaterialReceipt,
  updateMaterialReceipt,
  deleteMaterialReceipt,
  deleteMaterialReceiptItem,
};
