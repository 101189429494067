// src/pages/UI/ProductManagement/ProductManagement.js
import React, { useState } from 'react';
import { Layout } from 'antd';
import ProductManagementSidebar from './ProductManagementSidebar';
import ProductManagementTable from './ProductManagementTable';

const { Content } = Layout;

const ProductManagement = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };

  return (
    <Layout>
      <ProductManagementSidebar onFilter={handleFilter} />
      <Content className="site-layout-content">
        <div style={{height: "100%"}}>
          <ProductManagementTable filters={filters} />
        </div>
      </Content>
    </Layout>
  );
};

export default ProductManagement;
