import axiosInstance from "./axiosInstance";

const getBOMs = (filters) => axiosInstance.get('/boms', { params: filters });
const getBOMById = (id) => axiosInstance.get(`/boms/${id}`);
const createBOM = (BOMData) => axiosInstance.post('/boms', BOMData);
const updateBOM = (id, BOMData) => axiosInstance.put(`/boms/${id}`, BOMData);
const deleteBOM = (id) => axiosInstance.delete(`/boms/${id}`);

export default {
  getBOMs,
  getBOMById,
  createBOM,
  updateBOM,
  deleteBOM,
};
