// src/pages/UI/CustomerManagement/CustomerManagement.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import CustomerSidebar from './CustomerSidebar';
import CustomerTable from './CustomerTable';

const { Header, Content } = Layout;

const CustomerManagement = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  // useEffect(()=>{
  //   console.log(filters);
  // }, [filters])
  return (
      <Layout>
         <CustomerSidebar onFilter={handleFilter}/>
        <Content className="site-layout-content">
          <CustomerTable filters={filters}/>
        </Content>
      </Layout>
  );
};

export default CustomerManagement;
