import { Card, message } from 'antd';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../api/axiosInstance';

const TaktTimeCycleTimeChart = ({ filters }) => {
    const [data, setData] = useState([]);
    const [dataDate, setDataDate] = useState([]);
    const [dataProduct, setDataProduct] = useState([]);
    //Test get data from api
    useEffect(() => {
        fetchData({ ...filters });
    }, [filters]);

    const [chartOptions, setChartOptions] = useState(null);

    const getDatesInRange = (startDate, endDate) => {
        const dates = [];
        let currentDate = startDate;

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
            dates.push(currentDate.format("DD/MM"));
            currentDate = currentDate.add(1, "day");
        }

        return dates;
    };

    useEffect(() => {
        const result = [];
        if (filters?.start_time && filters?.end_time) {
            const dates = getDatesInRange(dayjs(filters.start_time), dayjs(filters.end_time));
            dates.forEach(date => {
                const findData = data.find((item) => dayjs(item.date).format('DD/MM') === date);
                if (findData) result.push({ date: dayjs(findData.date).format('DD/MM'), total_time_seconds: Number(findData.total_time_seconds), total_bag_number: Number(findData.total_bag_number) });
                else result.push({ date, total_time_seconds: 0, total_bag_number: 0 });
            });
        } else {
            const dates = getDatesInRange(dayjs().subtract(7, 'days'), dayjs());
            dates.forEach(date => {
                const findData = data.find((item) => dayjs(item.date).format('DD/MM') === date);
                if (findData) result.push({ date: dayjs(findData.date).format('DD/MM'), total_time_seconds: Number(findData.total_time_seconds), total_bag_number: Number(findData.total_bag_number) });
                else result.push({ date, total_time_seconds: 0, total_bag_number: 0 });
            });
        }

        const categories = result.map(item => item.date);
        const timeData = result.map(item => item.total_time_seconds);
        const numberData = result.map(item => item.total_bag_number);
        console.log(result);

        setChartOptions({
            chart: {
                zoomType: 'xy',
                height: 300
            },
            title: {
                text: ' '
            },
            xAxis: {
                categories: categories,
                crosshair: true
            },
            yAxis: [
                {
                    min: 0,
                    title: {
                        text: 'Standard'
                    }
                },
                {
                    title: {
                        text: 'Takt time'
                    },
                    opposite: true,
                    labels: {
                        format: '{value}'
                    }
                }
            ],
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>'
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    pointWidth: 30
                },
                line: {
                    dataLabels: {
                        enabled: true,
                        format: '{y:.2f}'
                    },
                    enableMouseTracking: true
                }
            },
            series: [
                {
                    name: 'Standard',
                    type: 'column',
                    data: timeData,
                    color: '#007bff'
                },
                {
                    name: 'Takt time',
                    type: 'line',
                    yAxis: 1,
                    data: numberData,
                    color: '#FFA500',
                    marker: {
                        enabled: true,
                        radius: 5
                    },
                    lineWidth: 2,
                    tooltip: {
                        valueSuffix: ''
                    }
                }
            ],
            credits: { enabled: false }
        });
    }, [data]);

    const fetchData = async (filters) => {
        try {
            if (filters?.start_time) filters.start_time = dayjs(filters.start_time).format('YYYY-MM-DD');
            if (filters?.end_time) filters.end_time = dayjs(filters.end_time).format('YYYY-MM-DD');

            const response = await axiosInstance.get('/lot-histories-cycle-time-summary', { params: filters }) // Thay đổi endpoint theo API của bạn
            if (response?.success) {
                setData(response.data ?? []);
            }
        } catch (error) {
            message.error(error.message);
        }
    };

    return (
        <Card title="">
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </Card>
    );
};

export default TaktTimeCycleTimeChart;
