// src/pages/UI/EquipmentManagement/EquipmentTable.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, message, Upload, Popconfirm, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import axiosInstance from '../../../api/axiosInstance'; // Import axiosInstance
import equipmentApi from '../../../api/equipmentManagementApi';
import config from '../../../config/config';
import processManagementApi from '../../../api/processManagementApi';
import { b64toBlob } from '../../../utils/dataBlob';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import AuthContext from '../../../context/AuthContext';

const EquipmentManagementTable = ({ filters }) => {
    const { user } = useContext(AuthContext);
    const [permissions, setPermissions] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [form] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState(null);
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: config.pageSize,
        total: 0,
    });
    const tableHeight = useRef('71vh');

    useEffect(() => {
        fetchData({ ...filters, page: 1, pageSize: config.pageSize });
    }, [filters]);

    useEffect(() => {
        setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
    }, []);

    const [processes, setProcesses] = useState([]);

    useEffect(() => {
        if (isModalVisible) {
            getProcesses();
        }
    }, [isModalVisible]);

    const [loadingProcesses, setLoadingProcesses] = useState(false);
    const getProcesses = async () => {
        setLoadingProcesses(true);
        const res = await processManagementApi.getProcesses();
        if (res.success) setProcesses(res.data.data.map(e => ({ ...e, value: e.id, label: e.process_name })))
        setLoadingProcesses(false);
    }

    const fetchData = async (filters) => {
        try {
            setLoading(true);
            const response = await equipmentApi.getEquipments(filters); // Thay đổi endpoint theo API của bạn
            if (response.success) {
                setData(response.data.data);
                response.data?.pagination && setPagination(response.data.pagination)
            }
            setTimeout(() => {
                tableHeight.current = CalculateTableHeight('table-equipment');
            }, 300);
            setLoading(false);
        } catch (error) {
            message.error('Failed to fetch equipment');
        }
    };
    const handleUploadChange = (info) => {
        try {
            if (info.file.status === 'uploading') {
                setUploading(true);
            } else {
                if (info.file.status === 'done') {
                    if (!info.file?.response?.success)
                        throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
                    fetchData({ ...filters, ...pagination });
                    message.success(`${info.file.name} - Upload thành công`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} - Upload thất bại`);
                }
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setUploading(false);
        }
    };
    const uploadProps = {
        name: 'file',
        accept: '.xlsx, .xls',
        action: config.apiUrl + '/import-equipments', // Đường dẫn API để xử lý upload file
        onChange: handleUploadChange,
        showUploadList: false, // Ẩn danh sách file
    };

    const showModal = () => {
        setIsEditMode(false);
        setEditingRecord(null);
        setIsModalVisible(true);
    };

    const showEditModal = (record) => {
        setIsEditMode(true);
        setEditingRecord(record);
        form.setFieldsValue(record);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        form.validateFields().then(async (values) => {
            try {
                if (isEditMode) {
                    const response = await axiosInstance.put(`/equipments/${editingRecord.id}`, values); // Thay đổi endpoint theo API của bạn
                    if (response.success) fetchData({ ...filters, ...pagination });
                    // message.success('Cập nhật thiết bị thành công');
                } else {
                    const response = await axiosInstance.post('/equipments', values); // Thay đổi endpoint theo API của bạn
                    if (response.success) fetchData({ ...filters, ...pagination });
                    // message.success('Thêm thiết bị mới thành công');
                }
                setIsModalVisible(false);
                form.resetFields();
            } catch (error) {
                message.error('Failed to save equipment');
            }
        }).catch((error) => {
            console.log(error);
        });
    };
    const handleDelete = async (id) => {
        try {
            const response = await axiosInstance.delete(`/equipments/${id}`); // Thay đổi endpoint theo API của bạn
            if (response.success) fetchData({ ...filters, ...pagination });
            // message.success('Xóa thiết bị thành công');
        } catch (error) {
            message.error('Failed to delete equipment');
        }
    };
    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    const columns = [
        {
            title: 'STT',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
            render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
        },
        {
            title: 'Mã thiết bị',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Tên thiết bị',
            dataIndex: 'equipment_name',
            key: 'equipment_name',
            align: 'center',
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {permissions.includes('equipment.update') && (<Button type='primary' icon={<EditOutlined />} onClick={() => showEditModal(record)} size='small' />)}
                    {permissions.includes('equipment.delete') && (<Popconfirm
                        title="Bạn có chắc chắn muốn xóa thiết bị này?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button icon={<DeleteOutlined />} type='primary' danger size='small' />
                    </Popconfirm>)}
                </Space>
            ),
            align: 'center',
        },
    ];

    const handleExportExcel = async () => {
        setLoading(true);
        const response = await axiosInstance.get(config.apiUrl + '/equipments-export');
        if (response?.success) {
            const { data, type, file } = response.data;
            // Download
            if (data && type && file) {
                const blob = b64toBlob(data, type)
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = file
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } else message.error('Data invalid!');
        }
        setLoading(false);
    }

    return (
        <Card
            style={{ height: "100%" }}
            title="Quản lý thiết bị"
            extra={
                <div className="table-actions">
                    {permissions.includes('equipment.create') && (<>
                        <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel}>Xuất file</Button>
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                                {uploading ? 'Đang tải lên' : 'Upload Excel'}
                            </Button>
                        </Upload>
                        <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm thiết bị</Button>
                    </>)}
                </div>
            }
        >
            <Table
                className='table-equipment'
                loading={loading}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: (page, pageSize) => {
                        fetchData({ ...filters, page, pageSize });
                    }
                }}
                bordered  // Thêm thuộc tính bordered để có viền
                size="small"  // Sử dụng kích thước bảng nhỏ
                scroll={{ y: tableHeight.current }}
            />

            <Modal
                title={
                    <div className="modal-title">
                        {isEditMode ? "Chỉnh sửa thiết bị" : "Thêm thiết bị"}
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Lưu lại"
                cancelText="Thoát"
                width={800}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <div className="modal-form-grid">
                        <Form.Item name="id" label="Mã thiết bị" rules={[{ required: true, message: 'Vui lòng nhập mã thiết bị!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="equipment_name" label="Tên thiết bị" rules={[{ required: true, message: 'Vui lòng nhập tên thiết bị!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="process_id" label="Công đoạn" rules={[{ required: true, message: 'Vui lòng chọn công đoạn!' }]}>
                            <Select options={processes} optionFilterProp='label' showSearch loading={loadingProcesses} />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </Card>
    );
};

export default EquipmentManagementTable;
