// src/pages/UI/MaterialManagement/MaterialSidebar.js
import React, { useState } from 'react';
import { Layout, Tree, DatePicker, Input, Button, Form, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/locale/vi_VN';
import { ConfigProvider } from 'antd';
import UISidebar from '../components/Sidebar';

dayjs.locale('vi');

const { Sider } = Layout;

const treeData = [
  {
    title: 'Loại nguyên vật liệu A',
    key: '0-0',
  },
  {
    title: 'Loại nguyên vật liệu B',
    key: '0-1',
  },
];

const SemiFinishedProductSidebar = ({onFilter}) => {
  const [pickerType, setPickerType] = useState('date');

  const formFields = [
    {
      type: 'Input',
      label: 'Mã BTP',
      name: 'id',
      placeholder: 'Mã BTP',
    },
    {
      type: 'Input',
      label: 'Tên BTP',
      name: 'material_name',
      placeholder: 'Tên BTP',
    },
    {
      type: 'DatePicker',
      label: 'Thời gian truy vấn',
      name: 'start_date',
      placeholder: 'Ngày bắt đầu',
      format: pickerType === 'week' ? 'wo [tuần] YYYY' : (pickerType === 'month' ? 'MM/YYYY' : 'DD/MM/YYYY'),
      className: 'mb-1'
    },
    {
      type: 'DatePicker',
      label: '',
      name: 'end_date',
      placeholder: 'Ngày kết thúc',
      format: pickerType === 'week' ? 'wo [tuần] YYYY' : (pickerType === 'month' ? 'MM/YYYY' : 'DD/MM/YYYY'),
    },
    // Add other fields here
  ];

  return (
    <UISidebar formFields={formFields} onFilter={onFilter}/>
  );
};

export default SemiFinishedProductSidebar;
