import axiosInstance from "./axiosInstance";

const getAccounts = (filters) => axiosInstance.get('/users', { params: filters });
const getAccountById = (id) => axiosInstance.get(`/users/${id}`);
const createAccount = (accountData) => axiosInstance.post('/users', accountData);
const updateAccount = (id, accountData) => axiosInstance.put(`/users/${id}`, accountData);
const deleteAccount = (id) => axiosInstance.delete(`/users/${id}`);

export default {
  getAccounts,
  getAccountById,
  createAccount,
  updateAccount,
  deleteAccount,
};
