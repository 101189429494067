import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Space, Upload, message } from 'antd';
import config from '../../../../config/config';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { render } from '@testing-library/react';
import axiosInstance from '../../../../api/axiosInstance';
import ExportExcelButton from '../../../../components/ExportExcelButton';
import { b64toBlob } from '../../../../utils/dataBlob';
import CalculateTableHeight from '../../../../components/calculateTableHeight';

const EquipmentMaintenancePlanTable = ({ filters }) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [uploading, setUploading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate);
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  const fetchData = async (params) => {
    const response = await axiosInstance.get('/maintenance-plan', { params });
    console.log(response);
    setData(response?.data.data ?? []);
    setPagination({ ...pagination, total: response?.data?.data?.length });
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Thực hiện thất bại`);
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(info.file?.response?.message);
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-maintenance-plan', // Thay đổi endpoint theo API của bạn
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'), // Thêm token vào header
    },
    onChange: handleUploadChange,
    showUploadList: false,
  };

  const columns = [
    { title: 'STT', dataIndex: 'stt', align: 'center', width: 70, render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1 },
    { title: 'Tên thiết bị', dataIndex: 'equipment_name', align: 'center', width: 110, render: (_, record) => record?.equipment?.equipment_name },
    { title: 'Mã số', dataIndex: 'plan_code', align: 'center', width: 100 },
    { title: 'Đơn vị sử dụng', dataIndex: 'using_unit', align: 'center', width: 140 },
    { title: 'Ngày bảo dưỡng', dataIndex: 'maintenance_date', align: 'center', width: 140, render: (text) => dayjs(text).format('DD/MM/YYYY') },
    { title: 'Bắt đầu', dataIndex: 'start_time', align: 'center', width: 80, render: (value) => value ? dayjs(value).format('HH:mm') : '-' },
    { title: 'Kết thúc', dataIndex: 'end_time', align: 'center', width: 80, render: (value) => value ? dayjs(value).format('HH:mm') : '-' },
    { title: 'Đơn vị bảo dưỡng', dataIndex: 'maintenance_unit', align: 'center', width: 140 },
    { title: 'Người bảo dưỡng', dataIndex: 'maintenance_person', align: 'center', width: 140, render: (_, record) => record?.user?.name },
    { title: 'Ghi chú', dataIndex: 'notes', align: 'center', width: 140 }
  ];

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/maintenance-plan/export');
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  const fakeData = [];
  const pageSize = 10;
  

  for (let i = 0; i < pageSize; i++) {
    fakeData.push({
      stt: i + 1,
      equipment: { equipment_name: `Máy 02` },
      plan_code: `MS${1000 + i}`,
      using_unit: `Đơn vị ${i + 1}`,
      maintenance_date: dayjs('2024-07-30'),
      maintenance_unit: `Đơn vị bảo dưỡng`,
      user: { name: `User` },
      notes: `-`
    });
  }

  return (
    <Card
      style={{height: '100%'}}
      title="Kế hoạch bảo dưỡng thiết bị"
      extra={
        <Space>
          <Button target="_blank" href={config.apiUrl.replace('/api', '') + '/excel/MaintenancePlan.xlsx'} icon={<DownloadOutlined />}>Tải excel mẫu</Button>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
              {uploading ? 'Đang tải lên' : 'Upload Excel'}
            </Button>
          </Upload>
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel}>Xuất Excel</Button>
        </Space>
      }
    >
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
        <Table
          className='table-maintenance'
          loading={loading}
          columns={columns}
          // dataSource={data.length > 0 ? data?.map((e, i) => ({ ...e, key: i })) : fakeData}
          dataSource={data?.map((e, i) => ({ ...e, key: i }))}
          bordered
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, page, pageSize });
              fetchData({ page, pageSize });
            },
          }}
          scroll={{ x: 1300, y: CalculateTableHeight('table-maintenance') }}
          size="small"
        />
      </div>
    </Card>
  );
};

export default EquipmentMaintenancePlanTable;
