import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Space, message, Tabs, Tag } from 'antd';
import config from '../../../../config/config';
import dayjs from 'dayjs';
import { getWarehouseHistories } from '../../../../api/warehouseHistoryApi';
import TabPane from 'antd/es/tabs/TabPane';
import warehouseEntryManagementApi from '../../../../api/warehouseEntryManagementApi';
import { numberFormat } from '../../../../utils/numberFormat';

const SparepartWarehouseTable = ({ filters }) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [paginationEntry, setPaginationEntry] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const [data, setData] = useState([]);
  const [dataEntry, setDataEntry] = useState([]);

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    const paginateEntry = { ...paginationEntry, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
    fetchWarehouseInventory({ ...filters, ...paginateEntry });
  }, [filters]);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      filters.model = 'pallet';
      filters.material_category = 'CCDC'; // Kho tieu hao
      if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
      if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');
      
      const response = await getWarehouseHistories(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data.data ?? []);
      response.data?.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchWarehouseInventory = async (filters) => {
    try {
      filters.model = 'pallet';
      filters.material_category = 'CCDC'; // Kho tieu hao
      const response = await warehouseEntryManagementApi.getWarehouseEntriesInventory(filters); // Thay đổi endpoint theo API của bạn
      setDataEntry(response.data.data ?? []);
      response.data?.pagination && setPaginationEntry(response.data.pagination)
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    { title: 'STT', dataIndex: 'stt', align: 'center', width: '50px', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1 },
    { title: 'Loại', dataIndex: 'type', align: 'center', width: '80px', render: (value) => value === 'export' ? <Tag color="#2DB7F5">XUẤT</Tag> : <Tag color="#F50">NHẬP</Tag> },
    { title: 'Vị trí', dataIndex: 'warehouse_location_id', align: 'center', width: '80px' },
    { title: 'Mã Pallet', dataIndex: 'warehousehistoryable_id', align: 'center', width: '120px' },
    { title: 'Tên vật tư tiêu hao', dataIndex: 'material_name', align: 'center', width: '150px', render: (_, record) => record?.pallet?.material?.material_name },
    { title: 'Số lượng', dataIndex: 'quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Tồn', dataIndex: 'inventory_quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Đơn vị tính', dataIndex: 'unit_name', align: 'center', width: '100px', render: (_, record) => record?.unit?.unit_name },
    { title: 'Thời gian', dataIndex: 'created_at', align: 'center', width: '100px' },
    { title: 'Người tạo', dataIndex: 'created_by', align: 'center', width: '100px', render: (_, record) => record?.created_by?.name }
  ];
  
  const columnsEntry = [
    { title: 'STT', dataIndex: 'stt', align: 'left', width: '50px', render: (_, record, index) => ((paginationEntry.page - 1) * paginationEntry.pageSize) + index + 1 },
    // { title: 'Vị trí', dataIndex: 'warehouse_location_id', align: 'left', width: '80px' },
    { title: 'Mã vật tư', dataIndex: 'material_name', align: 'left', width: '100px', render: (_, record) => record?.material?.material_name },
    { title: 'Tên vật tư tiêu hao', dataIndex: 'material_name', align: 'left', width: '150px', render: (_, record) => record?.material?.material_name },
    { title: 'Số lượng', dataIndex: 'quantity', align: 'left', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Đơn vị tính', dataIndex: 'unit_name', align: 'left', width: '100px', render: (_, record) => record?.unit?.unit_name },
  ];
  

  return (
    <Tabs defaultActiveKey="1" type="card" style={{ width: '100%' }} className='custom-oi-tabs' animated={false} moreIcon={null}>
      <TabPane tab="Lịch sử nhập/xuất kho tiêu hao" key="1">
        <Table
          className="table-mh70"
          loading={loading}
          columns={columns}
          dataSource={data.map((e, key) => ({ ...e, key }))}
          bordered
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, page, pageSize });
              fetchData({ ...filters, page, pageSize });
            }
          }}
          scroll={{ x: '880px' }}
          size="small"
        />
      </TabPane>
      <TabPane tab="Tồn kho tiêu hao" key="2">
        <Table
          className="table-mh70"
          loading={loading}
          columns={columnsEntry}
          dataSource={dataEntry.map((e, key) => ({ ...e, key }))}
          bordered
          pagination={{
            ...paginationEntry,
            onChange: (page, pageSize) => {
              setPagination({ ...paginationEntry, page, pageSize });
              fetchWarehouseInventory({ page, pageSize });
            },
          }}
          scroll={{ x: '500px' }}
          size="small"
        />
      </TabPane>
    </Tabs>
  );
};

export default SparepartWarehouseTable;
