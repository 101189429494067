import React, { useState } from 'react';
import { Table, Button, Card, Space, Modal, Row, Col } from 'antd';
import './POPlanTable.css';
import config from '../../../../config/config';
import dayjs from 'dayjs';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const POPlanTable = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const overallManufactureHistoryColumn = [
    {
      title: 'Số lượng đầu ra KH',
      dataIndex: 'sl_dau_ra_kh',
    },
    {
      title: 'Số lượng đầu ra thực tế',
      dataIndex: 'sl_dau_ra_tt',
    },
    {
      title: 'Chênh lệch (TT - KH)',
      dataIndex: 'chenh_lech',
    },
    {
      title: 'Tỷ lệ hoàn thành',
      dataIndex: 'compete_percentage',
    },
    {
      title: 'Số lượng NG',
      dataIndex: 'sl_ng',
    },
  ];

  const overallManufactureHistoryData = [
    {
      key: '1',
      sl_dau_ra_kh: '1000',
      sl_dau_ra_tt: '950',
      chenh_lech: '-50',
      compete_percentage: '95%',
      sl_ng: '50',
    },
  ];

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Ngày cập nhật',
      dataIndex: 'field1',
      key: 'field1',
    },
    {
      title: 'Số CT',
      dataIndex: 'field2',
      key: 'field2',
    },
    {
      title: 'PO',
      dataIndex: 'field3',
      key: 'field3',
    },
    {
      title: 'Loại đơn hàng',
      dataIndex: 'field4',
      key: 'field4',
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'field5',
      key: 'field5',
    },
    {
      title: 'Mã hàng',
      dataIndex: 'field6',
      key: 'field6',
    },
    {
      title: 'Ngày giao hàng',
      dataIndex: 'field7',
      key: 'field7',
    },
    {
      title: 'Số lượng',
      dataIndex: 'field8',
      key: 'field8',
    },
    {
      title: 'Số lượng 2',
      dataIndex: 'field9',
      key: 'field9',
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'field10',
      key: 'field10',
    },
    {
      title: 'CĐ thắt cổ chai',
      dataIndex: 'field11',
      key: 'field11',
    },
    {
      title: 'Ngày dự kiến giao hàng',
      dataIndex: 'field12',
      key: 'field12',
    },
  ];

  const columnsModalDetail = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (_, record, index) => index + 1,
    },
    {
      title: 'PO',
      dataIndex: 'field1',
      key: 'field1',
    },
    {
      title: 'Mã CĐ',
      dataIndex: 'field2',
      key: 'field2',
    },
    {
      title: 'Tên CĐ',
      dataIndex: 'field3',
      key: 'field3',
    },
    {
      title: 'Máy',
      dataIndex: 'field4',
      key: 'field4',
    },
    {
      title: 'Thời gian bắt đầu',
      dataIndex: 'field5',
      key: 'field5',
    },
    {
      title: 'Thời gian kết thúc',
      dataIndex: 'field6',
      key: 'field6',
    },
    {
      title: 'Số lượng',
      dataIndex: 'field7',
      key: 'field7',
    },
    {
      title: 'Số lượng Lot',
      dataIndex: 'field8',
      key: 'field8',
    },
  ];

  const data = Array(100).fill().map((_, key) => {
    return {
      key,
      field1: dayjs().format('DD/MM/YYYY'),
      field2: `DDH10${Math.floor(Math.random() * 900) + 100}A`,
      field3: `DDH10${Math.floor(Math.random() * 900) + 100}A`,
      field4: ['Đơn hàng nội địa', 'Đơn hàng xuất khẩu'][Math.floor(Math.random() * 2)],
      field5: (Math.random() + 1).toString(36).substring(7).toUpperCase(),
      field6: (Math.random() + 1).toString(36).substring(7).toUpperCase(),
      field7: dayjs().format('DD/MM/YYYY'),
      field8: Math.floor(Math.random() * 900) + 100,
      field9: Math.floor(Math.random() * 900) + 100,
      field10: dayjs().format('DD/MM/YYYY'),
      field11: Math.floor(Math.random() * 900) + 100,
      field12: dayjs().format('DD/MM/YYYY'),
    }
  });

  const data2 = Array(10).fill().map((_, key) => {
    return {
      key,
      field1: `DDH10${Math.floor(Math.random() * 900) + 100}A`,
      field2: `DDH10${Math.floor(Math.random() * 900) + 100}A`,
      field3: ['Ép đùn', 'Hóa già', 'Cắt đoạn', 'CND', 'Đột', 'Khắc Laze'][Math.floor(Math.random() * 6)],
      field4: (Math.random() + 1).toString(36).substring(7).toUpperCase(),
      field5: dayjs().format('DD/MM/YYYY'),
      field6: dayjs().format('DD/MM/YYYY'),
      field7: Math.floor(Math.random() * 900) + 100,
      field8: Math.floor(Math.random() * 900) + 100,
    }
  });

  const showModal = (record) => {
    console.log(record)
    setDataSelected(record)
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const navigateToProductionPlan = () => {
    navigate('/ui/production-plan');
  }

  return (
    <Card
      title="Quản lý PO"
      extra={
        <Space>
          <Button icon={<UploadOutlined />}>Upload</Button>
          <Button>Insert</Button>
          <Button>Duplicate</Button>
          <Button>Run</Button>
          <Button type="primary" onClick={navigateToProductionPlan}>Save</Button>
        </Space>
      }
    >
      <Table
        columns={columns.map(e => ({ ...e, align: 'center' }))}
        dataSource={data}
        bordered
        pagination={{ pageSize: 20 }}
        scroll={{ x: true }}
        size='small'
        onRow={(record) => ({
          onClick: () => showModal(record)
        })}
      />

      {/* Modal */}
      <Modal
        title={"Chi tiết PO"}
        open={isModalVisible}
        onCancel={handleCancel}
        okButtonProps={{style: { display: 'none' }}}
        cancelText="Thoát"
        width={'90%'}
      >
        {dataSelected ?
          (
            <div>
              <b>Thông tin chi tiết PO</b>
              <Row className="custom-row">
                <Col className="custom-col-main" span={4}>Mã PO</Col>
                <Col className="custom-col" span={8}>{dataSelected?.field3}</Col>
                <Col className="custom-col-main" span={4}>Mã CT</Col>
                <Col className="custom-col" span={8}>{dataSelected?.field4}</Col>
                <Col className="custom-col-main" span={4}>Mã sản phẩm</Col>
                <Col className="custom-col" span={8}>{dataSelected?.field5}</Col>
                <Col className="custom-col-main" span={4}>Tên sản phẩm</Col>
                <Col className="custom-col" span={8}>{dataSelected?.field6}</Col>
                <Col className="custom-col-main" span={4}>Ngày Upload</Col>
                <Col className="custom-col" span={8}>{dataSelected?.field7}</Col>
                <Col className="custom-col-main" span={4}>Số lượng</Col>
                <Col className="custom-col" span={8}>{dataSelected?.field8}</Col>
                <Col className="custom-col-main" span={4}>Mô tả</Col>
                <Col className="custom-col" span={20}>{dataSelected?.field9}</Col>
              </Row>

              <b>Thông tin thời gian sản xuất</b>
              <Row className="custom-row">
                <Col className="custom-col-main" span={4}>Ngày&Giờ lập kế hoạch</Col>
                <Col className="custom-col" span={4}>{dataSelected?.field3}</Col>
                <Col className="custom-col-main" span={4}>Ngày&Giờ xác định KH</Col>
                <Col className="custom-col" span={4}>{dataSelected?.field4}</Col>
                <Col className="custom-col-main" span={4}>-</Col>
                <Col className="custom-col" span={4}>{dataSelected?.field5}</Col>
                <Col className="custom-col-main" span={4}>Ngày&Giờ bắt đầu SX</Col>
                <Col className="custom-col" span={4}>{dataSelected?.field6}</Col>
                <Col className="custom-col-main" span={4}>Ngày&Giờ hoàn thành SX</Col>
                <Col className="custom-col" span={4}>{dataSelected?.field7}</Col>
                <Col className="custom-col-main" span={4}>Leadtime(hr)</Col>
                <Col className="custom-col" span={4}>{dataSelected?.field8}</Col>
              </Row>

              <b>Thông tin chi tiết các công đoạn sản xuất</b>
              <Table
                className="custom-table-po"
                columns={columnsModalDetail.map(e => ({ ...e, align: 'center' }))}
                dataSource={data2}
                bordered
                pagination={false}
                scroll={{ x: true, y: '250px' }}
                size='small'
                onScroll={(event) => {
                  const {scrollTop, clientHeight, scrollHeight} = event.target
                  const check = scrollHeight - scrollTop === clientHeight
                  console.log(scrollTop, clientHeight, scrollHeight, check)
                }}
              />
            </div>
          ) : (
            <p>Không có dữ liệu</p>
          )}

      </Modal>
    </Card>
  );
};

export default POPlanTable;
