import React, { useEffect, useState } from 'react';
import errorEquipmentApi from '../../../../api/errorEquipmentManagementApi';
import { Button, Form, Input, Modal, AutoComplete, Table, message } from 'antd';
import errorEquipmentLog from '../../../../api/errorEquipmentLog';
import dayjs from 'dayjs';

const ErrorSelectionTab = ({ equipmentId }) => {
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorOptions, setErrorOptions] = useState([]);
  const [isFieldDisabled, setIsFieldDisabled] = useState({
    error_equipment_id: false,
    error_equipment_name: false,
    reason: false,
    fix: false,
    protection_measures: false,
  });

  useEffect(() => {
    if (equipmentId) {
      fetchData({ equipment_id: equipmentId });
    }
  }, [equipmentId]);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await errorEquipmentLog.getErrorEquipmentLogs(filters);
      setData(response.data.data ?? []);
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch error equipment data');
      setLoading(false);
    }
  };

  const fetchErrorOptions = async () => {
    try {
      const response = await errorEquipmentApi.getErrorEquipments();
      setErrorOptions(response.data.data ?? []);
    } catch (error) {
      message.error('Failed to fetch error options');
    }
  };

  useEffect(() => {
    fetchErrorOptions();
  }, []);

  const handleRowClick = (record) => {
    setSelectedRowData(record);
  };

  const handleSaveClick = () => {
    setIsModalVisible(true);
    modalForm.resetFields(); // Reset form fields
  };

  const handleModalOk = async () => {
    try {
      const values = await modalForm.validateFields();
      values.equipment_id = equipmentId;
      values.id = selectedRowData.id;
      await errorEquipmentLog.createErrorEquipmentLog(values);
      setSelectedRowData(values);
      setIsModalVisible(false);
      fetchData({ equipment_id: equipmentId });
    } catch (error) {
      message.error('Failed to save data');
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSelect = (value) => {
    const selectedError = errorOptions.find(option => option.id === value);
    if (selectedError) {
      modalForm.setFieldsValue({
        error_equipment_name: selectedError.error_equipment_name,
        reason: selectedError.reason,
        fix: selectedError.fix,
        protection_measures: selectedError.protection_measures,
      });
      setIsFieldDisabled({
        error_equipment_name: true,
        reason: true,
        fix: true,
        protection_measures: true,
      });
    }
  };

  const handleSelectName = (value) => {
    const selectedError = errorOptions.find(option => option.error_equipment_name === value);
    if (selectedError) {
      modalForm.setFieldsValue({
        error_equipment_id: selectedError.id,
        reason: selectedError.reason,
        fix: selectedError.fix,
        protection_measures: selectedError.protection_measures,
      });
      setIsFieldDisabled({
        error_equipment_id: true,
        reason: !!selectedError.reason,
        fix: !!selectedError.fix,
        protection_measures: !!selectedError.protection_measures,
      });
    }
  };

  const handleChange = (value) => {
    if (!value) {
      modalForm.resetFields(['error_equipment_name', 'reason', 'fix', 'protection_measures']);
      setIsFieldDisabled({
        error_equipment_id: false,
        error_equipment_name: false,
        reason: false,
        fix: false,
        protection_measures: false,
      });
    }
  };

  const handleChangeName = (value) => {
    if (!value) {
      modalForm.resetFields(['error_equipment_id', 'reason', 'fix', 'protection_measures']);
      setIsFieldDisabled({
        error_equipment_id: false,
        error_equipment_name: false,
        reason: false,
        fix: false,
        protection_measures: false,
      });
    }
  };

  const columns = [
    {
      title: 'Thời gian dừng',
      dataIndex: 'stop_time',
      key: 'stop_time',
      align: 'center',
      width: '120px',
      render: () => selectedRowData.stop_time ? dayjs(selectedRowData.stop_time).format('DD/MM/YYYY HH:mm') : '',
    },
    {
      title: 'Thời gian chạy',
      dataIndex: 'start_time',
      key: 'start_time',
      align: 'center',
      width: '120px',
      render: () => selectedRowData.start_time ? dayjs(selectedRowData.start_time).format('DD/MM/YYYY HH:mm') : '',
    },
    {
      title: 'Mã lỗi',
      dataIndex: 'error_equipment_id',
      key: 'error_equipment_id',
      align: 'center',
      width: '120px',
      render: () => selectedRowData.error_equipment_id || '',
    },
    {
      title: 'Tên lỗi',
      dataIndex: 'error_equipment_name',
      key: 'error_equipment_name',
      align: 'center',
      width: '120px',
      render: () => selectedRowData.equipment_error?.error_equipment_name || '',
    },
    {
      title: 'Nguyên nhân',
      dataIndex: 'reason',
      key: 'reason',
      align: 'center',
      width: '120px',
      render: () => selectedRowData.equipment_error?.reason || '',
    },
    {
      title: 'Tác vụ',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: '90px',
      render: () => <Button onClick={handleSaveClick}>Nhập lỗi</Button>,
    },
  ];

  const columnsTable = [
    {
      title: 'STT',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: '50px',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Thời gian dừng',
      dataIndex: 'stop_time',
      key: 'stop_time',
      align: 'center',
      width: '150px',
      render: (value) => value ? dayjs(value).format('DD/MM/YYYY HH:mm') : '',
    },
    {
      title: 'Thời gian chạy',
      dataIndex: 'start_time',
      key: 'start_time',
      align: 'center',
      width: '150px',
      render: (value) => value ? dayjs(value).format('DD/MM/YYYY HH:mm') : '',
    },
    {
      title: 'Mã lỗi',
      dataIndex: 'error_equipment_id',
      key: 'error_equipment_id',
      align: 'center',
      width: '110px',
    },
    {
      title: 'Tên lỗi',
      dataIndex: 'error_equipment_name',
      key: 'error_equipment_name',
      align: 'center',
      width: '140px',
      render: (value, record) => record.equipment_error?.error_equipment_name,
    },
    {
      title: 'Nguyên nhân',
      dataIndex: 'reason',
      key: 'reason',
      align: 'center',
      width: '160px',
      render: (value, record) => record.equipment_error?.reason,
    },
    {
      title: 'Cách khắc phục',
      dataIndex: 'fix',
      key: 'fix',
      align: 'center',
      width: '180px',
      render: (value, record) => record.equipment_error?.fix,
    },
    {
      title: 'Phòng ngừa',
      dataIndex: 'protection_measures',
      key: 'protection_measures',
      align: 'center',
      width: '180px',
      render: (value, record) => record.equipment_error?.protection_measures,
    },
  ];

  useEffect(() => {
    const interval = setInterval(async () => {
      if (equipmentId) {
        const response = await errorEquipmentLog.getErrorEquipmentLogs({ equipment_id: equipmentId });
        setData(response.data?.data ?? []);
      }
    }, 10000);
    return () => clearInterval(interval);
  });

  return (
    <div>
      <Form form={form} layout="vertical" name="form_in_modal">
        <Table
          className="mb-3"
          loading={loading}
          dataSource={[selectedRowData]}
          columns={columns}
          pagination={false}
          scroll={{ x: '750px' }}
          bordered
          size="small"
        />
      </Form>
      <Table
        loading={loading}
        columns={columnsTable}
        dataSource={data.map((record, key) => ({ key, ...record }))}
        scroll={{ x: '1150px', y: '38vh' }}
        bordered
        size="small"
        pagination={false}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
      <Modal
        title="Nhập thông tin lỗi dừng máy"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Lưu"
        cancelText="Hủy"
      >
        <Form form={modalForm} layout="vertical">
          <Form.Item
            name="error_equipment_id"
            label="Mã lỗi"
            rules={[{ required: true, message: 'Vui lòng nhập mã lỗi' }]}
          >
            <AutoComplete
              disabled={isFieldDisabled.error_equipment_id}
              options={errorOptions.map(option => ({ label: option.id, value: option.id }))}
              placeholder="Chọn hoặc nhập mã lỗi"
              onSelect={handleSelect}
              onChange={handleChange}
              filterOption={(inputValue, option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            name="error_equipment_name"
            label="Tên lỗi"
            rules={[{ required: true, message: 'Vui lòng nhập tên lỗi' }]}
          >
            <AutoComplete
              disabled={isFieldDisabled.error_equipment_name}
              options={errorOptions.map(option => ({ label: option.error_equipment_name, value: option.error_equipment_name }))}
              placeholder="Chọn hoặc nhập tên lỗi"
              onSelect={handleSelectName}
              onChange={handleChangeName}
              filterOption={(inputValue, option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            name="reason"
            label="Nguyên nhân"
          >
            <Input disabled={isFieldDisabled.reason} placeholder="Nguyên nhân" />
          </Form.Item>
          <Form.Item
            name="fix"
            label="Cách khắc phục"
          >
            <Input disabled={isFieldDisabled.fix} placeholder="Cách khắc phục" />
          </Form.Item>
          <Form.Item
            name="protection_measures"
            label="Phòng ngừa"
          >
            <Input disabled={isFieldDisabled.protection_measures} placeholder="Phòng ngừa" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ErrorSelectionTab;
