import axiosInstance from "./axiosInstance";

const getProcesses = (filters) => axiosInstance.get('/processes', { params: filters });
const getProcessById = (id) => axiosInstance.get(`/processes/${id}`);
const createProcess = (processData) => axiosInstance.post('/processes', processData);
const updateProcess = (id, processData) => axiosInstance.put(`/processes/${id}`, processData);
const deleteProcess = (id) => axiosInstance.delete(`/processes/${id}`);

export default {
  getProcesses,
  getProcessById,
  createProcess,
  updateProcess,
  deleteProcess,
};
