// src/pages/UI/Manufacturing/ManufacturingSidebar.js
import React, { useState } from 'react';
import { Layout, Tree, DatePicker, Button, Form, Divider, Select, ConfigProvider, Radio, Input } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../components/Sidebar';

dayjs.locale('vi');

const formFields = [
  {
    type: 'DatePicker',
    label: 'Thời gian dừng máy',
    name: 'start_time',
    placeholder: 'Ngày bắt đầu',
    className: 'mb-1'
  },
  {
    type: 'DatePicker',
    name: 'end_date',
    placeholder: 'Ngày kết thúc',
  },
  {
    type: 'Input',
    label: 'Mã khách hàng',
    name: 'customer_id',
    placeholder: 'Nhập mã khách hàng',
  },
  {
    type: 'Input',
    label: 'Mã sản phẩm',
    name: 'product_id',
    placeholder: 'Nhập mã sản phẩm',
  },
  {
    type: 'Input',
    label: 'Tên sản phẩm',
    name: 'product_name',
    placeholder: 'Nhập tên sản phẩm',
  },
  {
    type: 'Input',
    label: 'Số LSX',
    name: 'lo_sx',
    placeholder: 'Nhập số lô sản xuất',
  },
  // Add other fields here
];

const EquipmentSidebar = ({ onFilter }) => {

  return (
    <UISidebar formFields={formFields} onFilter={onFilter}/>
  );
};

export default EquipmentSidebar;
