import { Layout } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import React from 'react';
import UISidebar from '../components/Sidebar';

dayjs.locale('vi');

const { Sider } = Layout;

const treeData = [
  {
    title: 'Loại sản phẩm A',
    key: '0-0',
  },
  {
    title: 'Loại sản phẩm B',
    key: '0-1',
  },
];

const formFields = [
  {
    type: 'Input',
    label: 'Mã NVL/SP',
    name: 'relationship_id',
    placeholder: 'Mã NVL/SP',
  },
  {
    type: 'Select',
    label: 'Phân loại 1',
    name: 'type',
    placeholder: 'IQC/PQC/OQC/DD',
    options: [
      {label: 'IQC', value: 'IQC'},
      {label: 'PQC', value: 'PQC'},
      {label: 'OQC', value: 'OQC'},
      {label: 'DD', value: 'DD'},
    ]
  },
  // Add other fields here
];

const InspectionCriteriaSidebar = ({ onFilter }) => {
  return (
    <UISidebar formFields={formFields} onFilter={onFilter} treeData={treeData} />
  );
};

export default InspectionCriteriaSidebar;
