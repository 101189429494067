// src/pages/UI/Manufacturing/ManufacturingSidebar.js
import React, { useEffect, useState } from 'react';
import { Layout, Tree, DatePicker, Button, Form, Divider, Select, ConfigProvider, Radio, Input } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../../components/Sidebar';
import axiosInstance from '../../../../api/axiosInstance';
import config from '../../../../config/config';

dayjs.locale('vi');

const treeData = [
  {
    title: 'Nhà máy Hoà Bình',
    key: '0-0',
    children: [
      {
        title: 'Cân NVL',
        key: '0-0-0',
      },
      {
        title: 'Trộn',
        key: '0-0-1',
      },
      {
        title: 'Đóng gói',
        key: '0-0-2',
      },
    ],
  },
];

const EquipmentParametersSidebar = ({ onFilter }) => {
  const [equipmentOptions, setEquipmentOptions] = useState([]);

  const formFields = [
    // {
    //   type: 'Tree',
    //   label: '',
    //   name: 'equipment_id',
    //   treeData: treeData,
    // },
    {
      type: 'Select',
      label: 'Thiết bị',
      name: 'equipment_id',
      placeholder: 'Chọn thiết bị',
      options: equipmentOptions,
    },
    {
      type: 'DatePicker',
      label: 'Thời gian bắt đầu',
      name: 'start_time',
      placeholder: 'Thời gian bắt đầu',
    },
    {
      type: 'DatePicker',
      label: 'Thời gian kết thúc',
      name: 'end_time',
      placeholder: 'Thời gian kết thúc',
    },
    // Add other fields here
  ];

  const fetchEquipment = async () => {
    const response = await axiosInstance.get(config.apiUrl + '/equipments');
    if (response?.success) {
      setEquipmentOptions((response.data?.data ?? []).map((item) => {
        return { label: item?.equipment_name, value: item?.id };
      }));
    }
  }

  useEffect(() => {
    fetchEquipment();
  }, []);


  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default EquipmentParametersSidebar;
