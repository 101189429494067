import axiosInstance from "./axiosInstance";

const headers = {
  'Content-Type': 'multipart/form-data'
};

const getCompanyConfig = (filters) => axiosInstance.get('/company-config');
const createCompanyConfig = (data) => axiosInstance.post('/company-config', data, {headers});
const updateCompanyConfig = (data) => axiosInstance.put(`/company-config`, data);

export {
  getCompanyConfig,
  createCompanyConfig,
  updateCompanyConfig,
};
