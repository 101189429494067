import { Layout } from 'antd';
import React, { useState } from 'react';
import InspectionCriteriaSidebar from './InspectionCriteriaSidebar';
import InspectionCriteriaTable from './InspectionCriteriaTable';

const { Header, Content } = Layout;

const InspectionCriteriaManagement = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };

  return (
    <Layout>
      <InspectionCriteriaSidebar onFilter={handleFilter} />
      <Content className="site-layout-content">
        <InspectionCriteriaTable filters={filters} />
      </Content>
    </Layout>
  );
};

export default InspectionCriteriaManagement;
