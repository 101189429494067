// src/pages/UI/BOMManagement/ProductSidebar.js
import React, { useRef, useState } from 'react';
import { Layout, Tree, DatePicker, Input, Button, Form, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../components/Sidebar';
import axiosInstance from '../../../api/axiosInstance';
import config from '../../../config/config';

dayjs.locale('vi');

const treeData = [
  {
    title: 'Loại sản phẩm A',
    key: '0-0',
  },
  {
    title: 'Loại sản phẩm B',
    key: '0-1',
  },
];

const BOMManagementSidebar = ({onFilter}) => {
  const [bomOptions, setBomOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [piOptions, setPiOptions] = useState([]);
  const timer = useRef();

  const formFields = [
    // {
    //   type: 'Input',
    //   label: 'Mã bom',
    //   name: 'id',
    //   placeholder: 'Mã bom',
    // },
    // {
    //   type: 'Input',
    //   label: 'Mã sản phẩm',
    //   name: 'product_id',
    //   placeholder: 'Mã sản phẩm',
    // },
    {
      type: 'AutoComplete',
      label: 'Mã bom',
      name: 'id',
      placeholder: 'Mã bom',
      options: bomOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchBom({ id: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
    {
      type: 'AutoComplete',
      label: 'Số PI',
      name: 'pi',
      placeholder: 'PI',
      options: piOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchPi({ pi: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
    {
      type: 'AutoComplete',
      label: 'Mã nội bộ',
      name: 'product_id',
      placeholder: 'Mã nội bộ',
      options: productOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchProduct({ id: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
    {
      type: 'AutoComplete',
      label: 'Mã khách hàng',
      name: 'customer_id',
      placeholder: 'Mã khách hàng',
      options: customerOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchCustomer({ id: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
    {
      type: 'DatePicker',
      label: 'Thời gian truy vấn',
      name: 'start_date',
      placeholder: 'Ngày bắt đầu',
      className: 'mb-1'
    },
    {
      type: 'DatePicker',
      label: '',
      name: 'end_date',
      placeholder: 'Ngày kết thúc',
    },
    // Add other fields here
  ];

  const fetchBom = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/boms', { params });
    if (response?.success) {
      setBomOptions((response.data?.data ?? []).map((item) => ({ label: item?.id, value: item?.id })));
    }
  }

  const fetchPi = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/boms', { params });
    if (response?.success) {
      setPiOptions((response.data?.data ?? []).map((item) => ({ label: item?.pi, value: item?.pi })));
    }
  }

  const fetchProduct = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/products', { params });
    if (response?.success) {
      setProductOptions((response.data?.data ?? []).map((item) => ({ label: item?.pi, value: item?.id })));
    }
  }

  const fetchCustomer = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/customers', { params });
    if (response?.success) {
      setCustomerOptions((response.data?.data ?? []).map((item) => ({ label: item?.id, value: item?.id })));
    }
  }

  return (
    <UISidebar formFields={formFields} onFilter={onFilter}/>
  );
};

export default BOMManagementSidebar;
