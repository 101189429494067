import React, { useEffect, useState } from 'react';
import { Layout, Table, Space, DatePicker, message, Button, Modal, Input, Form, Radio, Col, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useOutletContext } from 'react-router-dom';
import './OIQualityDD.css';
import axiosInstance from '../../../../api/axiosInstance';
import config from '../../../../config/config';
import equipmentApi from '../../../../api/equipmentManagementApi';
import lotHistoryApi from '../../../../api/lotHistoryApi';
import { numberFormat } from '../../../../utils/numberFormat';
import { render } from '@testing-library/react';

const { Content } = Layout;

const OIQualityDD = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const context = useOutletContext();
    const [loading, setLoading] = useState(false);
    const [inspectionType, setInspectionType] = useState('');
    const [criteria, setCriteria] = useState([]);
    const [buttonStatus, setButtonStatus] = useState({});
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [lotHistory, setLotHistory] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedValues, setSelectedValues] = useState({});
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [selectedEquipment, setSelectedEquipment] = useState(null);
    const [dataTop, setDataTop] = useState([{ total: 0, percent_ng: 0, lot_ok: 0 }]);

    useEffect(() => {
        if (context && context.setTitle) {
            context.setTitle('QUẢN LÝ QC ĐẦU ĐƠN');
        }
    }, [context]);

    useEffect(() => {
        fetchEquipment();
        if (selectedEquipment) {
            fetchLotHistory();
            fetchLot();
        }
    }, [startDate, endDate, selectedEquipment]);

    const fetchLotHistory = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('qc-dd-log', {
                params: {
                    start_date: dayjs(startDate).format('YYYY-MM-DD'),
                    end_date: dayjs(endDate).format('YYYY-MM-DD'),
                }
            });
            setLotHistory(response.data);
        } catch (error) {
            message.error('Failed to fetch criteria');
        } finally {
            setLoading(false);
        }
    }

    const fetchLot = async () => {
        console.log(selectedEquipment);
        const response = await axiosInstance.get('/replace-net', {
            params: { equipment_id: selectedEquipment }
        });
        if (response.data) {
            setSelectedRow(response.data)
        } else {
            setSelectedRow(null);
        }
    }
    const fetchEquipment = async () => {
        try {
            const response = await equipmentApi.getEquipments();
            setEquipmentOptions(response.data.data.map(e => ({ value: e.id, label: e.equipment_name })));
            if (!selectedEquipment) setSelectedEquipment(response.data.data[0]?.id);

        } catch (error) {
            message.error('Failed to fetch equipments');
        }
    };

    const fetchLotHistorySummary = async (id) => {
        const response = await lotHistoryApi.getLotHistoriesSummary({ equipment_id: id });
        if (response?.success) {
            const { total, percent_ng, lot_ok } = response.data;
            setDataTop([{ total, percent_ng, lot_ok }]);
        }
    }

    const fetchCriteria = async (category) => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/inspection-criteria/search', {
                params: {
                    type: 'DD',
                    // category: category
                }
            });
            const criteriaData = response.data || [];
            setCriteria(criteriaData);
            const initialButtonStatus = {};
            criteriaData.forEach(item => {
                initialButtonStatus[item.id] = { status: 'OK/NG', bg: 'white', color: 'black' };
            });
            setButtonStatus(initialButtonStatus);
            const initialValues = {};
            criteriaData.forEach(item => {
                initialValues[item.id] = item.acceptablerange === 'OK/NG' ? '' : '';
            });
            form.setFieldsValue(initialValues);
        } catch (error) {
            message.error('Failed to fetch criteria');
        } finally {
            setLoading(false);
        }
    };

    const showModal = (type) => {
        if (selectedRow) {
            setInspectionType(type);
            setIsModalVisible(true);
            fetchCriteria(type === 'Kiểm tra chỉ tiêu' ? 'tinh_nang' : 'ngoai_quan');
        } else {
            message.info('Chưa có Lot sẵn sàng kiểm tra !');
        }
    };

    const handleResult = (result) => {
        form.validateFields().then(async (values) => {
            const formData = Object.entries(values).map(([id, value]) => {
                const criterion = criteria.find(item => item.id === parseInt(id));
                const type = determineTypeInput(criterion.acceptablerange);
                if (type === 1) {
                    return {
                        inspection_criteria_id: id,
                        measurement: value,
                        result: buttonStatus[id]?.status
                    };
                } else if (type === 2) {
                    return {
                        inspection_criteria_id: id,
                        measurement: value,
                        result: value
                    };
                } else {
                    return {
                        inspection_criteria_id: id,
                        measurement: value,
                        result: ''
                    };
                }
            });
            const inputData = {
                lot_history_id: selectedRow?.id,
                items: formData,
                result: result,
                check_type: 'DD',
            };
            const response = await axiosInstance.post(config.apiUrl + '/quality-checks', inputData);
            if (response.success) {
                setIsModalVisible(false);
                form.resetFields();
                fetchLotHistory();
                fetchLot();
            }
        }).catch(errorInfo => {
            console.log('Validate Failed:', errorInfo);
        });
    };

    const handleInputChange = (value, id, range) => {
        if (value === '') {
            setButtonStatus(prevStatus => ({
                ...prevStatus,
                [id]: {
                    status: 'OK/NG',
                    bg: 'white',
                    color: 'black'
                }
            }));
        } else {
            const isValid = checkMeasurement(parseFloat(value), range);
            setButtonStatus(prevStatus => ({
                ...prevStatus,
                [id]: {
                    status: isValid ? 'OK' : 'NG',
                    bg: isValid ? 'green' : 'red',
                    color: 'white'
                }
            }));
        }
    };

    const checkMeasurement = (measurement, acceptableRange) => {
        acceptableRange = acceptableRange.trim();
        measurement = parseFloat(measurement);
        acceptableRange = acceptableRange.replace(/≤/g, '<=').replace(/≥/g, '>=');
        const regex = /^(<=|>=|>|<|=|==)?\s*(\d+(\.\d+)?)\s*(\w+)?$/;

        if (regex.test(acceptableRange)) {
            const matches = acceptableRange.match(regex);
            const comparisonType = matches[1];
            const compareValue = parseFloat(matches[2]);

            switch (comparisonType) {
                case '<=':
                    return measurement <= compareValue;
                case '>=':
                    return measurement >= compareValue;
                case '>':
                    return measurement > compareValue;
                case '<':
                    return measurement < compareValue;
                case '=':
                case '==':
                    return measurement === compareValue;
                default:
                    return false;
            }
        }

        return false;
    };

    const determineTypeInput = (acceptablerange) => {
        if (acceptablerange === null) return 3;
        if (acceptablerange === 'OK/NG') return 2;
        return 1;
    };

    const handleChange = (id, value) => {
        setSelectedValues(prevValues => ({
            ...prevValues,
            [id]: value,
        }));
    };

    const handleChangeEquipment = async (value) => {
        setSelectedEquipment(value);
    }

    const renderInput = (id, criteria_name, range) => {
        const type = determineTypeInput(range);
        switch (type) {
            case 1:
                return (
                    <Form.Item label={criteria_name} key={id} style={{ marginBottom: '12px' }}>
                        <Input.Group compact>
                            <Form.Item
                                name={id}
                                noStyle
                                rules={[{ required: true, message: 'Dữ liệu không được để trống !' }]}
                            >
                                <Input
                                    style={{ width: 'calc(100% - 80px)' }}
                                    placeholder="Nhập giá trị"
                                    onChange={(e) => handleInputChange(e.target.value, id, range)}
                                />
                            </Form.Item>
                            <Button style={{ width: '80px', backgroundColor: buttonStatus[id]?.bg || 'white', color: buttonStatus[id]?.color || 'black' }}>
                                {buttonStatus[id]?.status || 'OK/NG'}
                            </Button>
                        </Input.Group>
                    </Form.Item>
                );
            case 2:
                return (
                    <Form.Item
                        label={criteria_name}
                        key={id}
                        name={id}
                        style={{ marginBottom: '12px' }}
                        rules={[{ required: true, message: 'Dữ liệu không được để trống !' }]}
                    >
                        <Radio.Group
                            style={{ textAlign: 'left' }}
                            name={id}
                            optionType="button"
                            buttonStyle="solid"
                            onChange={e => handleChange(id, e.target.value)}
                            className={selectedValues[id] === 'NG' ? 'red-radio' : ''}
                        >
                            <Radio.Button value="OK">OK</Radio.Button>
                            <Radio.Button value="NG">NG</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                );
            case 3:
                return (
                    <Form.Item
                        style={{ marginBottom: '12px' }}
                        name={id}
                        label={criteria_name}
                        rules={[{ required: true, message: 'Dữ liệu không được để trống !' }]}
                        key={id}
                    >
                        <Input placeholder="Nhập giá trị" />
                    </Form.Item>
                );
            default:
                return null;
        }
    };

    const columnsTop = [
        { title: 'Tổng thực tế', dataIndex: 'total', width: '120px', render: (value) => numberFormat(value) },
        { title: 'Tổng Lot OK', dataIndex: 'lot_ok', width: '100px', render: (value) => numberFormat(value) },
        { title: 'Tổng KG', dataIndex: 'total', width: '100px', render: (value) => numberFormat(value) },
        { title: 'Tỉ lệ NG', dataIndex: 'percent_ng', width: '100px' },
    ];

    const columns1 = [
        { title: 'Mã LSX', dataIndex: 'batch_id', align: 'center' },
        { title: 'Số lot', dataIndex: 'lot_id', align: 'center' },
        { title: 'Số lượng bao', dataIndex: 'quantity', align: 'center', render: (value) => numberFormat(value) },
        { title: 'Tổng số KG', dataIndex: 'quantity', align: 'center', render: (value) => numberFormat(value) },
    ];

    const columns2 = [
        { title: 'Mã LSX', dataIndex: 'batch_id', align: 'center', width: 250, render: (text, record) => record.lot_history.batch_id },
        { title: 'Mã Lot', dataIndex: 'lot_id', align: 'center', width: 250, render: (text, record) => record.lot_history.lot_id },
        { title: 'Số lượng', dataIndex: 'quantity', align: 'center', width: 220, render: (text, record) => record.lot_history.quantity },
        { title: 'Kết quả kiểm tra', dataIndex: '', align: 'center', width: 340, render: (text, record) => record.result },
    ];

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const rowClassName = (record) => {
        switch (record.result) {
            case 'NG':
                return 'table-row-ng';
            default:
                return "";
        }
    }
    return (
        <Content className="oi-content">
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <div className="oi-select-row">
                    <div className="oi-select-container">
                        <div className="oi-select-header">Máy</div>
                        <Select
                            showSearch
                            optionFilterProp='label'
                            options={equipmentOptions}
                            value={selectedEquipment}
                            style={{ width: '100%', height: '-webkit-fill-available' }}
                            onChange={handleChangeEquipment}
                            className="oi-select"
                            placeholder="Chọn máy"
                        />
                    </div>
                    <div className="oi-statistics-container">
                        <Table
                            className="custom-table"
                            locale={{ emptyText: 'Không có dữ liệu' }}
                            dataSource={dataTop}
                            columns={columnsTop.map(col => ({ ...col, align: 'center' }))}
                            pagination={false}
                            size="small"
                            bordered
                            scroll={{ x: '420px' }}
                        />
                    </div>
                </div>
                <Row gutter={[8, 8]}>
                    <Col span={20} xs={24} md={20} lg={20}>
                        <Table
                            locale={{ emptyText: 'Không có dữ liệu' }}
                            className="custom-table"
                            dataSource={selectedRow ? [selectedRow] : []}
                            columns={columns1}
                            pagination={false}
                            size="small"
                            bordered
                            loading={loading}
                        />
                    </Col>
                    <Col span={4} xs={24} md={4} lg={4}>
                        <Button style={{ width: '100%', height: '100%', fontSize: '16px' }} type="primary" onClick={() => { showModal('Kiểm tra chỉ tiêu'); }}>
                            <span style={{ textWrap: 'balance' }}>Kiểm tra chỉ tiêu</span>
                        </Button>
                    </Col>
                </Row>
                <div className="oi-select-row" style={{ gap: '5px' }}>
                    <DatePicker defaultValue={dayjs()} format="DD-MM-YYYY" onChange={(date) => { setStartDate(date) }} />
                    <DatePicker defaultValue={dayjs()} format="DD-MM-YYYY" onChange={(date) => { setEndDate(date) }} />
                </div>
            </Space>

            <Table
                rowClassName={rowClassName}
                dataSource={lotHistory}
                columns={columns2}
                pagination={false}
                scroll={{ x: true, y: 400 }}
                size="small"
                bordered
                loading={loading}
            />

            <Modal
                title={`${inspectionType}`}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={[
                    <Button key="submit" type="primary" onClick={() => { handleResult('OK') }}>
                        OK
                    </Button>,
                    <Button key="save" type="primary" onClick={() => { handleResult('NG') }} danger>
                        NG
                    </Button>,
                    <Button key="cancel" onClick={handleCancel}>
                        Hủy
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical">
                    {criteria.length > 0 && criteria.map(item => renderInput(item.id, item.criteria_name, item.acceptablerange))}
                </Form>
            </Modal>
        </Content>
    );
};

export default OIQualityDD;
