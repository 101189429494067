import axiosInstance from "./axiosInstance";

const getWarehouseHistories = (filters) => axiosInstance.get('/warehouse-histories', { params: filters });
const createWarehouseHistory = (data) => axiosInstance.post('/warehouse-histories', data);
const summaryWarehouseHistory = (filters) => axiosInstance.get('/warehouse-histories/summary', { params: filters });

export {
  getWarehouseHistories,
  createWarehouseHistory,
  summaryWarehouseHistory,
};
