import React, { useState } from 'react';
import { Layout, Tree, DatePicker, Input, Button, Form, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/locale/vi_VN';
import { ConfigProvider } from 'antd';
import './UnitSidebar.css';
import UISidebar from '../components/Sidebar';

dayjs.locale('vi');

const { Sider } = Layout;

const treeData = [
    {
        title: 'Loại sản phẩm A',
        key: '0-0',
    },
    {
        title: 'Loại sản phẩm B',
        key: '0-1',
    },
];

const formFields = [
    {
        type: 'Input',
        label: 'Tên đơn vị tính',
        name: 'unit_name',
        placeholder: 'Tên đơn vị tính',
    },
    // Add other fields here
];

const UnitSidebar = ({ onFilter }) => {
    return (
        <UISidebar formFields={formFields} onFilter={onFilter} treeData={treeData} />
    );
};

export default UnitSidebar;
