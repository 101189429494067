import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Space, Upload, message, Modal, Descriptions } from 'antd';
import config from '../../../../config/config';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axiosInstance from '../../../../api/axiosInstance';
import duration from 'dayjs/plugin/duration';
import { b64toBlob } from '../../../../utils/dataBlob';
import { numberFormat } from '../../../../utils/numberFormat';
import CalculateTableHeight from '../../../../components/calculateTableHeight';

dayjs.extend(duration);

const EquipmentMaintenanceHistoryTable = ({ filters }) => {
  const [loading, setLoading] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [dataSelected, setDataSelected] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate);
    if (filters?.maintenance_date) filters.maintenance_date = dayjs(filters.maintenance_date).format('YYYY-MM-DD');
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  const fetchData = async (params) => {
    setLoading(true);
    const response = await axiosInstance.get('/maintenance-logs', { params });
    if (response?.success) {
      setData(response?.data.data ?? []);
      setPagination({ ...pagination, total: response?.data?.data?.length });
    }
    setLoading(false);
  };

  const handleViewSparepart = (record) => {
    console.log(record);
    setDataSelected(record);
    setIsViewModalVisible(true);
  }

  function generateMockData() {
    const data = [];
    const equipmentNames = ['Máy 02'];
    const maintenanceUnits = ['Unit A'];
    const maintenanceTypes = ['Type A', 'Type B', 'Type C', 'Type D', 'Type E'];
    const notes = ['Note 1', 'Note 2', 'Note 3', 'Note 4', 'Note 5'];
    const explains = ['-'];
    const users = [
      { name: 'Nguyễn Thị A' },
      { name: 'Nguyễn Thị B' },
      { name: 'Nguyễn Thị C' },
      { name: 'Nguyễn Thị D' },
      { name: 'Nguyễn Thị E' },
    ];

    for (let i = 0; i < 10; i++) {
      const maintenanceDate = dayjs().subtract(i, 'days').format('YYYY-MM-DDTHH:mm:ssZ');
      const startTime = dayjs().subtract(i, 'days').add(1, 'hour').format('YYYY-MM-DDTHH:mm:ssZ');
      const endTime = dayjs().subtract(i, 'days').add(2, 'hours').format('YYYY-MM-DDTHH:mm:ssZ');
      const userIndex = i % users.length;
      data.push({
        stt: i + 1,
        maintenance_plan: {
          maintenance_date: maintenanceDate,
          equipment: { equipment_name: equipmentNames[i % equipmentNames.length] },
          maintenance_unit: maintenanceUnits[i % maintenanceUnits.length],
          maintenance_type: maintenanceTypes[i % maintenanceTypes.length],
          notes: notes[i % notes.length],
          explain: explains[i % explains.length],
          start_time: startTime,
          end_time: endTime,
          user: users[userIndex],
        },
        time: 60, // Example fixed value; you can change this to a calculated value if needed
        user: users[userIndex].name,
      });
    }

    return data;
  }

  const columns = [
    {
      title: 'BẢNG GHI CHÉP SỬA CHỮA BẢO DƯỠNG MÁY MÓC THIẾT BỊ', align: 'center', children: [
        { title: 'STT', dataIndex: 'stt', align: 'center', width: '50px', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1 },
        { title: 'Ngày bảo dưỡng', dataIndex: 'maintenance_date', align: 'center', width: '150px', render: (_, record) => dayjs(record?.maintenance_plan?.maintenance_date).format('DD/MM/YYYY') },
        { title: 'Tên thiết bị', dataIndex: 'equipment', align: 'center', width: '150px', render: (_, record) => record?.maintenance_plan?.equipment?.equipment_name },
        { title: 'Đơn vị sử dụng', dataIndex: 'maintenance_unit', align: 'center', width: '150px', render: (_, record) => record?.maintenance_plan?.maintenance_unit },
        { title: 'Hạng mục bảo dưỡng', dataIndex: 'maintenance_type', align: 'center', width: '170px', render: (_, record) => record?.maintenance_plan?.maintenance_type },
        { title: 'Nội dung bảo dưỡng thực tế', dataIndex: 'notes', align: 'center', width: '200px', render: (_, record) => record?.maintenance_plan?.notes },
        { title: 'Giải thích', dataIndex: 'explain', align: 'center', width: '150px', render: (_, record) => record?.maintenance_plan?.explain }
      ]
    },
    { title: 'Phụ tùng linh kiện thay thế', align: 'center', width: '150px', render: (_, record) => <Button onClick={() => handleViewSparepart(record)}>Xem chi tiết</Button> },
    {
      title: 'Thời gian thực hiện (phút)', dataIndex: 'time', align: 'center', width: '150px', render: (_, record) => {
        const startTime = dayjs(record?.maintenance_plan?.start_time);
        const endTime = dayjs(record?.maintenance_plan?.end_time);
        const diffInMinutes = dayjs.duration(endTime.diff(startTime)).asMinutes();
        return Number(diffInMinutes.toFixed(2));
      }
    },
    { title: 'Người thực hiện', dataIndex: 'user', align: 'center', width: '150px', render: (_, record) => record?.maintenance_plan?.user?.name }
  ];


  const columnsViewTable = [
    { title: 'STT', dataIndex: 'no', key: 'no', width: '50px', align: 'center', render: (_, record, index) => index + 1 },
    { title: 'Tên phụ tùng', dataIndex: 'material', key: 'material', align: 'center', width: '150px', render: (value) => value?.material_name },
    { title: 'Số lượng', dataIndex: 'quantity', key: 'quantity', align: 'center', width: '80px', render: (value) => numberFormat(value) },
  ];

  const itemsDescription = [
    { key: '1', label: 'Ngày bảo dưỡng', children: dayjs(dataSelected?.maintenance_plan?.maintenance_date).format('DD/MM/YYYY') },
    { key: '2', label: 'Tên thiết bị', children: dataSelected?.maintenance_plan?.equipment?.equipment_name },
    { key: '3', label: 'Người thực hiện', children: dataSelected?.maintenance_plan?.user?.name },
  ];

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/maintenance-logs/export');
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      style={{height: '100%'}}
      title="Lịch sử bảo dưỡng thiết bị"
      extra={
        <Space>
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel}>Xuất Excel</Button>
        </Space>
      }
    >
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
        <Table
          className='table-maintenance'
          loading={loading}
          columns={columns}
          dataSource={data.length > 0 ? data.map((e, i) => ({ ...e, key: i })) : generateMockData()}
          bordered
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, page, pageSize });
              fetchData({ page, pageSize });
            },
          }}
          scroll={{ x: '1550px', y: CalculateTableHeight('table-maintenance') }}
          size="small"
        />
      </div>

      {/* View modal */}
      <Modal
        title={
          <div className="modal-title">
            <span>Thông tin phụ tùng thay thế</span>
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isViewModalVisible}
        onCancel={() => setIsViewModalVisible(false)}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Thoát"
        width={800}
        loading={loading}
      >
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Descriptions items={itemsDescription} layout="vertical" bordered />
          <Table dataSource={dataSelected?.spareparts} columns={columnsViewTable} bordered pagination={false} scroll={{ x: '280px' }} />
        </Space>
      </Modal>
    </Card>
  );
};

export default EquipmentMaintenanceHistoryTable;
