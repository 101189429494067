import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import StandardParameterSidebar from './StandardParameterSidebar';
import StandardParameterTable from './StandardParameterTable';

const { Header, Content } = Layout;

const StandardParameterManagement = () => {
    const [filters, setFilters] = useState({});

    const handleFilter = (filterValues) => {
        setFilters(filterValues);
    };
    // useEffect(()=>{
    //   console.log(filters);
    // }, [filters])
    return (
        <Layout>
            <StandardParameterSidebar onFilter={handleFilter} />
            <Content className="site-layout-content">
                <StandardParameterTable filters={filters} />
            </Content>
        </Layout>
    );
};

export default StandardParameterManagement;
