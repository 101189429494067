import React, { useState } from 'react';
import { Layout, Tree, DatePicker, Input, Button, Form, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/locale/vi_VN';
import { ConfigProvider } from 'antd';
import './PermissionSidebar.css';
import UISidebar from '../components/Sidebar';

dayjs.locale('vi');

const { Sider } = Layout;

const treeData = [
  {
    title: 'Loại sản phẩm A',
    key: '0-0',
  },
  {
    title: 'Loại sản phẩm B',
    key: '0-1',
  },
];

const formFields = [
  // {
  //   type: 'Input',
  //   label: 'Mã quyền hạn',
  //   name: 'id',
  //   placeholder: 'Mã quyền hạn',
  // },
  {
    type: 'Input',
    label: 'Tên quyền hạn',
    name: 'name',
    placeholder: 'Tên quyền hạn',
  },
  // Add other fields here
];

const PermissionSidebar = ({onFilter}) => {
  return (
    <UISidebar formFields={formFields} onFilter={onFilter} treeData={treeData}/>
  );
};

export default PermissionSidebar;
