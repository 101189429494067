import React, { useEffect, useState } from 'react';
import { Table, Button, Card, Space, message } from 'antd';
import config from '../../../../config/config';
import lotHistoryApi from '../../../../api/lotHistoryApi';
import dayjs from 'dayjs';
import ExportExcelButton from '../../../../components/ExportExcelButton';
import axiosInstance from '../../../../api/axiosInstance';
import { b64toBlob } from '../../../../utils/dataBlob';
import { DownloadOutlined } from '@ant-design/icons';
const sampleData = [
  {
    stt: 1,
    checked_date: '2024-07-10T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 1' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10901',
    lot_id: 'L01',
    quantity: 1000,
    quantity_ok: 950,
    quantity_ng: 50,
    quality_check: { created_at: '2024-07-10T00:00:00Z', result: 'OK', inspector: { name: 'Nguyễn Văn A' } },
    checker_name: 'Nguyễn Văn A',
  },
  {
    stt: 2,
    checked_date: '2024-07-11T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 2' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10902',
    lot_id: 'L02',
    quantity: 1200,
    quantity_ok: 1180,
    quantity_ng: 20,
    quality_check: { created_at: '2024-07-11T00:00:00Z', result: 'OK', inspector: { name: 'Trần Thị B' } },
    checker_name: 'Trần Thị B',
  },
  {
    stt: 3,
    checked_date: '2024-07-12T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 3' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10903',
    lot_id: 'L03',
    quantity: 1500,
    quantity_ok: 1450,
    quantity_ng: 50,
    quality_check: { created_at: '2024-07-12T00:00:00Z', result: 'NG', inspector: { name: 'Lê Văn C' } },
    checker_name: 'Lê Văn C',
  },
  {
    stt: 4,
    checked_date: '2024-07-13T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 4' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10904',
    lot_id: 'L04',
    quantity: 1100,
    quantity_ok: 1090,
    quantity_ng: 10,
    quality_check: { created_at: '2024-07-13T00:00:00Z', result: 'OK', inspector: { name: 'Phạm Thị D' } },
    checker_name: 'Phạm Thị D',
  },
  {
    stt: 5,
    checked_date: '2024-07-14T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 5' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10905',
    lot_id: 'L05',
    quantity: 1300,
    quantity_ok: 1280,
    quantity_ng: 20,
    quality_check: { created_at: '2024-07-14T00:00:00Z', result: 'OK', inspector: { name: 'Võ Văn E' } },
    checker_name: 'Võ Văn E',
  },
  {
    stt: 6,
    checked_date: '2024-07-15T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 6' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10906',
    lot_id: 'L06',
    quantity: 1400,
    quantity_ok: 1370,
    quantity_ng: 30,
    quality_check: { created_at: '2024-07-15T00:00:00Z', result: 'OK', inspector: { name: 'Nguyễn Thị F' } },
    checker_name: 'Nguyễn Thị F',
  },
  {
    stt: 7,
    checked_date: '2024-07-16T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 7' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10907',
    lot_id: 'L07',
    quantity: 1600,
    quantity_ok: 1590,
    quantity_ng: 10,
    quality_check: { created_at: '2024-07-16T00:00:00Z', result: 'OK', inspector: { name: 'Lê Văn G' } },
    checker_name: 'Lê Văn G',
  },
  {
    stt: 8,
    checked_date: '2024-07-17T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 8' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10908',
    lot_id: 'L08',
    quantity: 1700,
    quantity_ok: 1650,
    quantity_ng: 50,
    quality_check: { created_at: '2024-07-17T00:00:00Z', result: 'NG', inspector: { name: 'Trần Thị H' } },
    checker_name: 'Trần Thị H',
  },
  {
    stt: 9,
    checked_date: '2024-07-18T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 9' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10909',
    lot_id: 'L09',
    quantity: 1500,
    quantity_ok: 1450,
    quantity_ng: 50,
    quality_check: { created_at: '2024-07-18T00:00:00Z', result: 'OK', inspector: { name: 'Phạm Văn I' } },
    checker_name: 'Phạm Văn I',
  },
  {
    stt: 10,
    checked_date: '2024-07-19T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 10' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10910',
    lot_id: 'L10',
    quantity: 1100,
    quantity_ok: 1080,
    quantity_ng: 20,
    quality_check: { created_at: '2024-07-19T00:00:00Z', result: 'OK', inspector: { name: 'Võ Thị J' } },
    checker_name: 'Võ Thị J',
  },
  {
    stt: 11,
    checked_date: '2024-07-20T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 11' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10911',
    lot_id: 'L11',
    quantity: 1200,
    quantity_ok: 1190,
    quantity_ng: 10,
    quality_check: { created_at: '2024-07-20T00:00:00Z', result: 'OK', inspector: { name: 'Ngô Văn K' } },
    checker_name: 'Ngô Văn K',
  },
  {
    stt: 12,
    checked_date: '2024-07-21T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 12' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10912',
    lot_id: 'L12',
    quantity: 1300,
    quantity_ok: 1280,
    quantity_ng: 20,
    quality_check: { created_at: '2024-07-21T00:00:00Z', result: 'OK', inspector: { name: 'Bùi Văn L' } },
    checker_name: 'Bùi Văn L',
  },
  {
    stt: 13,
    checked_date: '2024-07-22T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 13' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10913',
    lot_id: 'L13',
    quantity: 1400,
    quantity_ok: 1380,
    quantity_ng: 20,
    quality_check: { created_at: '2024-07-22T00:00:00Z', result: 'OK', inspector: { name: 'Nguyễn Văn M' } },
    checker_name: 'Nguyễn Văn M',
  },
  {
    stt: 14,
    checked_date: '2024-07-23T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 14' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10914',
    lot_id: 'L14',
    quantity: 1500,
    quantity_ok: 1480,
    quantity_ng: 20,
    quality_check: { created_at: '2024-07-23T00:00:00Z', result: 'OK', inspector: { name: 'Trần Thị N' } },
    checker_name: 'Trần Thị N',
  },
  {
    stt: 15,
    checked_date: '2024-07-24T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 15' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10915',
    lot_id: 'L15',
    quantity: 1600,
    quantity_ok: 1580,
    quantity_ng: 20,
    quality_check: { created_at: '2024-07-24T00:00:00Z', result: 'OK', inspector: { name: 'Phạm Thị O' } },
    checker_name: 'Phạm Thị O',
  },
  {
    stt: 16,
    checked_date: '2024-07-25T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 16' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10916',
    lot_id: 'L16',
    quantity: 1700,
    quantity_ok: 1650,
    quantity_ng: 50,
    quality_check: { created_at: '2024-07-25T00:00:00Z', result: 'OK', inspector: { name: 'Lê Văn P' } },
    checker_name: 'Lê Văn P',
  },
  {
    stt: 17,
    checked_date: '2024-07-26T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 17' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10917',
    lot_id: 'L17',
    quantity: 1800,
    quantity_ok: 1750,
    quantity_ng: 50,
    quality_check: { created_at: '2024-07-26T00:00:00Z', result: 'NG', inspector: { name: 'Nguyễn Thị Q' } },
    checker_name: 'Nguyễn Thị Q',
  },
  {
    stt: 18,
    checked_date: '2024-07-27T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 18' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10918',
    lot_id: 'L18',
    quantity: 1900,
    quantity_ok: 1850,
    quantity_ng: 50,
    quality_check: { created_at: '2024-07-27T00:00:00Z', result: 'OK', inspector: { name: 'Phạm Văn R' } },
    checker_name: 'Phạm Văn R',
  },
  {
    stt: 19,
    checked_date: '2024-07-28T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 19' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10919',
    lot_id: 'L19',
    quantity: 2000,
    quantity_ok: 1980,
    quantity_ng: 20,
    quality_check: { created_at: '2024-07-28T00:00:00Z', result: 'OK', inspector: { name: 'Võ Thị S' } },
    checker_name: 'Võ Thị S',
  },
  {
    stt: 20,
    checked_date: '2024-07-29T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 20' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10920',
    lot_id: 'L20',
    quantity: 2100,
    quantity_ok: 2090,
    quantity_ng: 10,
    quality_check: { created_at: '2024-07-29T00:00:00Z', result: 'OK', inspector: { name: 'Bùi Văn T' } },
    checker_name: 'Bùi Văn T',
  },
  {
    stt: 21,
    checked_date: '2024-07-30T00:00:00Z',
    equipment: { process: { process_name: 'Công đoạn 21' } },
    equipment_id: 'Máy 02',
    product_id: 'TP UF10921',
    lot_id: 'L21',
    quantity: 2200,
    quantity_ok: 2180,
    quantity_ng: 20,
    quality_check: { created_at: '2024-07-30T00:00:00Z', result: 'OK', inspector: { name: 'Ngô Văn U' } },
    checker_name: 'Ngô Văn U',
  },
];
const PQCTable = ({ filters }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const columns = [
    { title: 'STT', dataIndex: 'stt', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1, width: '50px' },
    { title: 'Ngày kiểm tra', dataIndex: 'checked_date', width: '120px', render: (_, record) => dayjs(record?.quality_check?.created_at).format('DD/MM/YYYY') },
    { title: 'Công đoạn', dataIndex: 'proccess_id', width: '100px', render: (_, record) => record?.equipment?.process?.process_name, hidden: true },
    { title: 'Máy sản xuất', dataIndex: 'equipment_id', width: '150px' },
    { title: 'Mã sản phẩm', dataIndex: 'product_id', width: '150px' },
    { title: 'Mã Lot', dataIndex: 'lot_id', width: '100px' },
    { title: 'Số lượng đầu ra', dataIndex: 'quantity', width: '120px', render: (value) => Number(Number(value).toFixed(2)).toLocaleString('en-US') },
    { title: 'SL đầu ra OK', dataIndex: 'quantity_ok', width: '120px', render: (_, record) => Number(record?.quantity) - Number(record?.quantity_ng) },
    { title: 'Số lượng NG', dataIndex: 'quantity_ng', width: '100px', render: (value) => Number(Number(value).toFixed(2)).toLocaleString('en-US') },
    { title: 'Tỷ lệ NG', dataIndex: 'failure_percentage', width: '100px', render: (_, record) => record?.quantity > 0 ? (Number(Number(record?.quantity_ng ?? 0) / Number(record?.quantity ?? 1) * 100).toFixed(2) + '%') : '0%' },
    { title: 'Kết quả KT', dataIndex: 'quality_check', width: '100px', render: (value) => value?.result },
    { title: 'Người kiểm tra', dataIndex: 'checker_name', width: '120px', render: (_, record) => record?.quality_check?.inspector?.name }
  ];


  const fetchData = async (filters) => {
    try {
      setLoading(true);
      filters.quality_check = 'PQC';
      filters.check_type = 'PQC';
      if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
      if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');

      const response = await lotHistoryApi.getLotHistories(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data.data);
      response.data?.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  const getTableHeight = () => {
    const layout = document.querySelector('.site-layout-content');
    if (layout) {
      const sectionChart = layout?.querySelector('.section-chart');
      const sectionCard = layout?.querySelector('.section-card');
      const cardHead = sectionCard?.querySelector('.ant-card-head');
      const cardBody = sectionCard?.querySelector('.ant-card-body');
      const table = sectionCard?.querySelector('.table-pqc');
      const tableBody = table?.querySelector('.ant-table-body');
      const antEmpty = table?.querySelector('.ant-empty-normal');
      const tableHeader = table?.querySelector('.ant-table-thead');
      const tablePagination = table?.querySelector('.ant-table-pagination');
      let paddingTop = 0;
      let paddingBottom = 0;
      if (cardBody) {
        const style = window.getComputedStyle(cardBody);
        paddingTop = parseFloat(style.paddingTop);
        paddingBottom = parseFloat(style.paddingBottom);
      }
      console.log(layout?.offsetHeight, sectionChart?.offsetHeight);
      const result = (layout?.offsetHeight ?? 0) - (sectionChart?.offsetHeight ?? 0) - (cardHead?.offsetHeight ?? 0) - (tableHeader?.offsetHeight ?? 0) - (tablePagination?.offsetHeight ?? 0) - paddingTop - paddingBottom - 48 - 40;
      if (tableBody) tableBody?.style.setProperty('height', `${result}px`, 'important');
      if (antEmpty) antEmpty?.style.setProperty('height', `${result - 114}px`);
      console.log(result);
      if (Number(result)) return result;
    }
    return undefined;
  }

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/quality-export/pqc', {params: filters});
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      className='section-card'
      title="Bảng chi tiết lỗi"
      extra={
        <Space>
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel} loading={loading}>Xuất file</Button>
          {/* <ExportExcelButton data={data} filename={'PQC_OkNg'} sheetname={'PQC_OkNg'} /> */}
        </Space>
      }
    >
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
        <Table
          className='table-pqc'
          loading={loading}
          columns={columns.map(e => ({ ...e, align: 'center' }))}
          dataSource={data}
          bordered
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) => {
              fetchData({ ...filters, page, pageSize });
            }
          }}
          scroll={{ x: '1330px', y: getTableHeight() }}
          size='small'
        />
      </div>
    </Card>
  );
};

export default PQCTable;
