import axiosInstance from "./axiosInstance";

const getWarehouseLocations = (filters) => axiosInstance.get('/warehouse-locations', { params: filters });
const getWarehouseLocationById = (id) => axiosInstance.get(`/warehouse-locations/${id}`);
const createWarehouseLocation = (warehouseLocationData) => axiosInstance.post('/warehouse-locations', warehouseLocationData);
const updateWarehouseLocation = (id, warehouseLocationData) => axiosInstance.put(`/warehouse-locations/${id}`, warehouseLocationData);
const deleteWarehouseLocation = (id) => axiosInstance.delete(`/warehouse-locations/${id}`);
const getWarehouseLocationsSuggest = (pallet_id) => axiosInstance.get(`/warehouse-locations/suggest/${pallet_id}`);
const getWarehouseLocationsCapacity = (warehouse_id) => axiosInstance.get(`/warehouse-locations/capacity/${warehouse_id}`);

export default {
  getWarehouseLocations,
  getWarehouseLocationById,
  createWarehouseLocation,
  updateWarehouseLocation,
  deleteWarehouseLocation,
  getWarehouseLocationsSuggest,
  getWarehouseLocationsCapacity
};
