import axiosInstance from "./axiosInstance";

const getCustomers = (filters) => axiosInstance.get('/customers', { params: filters });
const getCustomerById = (id) => axiosInstance.get(`/customers/${id}`);
const createCustomer = (customerData) => axiosInstance.post('/customers', customerData);
const updateCustomer = (id, customerData) => axiosInstance.put(`/customers/${id}`, customerData);
const deleteCustomer = (id) => axiosInstance.delete(`/customers/${id}`);

export default {
  getCustomers,
  getCustomerById,
  createCustomer,
  updateCustomer,
  deleteCustomer,
};
