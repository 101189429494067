import React, { useEffect, useRef, useState } from 'react';
import './SketchfabModel.css';
import NodeList from './NodeList'; // Import NodeList component
import { Modal, Spin } from 'antd';
import axiosInstance from '../../../api/axiosInstance';
import config from '../../../config/config';
import dayjs from 'dayjs';

// const model1 = ['Mesh401_Red_0', 'Mesh401_FrontColor_0', 'Mesh397_Red_0', 'Mesh400_Red_0', 'Mesh399_FrontColor_0'];
// const model2 = ['Mesh385_Red_0', 'Mesh384_FrontColor_0', 'Mesh382_Red_0', 'Mesh386_FrontColor_0', 'Mesh386_Red_0'];
// const model3 = ['Mesh391_Red_0', 'Mesh391_FrontColor_0', 'Mesh387_Red_0', 'Mesh390_Red_0', 'Mesh389_FrontColor_0'];
// const model4 = ['Mesh395_Red_0', 'Mesh394_FrontColor_0', 'Mesh392_Red_0', 'Mesh396_Red_0', 'Mesh396_FrontColor_0'];

const warning1 = { id: 8381, name: "Group170" };
const warning2 = { id: 8065, name: "Group155" };
const warning3 = { id: 8181, name: "Group160" };
const warning4 = { id: 8281, name: "Group165" };

const SketchfabModel = ({ modelUid }) => {
  const iframeRef = useRef(null);
  const [client, setClient] = useState(null);
  const [nodes, setNodes] = useState({});
  const [selectedNode, setSelectedNode] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [visibilityMap, setVisibilityMap] = useState({});
  const [color, setColor] = useState('#ffffff'); // Default color
  const [text, setText] = useState(''); // Default text
  const [equipmentParameterLog, setEquipmentParameterLog] = useState([]);
  const models = useRef([]);

  useEffect(() => {
    const iframe = iframeRef.current;
    const sketchfabClient = new window.Sketchfab('1.12.1', iframe);

    sketchfabClient.init(modelUid, {
      success: (api) => {
        setClient(api);
        api.start();
        api.addEventListener('viewerready', async function () {
          console.log('Viewer is ready');
          await loadNodes(api);
          hideNodes(api);
        });
      },
      error: function onError() {
        console.error('Viewer error');
      }
    });
  }, [modelUid]);

  const loadNodes = (api) => {
    api.getNodeMap(async (err, nodes) => {
      if (!err) {
        setNodes(nodes);
        console.log('Nodes:', nodes);
        await loadModels(nodes);
        setupNodeClick(api, nodes);
        initializeVisibilityMap(nodes);
      }
    });
  };

  const hideNodes = api => {
    api.getNodeMap((err, nodes) => {
      if (!err) {
        // Ẩn các mô hình cụ thể khi view được tải
        const instanceIdsToHide = models.current.map((models) => models.instanceID);
        instanceIdsToHide.forEach(id => {
          const node = nodes[id];
          if (node) api.hide(node.instanceID);
        });
      }
    });
  };

  const loadModels = async (nodes) => {
    const hideModels = [warning1.id, warning2.id, warning3.id, warning4.id,
      8473, 8465, 8451, 8436, 8421,
      8473, 8465, 8141, 8126, 8111, 8163, 8155,
      8473, 8465, 8241, 8226, 8211, 8263, 8255,
      8473, 8465, 8341, 8326, 8311, 8363, 8355
    ];
    models.current = Object.values(nodes).filter((value) =>
      hideModels.includes(value.instanceID)
    );
  };

  const setupNodeClick = (api, nodes) => {
    api.addEventListener('click', (event) => {
      const nodeId = event.instanceID;
      const node = Object.values(nodes).find(node => node.instanceID === nodeId);
      if (node) {
        setSelectedNode(node);
        // addAnnotation(node, api, text);
        // zoomToNode(api, node);
        // setIsOpenModal(true);
      }
    });
  };

  const initializeVisibilityMap = (nodes) => {
    const visibility = {};
    Object.values(nodes).forEach(node => {
      visibility[node.instanceID] = true; // Default all nodes to visible
    });
    setVisibilityMap(visibility);
  };

  const closePopup = () => {
    setIsOpenModal(false);
    setSelectedNode(null);
  };

  const toggleVisibility = (nodeId) => {
    if (client) {
      const newVisibility = !visibilityMap[nodeId];
      if (newVisibility) {
        client.show(nodeId);
      } else {
        client.hide(nodeId);
      }
      setVisibilityMap({
        ...visibilityMap,
        [nodeId]: newVisibility,
      });
    }
  };

  const changeColor = (nodeId, color) => {
    if (client) {
      client.getMaterialList((err, materials) => {
        if (!err) {
          const material = materials.find(mat => mat.channels.AlbedoPBR);
          if (material) {
            const newColor = color.replace('#', '0x');
            client.setMaterial(material, {
              channels: {
                AlbedoPBR: {
                  color: newColor
                }
              }
            }, (err) => {
              if (err) {
                console.error('Error setting material color:', err);
              }
            });
          }
        }
      });
    }
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
    if (selectedNode) {
      changeColor(selectedNode.instanceID, event.target.value);
    }
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const addAnnotation = (node, api, text) => {
    api.createAnnotation(node.instanceID, [0, 0, 0], `Node ${node.instanceID}`, text, (err) => {
      if (err) {
        console.error('Error creating annotation:', err);
      }
    });
  };

  const zoomToNode = (api, node) => {
    const worldMatrix = node.worldMatrix;
    if (worldMatrix && worldMatrix.length >= 16) {
      const position = [worldMatrix[12], worldMatrix[13], worldMatrix[14]];
      api.setCameraLookAt(position, [0, 0, 0], 2.0, (err) => {
        if (err) {
          console.error('Error zooming to node:', err);
        }
      });
    } else {
      console.error('Invalid worldMatrix:', worldMatrix);
    }
  };

  const setCameraView = (api, position, target) => {
    api.setCameraLookAt(position, target, 2.0, (err) => {
      if (err) {
        console.error('Error setting camera view:', err);
      }
    });
  };

  const handleFrontView = () => {
    if (client) {
      setCameraView(client, [-100, -100, 5], [100, 100, 100]); // Adjust these values as needed
    }
  };

  const handleSideView = () => {
    if (client) {
      setCameraView(client, [5, 0, 0], [0, 0, 0]); // Adjust these values as needed
    }
  };

  const handleTopView = () => {
    if (client) {
      setCameraView(client, [0, 5, 0], [0, 0, 0]); // Adjust these values as needed
    }
  };

  const fetchEquipmentParameterLog = async () => {
    const response = await axiosInstance(config.apiUrl + '/equipment-parameter-logs', {
      params: {
        page: 1, pageSize: 5
      }
    });
    if (response.success) {
      setEquipmentParameterLog(response.data?.data ?? []);
    }
  }

  /**
   * Warning1 status
   * @param show 
   */
  const warning1Status = (show = true) => {
    if (show) {
      client.show(warning1.id);
      client.show(8473);
      client.show(8465);
      client.show(8451);
      client.show(8436);
      client.show(8421);
    } else {
      client.hide(warning1.id);
      client.hide(8473);
      client.hide(8465);
      client.hide(8451);
      client.hide(8436);
      client.hide(8421);
    }
  }

  /**
   * Warning2 status
   * @param show 
   */
  const warning2Status = (show = true) => {
    if (show) {
      client.show(warning2.id);
      client.show(8473);
      client.show(8465);
      client.show(8141);
      client.show(8126);
      client.show(8111);
      client.show(8163);
      client.show(8155);
    } else {
      client.hide(warning2.id);
      client.hide(8473);
      client.hide(8465);
      client.hide(8141);
      client.hide(8126);
      client.hide(8111);
      client.hide(8163);
      client.hide(8155);
    }
  }

  /**
   * Warning3 status
   * @param show 
   */
  const warning3Status = (show = true) => {
    if (show) {
      client.show(warning3.id);
      client.show(8473);
      client.show(8465);
      client.show(8241);
      client.show(8226);
      client.show(8211);
      client.show(8263);
      client.show(8255);
    } else {
      client.hide(warning3.id);
      client.hide(8473);
      client.hide(8465);
      client.hide(8241);
      client.hide(8226);
      client.hide(8211);
      client.hide(8263);
      client.hide(8255);
    }
  }

  /**
   * Warning4 status
   * @param show 
   */
  const warning4Status = (show = true) => {
    if (show) {
      client.show(warning4.id);
      client.show(8473);
      client.show(8465);
      client.show(8341);
      client.show(8326);
      client.show(8311);
      client.show(8363);
      client.show(8355);
    } else {
      client.hide(warning4.id);
      client.hide(8473);
      client.hide(8465);
      client.hide(8341);
      client.hide(8326);
      client.hide(8311);
      client.hide(8363);
      client.hide(8355);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      fetchEquipmentParameterLog();
      if (client) {
        // console.clear();
        // console.log(`Client ${dayjs().format('DD/MM/YYYY HH:mm:ss')}`, equipmentParameterLog);
        const tem_lieu = equipmentParameterLog.find((item) => item?.equipment_parameter_id === 'tem_lieu');
        const tem_vo = equipmentParameterLog.find((item) => item?.equipment_parameter_id === 'tem_vo');
        // warning1Status(false);
        // warning2Status(false);
        // warning3Status(false);
        // warning4Status(false);

        if (tem_lieu?.value) { // Demo with waring 4
          if (Number(tem_lieu.value) >= 55) warning4Status(true);
          else warning4Status(false);
        }

        if (tem_vo?.value) { // Demo with waring 1
          if (Number(tem_vo.value) >= 99 && Number(tem_vo.value) <= 125) warning1Status(false);
          else warning1Status(true);
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  });

  return (
    <div style={{ width: "100%", marginTop: "55px", height: "92vh" }}>
      <iframe
        ref={iframeRef}
        title="Sketchfab Model"
        width="100%"
        height="100%"
        frameBorder="0"
        allow="autoplay; fullscreen; vr"
        src={`https://sketchfab.com/models/${modelUid}/embed`}
      ></iframe>
      {/* <NodeList nodes={nodes} visibilityMap={visibilityMap} toggleVisibility={toggleVisibility} /> */}

      <Modal
        title="Thông tin chi tiết"
        visible={isOpenModal}
        onCancel={closePopup}
        footer={null}
        width={600}
        loading={!selectedNode}
      >
        {selectedNode && (
          <div>
            <p>ID: {selectedNode.instanceID}</p>
            <p>Name: {selectedNode.name}</p>
            <p>Type: {selectedNode.type}</p>
            <p>Material ID: {selectedNode.materialID}</p>
            {/* <button onClick={() => toggleVisibility(selectedNode.instanceID)}>
              {visibilityMap[selectedNode.instanceID] ? 'Hide' : 'Show'} Node
            </button>
            <input type="color" value={color} onChange={handleColorChange} />
            <input type="text" value={text} onChange={handleTextChange} placeholder="Enter annotation text" /> */}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SketchfabModel;
