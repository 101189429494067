import React, { useEffect, useState, useRef } from 'react';
import { Layout, Select, message, Table, Button, Row, Col, Modal, Form, InputNumber, Popconfirm, Typography, Divider, FloatButton, QRCode } from 'antd';
import './OIManufacture.css';
import { EditOutlined, PrinterOutlined, QrcodeOutlined, RollbackOutlined, SearchOutlined, StopOutlined } from '@ant-design/icons';
import equipmentApi from '../../../api/equipmentManagementApi';
import { useOutletContext } from 'react-router-dom';
import Scanner from '../../../components/Scanner';
import axiosInstance from '../../../api/axiosInstance';
import { ReactToPrint } from 'react-to-print';
import { numberFormat } from '../../../utils/numberFormat';
import echo from '../../../components/echo';
import dayjs from 'dayjs';
import { tableHeight } from '../components/OICalculateTableHeight';

const { Content } = Layout;
const { Title, Text } = Typography;

const columnsII = [
    { title: 'T1', dataIndex: 't1', key: 't1', align: 'center' },
    { title: 'T2', dataIndex: 't2', key: 't2', align: 'center' },
    { title: 'T3', dataIndex: 't3', key: 't3', align: 'center' },
    { title: 'T4', dataIndex: 't4', key: 't4', align: 'center' },
    { title: 'T5', dataIndex: 't5', key: 't5', align: 'center' },
    { title: 'T6', dataIndex: 't6', key: 't6', align: 'center' },
    { title: 'T7', dataIndex: 't7', key: 't7', align: 'center' },
    { title: 'T8', dataIndex: 't8', key: 't8', align: 'center' },
    { title: 'T9', dataIndex: 't9', key: 't9', align: 'center' },
    { title: 'T10', dataIndex: 't10', key: 't10', align: 'center' },
    { title: 'T11', dataIndex: 't11', key: 't11', align: 'center' },
    { title: 'DH', dataIndex: 'dh', key: 'dh', align: 'center' },

];

const data2 = [
    { key: '1', stt: '1', content: 'Áp suất chân không', standard: '> 0.08 Mpa' },
    // Add other rows here
];

const OIManufacture = () => {
    const context = useOutletContext();
    const [form] = Form.useForm();
    const [selectedEquipment, setSelectedEquipment] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isInputModalVisible, setIsInputModalVisible] = useState(false);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [listHistoryLot, setListHistoryLot] = useState([]);
    const isScanningRef = useRef(true);
    const [loadingTable, setLoadingTable] = useState(false);
    const [isModalBomInfoVisible, setIsModalBomInfoVisible] = useState(false);
    const [infoBom, setInfoBom] = useState([]);
    const [infoProduct, setInfoProduct] = useState(null);
    const [productAttributes, setProductAttributes] = useState([]);
    const [productAttributesII, setProductAttributesII] = useState([]);
    const [footerProductAttributesII, setFooterProductAttributesII] = useState(null); // luoi_lap_theo_thu_tu
    const [productAttributesIII, setProductAttributesIII] = useState([]);
    const [dataPacking, setDataPacking] = useState([]);
    const [htmlContent, setHtmlContent] = useState('');
    const componentRef1 = React.useRef();
    const reactToPrintRef = useRef();
    const [isScanning, setIsScanning] = useState(true);
    const [overallData, setOverallData] = useState({ totalPlan: 0, totalOK: 0, totalNG: 0, totalComplete: 0 });
    const [listMapping, setListMapping] = useState([]);
    const tableRef = useRef();

    const [currentLot, setCurrentLot] = useState({});
    const columns = [
        { title: 'Tổng SL KH', dataIndex: 'totalPlan', width: '120px', render: (value) => numberFormat(value) },
        { title: 'Tổng SL OK', dataIndex: 'totalOK', width: '120px', render: (value) => numberFormat(value) },
        { title: 'Tổng SL NG', dataIndex: 'totalNG', width: '120px', render: (value) => numberFormat(value) },
        { title: 'Tỉ lệ hoàn thành', dataIndex: 'totalComplete', width: '140px', render: (value) => !isNaN(value) ? numberFormat(value) : 0 }
    ];

    const columns1 = [
        { title: 'Mã LSX', dataIndex: 'batch_id', width: '100px' },
        { title: 'Số Lot', dataIndex: 'lot_id', width: '100px' },
        { title: 'Số lượng bao', dataIndex: 'field3', width: '100px', render: (text, record) => (record.quantity / record.bag_stacking).toFixed(0) ?? '' },
        { title: 'Tổng số KG', dataIndex: 'quantity', width: '100px', render: (value) => !isNaN(value) ? numberFormat(value) : 0 }
    ];

    const columns2 = [
        { title: 'Số LSX', dataIndex: 'batch_id', width: '80px' },
        { title: 'Số Lot', dataIndex: 'lot_id', width: '100px' },
        { title: 'Mã nội bộ', dataIndex: 'product_id', width: '140px' },
        { title: 'Mã khách hàng', dataIndex: 'customer_id', width: '140px' },
        { title: 'Số bao KH', dataIndex: 'bag_number', width: '100px' },
        { title: 'Số kg KH', dataIndex: 'quantity_plan', width: '100px', render: (value) => numberFormat(value) },
        { title: 'Số bao TT', dataIndex: 'field11', width: '90px', render: (text, record) => (record.quantity / record.bag_stacking).toFixed(0) ?? '' },
        { title: 'Số kg TT', dataIndex: 'quantity', width: '90px', render: (value) => numberFormat(value) },
        { title: 'TG bắt đầu', dataIndex: 'start_time', width: '130px' },
        { title: 'TG kết thúc', dataIndex: 'end_time', width: '130px' },
        { title: 'TG hoàn thành', dataIndex: 'field12', width: '120px' },
        {
            title: 'Trạng thái', dataIndex: 'status', width: '120px', render: (text, record) => {
                switch (record.status) {
                    case 2: return <span>Hoàn thành</span>;
                    case 1: return <span>Đang sản xuất</span>;
                    default: return <span>Chờ sản xuất</span>;
                }
            }
        }
    ];

    const columnsMapping = [
        { title: 'Vít cấp liệu số', dataIndex: 'id', width: '40%', render: (_, record, index) => index + 1 },
        { title: 'Mã nguyên vật liệu', dataIndex: 'material_id', width: '60%' }
    ];

    const columnsInfoBom = [
        { title: 'Mã NVL', dataIndex: 'component_id', width: '100px', align: 'center' },
        { title: 'Lot no', dataIndex: 'lot_no', width: '100px', align: 'center' },
        { title: 'Tỷ lệ %', dataIndex: 'unit_weight', width: '100px', align: 'center', render: (value) => Number((Number(value) * 100).toFixed(2)) + '%' },
        {
            title: 'Số lượng', dataIndex: 'unit_weight', align: 'center', width: '80px', render: (text, record) => {
                const order = [1, 0, 2];
                const sortedArray = listHistoryLot.sort((a, b) => {
                    return order.indexOf(a.status) - order.indexOf(b.status);
                });
                return <span>{Number((record.unit_weight * sortedArray[0].volume_of_batch).toFixed(2))} kg</span>
            }
        },
        { title: 'Mô tả thành phần', dataIndex: 'component_description', width: '150px', align: 'center' },
    ];

    const rowsProductionAtribute = [
        { title: 'Thuộc tính', dataIndex: 'attribute', width: '100px', align: 'left' },
        { title: 'Giá trị', dataIndex: 'value', width: '100px', align: 'left' },
    ];

    const rowsPacking = [
        { title: 'Thuộc tính', dataIndex: 'attribute', width: '100px', align: 'left' },
        { title: 'Giá trị', dataIndex: 'value', width: '100px', align: 'left' },
    ];

    const rowsProductionAtributeIII = [
        { title: 'STT', dataIndex: 'stt', width: '50px', align: 'center', render: (_, record, index) => index + 1 },
        { title: 'Nội dung', dataIndex: 'attribute', width: '100px', align: 'left' },
        { title: 'Tiêu chuẩn', dataIndex: 'value', width: '100px', align: 'left' },
    ];

    const fetchHistoryLot = async () => {
        setSpecifiedRowKey(null);
        setLoadingTable(true);
        const start_date = dayjs().subtract(5, 'day').format('YYYY-MM-DD'); // 👈
        const end_date = dayjs().format('YYYY-MM-DD');
        // const response = await axiosInstance.get('/oi-lot-histories', { params: { equipment_id: selectedEquipment, start_date, end_date } });
        const response = await axiosInstance.get('/oi-lot-histories-latest', { params: { equipment_id: selectedEquipment, start_date, end_date } });
        setListHistoryLot((response.data ?? []).map((e, i) => {
            if (e.status == 1) {
                setSpecifiedRowKey(i);
            }
            return { ...e, key: i };
        }));
        const acc = { totalPlan: 0, totalOK: 0, totalNG: 0, totalComplete: 0 };
        response.data.forEach(e => {
            // console.log(e);
            acc.totalPlan += e.pallet_stacking;
            acc.totalOK += e.quantity - e.quantity_ng;
            acc.totalNG += e.quantity_ng;
        })
        acc.totalComplete = Number(acc.totalOK / acc.totalPlan * 100);
        setOverallData(acc);
        const res = response.data.find(e => e.status == 1) ?? {};
        console.log(res);
        setCurrentLot(res);
        setLoadingTable(false);
    };

    const fetchEquipment = async () => {
        try {
            const response = await equipmentApi.getEquipments();
            const options = response.data.data.map((e) => ({ value: e.id, label: e.equipment_name }));
            setEquipmentOptions(options);
            setSelectedEquipment(options[0]?.value);
        } catch (error) {
            message.error('Failed to fetch equipments');
        }
    };

    useEffect(() => {
        if (context && context.setTitle) {
            context.setTitle('QUẢN LÝ SẢN XUẤT');
        }
        fetchEquipment();
    }, [context]);

    useEffect(() => {
        if (selectedEquipment) {
            fetchHistoryLot();
        }
    }, [selectedEquipment]);

    const onSelectChange = (record, selected) => {
        setSelectedRowKey(selected ? record.key : null);
        setSelectedRow(selected ? record : null);
    };

    const rowSelection = {
        type: 'radio',
        selectedRowKeys: [selectedRowKey],
        onSelect: onSelectChange,
    };

    const rowClassName = (record) => {
        switch (record.status) {
            case 2:
                return 'table-row-grey';
            case 1:
                return 'table-row-green';
            default:
                return "";
        }
    }
    const handleQrScanner = async (res) => {
        if (!res || !isScanningRef.current) return;

        const order = [1, 0, 2];
        const sortedArray = listHistoryLot.sort((a, b) => {
            return order.indexOf(a.status) - order.indexOf(b.status);
        });
        const currentLot = sortedArray[0];
        console.log(currentLot);
        if (!currentLot || currentLot.status != 0) {
            message.error('Không có lot nào có thể cập nhật.');
            return;
        }

        isScanningRef.current = false;

        try {
            const response = await axiosInstance.post('mapping-material', {
                equipment_id: selectedEquipment,
                customer_id: currentLot.customer_id,
                pallet_id: res,
                pi: currentLot.pi,
                batch_id: currentLot.batch_id,
            });

            if (response.success) {
                if (response.data.length > 0) {
                    updateHistoryLot();
                    setIsModalVisible(false);
                }
                fetchMappingLog();
            }
        } catch (error) {
            message.error('Có lỗi xảy ra khi cập nhật mapping.');
            console.error(error);
        } finally {
            setTimeout(() => {
                isScanningRef.current = true;
            }, 2000);
        }
    };

    const startFirstLot = async () => {
        const order = [1, 0, 2];
        const sortedArray = listHistoryLot.sort((a, b) => {
            return order.indexOf(a.status) - order.indexOf(b.status);
        });
        const currentLot = sortedArray[0];
        console.log(currentLot);
        if (!currentLot || currentLot.status != 0) {
            message.error('Không có lot nào có thể cập nhật.');
            return;
        }

        await updateHistoryLot();
    }

    const updateHistoryLot = async () => {
        try {
            const order = [1, 0, 2];
            const sortedArray = listHistoryLot.sort((a, b) => {
                return order.indexOf(a.status) - order.indexOf(b.status);
            });
            const response = await axiosInstance.put(`lot-histories/${sortedArray[0]?.id}`, { status: '1' });
            if (response.success) {
                fetchHistoryLot();
            }
        } catch (error) {
            message.error('Failed to update lot history');
        }
    };

    const fetchMappingLog = async () => {
        try {
            const order = [1, 0, 2];
            const sortedArray = listHistoryLot.sort((a, b) => {
                return order.indexOf(a.status) - order.indexOf(b.status);
            });
            const response = await axiosInstance.get('mapping-log', {
                params: { batch_id: sortedArray[0]?.batch_id, equipment_id: selectedEquipment },
            });
            setListMapping(response.data);
        } catch (error) {
            message.error('Failed to fetch mapping log');
        }
    }

    const showModalMapping = () => {
        setIsScanning(true); // 👈 Để tạm
        // if (Object.keys(currentLot).length > 0) {
        //     setIsScanning(false);
        // }
        setIsModalVisible(true);
        fetchMappingLog();
    };

    const showModalInputQuantity = () => {
        if (Object.keys(currentLot).length > 0) {
            form.resetFields();
            if (currentLot?.quantity_plan && currentLot?.quantity === 0) {
                form.setFieldsValue({quantity: currentLot.quantity_plan});
            } else if (currentLot?.quantity_plan && currentLot?.quantity > 0) {
                form.setFieldsValue({quantity: currentLot.quantity});
            }
            setIsInputModalVisible(true);
        } else {
            message.error('KHÔNG CÓ LOT CÓ THỂ NHẬP SỐ LƯỢNG');
        }
    };

    const handleSubmitForm = async () => {
        try {
            const values = await form.validateFields();
            const response = await axiosInstance.put(`lot-histories/${currentLot.id}`, values);
            if (response.success) {
                fetchHistoryLot();
                setIsInputModalVisible(false);
            }
        } catch (error) {
            console.log('Failed:', error);
        }
    };

    const handleChangeEquipment = (value) => {
        setSelectedEquipment(value);
    };

    const showBomInfo = async () => {
        if (Object.keys(currentLot).length > 0) {
            var lotCurrent = currentLot;
        } else {
            var lotCurrent = listHistoryLot
                .filter(lot => lot.status === 0) // Step 1: Filter lots with status = 0
                .sort((a, b) => a.lot_id - b.lot_id) // Step 2: Sort by lot_id ascending
            [0];
        }
        if (lotCurrent?.product_id) {
            // const response = await axiosInstance.get(`bom-info`, { params: { customer_id: lotCurrent?.product_plan?.customer_id, pi: lotCurrent?.product_plan?.pi } });
            let payload = { customer_id: lotCurrent?.customer_id, pi: lotCurrent?.pi };
            if (lotCurrent?.product_plan?.customer_id && lotCurrent?.product_plan?.pi) {
                payload = { customer_id: lotCurrent?.product_plan?.customer_id, pi: lotCurrent?.product_plan?.pi };
            }
            const response = await axiosInstance.get(`bom-info`, { params: payload });
            if (response?.success) {
                setInfoBom(response.data);
    
                const order = [1, 0, 2];
                const sortedArray = listHistoryLot.sort((a, b) => {
                    return order.indexOf(a.status) - order.indexOf(b.status);
                });
    
                setInfoProduct(sortedArray[0]);
                loadProductAttributes(sortedArray[0]?.product_id);
                loadDataPacking(sortedArray[0]);
                setIsModalBomInfoVisible(true);
            }
        } else {
            message.error('KHÔNG CÓ LOT SẢN XUẤT NÀO');
        }
    };

    const printLot = async () => {
        if (selectedRow && selectedRow.status === 2) {//
            setHtmlContent(selectedRow);
            // try {
            //     const response = await axiosInstance.get(`lots/${selectedRow.lot_id}/print`);
            //     if (response?.success) {
            //         setHtmlContent(response.data);
            //     }
            // } catch (error) {
            //     message.error('Failed to print lot');
            // }
        } else {
            message.info('CHƯA CHỌN LOT SẢN XUẤT HOẶC LOT SẢN XUẤT CHƯA HOÀN THÀNH');
        }
    };

    const handleFinishBatch = async () => {
        try {
            const order = [1, 0, 2];
            const sortedArray = listHistoryLot.sort((a, b) => {
                return order.indexOf(a.status) - order.indexOf(b.status);
            });
            const response = await axiosInstance.put(`lot-histories/${sortedArray[0]?.id}`, { status: 2 });
            if (response.success) {
                fetchHistoryLot();
            }
        } catch (error) {
            message.error('Failed to finish batch');
        }
    };

    useEffect(() => {
        if (htmlContent) {
            reactToPrintRef.current.handlePrint();
            setHtmlContent('');
        }
    }, [htmlContent]);

    const pauseLot = async () => {
        if (currentLot) {
            try {
                const response = await axiosInstance.put('pause-tracking', {
                    lot_id: currentLot?.lot_id,
                    equipment_id: selectedEquipment,
                });
                if (response.success) {
                    fetchHistoryLot();
                }
            } catch (error) {
                message.error('Failed to pause lot');
            }
        } else {
            message.error('KHÔNG CÓ LOT NÀO ĐANG SẢN XUẤT');
        }
    };

    const loadProductAttributes = async (product_id) => {
        const attr1 = [
            'dieu_kien_tron',
            'thu_tu_do_nguyen_lieu',
            'thoi_gian_tron_min_phut',
            'toc_do_tron',
            'nhiet_do_nguyen_lieu_min',
            'nhiet_do_nguyen_lieu_max',
            'nhiet_vo',
            'bien_tan_min',
            'bien_tan_max',
        ];
        const attr2 = ['t1', 't2', 't3', 't4', 't5', 't6', 't7', 't8', 't9', 't10', 't11', 'dh'];
        const attr3 = [
            'ap_suat_chan_khong_min',
            'ap_suat_nhua_max',
            'nhiet_do_nuoc_lam_mat_nhua_min',
            'bien_tan_may_vat',
            'khoang_thoat_khi',
            'cuong_do_dong_dien',
            'toc_do_truc_vit',
            'cong_suat_may',
            'ngoai_quan_hat',
        ];
        const response = await axiosInstance.get(`/products-find`, { params: { id: product_id } });
        if (response.success) {
            const resources = response.data?.attribute_values ?? [];
            const attributes = [];
            const attributesII = {};
            const attributesIII = [];
            resources.map((row) => {
                if (attr1.includes(row?.product_attribute_id)) {
                    attributes.push({
                        attribute: row?.attribute.name,
                        value: row.value
                    });
                }
                if (attr2.includes(row?.product_attribute_id)) attributesII[row?.product_attribute_id] = row.value;
                if (row?.product_attribute_id === 'luoi_lap_theo_thu_tu') setFooterProductAttributesII(row?.value);
                if (attr3.includes(row?.product_attribute_id)) {
                    attributesIII.push({
                        attribute: row?.attribute.name,
                        value: row.value
                    });
                }
            });
            setProductAttributes(attributes);
            setProductAttributesII([{ ...attributesII }]);
            setProductAttributesIII(attributesIII);
        }
    }

    const loadDataPacking = (data) => {
        const fields = [
            'label',
            'packaging_type',
            'lot_pallet',
            'bag_stacking',
            'pallet_type',
            'pallet_stacking',
            'shrink_wrap',
        ];
        const fieldsName = {
            label: 'Tem',
            packaging_type: 'Loại bao bì',
            lot_pallet: 'Lót Pallet',
            bag_stacking: 'Khối lượng bao',
            pallet_type: 'Loại pallet',
            pallet_stacking: 'Xếp Pallet',
            shrink_wrap: 'Màng co, shipping',
        };
        const attributes = [];
        Object.keys(data).map((key) => {
            if (fields.includes(key)) attributes.push({ attribute: fieldsName[key], value: data[key] });
        })
        setDataPacking(attributes);
    }

    useEffect(() => {
        echo.connector.socket.on('connect', () => {
            console.log('WebSocket connected!');
        });
        echo.connector.socket.on('connect_error', (error) => {
            console.error('WebSocket connection error:', error);
        });
        echo.connector.socket.on('disconnect', () => {
            console.log('WebSocket disconnected!');
        });
        echo.channel('laravel_database_productionchannel')
            .listen('.my-event', (e) => {
                const lotID = e.productionData.lot_id;
                const quantity = e.productionData.quantity;
                setListHistoryLot((prev) => {
                    const index = prev.findIndex((lot) => lot.lot_id === lotID);
                    if (index !== -1) {
                        prev[index].quantity = quantity;
                        return [...prev];
                    }
                    return prev;
                });
                if (e.productionData.reload) {
                    fetchHistoryLot();
                }
            });
        return () => {
            echo.leaveChannel('laravel_database_productionchannel');
        };
    }, []);

    const [specifiedRowKey, setSpecifiedRowKey] = useState(null);
    const handleScrollToRow = async () => {
        if (specifiedRowKey !== null && tableRef.current) {
            tableRef.current?.scrollTo({ key: specifiedRowKey });
        }
    };
    useEffect(() => {
        listHistoryLot.length && handleScrollToRow();
    }, [specifiedRowKey, listHistoryLot]);

    return (
        <Content className="oi-content">
            <Row gutter={[8, 8]}>
                <Col span={6} className="section-top">
                    <div className="oi-select-header">Máy</div>
                    <Select
                        showSearch
                        optionFilterProp="label"
                        options={equipmentOptions}
                        value={selectedEquipment}
                        variant="borderless"
                        onChange={handleChangeEquipment}
                        className="oi-select"
                        placeholder="Chọn máy"
                    />
                </Col>
                <Col span={18}>
                    <Table
                        loading={loadingTable}
                        locale={{ emptyText: 'Không có dữ liệu' }}
                        className="custom-table"
                        dataSource={Object.keys(overallData).length > 0 ? [overallData] : []}
                        columns={columns.map((col) => ({ ...col, align: 'center' }))}
                        pagination={false}
                        size="small"
                        scroll={{ x: '480px' }}
                        bordered
                    />
                </Col>
                <Col span={24} className='section-middle'>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Table
                                locale={{ emptyText: 'Không có dữ liệu' }}
                                dataSource={Object.keys(currentLot).length > 0 ? [currentLot] : []}
                                columns={columns1.map((e) => ({ ...e, align: 'center' }))}
                                pagination={false}
                                scroll={{ x: '480px' }}
                                size="small"
                                bordered
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className='section-button'>
                    <div className="oi-manufacture-row-button">
                        <Button type="primary" block onClick={showModalMapping}>
                            <QrcodeOutlined style={{ fontSize: '20px' }} />
                            <span className="hidden-on-mobile">Mapping NVL</span>
                        </Button>
                        <Button type="primary" block onClick={startFirstLot}>
                            <span>Bắt đầu</span>
                        </Button>
                        <Button type="primary" onClick={showBomInfo}>
                            Thông tin mẻ trộn
                        </Button>
                        <Popconfirm
                            title="Xác nhận thay lưới?"
                            onConfirm={pauseLot}
                            onCancel={() => { }}
                            okText="Xác nhận"
                            cancelText="Hủy"
                        >
                            <Button type="primary">Thay lưới</Button>
                        </Popconfirm>
                        <Button type="primary" block onClick={printLot}>
                            <PrinterOutlined style={{ fontSize: '20px' }} />
                            <span className="hidden-on-mobile">In tem</span>
                        </Button>
                        <Button type="primary" block onClick={showModalInputQuantity}>
                            <EditOutlined style={{ fontSize: '20px' }} />
                            <span className="hidden-on-mobile">Nhập số lượng</span>
                        </Button>
                        {/* <Popconfirm
                            title="Xác nhận kết thúc đơn?"
                            onConfirm={handleFinishBatch}
                            onCancel={() => { }}
                            okText="Xác nhận"
                            cancelText="Hủy"
                        >
                            <Button type="primary" block>
                                <StopOutlined style={{ fontSize: '20px' }} />
                                <span className="hidden-on-mobile">Kết thúc đơn</span>
                            </Button>
                        </Popconfirm> */}
                    </div>
                </Col>
                <Col span={24}>
                    <Table
                        className='table-manufacture'
                        ref={tableRef}
                        loading={loadingTable}
                        locale={{ emptyText: 'Không có dữ liệu' }}
                        dataSource={listHistoryLot}
                        rowSelection={rowSelection}
                        onRow={(record) => ({
                            onClick: () => {
                                setSelectedRowKey(record.key);  // Cập nhật dòng được chọn khi click vào dòng
                                setSelectedRow(record);
                            },
                        })}
                        columns={columns2.map((e) => ({ ...e, align: 'center' }))}
                        pagination={false}
                        scroll={{ x: '1200px', y: tableHeight('table-manufacture') ?? 'calc(100vh - 400px)' }}
                        size="small"
                        bordered
                        rowClassName={rowClassName}
                        footer={() => specifiedRowKey !== null ? (
                            <FloatButton
                                type="primary"
                                icon={<RollbackOutlined />}
                                tooltip={<div>Quay lại lot đang chạy</div>}
                                style={{ position: 'absolute', bottom: 40, right: 30 }}
                                onClick={() => {
                                    fetchHistoryLot();
                                    handleScrollToRow();
                                }}
                            />
                        ) : null}
                    />

                </Col>
            </Row>
            <Modal
                centered
                title={'Mapping NVL'}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText="Thoát"
                width={500}
            >
                {isModalVisible && isScanning && <Scanner isScan={true} onResult={(res) => handleQrScanner(res)} />}
                <Table
                    dataSource={listMapping}
                    columns={columnsMapping.map((e) => ({ ...e, align: 'center' }))}
                    pagination={false}
                    scroll={{ x: true, y: 'calc(100vh - 400px)' }}
                    size="small"
                    bordered
                    rowClassName={rowClassName}
                />
            </Modal>

            <Modal
                centered
                title={'Nhập số lượng'}
                open={isInputModalVisible}
                onCancel={() => setIsInputModalVisible(false)}
                onOk={handleSubmitForm}
                okText="Lưu"
                cancelText="Thoát"
                width={500}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <Form.Item
                        name="quantity"
                        label="Số lượng"
                        rules={[{ required: true, message: 'Vui lòng nhập số lượng!' }]}
                    >
                        <InputNumber style={{ width: '100%' }} min={0} placeholder="Số lượng" />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Thông tin đơn sản xuất"
                open={isModalBomInfoVisible}
                onCancel={() => setIsModalBomInfoVisible(false)}
                footer={null}
                width={'80%'}
                style={{ top: 20 }}
            >
                <div>
                    <Row>
                        <Col span={12}>
                            <Text strong>Số đơn sản xuất:</Text> {` ${infoProduct?.batch_id}`}
                        </Col>
                        <Col span={12}>
                            <Text strong>Số PI:</Text> {` ${infoProduct?.pi}`}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Text strong>Tên sản phẩm:</Text> {` ${infoProduct?.product_name}`}
                        </Col>
                        <Col span={12}>
                            <Text strong>Mã lô:</Text> {` ${infoProduct?.batch_code}`}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Text strong>Khối lượng:</Text> {` ${infoProduct?.quantity}`}
                        </Col>
                    </Row>
                    {/* Info bom */}
                    <Row>
                        <Col span={24}>
                            <Table
                                dataSource={infoBom}
                                columns={columnsInfoBom}
                                pagination={false}
                                size='small'
                                bordered
                                scroll={{ x: '430px' }}
                                footer={() => {
                                    const order = [1, 0, 2];
                                    const sortedArray = listHistoryLot.sort((a, b) => {
                                        return order.indexOf(a.status) - order.indexOf(b.status);
                                    });
                                    return <div style={{ fontSize: '16px' }}>Khối lượng mẻ trộn tiêu chuẩn (kg):  <span style={{ float: 'right' }}>{sortedArray[0]?.volume_of_batch}</span></div>
                                }}
                            />
                        </Col>
                    </Row>
                    {/* Product attributes */}
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Table
                                showHeader={false}
                                dataSource={productAttributes}
                                columns={rowsProductionAtribute}
                                pagination={false}
                                size='small'
                                bordered
                                scroll={{ x: '200px' }}
                                title={() => <b>Thuộc tính sản phẩm</b>}
                            />
                        </Col>
                    </Row>
                    {/* Quy cách đóng gói */}
                    <Divider />
                    <Row>
                        <Col span={24}>
                            <Table
                                showHeader={false}
                                dataSource={dataPacking}
                                columns={rowsPacking}
                                pagination={false}
                                size='small'
                                bordered
                                scroll={{ x: '200px' }}
                                title={() => <b>Quy cách đóng gói</b>}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title level={5}>I. Chuẩn bị máy trước khi chạy máy</Title>
                            <Table
                                columns={columnsII}
                                dataSource={productAttributesII}
                                pagination={false}
                                bordered
                                title={() => <b>1. Cài đặt nhiệt độ cho máy</b>}
                                footer={() => <span><b>2. Lưới lắp theo thứ tự:</b>{` ${footerProductAttributesII}`}</span>}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title level={5}>II. Kiểm tra trước khi chạy máy</Title>
                            <Col span={24}>
                                <Table
                                    dataSource={productAttributesIII}
                                    columns={rowsProductionAtributeIII}
                                    pagination={false}
                                    size='small'
                                    bordered
                                    scroll={{ x: '250px' }}
                                />
                            </Col>
                        </Col>
                    </Row>
                </div>
            </Modal>
            <div style={{display: 'none'}}>
                <ReactToPrint
                    ref={reactToPrintRef}
                    trigger={() => <button style={{ display: 'none' }}>Print this out!</button>}
                    content={() => componentRef1.current}
                />
                <div ref={componentRef1}>
                    {selectedRow && <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            <QRCode value={selectedRow?.lot_id} style={{width: '100%', height: '100%'}} />
                            <b>{selectedRow?.lot_id}</b>
                        </div>}
                </div>
                   {/* {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />} */}
            </div>
        </Content>
    );
};

export default OIManufacture;
