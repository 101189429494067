import axiosInstance from "./axiosInstance";

const getEquipments = (filters) => axiosInstance.get('/equipments', { params: filters });
const getEquipmentById = (id) => axiosInstance.get(`/equipments/${id}`);
const createEquipment = (equipmentData) => axiosInstance.post('/equipments', equipmentData);
const updateEquipment = (id, equipmentData) => axiosInstance.put(`/equipments/${id}`, equipmentData);
const deleteEquipment = (id) => axiosInstance.delete(`/equipments/${id}`);

export default {
  getEquipments,
  getEquipmentById,
  createEquipment,
  updateEquipment,
  deleteEquipment,
};
