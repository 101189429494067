import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PrinterOutlined } from '@ant-design/icons';
import axiosInstance from '../../../api/axiosInstance'; // Import axiosInstance
import './PermissionTable.css';
import { useReactToPrint } from 'react-to-print';
import permissionManagementApi from "../../../api/permissionManagementApi.js";
import config from '../../../config/config';
import AuthContext from '../../../context/AuthContext.js';

const PermissionTable = ({ filters }) => {
  const { user } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  useEffect(() => {
    if (htmlContent) {
      handlePrint();
    }
  }, [htmlContent]);

  useEffect(() => {
    setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
  }, []);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await permissionManagementApi.getPermissions(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data.data);
      response.data?.pagination && setPagination(response.data.pagination)
    } catch (error) {
      message.error('Failed to fetch permissions');
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsEditMode(false);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setIsEditMode(true);
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      try {
        if (isEditMode) {
          const response = await permissionManagementApi.updatePermission(editingRecord.id, values); // Thay đổi endpoint theo API của bạn
          if (response.success) {
            // setData((prevData) => [{ ...values }, ...prevData.filter(item => item.id !== editingRecord.id)]);
            await fetchData(pagination);
          } else throw new Error('Action failure');
        } else {
          const response = await permissionManagementApi.createPermission(values); // Thay đổi endpoint theo API của bạn
          if (response.success) {
            // setData((prevData) => [{ ...values, id: response.data.id }, ...prevData]);
            setPagination({ ...pagination, total: pagination.total++ });
            await fetchData(pagination);
          } else throw new Error('Action failure');
        }
        setIsModalVisible(false);
        form.resetFields();
      } catch (error) {
        message.error('Failed to save permission');
      }
    }).catch((error) => {
      console.log(error);
    });;
  };
  const handleDelete = async (id) => {
    try {
      await permissionManagementApi.deletePermission(id); // Thay đổi endpoint theo API của bạn
      setData((prevData) => prevData.filter(item => item.id !== id));
      // message.success('Xóa quyền hạn thành công');
    } catch (error) {
      message.error('Failed to delete equipment');
    }
  };
  const printRecord = async (record) => {
    const res = permissionManagementApi.getPermissionById(record.id);
    if (res.success) {
      setHtmlContent(res.data);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} - Upload thất bại`);
        }
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-permissions', // Đường dẫn API để xử lý upload file
    onChange: handleUploadChange,
    showUploadList: false, // Ẩn danh sách file
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Mã quyền hạn',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      align: 'center',
    },
    {
      title: 'Tên quyền hạn',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {permissions.includes('permission.update') && (<Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
          {permissions.includes('permission.delete') && (<Popconfirm
            title="Bạn có chắc chắn muốn xóa?"
            onConfirm={() => handleDelete(record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
          </Popconfirm>)}
        </Space>
      ),
      align: 'center',
    },
  ];
  return (
    <Card
      title="Quản lý quyền hạn"
      extra={
        <div className="table-actions">
          {/* <Button type="primary">Xuất file</Button> */}
          {/* <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
              {uploading ? 'Đang tải lên' : 'Upload Excel'}
            </Button>
          </Upload> */}
          {permissions.includes('permission.create') && (<Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm quyền hạn</Button>)}
        </div>
      }
    >
      <Table
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((record, key) => { return { key, ...record } })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, page, pageSize });
            fetchData({ ...filters, page, pageSize });
          }
        }}
        bordered  // Thêm thuộc tính bordered để có viền
        size="small"  // Sử dụng kích thước bảng nhỏ
      />
      <div ref={componentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa quyền hạn" : "Thêm quyền hạn"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={400}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item name="slug" label="Slug" rules={[{ required: true, message: 'Vui lòng nhập!' }]}>
            <Input placeholder="Slug" />
          </Form.Item>
          <Form.Item name="name" label="Tên quyền hạn" rules={[{ required: true, message: 'Vui lòng nhập tên quyền hạn!' }]}>
            <Input placeholder="Nhập tên quyền hạn" />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default PermissionTable;
