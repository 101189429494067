import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import './MainLayout.css';

const { Header, Content } = Layout;

const MainLayout = () => (
  <Layout>
      <Outlet />
  </Layout>
);

export default MainLayout;
