import axiosInstance from "./axiosInstance";

const getProducts = (filters) => axiosInstance.get('/products', { params: filters });
const getProductById = (id) => axiosInstance.get(`/products/${id}`);
const createProduct = (productData) => axiosInstance.post('/products', productData);
const updateProduct = (id, productData) => axiosInstance.put(`/products/${id}`, productData);
const deleteProduct = (id) => axiosInstance.delete(`/products/${id}`);
const printProduct = (id) => axiosInstance.get(`/products/printer/${id}`);

export default {
  getProducts,
  getProductById,
  createProduct,
  updateProduct,
  deleteProduct,
  printProduct
};
