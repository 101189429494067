import React from 'react';
import { Layout, DatePicker, Input, Button, Form, Divider } from 'antd';

const { Sider } = Layout;

const FinishedProductExportSidebar  = ({ onFilter }) => {
    const [form] = Form.useForm();

    const handleFinish = (values) => {
        onFilter(values);
    };

    return (
        <Sider width={250} className="site-layout-sider">
            <div className="sidebar-content">
                <Divider>Tìm kiếm</Divider>
                <div className="mb-3">
                    <Form
                        form={form}
                        style={{ margin: '0 15px' }}
                        layout="vertical"
                        onFinish={handleFinish}
                    >
                        <Form.Item label="Mã phiếu xuất" name="id" className="mb-3">
                            <Input placeholder="Nhập mã phiếu xuất" />
                        </Form.Item>
                        <Form.Item label="Ngày xuất" name="export_date" className="mb-3">
                            <DatePicker style={{ width: '100%' }} placeholder='Ngày xuất' />
                        </Form.Item>
                    </Form>
                </div>
                <div className="sidebar-footer">
                    <Button
                        type="primary"
                        style={{ width: '80%' }}
                        onClick={() => form.submit()}
                    >
                        Truy vấn
                    </Button>
                </div>
            </div>
        </Sider>
    );
};

export default FinishedProductExportSidebar ;
