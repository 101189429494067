import { Layout } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import React from 'react';
import UISidebar from '../components/Sidebar';

dayjs.locale('vi');

const { Sider } = Layout;
const formFields = [
  {
    type: 'DatePicker',
    label: 'Ngày bắt đầu',
    name: 'start_date',
    placeholder: 'Ngày bắt đầu',
  },
  {
    type: 'DatePicker',
    label: 'Ngày kết thúc',
    name: 'end_date',
    placeholder: 'Ngày kết thúc',
  },
  // Add other fields here
];

const KPISidebar = ({onFilter}) => {
  return (
    <UISidebar formFields={formFields} onFilter={onFilter}/>
  );
};

export default KPISidebar;
