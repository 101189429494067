import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import KPITable from './KPITable';
import KPISidebar from './KPISidebar';

const { Header, Content } = Layout;

const KPIManagement = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  return (
    <Layout>
      <Layout>
        {/* <KPISidebar onFilter={handleFilter}/> */}
        <Content className="site-layout-content">
          <KPITable filters={filters}/>
        </Content>
      </Layout>
    </Layout>
  );
};

export default KPIManagement;
