// src/pages/UI/BOMManagement/BOMManagement.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import BOMManagementSidebar from './BOMManagementSidebar';
import BOMManagementTable from './BOMManagementTable';

const { Header, Content } = Layout;

const BOMManagement = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  // useEffect(()=>{
  //   console.log(filters);
  // }, [filters])
  return (
      <Layout>
         <BOMManagementSidebar onFilter={handleFilter}/>
        <Content className="site-layout-content">
          <div style={{height: '100%'}}>
            <BOMManagementTable filters={filters}/>
          </div>
        </Content>
      </Layout>
  );
};

export default BOMManagement;
