// src/pages/UI/MaterialManagement/MaterialSidebar.js
import React, { useRef, useState } from 'react';
import { Layout, Tree, DatePicker, Input, Button, Form, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../components/Sidebar';
import axiosInstance from '../../../api/axiosInstance';
import config from '../../../config/config';

dayjs.locale('vi');

const { Sider } = Layout;

const MaterialManagementSidebar = ({ onFilter }) => {
  const [pickerType, setPickerType] = useState('date');
  const [materialOptions, setMaterialOptions] = useState([]);
  const [materialNameOptions, setMaterialNameOptions] = useState([]);
  const timer = useRef();

  const formFields = [
    // {
    //   type: 'Input',
    //   label: 'Mã nguyên vật liệu',
    //   name: 'id',
    //   placeholder: 'Mã NVL',
    // },
    // {
    //   type: 'Input',
    //   label: 'Tên nguyên vật liệu',
    //   name: 'material_name',
    //   placeholder: 'Tên NVL',
    // }
    {
      type: 'AutoComplete',
      label: 'Mã nguyên vật liệu',
      name: 'id',
      placeholder: 'Mã nguyên vật liệu',
      options: materialOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchMaterial({ id: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
    {
      type: 'AutoComplete',
      label: 'Tên nguyên vật liệu',
      name: 'material_name',
      placeholder: 'Tên nguyên vật liệu',
      options: materialNameOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchMaterial({ material_name: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
  ];

  const fetchMaterial = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/materials', { params });
    if (response?.success) {
      setMaterialOptions((response.data?.data ?? []).map((item) => ({ label: item?.id, value: item?.id })));
      setMaterialNameOptions((response.data?.data ?? []).map((item) => ({ label: item?.material_name, value: item?.material_name })));
    }
  }

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default MaterialManagementSidebar;
