import axiosInstance from "./axiosInstance";

const getMaterialCategories = (filters) => axiosInstance.get('/material-categories', { params: filters });
const getMaterialCategoryById = (id) => axiosInstance.get(`/material-categories/${id}`);
const createMaterialCategory = (materialCategoryData) => axiosInstance.post('/material-categories', materialCategoryData);
const updateMaterialCategory = (id, materialCategoryData) => axiosInstance.put(`/material-ctegories/${id}`, materialCategoryData);
const deleteMaterialCategory = (id) => axiosInstance.delete(`/material-categories/${id}`);

export default {
  getMaterialCategories,
  getMaterialCategoryById,
  createMaterialCategory,
  updateMaterialCategory,
  deleteMaterialCategory,
};
