export const tableHeight = (name, windowHeight = null) => {
    const oiHeader = document.querySelector(".oi-header");
    const oiContent = document.querySelector(".oi-content");
    const oiFooter = document.querySelector(".oi-footer");
    if (oiContent) {
        const sectionTop = oiContent?.querySelector(".section-top");
        const sectionMiddle = oiContent?.querySelector(".section-middle");
        const sectionButton = oiContent?.querySelector(".section-button");
        const table = oiContent?.querySelector(`.${name}`);
        if (table) {
            const header = table?.querySelector(".ant-table-thead");
            const pagination = table?.querySelector(".ant-table-pagination");
            const tableBody = table?.querySelector(".ant-table-body");
            const antEmpty = table?.querySelector(".ant-empty-normal");
            const windowH = windowHeight ?? (oiContent?.offsetHeight ?? 0)
            console.log(
                windowH,
                oiHeader?.offsetHeight,
                oiFooter?.offsetHeight,
                sectionTop?.offsetHeight,
                sectionMiddle?.offsetHeight,
                sectionButton?.offsetHeight,
                header?.offsetHeight,
                pagination?.offsetHeight
            )
            const result =
                windowH -
                (oiHeader?.offsetHeight ?? 0) -
                (oiFooter?.offsetHeight ?? 0) -
                (sectionTop?.offsetHeight ?? 0) -
                (sectionMiddle?.offsetHeight ?? 0) -
                (sectionButton?.offsetHeight ?? 0) -
                (header?.offsetHeight ?? 0) -
                (pagination?.offsetHeight ?? 0) - 15;

            console.log('OITableHeight', result);
            if (Number(result) > 0) {
                if (tableBody) tableBody?.style.setProperty("height", `${result}px`, "important");
                if (antEmpty) antEmpty?.style.setProperty("height", `${result - 114}px`);
                return result;
            }
        }
    }
    return undefined;
};
