import React, { useState } from 'react';
import { Layout } from 'antd';
import MaterialReceiptTable from './MaterialReceiptTable';
import MaterialReceiptSidebar from './MaterialReceiptSidebar';
import './MaterialReceiptManagement.css';

const { Content, Sider } = Layout;

const MaterialReceiptManagement = () => {
    const [filters, setFilters] = useState({});

    const handleFilter = (newFilters) => {
        setFilters(newFilters);
    };

    return (
        <Layout>
            <Sider className="hidden-on-mobile" width={250}>
                <MaterialReceiptSidebar onFilter={handleFilter} />
            </Sider>
            <Content className="site-layout-content">
                <div style={{height: "100%"}}>
                    <MaterialReceiptTable filters={filters} />
                </div>
            </Content>
        </Layout>
    );
};

export default MaterialReceiptManagement;
