// src/pages/UI/RoleManagement/RoleManagement.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import RoleSidebar from './RoleSidebar';
import RoleTable from './RoleTable';

const { Header, Content } = Layout;

const RoleManagement = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  // useEffect(()=>{
  //   console.log(filters);
  // }, [filters])
  return (
      <Layout>
         <RoleSidebar onFilter={handleFilter}/>
        <Content className="site-layout-content">
          <RoleTable filters={filters}/>
        </Content>
      </Layout>
  );
};

export default RoleManagement;
