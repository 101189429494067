import { Layout } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import React from 'react';
import UISidebar from '../components/Sidebar';
import './EnginePowerSidebar.css';

dayjs.locale('vi');

const { Sider } = Layout;
const EnginePowerSidebar = ({ onFilter }) => {

  const formFields = [
    {
      type: 'Input',
      label: 'Năng suất',
      name: 'power',
      placeholder: 'Vui lòng nhập',
    },
  ];

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default EnginePowerSidebar;
