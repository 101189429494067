import axiosInstance from "./axiosInstance";

const getFinishedProductImports = (filters) => axiosInstance.get('/finished-product-imports', { params: filters });
const getFinishedProductImportById = (id) => axiosInstance.get(`/finished-product-imports/${id}`);
const createFinishedProductImport = (finishedProductImportData) => axiosInstance.post('/finished-product-imports', finishedProductImportData);
const updateFinishedProductImport = (id, finishedProductImportData) => axiosInstance.put(`/finished-product-imports/${id}`, finishedProductImportData);
const deleteFinishedProductImport = (id) => axiosInstance.delete(`/finished-product-imports/${id}`);

const getFinishedProductImportItems = (filters) => axiosInstance.get('/finished-product-import-items', { params: filters });

export default {
  getFinishedProductImportItems,
  getFinishedProductImports,
  getFinishedProductImportById,
  createFinishedProductImport,
  updateFinishedProductImport,
  deleteFinishedProductImport,
};
