// src/pages/UI/MaterialManagement/MaterialManagement.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import WarehouseLocationSidebar from './WarehouseLocationManagementSidebar';
import WarehouseLocationTable from './WarehouseLocationManagementTable';

const { Header, Content } = Layout;

const WarehouseLocationManagement = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  return (
    <Layout>
      <WarehouseLocationSidebar onFilter={handleFilter}/>
      <Layout>
        <Content className="site-layout-content">
          <WarehouseLocationTable filters={filters}/>
        </Content>
      </Layout>
    </Layout>
  );
};

export default WarehouseLocationManagement;
