import { CloseOutlined } from '@ant-design/icons';
import { Pie } from '@ant-design/plots';
import { Button, Descriptions, Flex, Layout, Modal, Select, Skeleton, Spin, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { Group, Layer, Rect, Stage, Text } from 'react-konva';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import palletManagementApi from '../../api/palletManagementApi';
import warehouseEntryManagementApi from '../../api/warehouseEntryManagementApi';
import warehouseManagementApi from '../../api/warehouseManagementApi';
import warehouseLocationManagementApi from '../../api/warehouseLocationManagementApi';
import { numberFormat } from '../../utils/numberFormat';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const { Header, Sider, Content } = Layout;

const columns = [
    {
        title: 'Mã pallet',
        dataIndex: 'warehouseentryable_id',
        key: 'warehouseentryable_id',
        align: 'center',
    },
    {
        title: 'Tên NVL',
        dataIndex: 'material_name',
        key: 'material_name',
        align: 'center',
        render: (_, record) => record?.material?.material_name,
    },
    {
        title: 'Số lượng',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
        render: (value) => numberFormat(value),
    },
    {
        title: 'Đơn vị',
        dataIndex: 'unit_name',
        key: 'unit_name',
        align: 'center',
        render: (_, record) => record?.unit?.unit_name,
    },
];

const columnsProduct = [
    {
        title: 'Mã lot',
        dataIndex: 'warehouseentryable_id',
        key: 'warehouseentryable_id',
        align: 'center',
    },
    {
        title: 'Tên SP',
        dataIndex: 'product_name',
        key: 'product_name',
        align: 'center',
        render: (_, record) => record?.lot?.product?.product_name,
    },
    {
        title: 'Số lượng',
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
        render: (value) => numberFormat(value),
    },
    {
        title: 'Đơn vị',
        dataIndex: 'unit_name',
        key: 'unit_name',
        align: 'center',
        render: (_, record) => record?.unit?.unit_name,
    },
];

const DBWarehouse = () => {
    const navigate = useNavigate();
    const [rects, setRects] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedMaterials, setSelectedMaterials] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRect, setSelectedRect] = useState(null);
    const [warehouseOptions, setWarehouseOption] = useState([]);
    const [warehouseSelected, setWarehouseSelected] = useState(null);
    const [pieData, setPieData] = useState({ used: 0, remain: 100 });
    const [locationSelected, setLocationSelected] = useState([]);

    const inventoryColumns = [
        {
            title: warehouseSelected === 'TP001' ? 'Tên TP' : 'Tên NVL',
            dataIndex: 'material_name',
            key: 'material_name',
            align: 'left',
            render: (_, record) => record?.material_name ?? record?.product_name,
        },
        {
            title: 'SL tồn',
            dataIndex: 'total_quantity',
            key: 'total_quantity',
            align: 'right',
            width: 80,
            render: (value) => numberFormat(value),
        },
    ];

    const fetchInventories = async () => {
        let model = 'pallet';
        if (warehouseSelected === 'TP001') model = 'lot';
        setLoading(true);
        const response = await warehouseEntryManagementApi.getWarehouseEntriesInventory({ model, warehouse_id: warehouseSelected });
        if (response?.success) {
            setInventories(response.data);
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const fetchWarehouses = async (params = null) => {
        const response = await warehouseManagementApi.getInventories(params);
        if (response?.success) {
            const data = response.data?.data ?? [];
            setWarehouseOption(data.map((item) => {
                return { label: item?.warehouse_name, value: item?.id };
            }))
            if (data.length > 0 && !warehouseSelected) {
                handleWarehouseChanged(data[0]?.id);
            }
        }
    }

    const fetchData = async () => {
        try {
            const res = await axiosInstance.get('/warehouse-location-list', { params: { warehouse_id: warehouseSelected } });
            setRects(res.data);
        } catch (error) {
            console.error('Có lỗi xảy ra khi lấy dữ liệu:', error);
            notification.error({
                message: 'Lỗi',
                description: 'Có lỗi xảy ra khi lấy dữ liệu.',
            });
        }
    };

    useEffect(() => {
        fetchWarehouses();
    }, []);

    useEffect(() => {
        if (warehouseSelected) {
            fetchData();
            fetchInventories();
        }
    }, [warehouseSelected]);

    const handleClick = async (record) => {
        setLocationSelected(record);
        // setLoading(true);
        try {
            console.log(record);
            const id = record?.id;
            const response = await warehouseEntryManagementApi.getWarehouseEntries({ warehouse_location_id: id, withs: 'lot.product' });
            if (response?.success) {
                const resource = response.data?.data ?? [];
                setSelectedMaterials(resource?.filter((item) => item?.warehouseentryable_type === 'App\\Models\\Pallet'));
                setSelectedProducts(resource?.filter((item) => item?.warehouseentryable_type === 'App\\Models\\Lot'));
                setSelectedRect(response.data);
                setModalVisible(true);
            }
        } catch (error) {
            console.error('Có lỗi xảy ra khi lấy thông tin vị trí:', error);
            notification.error({
                message: 'Lỗi',
                description: 'Có lỗi xảy ra khi lấy thông tin vị trí.',
            });
        } finally {
            // setLoading(false);
        }
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setSelectedMaterials([]);
        setSelectedRect(null);
    };

    const handleWarehouseChanged = async (value) => {
        setWarehouseSelected(value);
        const response = await warehouseLocationManagementApi.getWarehouseLocationsCapacity(value);
        if (response?.success) {
            const { used: u, total } = response.data;
            const used = Number(u);
            if (total > 0) {
                setPieData({ used, remain: 100 - used });
                // setPieData([
                //     { type: 'Đã sử dụng', value: used },
                //     { type: 'Còn trống', value: 100 - used },
                // ]);
            } else setPieData({ used: 0, remain: 100 });
        }
    }

    const getFillColor = (data) => {
        // https://codevivu.com/color-picker/
        const entries = (data?.warehouse_entry ?? []).reduce((a, b) => { return a + Number(b?.quantity) }, 0);
        if (entries > 0) {
            if (entries === Number(data?.capacity)) return '#b71c1c';
            return '#F57C00';
        }
        return '#1677ff';
    }

    const pieConfig = {
        data: pieData,
        angleField: 'value',
        colorField: 'type',
        innerRadius: 0.58,
        width: 180,
        height: 180,
        label: null,
        annotations: [
            {
                type: 'text',
                style: {
                    text: `${String(pieData[0]?.value)}%`,
                    x: '50%',
                    y: '50%',
                    textAlign: 'center',
                    fontSize: 16,
                    fontStyle: 'bold',
                },
            },
        ],
    };

    const options = {
        chart: {
            type: 'pie',
            height: 280, // Adjust the height for a smaller donut chart
            backgroundColor: 'transparent', // Make sure background matches your design
        },
        title: {
            text: null,
        },
        plotOptions: {
            pie: {
                innerSize: '60%', // Adjust the inner size to make it a donut
                size: '80%', // Adjust the overall size of the pie chart
                dataLabels: {
                    enabled: false, // Disable default data labels
                },
                showInLegend: true,
                center: ['50%', '50%'], // Center the donut chart
            },
        },
        series: [
            {
                name: 'Usage',
                data: [
                    {
                        name: 'Đã sử dụng',
                        y: pieData.used,
                        color: '#007bff', // Blue color
                    },
                    {
                        name: 'Còn trống',
                        y: pieData.remain,
                        color: '#00cec9', // Teal color
                    },
                ],
            },
        ],
        legend: {
            align: 'center',
            verticalAlign: 'top',
            layout: 'horizontal',
            itemStyle: {
                fontSize: '14px',
                fontWeight: 'normal',
            },
        },
        credits: {
            enabled: false, // Disable the Highcharts.com watermark
        },
        // Adding a custom data label in the center
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>',
        },
    };

    const centerTextStyle = {
        position: 'absolute',
        textAlign: 'center',
        width: '100%',
        top: '56%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '20px',
        color: '#333',
        fontWeight: 'bold',
    };

    const renderTextLocation = (data) => {
        if (Number(data?.capacity) > 1000) {
            const entries = (data?.warehouse_entry ?? []).reduce((a, b) => { return a + Number(b?.quantity) }, 0);
            const percent = Number((entries / Number(data.capacity) * 100).toFixed(1))
            // return `${data.id} (${percent}%)`;
            const remain = Number(data.capacity) - Number(entries);
            return `${data.id} (${percent}% - ${remain})`;
        } else return data.id;
    }

    const renderTextLocationHorizontal = (data) => {
        if (Number(data?.capacity) > 1000) {
            const entries = (data?.warehouse_entry ?? []).reduce((a, b) => { return a + Number(b?.quantity) }, 0);
            const percent = Number((entries / Number(data.capacity) * 100).toFixed(1))
            const remain = Number(data.capacity) - Number(entries);
            return `${data.id}\n\n${percent}%\n${remain}`;
        } else return data.id;
    }

    const remainLocation = (data) => {
        const entries = (data?.warehouse_entry ?? []).reduce((a, b) => { return a + Number(b?.quantity) }, 0);
        const remain = Number(data.capacity) - Number(entries);
        return remain;
    }

    const items = [
        {
            key: '1',
            label: 'Vị trí',
            children: locationSelected?.id,
        },
        {
            key: '3',
            label: 'Sức chứa',
            children: locationSelected?.capacity,
        },
        {
            key: '4',
            label: 'Còn trống',
            children: remainLocation(locationSelected),
        },
    ]

    return (
        <Layout>
            <Header style={{ background: 'blue', color: 'white', textAlign: 'center', fontWeight: '600', fontSize: '24px', padding: 0 }}>
                DASHBOARD KHO
                <div style={{ display: 'inline-block', float: 'right', alignItems: 'center', lineHeight: '56px' }}>
                    <Button style={{ marginRight: '15px' }} icon={<CloseOutlined />} onClick={() => navigate('/')} />
                </div>
            </Header>
            <Layout>
                <Sider width={(window.innerWidth * 20) / 100} style={{ background: '#ededed' }}>
                    <Select
                        loading={loading}
                        disabled={loading}
                        align={'center'} style={{ marginTop: '10px', padding: '0 5px' }}
                        options={warehouseOptions}
                        value={warehouseSelected}
                        onChange={handleWarehouseChanged} />
                    {/* <div style={{ textAlign: 'center' }}>
                        <Pie {...pieConfig} />
                    </div> */}
                    <div style={{ position: 'relative', height: '280px' }}>
                        <HighchartsReact highcharts={Highcharts} options={options} />
                        <div style={centerTextStyle}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span>{pieData.used}%</span>
                                <span style={{ fontSize: '9px', color: 'gray' }}>(Đã sử dụng)</span>
                            </div>
                        </div>
                    </div>
                    <Table
                        bordered
                        style={{ padding: '10px' }}
                        size='small'
                        columns={inventoryColumns}
                        dataSource={inventories}
                        rowKey="id"
                        pagination={false}
                        scroll={{ x: true, y: '50vh' }}
                    />

                </Sider>
                <Content style={{ background: '#1677ff29', padding: '5px' }}>
                    {!loading && rects.length === 0 && (<div style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span>Dữ liệu trống</span></div>)}
                    <Skeleton active loading={loading} paragraph={{ rows: 25 }}>
                        <Stage width={(window.innerWidth * 75) / 100} height={window.innerHeight - 60}>
                            {/* Kho NVL */}
                            {warehouseSelected === 'NVL 001' && (
                                <Layer>
                                    <Group x={45} y={10}>
                                        <Rect width={50} height={430} fill={'blue'} />
                                        <Text text={'Khu A'} width={50} height={430} align="center" verticalAlign="middle" fill='white' />
                                    </Group>
                                    <Group x={1045} y={10}>
                                        <Rect width={50} height={430} fill={'blue'} />
                                        <Text text={'Khu B'} width={50} height={430} align="center" verticalAlign="middle" fill='white' />
                                    </Group>
                                    <Group x={45} y={482}>
                                        <Rect width={50} height={334} fill={'blue'} />
                                        <Text text={'Khu C'} width={50} height={334} align="center" verticalAlign="middle" fill='white' />
                                    </Group>
                                    <Group x={1045} y={482}>
                                        <Rect width={50} height={334} fill={'blue'} />
                                        <Text text={'Khu D'} width={50} height={334} align="center" verticalAlign="middle" fill='white' />
                                    </Group>
                                    {rects.map(rect => (
                                        <Group key={rect.id} x={rect.x} y={rect.y} onClick={() => handleClick(rect)}>
                                            <Rect width={rect.width} height={rect.height} fill={getFillColor(rect)} />
                                            <Text text={renderTextLocation(rect)} width={rect.width} height={rect.height} align="center" fontSize={rect.capacity > 1000 ? 12 : 10} verticalAlign="middle" fill='white' />
                                        </Group>
                                    ))}
                                </Layer>
                            )}

                            {/* Kho TP */}
                            {warehouseSelected === 'TP001' && (
                                <Layer>
                                    <Group x={45} y={10}>
                                        <Rect width={50} height={358} fill={'blue'} />
                                        <Text text={'Khu A'} width={50} height={358} align="center" verticalAlign="middle" fill='white' />
                                    </Group>
                                    <Group x={1045} y={10}>
                                        <Rect width={50} height={622} fill={'blue'} />
                                        <Text text={'Khu B'} width={50} height={622} align="center" verticalAlign="middle" fill='white' />
                                    </Group>
                                    <Group x={45} y={406}>
                                        <Rect width={50} height={190} fill={'blue'} />
                                        <Text text={'Khu C'} width={50} height={190} align="center" verticalAlign="middle" fill='white' />
                                    </Group>
                                    <Group x={45} y={782}>
                                        <Rect width={1045} height={50} fill={'blue'} />
                                        <Text text={'Khu D'} width={1045} height={50} align="center" verticalAlign="middle" fill='white' />
                                    </Group>
                                    {rects.filter((item) => !String(item.id).includes('DT')).map(rect => (
                                        <Group key={rect.id} x={rect.x} y={rect.y} onClick={() => handleClick(rect)}>
                                            <Rect width={rect.width} height={rect.height} fill={getFillColor(rect)} />
                                            <Text text={renderTextLocation(rect)} width={rect.width} height={rect.height} align="center" fontSize={rect.capacity > 1000 ? 12 : 10} verticalAlign="middle" fill='white' />
                                        </Group>
                                    ))}
                                    {rects.filter((item) => String(item.id).includes('DT')).map(rect => (
                                        <Group key={rect.id} x={rect.x} y={rect.y} onClick={() => handleClick(rect)}>
                                            <Rect width={rect.width} height={rect.height} fill={getFillColor(rect)} />
                                            <Text text={renderTextLocationHorizontal(rect)} width={rect.width} height={rect.height} align="center" fontSize={11} verticalAlign="middle" fill='white' />
                                        </Group>
                                    ))}
                                </Layer>
                            )}

                            {/* Kho Bột đá */}
                            {warehouseSelected === 'NVL 005' && (
                                <Layer>
                                    <Group x={45} y={10}>
                                        <Rect width={50} height={238} fill={'blue'} />
                                        <Text text={'Khu A'} width={50} height={238} align="center" verticalAlign="middle" fill='white' />
                                    </Group>
                                    <Group x={45} y={316}>
                                        <Rect width={50} height={430} fill={'blue'} />
                                        <Text text={'Khu B'} width={50} height={430} align="center" verticalAlign="middle" fill='white' />
                                    </Group>
                                    {rects.map(rect => (
                                        <Group key={rect.id} x={rect.x} y={rect.y} onClick={() => handleClick(rect)}>
                                            <Rect width={rect.width} height={rect.height} fill={getFillColor(rect)} />
                                            <Text text={renderTextLocation(rect)} width={rect.width} height={rect.height} align="center" fontSize={rect.capacity > 1000 ? 12 : 10} verticalAlign="middle" fill='white' />
                                        </Group>
                                    ))}
                                </Layer>
                            )}
                        </Stage>
                    </Skeleton>
                </Content>
            </Layout>
            <Modal
                title="Thông tin chi tiết"
                visible={modalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={600}
            >
                {loading ? (
                    <Spin />
                ) : (
                    selectedRect && (
                        <div>
                            <Flex vertical gap="small">
                                <Descriptions items={items} column={7} layout='vertical' bordered />
                                {selectedMaterials.length > 0 && (
                                    <Table
                                        locale={{ emptyText: 'Dữ liệu trống' }}
                                        bordered
                                        size='small'
                                        columns={columns}
                                        dataSource={selectedMaterials}
                                        rowKey="id"
                                        pagination={false}
                                        scroll={{ y: '20vh' }}
                                    />
                                )}
                                {selectedProducts.length > 0 && (
                                    <Table
                                        bordered
                                        size='small'
                                        columns={columnsProduct}
                                        dataSource={selectedProducts}
                                        rowKey="id"
                                        pagination={false}
                                        scroll={{ y: '20vh' }}
                                    />
                                )}
                            </Flex>
                        </div>
                    )
                )}
            </Modal>
        </Layout>
    );
};

export default DBWarehouse;
