import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Modal, Popconfirm, Space, Table, Upload } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import axiosInstance from '../../../api/axiosInstance'; // Import axiosInstance
import departmentApi from "../../../api/departmentManagementApi";
import config from '../../../config/config';
import { b64toBlob } from '../../../utils/dataBlob';
import dayjs from 'dayjs';
import AuthContext from '../../../context/AuthContext';

const InspectionCriteriaTable = ({ filters }) => {
  const { user } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  useEffect(() => {
    if (htmlContent) {
      handlePrint();
    }
  }, [htmlContent]);

  useEffect(() => {
    setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
  }, []);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(config.apiUrl + '/inspection-criteria', { params: filters }); // Thay đổi endpoint theo API của bạn
      setData(response.data.data);
      response.data?.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  const showModal = () => {
    setIsEditMode(false);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setIsEditMode(true);
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      try {
        if (isEditMode) {
          const response = await departmentApi.updateDepartment(editingRecord.id, values); // Thay đổi endpoint theo API của bạn
          if (response.success) {
            setData((prevData) => [{ ...values }, ...prevData.filter(item => item.id !== editingRecord.id)]);
          } else throw new Error('Action failure');
        } else {
          const response = await departmentApi.createDepartment(values); // Thay đổi endpoint theo API của bạn
          if (response.success) {
            setData((prevData) => [{ ...values, id: response.data.id }, ...prevData]);
            setPagination({ ...pagination, total: pagination.total++ })
          } else throw new Error('Action failure');
        }
        setIsModalVisible(false);
        form.resetFields();
      } catch (error) {
        message.error('Failed to save product');
      }
    }).catch((error) => {
      console.log(error);
    });;
  };
  const handleDelete = async (id) => {
    try {
      await departmentApi.deleteDepartment(id); // Thay đổi endpoint theo API của bạn
      setData((prevData) => prevData.filter(item => item.id !== id));
      // message.success('Xóa phòng ban thành công');
    } catch (error) {
      message.error('Failed to delete equipment');
    }
  };
  const printRecord = async (record) => {
    const res = departmentApi.getDepartmentById(record.id);
    if (res.success) {
      setHtmlContent(res.data);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} - Upload thất bại`);
        }
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-inspection-criteria', // Đường dẫn API để xử lý upload file
    onChange: handleUploadChange,
    showUploadList: false, // Ẩn danh sách file
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'no',
      align: 'center',
      width: '70px',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Tên chỉ tiêu',
      dataIndex: 'criteria_name',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Tiêu chuẩn',
      dataIndex: 'acceptablerange',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Phân loại 1',
      dataIndex: 'type',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Mã công đoạn',
      dataIndex: 'process_id',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Mã NVL/SP',
      dataIndex: 'relationship_id',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Phân loại 2',
      dataIndex: 'model',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Phân loại 3',
      dataIndex: 'category',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      align: 'center',
      width: '100px',
      render: (value) => dayjs(value).format('DD/MM/YYYY'),
    },
    // {
    //   title: 'Hành động',
    //   key: 'action',
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />
    //       <Popconfirm
    //         title="Bạn có chắc chắn muốn xóa?"
    //         onConfirm={() => handleDelete(record.id)}
    //         okText="Có"
    //         cancelText="Không"
    //       >
    //         <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
    //       </Popconfirm>
    //     </Space>
    //   ),
    //   align: 'center',
    // },
  ];

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/departments-export');
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      title="Chỉ tiêu kiểm tra"
      extra={
        <div className="table-actions">
          {permissions.includes('inspection_criteria.create') && (<Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
              {uploading ? 'Đang tải lên' : 'Upload Excel'}
            </Button>
          </Upload>)}
        </div>
      }
    >
      <Table
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((record, key) => { return { key, ...record } })}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, page, pageSize });
            fetchData({ ...filters, page, pageSize });
          }
        }}
        bordered  // Thêm thuộc tính bordered để có viền
        size="small"  // Sử dụng kích thước bảng nhỏ
        scroll={{x: '970px'}}
      />
      <div ref={componentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa phòng ban" : "Thêm phòng ban"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={800}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <div className="modal-form-grid">
            {/* <Form.Item name="parent_id" label="Phòng ban" rules={[{ required: true, message: 'Vui lòng nhập phòng ban!' }]}>
              <Input />
            </Form.Item> */}
            <Form.Item name="id" label="Mã phòng ban" rules={[{ required: true, message: 'Vui lòng nhập mã phòng ban!' }]}>
              <Input disabled={isEditMode} />
            </Form.Item>
            <Form.Item name="name" label="Tên phòng ban" rules={[{ required: true, message: 'Vui lòng nhập tên phòng ban!' }]}>
              <Input />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

export default InspectionCriteriaTable;
