import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Form, Card, Upload, message, Popconfirm, Modal, Input, Select, InputNumber, DatePicker, AutoComplete, Row, Col, Descriptions, Checkbox, Switch } from 'antd';
import { EditOutlined, DeleteOutlined, UploadOutlined, PlusOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';
import materialReceiptApi from '../../../api/materialReceiptApi';
import config from '../../../config/config';
import { render } from '@testing-library/react';
import materialManagementApi from '../../../api/materialManagementApi';
import unitManagementApi from '../../../api/unitManagementApi';
import supplierApi from '../../../api/supplierApi';
import dayjs from 'dayjs';
import { numberFormat } from '../../../utils/numberFormat';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import axiosInstance from '../../../api/axiosInstance';

const MaterialReceiptTable = ({ filters }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [form] = Form.useForm();
    const [formSupplier] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState(null);
    const [data, setData] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [materialReceiptOptions, setMaterialReceiptOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [supplierOptions, setSupplierOptions] = useState([]);
    const [qualityCheckOptions, setQualityCheckOptions] = useState([
        { label: 'Chưa kiểm', value: 'Chưa kiểm' },
        { label: 'OK', value: 'OK' },
        { label: 'NG', value: 'NG' },
    ]);
    const [currentRowIndex, setCurrentRowIndex] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 20,
        total: 0,
    });

    useEffect(() => {
        fetchMaterial();
        fetchUnit();
        fetchSupplier();
        const paginate = { ...pagination, page: 1 };
        setPagination(paginate)
        fetchData({ ...filters, ...paginate });
    }, [filters]);

    const fetchData = async (params = {}) => {
        setLoading(true);
        try {
            if (params?.receipt_date) params.receipt_date = dayjs(params.receipt_date).format('YYYY-MM-DD');
            params.withs = 'history';

            const response = await materialReceiptApi.getMaterialReceipts(params);
            if (response.success) {
                setData(response.data.data);
                response.data?.pagination && setPagination(response.data.pagination)
            }
        } catch (error) {
            message.error('Failed to fetch data');
        }
        setLoading(false);
    };

    const fetchMaterial = async (params = null) => {
        try {
            const response = await materialManagementApi.getMaterials(params);
            await setMaterialOptions((response.data.data ?? []).map((item) => {
                return { label: <span>{item?.id}<small style={{ color: 'gray' }}>({item?.material_name})</small></span>, value: item?.id };
            }));
            if ((response.data.data ?? []).length === 0) message.info('NGUYÊN VẬT LIỆU TRỐNG!')
        } catch (error) {
            message.error(error.message);
        }
    }

    const fetchUnit = async () => {
        try {
            const response = await unitManagementApi.getUnits();
            await setUnitOptions((response.data.data ?? []).map((item) => {
                return { label: item?.unit_name, value: String(item?.id) };
            }));
            if ((response.data.data ?? []).length === 0) message.info('Dữ liệu ĐVT trống!')
        } catch (error) {
            message.error('Failed to fetch units');
        }
    }

    const fetchSupplier = async () => {
        try {
            const response = await supplierApi.getSuppliers();
            await setSupplierOptions((response.data.data ?? []).map((item) => {
                return { label: item?.supplier_name, value: item?.id };
            }));
            if ((response.data.data ?? []).length === 0) message.info('Dữ liệu nhà cung cấp trống!')
        } catch (error) {
            message.error('Failed to fetch suppliers');
        }
    }

    const showModal = async () => {
        setLoading(true);
        let material_receipt_id = null;
        setIsEditMode(false);
        setEditingRecord(null);
        form.setFieldsValue({
            receipt_date: dayjs(),
            material_receipt_id,
            materialItems: [{ material_id: null, supplier_id: null }],
        });
        setIsModalVisible(true);
        setLoading(false);
    };

    const showEditModal = async (record) => {
        setLoading(true);
        const rows = await record?.items?.map((row) => {
            return { material_id: row.material_id, supplier_id: row.supplier_id, packing: row.packing };
        });
        setIsEditMode(true);
        setEditingRecord(record);
        form.setFieldsValue({
            materialItems: rows
        });
        setIsModalVisible(true);
        setLoading(false);
    };

    const viewModal = async (record) => {
        setLoading(true);
        setEditingRecord(record);
        setIsViewModalVisible(true);
        setLoading(false);
    };

    const handleOk = async () => {
        form.validateFields().then(async (values) => {
            try {
                if (isEditMode) {
                    const response = await materialReceiptApi.updateMaterialReceipt(
                        editingRecord?.id,
                        {
                            material_items: values.materialItems,
                        }
                    );
                    if (response.success) await fetchData(pagination);
                } else {
                    const response = await materialReceiptApi.createMaterialReceipt({
                        material_items: values.materialItems,
                    });
                    if (response.success) {
                        setPagination({ ...pagination, total: pagination.total + 1 });
                        await fetchData(pagination);
                    }
                }
                setIsModalVisible(false);
                form.resetFields();
            } catch (error) {
                message.error('Failed to save');
            }
        }).catch((error) => { console.log(error) });
    };

    const handleDelete = async (id) => {
        try {
            const response = await materialReceiptApi.deleteMaterialReceipt(id);
            if (response?.success) {
                setPagination({ ...pagination, total: pagination.total - 1 });
                await fetchData(pagination);
            }
        } catch (error) {
            message.error('Failed to delete');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };


    const handleUploadChange = (info) => {
        if (info.file.status === 'uploading') {
            setUploading(true);
        } else {
            setUploading(false);
            if (info.file.status === 'done') {
                message.success(`${info.file.name} - Upload thành công`);
                fetchData({ ...filters, page: pagination.page, pageSize: pagination.pageSize });

            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} - Upload thất bại`);
            }
        }
    };

    const uploadProps = {
        name: 'file',
        accept: '.xlsx, .xls',
        action: config.apiUrl + '/import-material-receipts', // Thay đổi endpoint theo API của bạn
        onChange: handleUploadChange,
        showUploadList: false,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };

    const columns = [
        // {
        //     title: 'Xác nhận',
        //     dataIndex: 'status',
        //     key: 'status',
        //     align: 'center',
        //     width: 70,
        //     render: (text, record) => (
        //         <Checkbox
        //             checked={record.status === 1}
        //             onChange={(e) => handleStatusChange(record.id, e.target.checked)}
        //         />
        //     ),
        // },
        { title: 'STT', dataIndex: 'no', key: 'no', align: 'center', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1, width: 70 },
        { title: 'Mã phiếu nhập', dataIndex: 'id', key: 'id', align: 'center', width: 120 },
        { title: 'Ngày nhập', dataIndex: 'receipt_date', key: 'receipt_date', align: 'center', width: 100 },
        { title: 'Người nhập', dataIndex: 'created_by', key: 'created_by', align: 'center', width: 100, render: (_, record) => record?.user?.name },
        {
            title: 'Hành động', key: 'action', width: 100, render: (text, record) => (
                <Space size="middle">
                    <Button size='small' icon={<EyeOutlined />} onClick={() => viewModal(record)} />
                    <Button type={(record?.history ?? []).length > 0 ? undefined : 'primary'} size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} disabled={(record?.history ?? []).length > 0} />
                    <Popconfirm title="Bạn có chắc chắn muốn xóa?" onConfirm={() => handleDelete(record.id)} okText="Có" cancelText="Không" disabled={(record?.history ?? []).length > 0}>
                        <Button type='primary' size='small' icon={<DeleteOutlined />} danger disabled={(record?.history ?? []).length > 0} />
                    </Popconfirm>
                </Space>
            ), align: 'center'
        },
    ];

    const columnsViewTable = [
        { title: 'STT', dataIndex: 'no', key: 'no', width: '60px', align: 'center', render: (_, record, index) => index + 1 },
        { title: 'Mã NVL', dataIndex: 'material_id', key: 'material_id', align: 'center', width: '200px' },
        { title: 'Tên NVL', dataIndex: 'material_name', key: 'material_name', align: 'center', width: '200px', render: (_, record, index) => record?.material?.material_name },
        { title: 'Quy cách đóng gói', dataIndex: 'packing', key: 'packing', width: '170px', align: 'center' },
        { title: 'Số lượng', dataIndex: 'quantity', key: 'quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
        { title: 'ĐVT', dataIndex: 'unit_id', key: 'unit_id', align: 'center', width: '100px', render: (_, record, index) => record?.material?.unit?.unit_name },
        { title: 'Nhà cung cấp', dataIndex: 'supplier_name', key: 'supplier_name', align: 'center', width: '350px', render: (_, record, index) => <small>{record?.supplier?.supplier_name}</small> },
        { title: 'Ghi chú', dataIndex: 'note', key: 'note', width: '150px', align: 'center' },
    ];

    const itemsDescription = [
        { key: '1', label: 'Mã phiếu nhập', children: editingRecord?.id },
        { key: '2', label: 'Ngày nhập', children: editingRecord?.receipt_date },
        { key: '3', label: 'Người nhập', children: editingRecord?.user?.name },
    ];

    const handleStatusChange = async (id, checked) => {
        if (checked) {
            setLoading(true);
            const status = checked ? 1 : 0;
            const response = await materialReceiptApi.updateMaterialReceipt(id, { status });
            if (response?.success) {
                const newData = data.map(item => {
                    if (item.id === id) {
                        return { ...item, status };
                    }
                    return item;
                });
                setData(newData);
            } else message.error('CẬP NHẬT THẤT BẠI');
            setLoading(false);
        }
    };

    const handleOkSupplier = () => {
        formSupplier.validateFields().then(async (values) => {
            const response = await axiosInstance.post(config.apiUrl + '/suppliers', values);
            if (response.data?.id) {
                const { id, supplier_name } = response.data;
                await setSupplierOptions((prev) => [{ label: supplier_name, value: id }, ...prev]);
                formSupplier.resetFields();
                if (currentRowIndex !== null) {
                    const materialItems = form.getFieldValue('materialItems') || [];
                    materialItems[currentRowIndex] = {
                        ...materialItems[currentRowIndex],
                        supplier_id: id,
                    };
                    form.setFieldsValue({
                        materialItems,
                    });
                }
            }
        }).catch((error) => console.log(error));
    }

    return (
        <Card
            style={{ height: "100%" }}
            title="Quản lý phiếu nhập kho NVL"
            extra={
                <div className="table-actions">
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                            {uploading ? 'Đang tải lên' : 'Upload Excel'}
                        </Button>
                    </Upload>
                    <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Tạo lệnh nhập</Button>
                </div>
            }
        >
            <Table
                className="table-material-receipt"
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: (page, pageSize) => {
                        setPagination({ ...pagination, page, pageSize });
                        fetchData({ ...filters, page, pageSize });
                    },
                }}
                bordered
                size="small"
                scroll={{ x: 600, y: CalculateTableHeight("table-material-receipt") }}
            />

            <Modal
                title={
                    <div className="modal-title">
                        {isEditMode ? "Sửa phiếu nhập kho" : "Tạo phiếu nhập kho"}
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Lưu lại"
                cancelText="Thoát"
                width={800}
                loading={loading}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <Form.List name="materialItems">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <Row gutter={8} key={key}>
                                        <Col span={7} xs={12} md={7}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'material_id']}
                                                label={key === 0 ? "Mã nguyên vật liệu" : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập mã NVL!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Mã nguyên vật liệu" options={materialOptions.map(e => (form.getFieldValue('materialItems') ?? []).some(o => o?.material_id === e.value) ? ({ ...e, disabled: true }) : e)} showSearch optionFilterProp='value' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={7} xs={12} md={7}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'supplier_id']}
                                                label={key === 0 ? "Nhà cung cấp" : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập nhà cung cấp!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Nhà cung cấp"
                                                    options={supplierOptions}
                                                    showSearch
                                                    optionFilterProp='label'
                                                    onFocus={() => setCurrentRowIndex(index)}
                                                    dropdownStyle={{ width: 450 }}
                                                    dropdownRender={menu => (
                                                        <>
                                                            {menu}
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 8 }}>
                                                                <Form form={formSupplier} layout="vertical" name="form_in_modal">
                                                                    <div className="modal-form-grid">
                                                                        <Form.Item name="id" rules={[{ required: true, message: 'Nhập mã!' }]}>
                                                                            <Input placeholder='Mã nhà cung cấp' />
                                                                        </Form.Item>
                                                                        <Form.Item name="supplier_name" rules={[{ required: true, message: 'Nhập tên!' }]}>
                                                                            <Input placeholder='Tên nhà cung cấp' />
                                                                        </Form.Item>
                                                                    </div>
                                                                </Form>
                                                                <Button
                                                                    type="link"
                                                                    onClick={handleOkSupplier}
                                                                    style={{ flex: 'none', padding: '0 8px' }}
                                                                    icon={<PlusOutlined />}
                                                                >
                                                                    Thêm
                                                                </Button>
                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8} xs={20} md={8}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'packing']}
                                                label={key === 0 ? "Quy cách đóng gói" : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập quy cách đóng gói!',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Quy cách đóng gói" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2} xs={4} md={2}>
                                            <Form.Item label={key === 0 ? " " : ""}>
                                                <Button block icon={<CloseOutlined />} disabled={key === 0} onClick={() => remove(name)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Thêm nguyên vật liệu
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>

            {/* View modal */}
            <Modal
                title={
                    <div className="modal-title">
                        <span>Thông tin phiếu nhập</span>
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isViewModalVisible}
                onCancel={() => setIsViewModalVisible(false)}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText="Thoát"
                width={800}
                loading={loading}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Descriptions items={itemsDescription} layout="vertical" bordered />
                    <Table size="small" dataSource={editingRecord?.items} columns={columnsViewTable} bordered pagination={false} scroll={{ x: '1370px', y: 330 }} />
                </Space>
            </Modal>
        </Card>
    );
};

export default MaterialReceiptTable;
