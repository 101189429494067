import React, { useEffect, useState } from 'react';
import { Card, Row, Col, message } from 'antd';
import { Column, Pie } from '@ant-design/plots';
import productManagementApi from '../../../../api/productManagementApi';
import config from '../../../../config/config';
import axiosInstance from '../../../../api/axiosInstance';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const PQCCharts = ({ filters }) => {
    const [dataEquipment, setDataEquipment] = useState([]);
    const [dataDate, setDataDate] = useState([]);
    const [dataProduct, setDataProduct] = useState([]);

    const pieData = [
        {
            "value": 3.53,
            "frequency": 99,
            "id": "TP UV02"
        },
        {
            "value": 2.84,
            "frequency": 92,
            "id": "TP 101NR-1"
        },
        {
            "value": 1.75,
            "frequency": 88,
            "id": "TP UF106-1"
        },
        {
            "value": 1.79,
            "frequency": 65,
            "id": "TP UF111"
        }
    ]

    const pieConfig = {
        animation: false,
        appendPadding: 10,
        height: 200,
        // data: dataProduct,
        data: pieData,
        angleField: 'value',
        colorField: 'id',
        radius: 0.8,
        label: {
            text: (d) => `${d.id}\n ${d.value}`,
            position: 'spider',
        },
        legend: false,
    };
    function generateDataArray() {
        const data = [];
        const endDate = new Date();
        const numDays = 7;

        for (let i = 0; i < numDays; i++) {
            const date = new Date(endDate);
            date.setDate(endDate.getDate() - (numDays - 1 - i));

            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const formattedDate = `${day}/${month}`;

            const value = Math.floor(Math.random() * 7) + 4;

            data.push({ date: formattedDate, value });
        }

        return data;
    }
    const configColChart = {
        height: 200,
        // data: dataDate,
        data: generateDataArray(),
        xField: 'date',
        yField: 'value',
        yAxis: {
            min: 0,
        },
        label: {
            text: (d) => d.value + ' lỗi',
            textBaseline: 'bottom',
        },
        // line: {
        //     yField: 'target',
        //     style: {
        //         stroke: '#FF6B3B',
        //         lineWidth: 2,
        //     },
        // },
        tooltip: {
            channel: 'y', valueFormatter: (d) => {
                return d ?? "";
            }
        },
        legend: {
            position: 'top-left',
        },
    };

    //Test get data from api
    useEffect(() => {
        fetchData({ ...filters });
    }, [filters]);

    const [chartOptions, setChartOptions] = useState(null);
    const [pieOptions, setPieOptions] = useState(null);

    const getDatesInRange = (startDate, endDate) => {
        const dates = [];
        let currentDate = startDate;

        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
            dates.push(currentDate.format("DD/MM"));
            currentDate = currentDate.add(1, "day");
        }

        return dates;
    };

    useEffect(() => {
        // Simulating API response with updated data
        const data = [
            { day: "27/07", errors: 10, totalProduction: 40000 },
            { day: "28/07", errors: 12, totalProduction: 45000 },
            { day: "29/07", errors: 8, totalProduction: 42000 },
            { day: "30/07", errors: 15, totalProduction: 50000 },
            { day: "31/07", errors: 9, totalProduction: 46000 },
            { day: "01/08", errors: 5, totalProduction: 48000 },
            { day: "02/08", errors: 1, totalProduction: 55000 },
        ];

        // const categories = data.map(item => item.day);
        // const errorData = data.map(item => item.errors);
        // const percentageData = data.map(item => (item.errors / item.totalProduction) * 100);

        const result = [];
        if (filters?.start_date && filters?.end_date) {
            const dates = getDatesInRange(filters.start_date, filters.end_date);
            dates.forEach(date => {
                const findData = dataDate.find((item) => dayjs(item).format('DD/MM') === date);
                if (findData) result.push({date: findData.date, quantity_ng: findData.quantity_ng});
                else result.push({date, quantity_ng: 0});
            });
        } else {
            const dates = getDatesInRange(dayjs().subtract(7, 'days'), dayjs());
            dates.forEach(date => {
                const findData = dataDate.find((item) => dayjs(item).format('DD/MM') === date);
                if (findData) result.push({date: findData.date, quantity_ng: findData.quantity_ng});
                else result.push({date, quantity_ng: 0});
            });
        }

        const categories = result.map(item => item.date);
        const errorData = result.map(item => item.quantity_ng);
        const percentageData = result.map(item => 0);
        console.log(result);

        setChartOptions({
            chart: {
                zoomType: 'xy',
                height: 300
            },
            title: {
                text: 'Biểu đồ chất lượng'
            },
            xAxis: {
                categories: categories,
                crosshair: true
            },
            yAxis: [
                {
                    min: 0,
                    title: {
                        text: 'Số lỗi'
                    }
                },
                {
                    title: {
                        text: 'Tỉ lệ (%)'
                    },
                    opposite: true,
                    labels: {
                        format: '{value}%'
                    }
                }
            ],
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>'
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    pointWidth: 30
                },
                line: {
                    dataLabels: {
                        enabled: true,
                        format: '{y:.2f}%'
                    },
                    enableMouseTracking: true
                }
            },
            series: [
                {
                    name: 'Số lỗi',
                    type: 'column',
                    data: errorData,
                    color: '#007bff'
                },
                {
                    name: 'Tỉ lệ lỗi',
                    type: 'line',
                    yAxis: 1,
                    data: percentageData,
                    color: '#FFA500',
                    marker: {
                        enabled: true,
                        radius: 5
                    },
                    lineWidth: 2,
                    tooltip: {
                        valueSuffix: ''
                    }
                }
            ],
            credits: { enabled: false }
        });

        setPieOptions({
            chart: {
                type: 'pie',
                height: 300
            },
            title: {
                text: 'Biểu đồ tỷ lệ lỗi'
            },
            plotOptions: {
                series: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    dataLabels: [{
                        enabled: true,
                        distance: 5
                    }, {
                        enabled: true,
                        distance: -25,
                        format: '{point.percentage:.1f}',
                        style: {
                            fontSize: '1em',
                            opacity: 0.7
                        },
                        filter: {
                            operator: '>',
                            property: 'percentage',
                            value: 10
                        }
                    }]
                }
            },
            series: [
                {
                    name: 'Tỷ lệ',
                    // data: [
                    //     { name: 'TP UV02', y: 3.53 },
                    //     { name: 'TP UF111', y: 1.79 },
                    //     { name: 'TP UF106-1', y: 1.75 },
                    //     { name: 'TP 101NR-1', y: 2.84 }
                    // ]
                    data: dataProduct.map((item) => ({name: item?.product_name, y: item?.quantity_ng}))
                }
            ],
            credits: { enabled: false }
        })
    }, [dataDate, dataProduct]);

    const fetchData = async (filters) => {
        try {
            if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
            if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');

            const response = await axiosInstance.get('/lot-histories-summary', { params: filters }) // Thay đổi endpoint theo API của bạn
            if (response?.success) {
                const { dates, products } = response.data;

                // Dates
                setDataDate((dates ?? []).map((item) => {
                    return { date: dayjs(item?.date).format('DD/MM'), value: item?.quantity_ng };
                }));

                // Product
                setDataProduct((products ?? []).map((item) => {
                    return { id: item?.product_id, name: item?.product_name, value: item?.quantity_ng };
                }));
            }
        } catch (error) {
            message.error(error.message);
        }
    };

    return (
        <Row gutter={8} className='section-chart'>
            <Col span={16}>
                {/* <Card title="Biểu đồ thống kê lỗi theo ngày">
                    <Column {...configColChart} />
                </Card> */}
                <Card title="">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Card>
            </Col>
            <Col span={8}>
                <Card title="">
                    {/* <Pie {...pieConfig} /> */}
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={pieOptions}
                    />
                </Card>
            </Col>
        </Row>
    );
};

export default PQCCharts;
