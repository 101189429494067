import axiosInstance from "./axiosInstance";

const getSuppliers = (filters) => axiosInstance.get('/suppliers', { params: filters });
const getSupplierById = (id) => axiosInstance.get(`/suppliers/${id}`);
const createSupplier = (supplierData) => axiosInstance.post('/suppliers', supplierData);
const updateSupplier = (id, supplierData) => axiosInstance.put(`/suppliers/${id}`, supplierData);
const deleteSupplier = (id) => axiosInstance.delete(`/suppliers/${id}`);

export default {
  getSuppliers,
  getSupplierById,
  createSupplier,
  updateSupplier,
  deleteSupplier,
};
