import {Html5Qrcode, Html, Html5QrcodeScanner} from "html5-qrcode";
import React, {useEffect} from "react";
// import './Scanner.scss';
const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
    let config = {};
    if (props.fps) {
        config.fps = props.fps;
    }
    if (props.qrbox) {
        config.qrbox = props.qrbox;
    }
    if (props.aspectRatio) {
        config.aspectRatio = props.aspectRatio;
    }
    if (props.disableFlip !== undefined) {
        config.disableFlip = props.disableFlip;
    }
    return config;
};

const Html5QrcodePlugin = (props) => {

    useEffect(() => {
        // when component mounts
        const config = createConfig(props);
        const verbose = props.verbose === true;
        // Suceess callback is required.
        if (!(props.qrCodeSuccessCallback)) {
            throw "qrCodeSuccessCallback is required callback.";
        }
        const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
        html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch(error => {
                console.error("Failed to clear html5QrcodeScanner. ", error);
            });
        };
    }, []);

    return (
        <div id={qrcodeRegionId} />
    );
};
const Scanner = (props) => {
    const onNewScanResult = (decodedText, decodedResult) => {
        props.onResult(decodedText)
    };
    return (
        <div width="100%">
            <Html5QrcodePlugin
                fps={10}
                qrbox={250}
                disableFlip={true}
                qrCodeSuccessCallback={onNewScanResult}
            />
        </div>
    );
}
function scannerTranslator() {
	const traducciones = [
		// Html5QrcodeStrings
		{original: "QR code parse error, error =", traduccion: "Lỗi phân tích mã QR, lỗi ="},
		{original: "Error getting userMedia, error =", traduccion: "Lỗi lấy userMedia, lỗi ="},
		{original: "The device doesn't support navigator.mediaDevices , only supported cameraIdOrConfig in this case is deviceId parameter (string).", traduccion: "Thiết bị không hỗ trợ navigator.mediaDevices, chỉ hỗ trợ cameraIdOrConfig trong trường hợp này là tham số deviceId (chuỗi)."},
		{original: "Camera streaming not supported by the browser.", traduccion: "Truyền phát camera không được trình duyệt hỗ trợ."},
		{original: "Unable to query supported devices, unknown error.", traduccion: "Không thể truy vấn các thiết bị được hỗ trợ, lỗi không xác định."},
		{original: "Camera access is only supported in secure context like https or localhost.", traduccion: "Quyền truy cập vào máy ảnh chỉ được hỗ trợ trong bối cảnh bảo mật như https hoặc localhost."},
		{original: "Scanner paused", traduccion: "Máy quét đã tạm dừng"},
	
		// Html5QrcodeScannerStrings
		{original: "Scanning", traduccion: "Đang quét"},
		{original: "Idle", traduccion: "Nhàn rỗi"},
		{original: "Error", traduccion: "Lỗi"},
		{original: "Permission", traduccion: "Quyền"},
		{original: "No Cameras", traduccion: "Không có máy ảnh"},
		{original: "Last Match:", traduccion: "Kết quả đã quét cuối cùng:"},
		{original: "Code Scanner", traduccion: "Mã máy quét"},
		{original: "Request Camera Permissions", traduccion: "Yêu cầu quyền truy cập máy ảnh"},
		{original: "Requesting camera permissions...", traduccion: "Đang yêu cầu quyền truy cập máy ảnh..."},
		{original: "No camera found", traduccion: "Không tìm thấy máy ảnh"},
		{original: "Stop Scanning", traduccion: "Dừng quét"},
		{original: "Start Scanning", traduccion: "Bắt đầu quét"},
		{original: "Switch On Torch", traduccion: "Bật đèn"},
		{original: "Switch Off Torch", traduccion: "Tắt đèn"},
		{original: "Failed to turn on torch", traduccion: "Không thể bật đèn"},
		{original: "Failed to turn off torch", traduccion: "Không thể tắt đèn"},
		{original: "Launching Camera...", traduccion: "Đang khởi chạy máy ảnh..."},
		{original: "Scan an Image File", traduccion: "Quét tệp hình ảnh"},
		{original: "Scan using camera directly", traduccion: "Quét trực tiếp bằng camera"},
		{original: "Select Camera", traduccion: "Chọn máy ảnh"},
		{original: "Choose Image", traduccion: "Chọn hình ảnh"},
		{original: "Choose Another", traduccion: "Chọn cách khác"},
		{original: "No image choosen", traduccion: "Không có hình ảnh nào được chọn"},
		{original: "Anonymous Camera", traduccion: "Máy ảnh ẩn danh"},
		{original: "Or drop an image to scan", traduccion: "Hoặc thả hình ảnh để quét"},
		{original: "Or drop an image to scan (other files not supported)", traduccion: "Hoặc thả hình ảnh để quét (các file khác không được hỗ trợ)"},
		{original: "Thu/Phóng", traduccion: "Thu/Phóng"},
		{original: "Loading image...", traduccion: "Đang tải hình ảnh..."},
		{original: "Camera based scan", traduccion: "Quét dựa trên máy ảnh"},
		{original: "Fule based scan", traduccion: "Quét dựa trênq uy tắc"},
        {original: "NotFoundException: No MultiFormat Readers were able to detect the code.", traduccion: "Không có Trình đọc MultiFormat nào có thể phát hiện được mã"},

		// Others
        {original: "NotFoundError: Requested device not found", traduccion: "Không tìm thấy camera"},
		{original: "NotAllowedError: Permission denied", traduccion: "Quyền bị từ chối"}
	];

	// Función para traducir un texto
	function traducirTexto(texto) {
		const traduccion = traducciones.find(t => t.original === texto);
		return traduccion ? traduccion.traduccion : texto;
	}

	// Función para traducir los nodos de texto
	function traducirNodosDeTexto(nodo) {
		if (nodo.nodeType === Node.TEXT_NODE) {
			nodo.textContent = traducirTexto(nodo.textContent.trim());
		} else {
			for (let i = 0; i < nodo.childNodes.length; i++) {
				traducirNodosDeTexto(nodo.childNodes[i]);
			}
		}
	}

	// Crear el MutationObserver
	const observer = new MutationObserver((mutations) => {
		mutations.forEach((mutation) => {
			if (mutation.type === 'childList') {
				mutation.addedNodes.forEach((nodo) => {
					traducirNodosDeTexto(nodo);
				});
			}
		});
	});

	// Configurar y ejecutar el observer
	const config = {childList: true, subtree: true};
	observer.observe(document.body, config);

	// Traducir el contenido inicial
	traducirNodosDeTexto(document.body);
}

document.addEventListener('DOMContentLoaded', function () {
// Utilizando la función scannerTranslator
	scannerTranslator(document.querySelector('#'+qrcodeRegionId));
});

export default Scanner
