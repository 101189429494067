import axiosInstance from "./axiosInstance";

const getEquipmentParameterLogs = (filters) => axiosInstance.get('/equipment-parameter-logs', { params: filters });
const getEquipmentParameterLogsSearch = (filters) => axiosInstance.get('/equipment-parameter-logs/search', { params: filters });
const createEquipmentParameterLog = (equipmentParameterData) => axiosInstance.post('/equipment-parameter-logs', equipmentParameterData);
const updateEquipmentParameterLog = (id, equipmentParameterData) => axiosInstance.put(`/equipment-parameter-logs/${id}`, equipmentParameterData);
const deleteEquipmentParameterLog = (id) => axiosInstance.delete(`/equipment-parameter-logs/${id}`);

export default {
    getEquipmentParameterLogs,
    getEquipmentParameterLogsSearch,
    createEquipmentParameterLog,
    updateEquipmentParameterLog,
    deleteEquipmentParameterLog,
};
