import { BorderOutlined, LineOutlined } from '@ant-design/icons';
import { Column, Pie } from '@ant-design/plots';
import { Card, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const FourthChart = ({ data }) => {
    const [chartOptions, setChartOptions] = useState(null);

    // useEffect(() => {
    //     console.log(data);
    //     // Simulating API response with updated data
    //     // const data = {
    //     //     "2024-07-27": {
    //     //         "day": "27/07",
    //     //         "moisture": 0.000,
    //     //         "dispersion": 0.001,
    //     //         "colorError": 0.200,
    //     //         "badPellets": 0.010,
    //     //         "other": 0.000
    //     //     },
    //     //     "2024-07-28": {
    //     //         "day": "28/07",
    //     //         "moisture": 0.000,
    //     //         "dispersion": 0.001,
    //     //         "colorError": 0.200,
    //     //         "badPellets": 0.010,
    //     //         "other": 0.000
    //     //     },
    //     //     "2024-07-29": {
    //     //         "day": "29/07",
    //     //         "moisture": 0.000,
    //     //         "dispersion": 0.001,
    //     //         "colorError": 0.200,
    //     //         "badPellets": 0.010,
    //     //         "other": 0.000
    //     //     },
    //     //     "2024-07-30": {
    //     //         "day": "30/07",
    //     //         "moisture": 0.000,
    //     //         "dispersion": 0.001,
    //     //         "colorError": 0.200,
    //     //         "badPellets": 0.010,
    //     //         "other": 0.000
    //     //     },
    //     //     "2024-07-31": {
    //     //         "day": "31/07",
    //     //         "moisture": 0.000,
    //     //         "dispersion": 0.001,
    //     //         "colorError": 0.200,
    //     //         "badPellets": 0.010,
    //     //         "other": 0.000
    //     //     },
    //     //     "2024-08-01": {
    //     //         "day": "01/08",
    //     //         "moisture": 0.000,
    //     //         "dispersion": 0.001,
    //     //         "colorError": 0.200,
    //     //         "badPellets": 0.010,
    //     //         "other": 0.000
    //     //     },
    //     //     "2024-08-02": {
    //     //         "day": "02/08",
    //     //         "moisture": 0.000,
    //     //         "dispersion": 0.001,
    //     //         "colorError": 0.200,
    //     //         "badPellets": 0.010,
    //     //         "other": 0.000
    //     //     }
    //     // };

    //     // const categories = [];
    //     // const moistureData = [];
    //     // const dispersionData = [];
    //     // const colorErrorData = [];
    //     // const badPelletsData = [];
    //     // const otherErrorsData = [];

    //     // Object.keys(data).forEach(key => {
    //     //     categories.push(data[key].day);
    //     //     moistureData.push(data[key].moisture);
    //     //     dispersionData.push(data[key].dispersion);
    //     //     colorErrorData.push(data[key].colorError);
    //     //     badPelletsData.push(data[key].badPellets);
    //     //     otherErrorsData.push(data[key].other);
    //     // });

    //     setChartOptions({
    //         chart: {
    //             type: 'column',
    //             height: 350,
    //         },
    //         title: {
    //             text: 'Tỉ lệ thành phần lỗi chất lượng công đoạn'
    //         },
    //         xAxis: {
    //             // categories: ['Lỗi ẩm', 'Lỗi phân tán', 'Lỗi khác màu', 'Hạt xấu', 'Lỗi khác']
    //             categories: data?.map(item => item?.error_quality_id) || []
    //         },
    //         yAxis: {
    //             min: 0,
    //             title: {
    //                 text: 'Lỗi'
    //             },
    //             labels: {
    //                 format: '{value}'
    //             }
    //         },
    //         tooltip: {
    //             pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.3f}%</b><br/>',
    //             shared: true
    //         },
    //         plotOptions: {
    //             column: {
    //                 pointPadding: 0.2,
    //                 borderWidth: 0
    //             }
    //         },
    //         series: [{
    //             name: 'Tỉ lệ lỗi',
    //             // data: [
    //             //     {
    //             //         name: 'Lỗi ẩm',
    //             //         y: 0.000
    //             //     },
    //             //     {
    //             //         name: 'Lỗi phân tán',
    //             //         y: 0.001
    //             //     },
    //             //     {
    //             //         name: 'Lỗi khác màu',
    //             //         y: 0.200
    //             //     },
    //             //     {
    //             //         name: 'Hạt xấu',
    //             //         y: 0.010
    //             //     },
    //             //     {
    //             //         name: 'Lỗi khác',
    //             //         y: 0.000
    //             //     }
    //             // ]
    //             data: data?.map(item => ({name: item?.error_quality_id, y: item?.quantity_ng}))
    //         }],
    //         credits: { enabled: false }
    //     });
    // }, [data]);

    useEffect(() => {
        console.log(data);
        // Simulating API response with updated data
        // const data = {
        //     "2024-07-27": {
        //         "day": "27/07",
        //         "moisture": 0.000,
        //         "dispersion": 0.001,
        //         "colorError": 0.200,
        //         "badPellets": 0.010,
        //         "other": 0.000
        //     },
        //     "2024-07-28": {
        //         "day": "28/07",
        //         "moisture": 0.000,
        //         "dispersion": 0.001,
        //         "colorError": 0.200,
        //         "badPellets": 0.010,
        //         "other": 0.000
        //     },
        //     "2024-07-29": {
        //         "day": "29/07",
        //         "moisture": 0.000,
        //         "dispersion": 0.001,
        //         "colorError": 0.200,
        //         "badPellets": 0.010,
        //         "other": 0.000
        //     },
        //     "2024-07-30": {
        //         "day": "30/07",
        //         "moisture": 0.000,
        //         "dispersion": 0.001,
        //         "colorError": 0.200,
        //         "badPellets": 0.010,
        //         "other": 0.000
        //     },
        //     "2024-07-31": {
        //         "day": "31/07",
        //         "moisture": 0.000,
        //         "dispersion": 0.001,
        //         "colorError": 0.200,
        //         "badPellets": 0.010,
        //         "other": 0.000
        //     },
        //     "2024-08-01": {
        //         "day": "01/08",
        //         "moisture": 0.000,
        //         "dispersion": 0.001,
        //         "colorError": 0.200,
        //         "badPellets": 0.010,
        //         "other": 0.000
        //     },
        //     "2024-08-02": {
        //         "day": "02/08",
        //         "moisture": 0.000,
        //         "dispersion": 0.001,
        //         "colorError": 0.200,
        //         "badPellets": 0.010,
        //         "other": 0.000
        //     }
        // };

        // const categories = [];
        // const moistureData = [];
        // const dispersionData = [];
        // const colorErrorData = [];
        // const badPelletsData = [];
        // const otherErrorsData = [];

        // Object.keys(data).forEach(key => {
        //     categories.push(data[key].day);
        //     moistureData.push(data[key].moisture);
        //     dispersionData.push(data[key].dispersion);
        //     colorErrorData.push(data[key].colorError);
        //     badPelletsData.push(data[key].badPellets);
        //     otherErrorsData.push(data[key].other);
        // });

        const dates = [...new Set(data.map(item => item.date))];
        const A = data.map(item => item.a);
        const P = data.map(item => Number(item.p) > 100 ? 100 : item.p);
        const Q = data.map(item => item.q);
        const OEE = data.map(item => item.oee);
        const series = [
            {
                name: "A",
                data: A,
            },
            {
                name: "P",
                data: P,
            },
            {
                name: "Q",
                data: Q,
            },
            {
                name: "OEE",
                data: OEE,
            },
        ];

        setChartOptions({
            chart: {
                type: 'column',
                height: 350,
            },
            title: {
                text: 'OEE'
            },
            xAxis: {
                categories: dates,
                crosshair: true,
                accessibility: {
                    enabled: false
                }
            },
            plotOptions: {
                column: {
                    depth: 40,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:0.1f}%'
                    },
                    borderRadius: 0
                }
            },

            yAxis: {
                title: null,
            },
            series: series,
            exporting: false,
            credits: { enabled: false }
        });
    }, [data]);

    return (
        <Card className="kpi-chart" title="">
            {/* Biểu đồ tỉ lệ lỗi với mỗi thành phẩm sản xuất */}
            {/* <Pie {...pieConfig} /> */}
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </Card>
    );
};

export default FourthChart;
