import axiosInstance from "./axiosInstance";

const getFinishedProductExports = (filters) => axiosInstance.get('/finished-product-exports', { params: filters });
const getFinishedProductExportItems = (filters) => axiosInstance.get('/finished-product-export-items', { params: filters });
const getFinishedProductExportById = (id) => axiosInstance.get(`/finished-product-exports/${id}`);
const createFinishedProductExport = (finishedProductExportData) => axiosInstance.post('/finished-product-exports', finishedProductExportData);
const updateFinishedProductExport = (id, finishedProductExportData) => axiosInstance.put(`/finished-product-exports/${id}`, finishedProductExportData);
const deleteFinishedProductExport = (id) => axiosInstance.delete(`/finished-product-exports/${id}`);
const deleteFinishedProductExportItem = (id) => axiosInstance.delete(`/finished-product-export-items/${id}`);

export default {
  getFinishedProductExports,
  getFinishedProductExportItems,
  getFinishedProductExportById,
  createFinishedProductExport,
  updateFinishedProductExport,
  deleteFinishedProductExport,
  deleteFinishedProductExportItem,
};
