import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import moldApi from "../../../api/moldManagementApi";
import equipmentApi from '../../../api/equipmentManagementApi';
import config from '../../../config/config';

const MoldTable = ({ filters }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [equipments, setEquipments] = useState([]);

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate);
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  useEffect(() => {
    (async () => {
      const response = await equipmentApi.getEquipments();
      if (response.success) setEquipments(response.data.data.map(e => ({ ...e, label: e.equipment_name, value: e.id })));
    })()
  }, []);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await moldApi.getMolds(filters);
      if (response.success) {
        setData(response.data.data);
        response.data.pagination && setPagination(response.data.pagination);
      }
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch molds');
    }
  };

  const showModal = () => {
    setIsEditMode(false);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setIsEditMode(true);
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      try {
        if (isEditMode) {
          const response = await moldApi.updateMold(editingRecord.id, values);
          if (response.success) {
            setData((prevData) => [{ ...values }, ...prevData.filter(item => item.id !== editingRecord.id)]);
          }
        } else {
          const response = await moldApi.createMold(values);
          if (response.success) {
            setData((prevData) => [{ ...values }, ...prevData]);
            setPagination({ ...pagination, total: pagination.total + 1 });
          }
        }
        setIsModalVisible(false);
        form.resetFields();
      } catch (error) {
        message.error('Failed to save mold');
      }
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await moldApi.deleteMold(id);
      if (response.success) {
        setData((prevData) => prevData.filter(item => item.id !== id));
      }
    } catch (error) {
      message.error('Failed to delete mold');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} - Upload thất bại`);
        }
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-molds',
    onChange: handleUploadChange,
    showUploadList: false,
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Mã khuôn',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Tên khuôn',
      dataIndex: 'mold_name',
      key: 'mold_name',
      align: 'center',
    },
    {
      title: 'Mã thiết bị',
      dataIndex: 'equipment_id',
      key: 'equipment_id',
      align: 'center',
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />
          <Popconfirm
            title="Bạn có chắc chắn muốn xóa khuôn này?"
            onConfirm={() => handleDelete(record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
      align: 'center',
    },
  ];

  return (
    <Card
      title="Quản lý khuôn"
      extra={
        <div className="table-actions">
          <Button type="primary">Xuất file</Button>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
              {uploading ? 'Đang tải lên' : 'Upload Excel'}
            </Button>
          </Upload>
          <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm khuôn</Button>
        </div>
      }
    >
      <Table
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, page, pageSize });
            fetchData({ ...filters, page, pageSize });
          }
        }}
        bordered
        size="small"
      />
      <div ref={componentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa Khuôn" : "Thêm Khuôn"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={800}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <div className="modal-form-grid">
            <Form.Item name="id" label="Mã khuôn" rules={[{ required: true, message: 'Vui lòng nhập mã khuôn!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="mold_name" label="Tên khuôn" rules={[{ required: true, message: 'Vui lòng nhập tên khuôn!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="equipment_id" label="Thiết bị" rules={[{ required: true, message: 'Vui lòng nhập thiết bị!' }]}>
              <Select options={equipments} showSearch optionFilterProp='label' />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

export default MoldTable;
