// src/pages/UI/MaterialManagement/MaterialManagement.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import WarehouseSidebar from './WarehouseManagementSidebar';
import WarehouseTable from './WarehouseManagementTable';

const { Header, Content } = Layout;

const WarehouseManagement = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  return (
    <Layout>
      <WarehouseSidebar onFilter={handleFilter}/>
      <Layout>
        <Content className="site-layout-content">
          <WarehouseTable filters={filters}/>
        </Content>
      </Layout>
    </Layout>
  );
};

export default WarehouseManagement;
