import axiosInstance from "./axiosInstance";

const getSemiFinishedProducts = (filters) => axiosInstance.get('/semi-finished-products', { params: filters });
const getSemiFinishedProductById = (id) => axiosInstance.get(`/semi-finished-products/${id}`);
const createSemiFinishedProduct = (SemiFinishedProductsData) => axiosInstance.post('/semi-finished-products', SemiFinishedProductsData);
const updateSemiFinishedProduct = (id, SemiFinishedProductsData) => axiosInstance.put(`/semi-finished-products/${id}`, SemiFinishedProductsData);
const deleteSemiFinishedProduct = (id) => axiosInstance.delete(`/semi-finished-products/${id}`);

export default {
  getSemiFinishedProducts,
  getSemiFinishedProductById,
  createSemiFinishedProduct,
  updateSemiFinishedProduct,
  deleteSemiFinishedProduct,
};
