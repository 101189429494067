import React, { useRef, useState } from 'react';
import { Layout, Tree, DatePicker, Button, Form, Divider, Select, ConfigProvider, Radio, Input } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../../components/Sidebar';
import axiosInstance from '../../../../api/axiosInstance';
import config from '../../../../config/config';

dayjs.locale('vi');

const SparepartWarehouseSidebar = ({ onFilter }) => {
  const [palletOptions, setPalletOptions] = useState([]);
  const timer = useRef();

  const formFields = [
    {
      type: 'Select',
      label: 'Loại',
      name: 'type',
      placeholder: 'Nhập/Xuất',
      options: [
        { label: 'Nhập kho', value: 'import' },
        { label: 'Xuất kho', value: 'export' },
      ],
    },
    {
      type: 'DatePicker',
      label: 'Khoảng thời gian',
      name: 'start_date',
      placeholder: 'Ngày bắt đầu',
      className: 'mb-1'
    },
    {
      type: 'DatePicker',
      name: 'end_date',
      placeholder: 'Ngày kết thúc',
    },
    // {
    //   type: 'Input',
    //   label: 'Mã Pallet',
    //   name: 'pallet_id',
    //   placeholder: 'Nhập mã Pallet',
    // },
    {
      type: 'AutoComplete',
      label: 'Mã pallet',
      name: 'pallet_id',
      placeholder: 'Mã pallet',
      options: palletOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchPallet({ id: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
    // Add other fields here
  ];

  const fetchPallet = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/pallets', { params });
    if (response?.success) {
      setPalletOptions((response.data?.data ?? []).map((item) => ({ label: item?.id, value: item?.id })));
    }
  }

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default SparepartWarehouseSidebar;
