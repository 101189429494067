import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import warehouseApi from "../../../api/warehouseManagementApi";
import config from '../../../config/config';
import axiosInstance from '../../../api/axiosInstance';
import { b64toBlob } from '../../../utils/dataBlob';
import AuthContext from '../../../context/AuthContext';

const WarehouseTable = ({ filters }) => {
  const { user } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const componentRef1 = useRef();
  const reactToPrintRef = useRef();

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate);
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  useEffect(() => {
    if (htmlContent) {
      reactToPrintRef.current.handlePrint();
    }
  }, [htmlContent]);

  useEffect(() => {
    setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
  }, []);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await warehouseApi.getInventories(filters);
      if (response.success) {
        setData(response.data.data);
        response.data.pagination && setPagination(response.data.pagination);
      }
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch warehouses');
    }
  };

  const showModal = () => {
    setIsEditMode(false);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setIsEditMode(true);
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      try {
        if (isEditMode) {
          const response = await warehouseApi.updateWarehouse(editingRecord.id, values);
          response.success && setData((prevData) => [{ ...values }, ...prevData.filter(item => item.id !== editingRecord.id)]);
        } else {
          const response = await warehouseApi.createWarehouse(values);
          if (response.success) {
            setData((prevData) => [{ ...values, id: response.data.id }, ...prevData]);
            setPagination({ ...pagination, total: pagination.total + 1 });
          }
        }
        setIsModalVisible(false);
        form.resetFields();
      } catch (error) {
        message.error('Failed to save warehouse');
      }
    }).catch(error => {
      console.log(error);
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await warehouseApi.deleteWarehouse(id);
      response.success && setData((prevData) => prevData.filter(item => item.id !== id));
      // message.success('Xóa warehouse thành công');
    } catch (error) {
      message.error('Failed to delete warehouse');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} - Upload thất bại`);
        }
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-warehouses',
    onChange: handleUploadChange,
    showUploadList: false,
  };

  const printQR = (record) => async () => {
    try {
      // const response = await palletManagementApi.printQR(record?.id);
      // if (response?.success) {
      //     setHtmlContent(response.data);
      // }
    } catch (error) {
      message.error('Failed to print pallet');
    }
  }

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Mã kho',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Tên kho',
      dataIndex: 'warehouse_name',
      key: 'warehouse_name',
      align: 'center',
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {permissions.includes('warehouse.update') && (<Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
          {permissions.includes('warehouse.delete') && (<Popconfirm
            title="Bạn có chắc chắn muốn xóa bản ghi này?"
            onConfirm={() => handleDelete(record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
          </Popconfirm>)}
        </Space>
      ),
      align: 'center',
    },
  ];

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/warehouses-export');
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      title="Quản lý kho"
      extra={
        <div className="table-actions">
          {permissions.includes('warehouse.create') && (<>
            <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel}>Xuất file</Button>
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                {uploading ? 'Đang tải lên' : 'Upload Excel'}
              </Button>
            </Upload>
            <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm Warehouse</Button>
          </>)}
        </div>
      }
    >
      <Table
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((e, i) => ({ ...e, key: i }))}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, page, pageSize });
            fetchData({ ...filters, page, pageSize });
          }
        }}
        bordered
        size="small"
      />
      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa Warehouse" : "Thêm Warehouse"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={800}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <div className="modal-form-grid">
            <Form.Item name="id" label="Mã kho" rules={[{ required: true, message: 'Vui lòng nhập mã kho!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="warehouse_name" label="Tên kho" rules={[{ required: true, message: 'Vui lòng nhập tên kho!' }]}>
              <Input />
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <div>
        <ReactToPrint
          ref={reactToPrintRef}
          trigger={() => <button style={{ display: 'none' }}>Print this out!</button>}
          content={() => componentRef1.current}
        />
        <div ref={componentRef1} className="print-container">
          {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
        </div>
      </div>
    </Card>
  );
};

export default WarehouseTable;
