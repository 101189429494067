import React, { useEffect, useState } from 'react';
import { Button, Card, ColorPicker, Form, Input, Layout, Space, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { createCompanyConfig, getCompanyConfig } from '../../../api/companyConfigApi';
import axiosInstance from '../../../api/axiosInstance';
import config from '../../../config/config';

const { Content } = Layout;

const ConfigManagement = () => {
    const [form] = Form.useForm();
    const [formPrinter] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [backgroundValue, setBackgroundValue] = useState('#1677ff');
    const [colorValue, setColorValue] = useState('#2ecc71');
    const [printerIp, setPrinterIp] = useState('192.168.1.100');

    useEffect(() => {
        fetchConfig();
    }, [])

    const handleFinish = () => {
        form.validateFields().then(async (values) => {
            try {
                // const file = values.logo.file
                // const blob = new Blob([JSON.stringify(file)], { type: 'application/json' });
                // const logo = new File([blob], file.name, { type: file.type, lastModified: file.lastModified });
                const formData = new FormData();
                if (fileList[0]) formData.append('logo', fileList[0]);
                formData.append('background', backgroundValue);
                formData.append('color', colorValue);
                formData.append('font', values.font);
                formData.append('company_name', values.company_name);
                const response = await createCompanyConfig(formData)
                if (response.success) {
                    // TODO: mutate logo url
                    const result = response.data;
                    if (result?.logo) result.logo = `/company_config/${result.logo}`;
                    await localStorage.setItem('company_config', JSON.stringify(result));
                    // await fetchConfig();
                    window.location.reload();
                }
            } catch (error) {
                message.error(error.message)
            }
        }).catch((error) => { console.log(error) })
    }

    const fetchConfig = async () => {
        const response = await getCompanyConfig();
        if (response.success) {
            const result = response.data;
            result?.background && setBackgroundValue(result.background)
            result?.color && setColorValue(result.color)
            form.setFieldsValue({
                company_name: result?.company_name ?? null,
                background: result?.background ?? backgroundValue,
                color: result?.color ?? colorValue,
                font: result?.font ?? null,
            })
        }
    }

    const props = {
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    const handleBackgroundChange = (value) => {
        if (value?.toHexString()) setBackgroundValue(value.toHexString());
    }

    const handleColorChange = (value) => {
        if (value?.toHexString()) setColorValue(value.toHexString());
    }

    const validateIP = (_, value) => {
        if (!value || /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Nhập đúng định dạng IP bạn êi'));
    };

    const handleCheckConnectPrinter = async () => {
        formPrinter.validateFields().then(async (values) => {
            const response = await axiosInstance(config.apiUrl + '/check-printer', { params: { id: values.ip } });
        }, (error) => console.log(error));
    }

    return (
        <Layout>
            <Content className="site-layout-content">
                <Card>
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'start', gap: '5px'}}>
                            <Form form={formPrinter} initialValues={{ip: '192.168.1.100'}}>
                                <Form.Item name="ip" label="" rules={[{ required: true }, { validator: validateIP }]}>
                                    <Input placeholder='IP' allowClear />
                                </Form.Item>
                            </Form>
                            <Button onClick={handleCheckConnectPrinter}>Check connect printer</Button>
                        </div>
                        <Form form={form} onFinish={handleFinish} labelAlign='left' labelCol={{ style: { width: 120 } }}>
                            <Form.Item name="company_name" label="Company name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="background" label="Background" rules={[{ required: true }]}>
                                <ColorPicker defaultValue={backgroundValue} onChange={handleBackgroundChange} showText />
                            </Form.Item>
                            <Form.Item name="color" label="Color" rules={[{ required: true }]}>
                                <ColorPicker defaultValue={colorValue} onChange={handleColorChange} showText />
                            </Form.Item>
                            <Form.Item name="font" label="Font" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="logo" label="Logo">
                                <Upload
                                    showUploadList={false}
                                    {...props}
                                    accept='image/*'
                                >
                                    <Button icon={<UploadOutlined />}>Upload logo</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Space>
                </Card>
            </Content>
        </Layout>
    );
};

export default ConfigManagement;
