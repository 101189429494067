import React, { useEffect, useRef, useState } from 'react';
import { Layout, Select, message, Table, Space, Button, Radio, Form, Modal, Input, InputNumber, Row, Col } from 'antd';
import './OIQualityPQC.css';
import equipmentApi from '../../../../api/equipmentManagementApi';
import { CloseOutlined, PlusOutlined, PrinterOutlined, QrcodeOutlined } from '@ant-design/icons';
import { useOutletContext } from 'react-router-dom';
import axiosInstance from '../../../../api/axiosInstance';
import config from '../../../../config/config';
import Scanner from '../../../../components/Scanner';
import lotApi from '../../../../api/lotApi';
import lotHistoryApi from '../../../../api/lotHistoryApi';
import errorQualityManagementApi from '../../../../api/errorQualityManagementApi';
import { numberFormat } from '../../../../utils/numberFormat';

const { Content } = Layout;

const OIQualityPQC = () => {
  const context = useOutletContext();
  const [selectedEquipment, setSelectedEquipment] = useState();
  const [loading, setLoading] = useState(false);
  const [dataTop, setDataTop] = useState([{ total: 0, percent_ng: 0, lot_ok: 0 }]);
  const [dataMiddle, setDataMiddle] = useState({});
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [lotHistory, setLotHistory] = useState([]);
  const [inspectionType, setInspectionType] = useState('');
  const [criteria, setCriteria] = useState([]);
  const [buttonStatus, setButtonStatus] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [form] = Form.useForm();
  const [formInput] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalScannerVisible, setIsModalScannerVisible] = useState(false);
  const [isInputModalVisible, setIsInputModalVisible] = useState(false);
  const [scannerKey, setScannerKey] = useState(Date.now());
  const isScanningRef = useRef(true);

  const columnsTop = [
    { title: 'Tổng thực tế', dataIndex: 'total', width: '120px' },
    { title: 'Tổng Lot OK', dataIndex: 'lot_ok', width: '100px' },
    { title: 'Tổng KG', dataIndex: 'total', width: '100px' },
    { title: 'Tỉ lệ NG', dataIndex: 'percent_ng', width: '100px' },
  ];

  const columnsBottom = [
    { title: 'Số lot', dataIndex: 'lot_id', width: '90px', render: (_, record) => record?.lot_history?.lot_id },
    { title: 'Mã nội bộ', dataIndex: 'product_id', width: '120px', render: (_, record) => record?.lot_history?.product_id },
    { title: 'Số lượng (Bao)', dataIndex: 'quantity', width: '120px', render: (_, record) => record?.lot_history?.production_plan?.bag_stacking ? numberFormat(record?.lot_history?.quantity / record?.lot_history?.production_plan?.bag_stacking) : numberFormat(record?.lot_history?.quantity) },
    { title: 'Số lượng (KG)', dataIndex: 'quantity', width: '120px', render: (_, record) => numberFormat(record?.lot_history?.quantity) },
    { title: 'SL. NG (Bao)', dataIndex: 'quantity_ng', width: '100px', render: (_, record) => record?.lot_history?.production_plan?.bag_stacking ? numberFormat(record?.lot_history?.quantity_ng) / record?.lot_history?.production_plan?.bag_stacking : numberFormat(record?.lot_history?.quantity_ng) },
    { title: 'SL. NG (KG)', dataIndex: 'quantity_ng', width: '100px', render: (_, record) => numberFormat(record?.lot_history?.quantity_ng) },
    { title: 'Tỷ lệ NG', dataIndex: 'percent', width: '100px', render: (_, record) => ngPercent(record?.lot_history?.quantity_ng, record?.lot_history?.quantity) },
    { title: 'Kết quả', dataIndex: 'quality_check', width: '100px', render: (_, record) => record?.result },
  ];

  const columnsMiddle = [
    { title: 'Số lot', dataIndex: 'lot_id', width: '90px', render: (_, record) => record?.lot_id },
    { title: 'Mã nội bộ', dataIndex: 'product_id', width: '120px', render: (_, record) => record?.product_id },
    { title: 'Số lượng (Bao)', dataIndex: 'quantity', width: '120px', render: (_, record) => record?.production_plan?.bag_stacking ? numberFormat(record?.quantity / record?.production_plan?.bag_stacking) : numberFormat(record?.quantity) },
    { title: 'Số lượng (KG)', dataIndex: 'quantity', width: '120px', render: (_, record) => numberFormat(record?.quantity) },
    { title: 'SL. NG (Bao)', dataIndex: 'quantity_ng', width: '100px', render: (_, record) => record?.production_plan?.bag_stacking ? numberFormat(record?.quantity_ng) / record?.production_plan?.bag_stacking : numberFormat(record?.quantity_ng) },
    { title: 'SL. NG (KG)', dataIndex: 'quantity_ng', width: '100px', render: (_, record) => numberFormat(record?.quantity_ng) },
    { title: 'Tỷ lệ NG', dataIndex: 'percent', width: '100px', render: (_, record) => ngPercent(record?.quantity_ng, record?.quantity) },
    { title: 'Kết quả', dataIndex: 'quality_check', width: '100px', render: (_, record) => record?.result },
  ];

  const ngPercent = (quantity_ng, quantity) => {
    if (Number(quantity) > 0) {
      const percent = (Number(quantity_ng) / Number(quantity) * 100).toFixed(2);
      return `${Number(percent)}%`
    } else return 0;
  }

  const fetchEquipment = async () => {
    try {
      const response = await equipmentApi.getEquipments();
      if (response?.success) {
        const equipments = response.data?.data ?? []
        setEquipmentOptions(equipments.map((item) => {
          return { label: item?.equipment_name, value: item?.id };
        }));

        if (equipments.length > 0) {
          setSelectedEquipment(equipments[0]?.id);
          fetchLotHistory({ equipment_id: equipments[0]?.id });
          fetchLotHistorySummary(equipments[0]?.id);
          fetchCurrentLot(equipments[0]?.id);
        } else fetchLotHistory();
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchLotHistory = async (params = null) => {
    try {
      const response = await axiosInstance.get('/log-pqc', { params });
      if (response?.success) {
        console.log(response.data);
        const result = response.data ?? []
        setLotHistory(result);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchCurrentLot = async (id) => {
    const response = await axiosInstance.get('/lot-histories-last-complete', { params: { equipment_id: id } });
    if (response?.success) {
      if (response.data) {
        const result = response?.data;
        setDataMiddle(result);
      }
    }
  }

  const fetchLotHistorySummary = async (id) => {
    const response = await lotHistoryApi.getLotHistoriesSummary({ equipment_id: id });
    if (response?.success) {
      const { total, percent_ng, lot_ok } = response.data;
      setDataTop([{ total, percent_ng, lot_ok }]);
    }
  }

  useEffect(() => {
    if (context && context.setTitle) {
      context.setTitle('QUẢN LÝ PQC');
    }
  }, [context]);

  const handleInputChange = (value, id, range) => {
    if (value === '') {
      setButtonStatus(prevStatus => ({
        ...prevStatus,
        [id]: {
          status: 'OK/NG',
          bg: 'white',
          color: 'black'
        }
      }));
    } else {
      const isValid = checkMeasurement(parseFloat(value), range);
      setButtonStatus(prevStatus => ({
        ...prevStatus,
        [id]: {
          status: isValid ? 'OK' : 'NG',
          bg: isValid ? 'green' : 'red',
          color: 'white'
        }
      }));
    }
  };
  useEffect(() => {
    fetchEquipment();
  }, []);

  const determineTypeInput = (acceptablerange) => {
    if (acceptablerange === null) return 3;
    if (acceptablerange === 'OK/NG') return 2;
    return 1;
  };

  const renderInput = (id, description, range) => {
    const type = determineTypeInput(range);

    switch (type) {
      case 1:
        return (
          <Form.Item label={description} key={id} style={{ marginBottom: '12px' }}>
            <Input.Group compact>
              <Form.Item
                name={id}
                noStyle
                rules={[{ required: true, message: 'Dữ liệu không được để trống !' }]}
              >
                <Input
                  style={{ width: 'calc(100% - 80px)' }}
                  placeholder="Nhập giá trị"
                  onChange={(e) => handleInputChange(e.target.value, id, range)}
                />
              </Form.Item>
              <Button style={{ width: '80px', backgroundColor: buttonStatus[id]?.bg || 'white', color: buttonStatus[id]?.color || 'black' }}>
                {buttonStatus[id]?.status || 'OK/NG'}
              </Button>
            </Input.Group>
          </Form.Item>
        );
      case 2:
        return (
          <Form.Item
            label={description}
            key={id}
            name={id}
            style={{ marginBottom: '12px' }}
          >
            <Radio.Group
              style={{ textAlign: 'left' }}
              name={id}
              optionType="button"
              buttonStyle="solid"
              onChange={e => handleChange(id, e.target.value)}
              className={selectedValues[id] === 'NG' ? 'red-radio' : ''}
            >
              <Radio.Button value="OK">OK</Radio.Button>
              <Radio.Button value="NG">NG</Radio.Button>
            </Radio.Group>
          </Form.Item>
        );
      case 3:
        return (
          <Form.Item
            style={{ marginBottom: '12px' }}
            name={id}
            label={description}
            rules={[{ required: true, message: 'Dữ liệu không được để trống !' }]}
            key={id}
          >
            <Input placeholder="Nhập giá trị" />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const handleResult = (result) => {
    try {
      setLoading(true);
      if (!dataMiddle?.id) throw new Error('KHÔNG TÌM THẤY ID LỊCH SỬ LOT!');
      form.validateFields().then(async (values) => {
        const formData = Object.entries(values).map(([id, value]) => {
          const criterion = criteria.find(item => item.id === parseInt(id));
          const type = determineTypeInput(criterion.acceptablerange);
          if (type === 1) {
            return {
              inspection_criteria_id: id,
              measurement: value,
              result: buttonStatus[id]?.status
            };
          } else if (type === 2) {
            return {
              inspection_criteria_id: id,
              measurement: value,
              result: value
            };
          } else {
            return {
              inspection_criteria_id: id,
              measurement: value,
              result: ''
            };
          }
        });
        const inputData = {
          lot_history_id: dataMiddle.id,
          items: formData,
          result: result
        };
        await axiosInstance.post(config.apiUrl + '/quality-checks', inputData);
        setIsModalVisible(false);
        form.resetFields();
        await fetchLotHistory({ equipment_id: selectedEquipment });
        fetchCurrentLot();
      }).catch(errorInfo => {
        console.log('Validate Failed:', errorInfo);
      });
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkMeasurement = (measurement, acceptableRange) => {
    acceptableRange = acceptableRange.trim();
    measurement = parseFloat(measurement);
    acceptableRange = acceptableRange.replace(/≤/g, '<=').replace(/≥/g, '>=');
    const regex = /^(<=|>=|>|<|=|==)?\s*(\d+(\.\d+)?)\s*(\w+)?$/;

    if (regex.test(acceptableRange)) {
      const matches = acceptableRange.match(regex);
      const comparisonType = matches[1];
      const compareValue = parseFloat(matches[2]);

      switch (comparisonType) {
        case '<=':
          return measurement <= compareValue;
        case '>=':
          return measurement >= compareValue;
        case '>':
          return measurement > compareValue;
        case '<':
          return measurement < compareValue;
        case '=':
        case '==':
          return measurement === compareValue;
        default:
          return false;
      }
    }

    return false;
  };

  const handleChange = (id, value) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const fetchCriteria = async (category) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(config.apiUrl + '/inspection-criteria/search', {
        params: {
          type: 'PQC',
          // relationship_id: dataMiddle.product_id,
          // model: 'Product',
          // category: category
        }
      });
      const criteriaData = response.data || [];
      setCriteria(criteriaData);
      const initialButtonStatus = {};
      criteriaData.forEach(item => {
        initialButtonStatus[item.id] = { status: 'OK/NG', bg: 'white', color: 'black' };
      });
      setButtonStatus(initialButtonStatus);
      const initialValues = {};
      criteriaData.forEach(item => {
        initialValues[item.id] = item.acceptablerange === 'OK/NG' ? '' : '';
      });
      form.setFieldsValue(initialValues);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const showModal = (type) => {
    if (dataMiddle?.id) {
      const result = dataMiddle?.quality_check?.result ?? null;
      if (result === 'OK') message.info(`Lot: ${dataMiddle?.lot_id} đã kiểm tra - kết quả "${result}"`);
      else {
        setInspectionType(type);
        setIsModalVisible(true);
        fetchCriteria(type === 'Kiểm tra chỉ tiêu' ? 'tinh_nang' : 'ngoai_quan');
      }
    } else message.info('CHƯA QUÉT MÃ LOT!');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleQrScanner = async (res) => {
    if (res && selectedEquipment && isScanningRef.current) {
      isScanningRef.current = false;

      // Get lot
      const response = await lotApi.getLotById(res);
      if (response?.success) {
        // Get lot history
        const history = await lotHistoryApi.getLotHistories({
          lot_id: response.data.id,
          equipment_id: selectedEquipment,
        });

        if (history?.success) {
          let result = history.data?.data ?? [];
          if (result.length > 0) setDataMiddle(result[0]);
          setScannerKey(Date.now());
          setIsModalScannerVisible(false);
        }
      }

      setTimeout(() => {
        isScanningRef.current = true;
      }, 2000);
    }
  }

  const rowClassName = (record) => {
    switch (record?.result) {
      case 'NG':
        return 'table-row-ng';
      default:
        return "";
    }
  }

  const handleOpenScanner = () => {
    setScannerKey(Date.now());
    if (selectedEquipment) setIsModalScannerVisible(true);
    else message.info('VUI LÒNG CHỌN THIẾT BỊ!');
  }

  const handleEquipmentChange = async (value) => {
    setLoading(true);
    setSelectedEquipment(value);
    setDataMiddle({});
    await fetchLotHistory({ equipment_id: value });
    setLoading(false);
  }

  const handleRowClick = (record) => {
    setDataMiddle(record);
  };

  const handleUpdateNg = () => {
    formInput.validateFields().then(async (values) => {
      const response = await lotHistoryApi.updateManyNgLotHistory(dataMiddle?.id, values);
      if (response?.success) {
        fetchCurrentLot();
        await fetchLotHistory({ equipment_id: selectedEquipment });
        handleCloseUpdateNGModal();
      }
    }).catch(errorInfo => {
      console.log('Validate Failed:', errorInfo);
    });
  }

  const handleOpenUpdateNg = () => {
    if (dataMiddle?.id) {
      setIsInputModalVisible(true);
      getErrors();
    }
    else message.info('VUI LÒNG CHỌN LOT TRƯỚC KHI NHẬP SL.NG!');
  }

  const handleCloseUpdateNGModal = () => {
    setIsInputModalVisible(false);
    formInput.resetFields();
  }

  const [errors, setErrors] = useState([]);
  const [loadingErrors, setLoadingErrors] = useState(false);
  const getErrors = async () => {
    setLoadingErrors(true);
    const errors = await errorQualityManagementApi.getErrorQualities();
    if (errors.success) setErrors(errors.data.data.map(e => ({ ...e, value: e.id, label: e.id })));
    setLoadingErrors(false);
  }

  return (
    <Content className="oi-content">
      <div className="oi-select-row">
        <div className="oi-select-container">
          <div className="oi-select-header">Máy</div>
          <Select
            showSearch
            optionFilterProp='label'
            options={equipmentOptions}
            value={selectedEquipment}
            style={{ width: '100%', height: '-webkit-fill-available' }}
            onChange={handleEquipmentChange}
            className="oi-select"
            placeholder="Chọn máy"
          />
        </div>
        <div className="oi-statistics-container">
          <Table
            className="custom-table"
            locale={{ emptyText: 'Không có dữ liệu' }}
            dataSource={dataTop}
            columns={columnsTop.map(col => ({ ...col, align: 'center' }))}
            pagination={false}
            size="small"
            bordered
            scroll={{ x: '420px' }}
          />
        </div>
      </div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Table
          locale={{ emptyText: 'Không có dữ liệu' }}
          dataSource={Object.keys(dataMiddle).length > 0 ? [dataMiddle] : []}
          columns={columnsMiddle.map(e => ({ ...e, align: 'center' }))}
          pagination={false}
          scroll={{ x: '780px' }}
          size='small'
          bordered
        />
        <div className="oi-quality-row-button-pqc">
          {/* <Button block type="primary" onClick={handleOpenScanner}><QrcodeOutlined style={{ fontSize: 20 }} /></Button> */}
          <Button style={{ width: '100%', height: '100%' }} type="primary" onClick={() => showModal('Kiểm tra chỉ tiêu')}>Kiểm tra chỉ tiêu</Button>
          {/* <Button block type="primary"><PrinterOutlined style={{ fontSize: 20 }} /></Button> */}
          <Button block type="primary" danger onClick={handleOpenUpdateNg}>Nhập SL NG</Button>
        </div>

        <Table
          loading={loading}
          rowClassName={rowClassName}
          dataSource={lotHistory}
          columns={columnsBottom.map(e => ({ ...e, align: 'center' }))}
          pagination={false}
          scroll={{ x: '1000px', y: 400 }}
          size='small'
          bordered
        // onRow={(record) => ({
        //   onClick: () => handleRowClick(record),
        // })}
        />
      </Space>
      <Modal
        title={`${inspectionType}`}
        open={isModalVisible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button key="submit" type="primary" onClick={() => { handleResult('OK') }}>
            OK
          </Button>,
          <Button key="save" type="primary" onClick={() => { handleResult('NG') }} danger>
            NG
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Hủy
          </Button>,
        ]}
      >
        <div className="oi-modal-content-scroll">
          <Form form={form} layout="vertical">
            {criteria.length > 0 && criteria.map(item => renderInput(item.id, item?.criteria_name ?? item.description, item.acceptablerange))}
          </Form>
        </div>
      </Modal>
      <Modal
        key={scannerKey}
        centered
        title={"Quét số lot"}
        open={isModalScannerVisible}
        onCancel={() => {
          setScannerKey(Date.now());
          setIsModalScannerVisible(false);
        }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Thoát"
        width={500}
      >
        {isModalScannerVisible && <Scanner key={scannerKey} isScan={true} onResult={(res) => handleQrScanner(res)} />}
      </Modal>

      {/* Input quantity */}
      <Modal
        centered
        title={"Nhập số lượng"}
        open={isInputModalVisible}
        onCancel={() => handleCloseUpdateNGModal()}
        onOk={handleUpdateNg}
        okText="Xác nhận"
        cancelText="Thoát"
        width={500}
      >
        <Form form={formInput} layout="vertical" name="form_in_modal" initialValues={{
          errorList: [{}]
        }}>
          <Form.List name="errorList">
            {(fields, { add, remove }) => (
              <>
                {fields.length ?
                  <Row gutter={8} style={{ marginBottom: 12 }}>
                    <Col span={1} />
                    <Col span={11}>
                      <label class="ant-form-item-required" title="Mã lỗi">
                        <span style={{ color: 'red', fontSize: 14, fontFamily: 'SimSun', marginInlineEnd: 4 }}>*</span>
                        Mã lỗi
                      </label>
                    </Col>
                    <Col span={11}>
                      <label class="ant-form-item-required" title="Số lỗi">
                        <span style={{ color: 'red', fontSize: 14, fontFamily: 'SimSun', marginInlineEnd: 4 }}>*</span>
                        Số lượng (bao)
                      </label>
                    </Col>
                  </Row>
                  :
                  null
                }
                {fields.map(({ key, name, ...restField }, index) => (
                  <Row gutter={8} style={{ marginBottom: 12 }}>
                    <Col span={1} style={{ display: 'flex', alignItems: 'center' }}>{index + 1 + "."}</Col>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'error_quality_id']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập mã lỗi!',
                          },
                        ]}
                        style={{ marginBottom: 0 }}
                      >
                        <Select placeholder="Mã lỗi" options={errors} showSearch optionFilterProp='label' loading={loadingErrors} />
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item
                        {...restField}
                        name={[name, 'quantity_ng']}
                        style={{ marginBottom: 0 }}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập số lượng!',
                          },
                        ]}
                      >
                        <InputNumber placeholder="Số lượng" min={0} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={1} style={{ display: 'flex', alignItems: 'center' }}><CloseOutlined onClick={() => remove(name)} /></Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}></Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </Content>
  );
};

export default OIQualityPQC;
