import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PrinterOutlined } from '@ant-design/icons';
import axiosInstance from '../../../api/axiosInstance'; // Import axiosInstance
import './ErrorQualityTable.css';
import { useReactToPrint } from 'react-to-print';
import errorQualityApi from "../../../api/errorQualityManagementApi";
import processApi from "../../../api/processManagementApi";
import config from '../../../config/config';

const ErrorQualityTable = ({ filters }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [form] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState(null);
    const [data, setData] = useState([]);
    const [processOptions, setProcessOptions] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const componentRef = useRef();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: config.pageSize,
        total: 0,
    });
    const [searchValue, setSearchValue] = useState('');

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        fetchProcess()
        fetchData({ ...filters, page: 1, pageSize: config.pageSize });
    }, [filters]);

    useEffect(() => {
        if (htmlContent) {
            handlePrint();
        }
    }, [htmlContent]);

    const fetchData = async (filters) => {
        try {
            setLoading(true);
            const response = await errorQualityApi.getErrorQualities(filters); // Thay đổi endpoint theo API của bạn
            setData(response.data.data ?? []);
            response.data?.pagination && setPagination(response.data.pagination)
            setLoading(false);
        } catch (error) {
            message.error('Failed to fetch accounts');
        }
    };

    const fetchProcess = async () => {
        try {
            const response = await processApi.getProcesses();
            setProcessOptions((response.data.data ?? []).map((item) => {
                return { label: item?.process_name, value: item?.id };
            }));
            if ((response.data.data ?? []).length === 0) message.info('Dữ liệu công đoạn trống!')
        } catch (error) {
            message.error('Failed to fetch processes');
        }
    }

    const showModal = () => {
        setIsEditMode(false);
        setEditingRecord(null);
        setIsModalVisible(true);
    };

    const showEditModal = (record) => {
        setIsEditMode(true);
        setEditingRecord(record);
        form.setFieldsValue(record);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        form.validateFields().then(async (values) => {
            try {
                if (isEditMode) {
                    const response = await errorQualityApi.updateErrorQuality(editingRecord.id, values); // Thay đổi endpoint theo API của bạn
                    if (response.success) {
                        fetchData({...filters, ...pagination})
                    } else throw new Error('Action failure');
                } else {
                    const response = await errorQualityApi.createErrorQuality(values); // Thay đổi endpoint theo API của bạn
                    if (response.success) {
                        // setData((prevData) => [{ ...values, id: response.data.id }, ...prevData]);
                        fetchData({...filters, ...pagination})
                    } else throw new Error('Action failure');
                }
                setIsModalVisible(false);
                form.resetFields();
            } catch (error) {
                message.error('Failed to save product');
            }
        }).catch((error) => { console.log(error) });
    };
    const handleDelete = async (id) => {
        try {
            await errorQualityApi.deleteErrorQuality(id); // Thay đổi endpoint theo API của bạn
            fetchData({...filters, ...pagination})
            // message.success('Xóa lỗi công đoạn thành công');
        } catch (error) {
            message.error('Failed to delete equipment');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    const handleUploadChange = (info) => {
        try {
            if (info.file.status === 'uploading') {
                setUploading(true);
            } else {
                if (info.file.status === 'done') {
                    if (!info.file?.response?.success)
                        throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
                    fetchData({ ...filters, ...pagination });
                    message.success(`${info.file.name} - Upload thành công`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} - Upload thất bại`);
                }
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setUploading(false);
        }
    };

    const uploadProps = {
        name: 'file',
        accept: '.xlsx, .xls',
        action: config.apiUrl + '/import-error-qualities', // Đường dẫn API để xử lý upload file
        onChange: handleUploadChange,
        showUploadList: false, // Ẩn danh sách file
    };

    const columns = [
        {
            title: 'STT',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
            render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
        },
        {
            title: 'Mã lỗi',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Tên lỗi',
            dataIndex: 'error_quality_name',
            key: 'error_quality_name',
            align: 'center',
        },
        {
            title: 'Tên công đoạn',
            dataIndex: 'process_name',
            key: 'process_name',
            align: 'center',
            render: (value, record) => {
                return record.process?.process_name
            }
        },
        {
            title: 'Mô tả',
            dataIndex: 'description',
            key: 'description',
            align: 'center',
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />
                    <Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
                    </Popconfirm>
                    {/* <Button type='primary' size='small' icon={<PrinterOutlined />} onClick={() => printRecord(record)} /> */}
                </Space>
            ),
            align: 'center',
        },
    ];
    return (
        <Card
            title="Quản lý lỗi công đoạn"
            extra={
                <div className="table-actions">
                    {/* <Button type="primary">Xuất file</Button> */}
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                            {uploading ? 'Đang tải lên' : 'Upload Excel'}
                        </Button>
                    </Upload>
                    <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm lỗi công đoạn</Button>
                </div>
            }
        >
            <Table
                loading={loading}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={data.map((record, key) => { return { key, ...record } })}
                pagination={{
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: (page, pageSize) => {
                        setPagination({ ...pagination, page, pageSize });
                        fetchData({ ...filters, page, pageSize });
                    }
                }}
                bordered  // Thêm thuộc tính bordered để có viền
                size="small"  // Sử dụng kích thước bảng nhỏ
            />
            <div ref={componentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
            <Modal
                title={
                    <div className="modal-title">
                        {isEditMode ? "Chỉnh sửa lỗi công đoạn" : "Thêm lỗi công đoạn"}
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Lưu lại"
                cancelText="Thoát"
                width={800}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <div className="modal-form-grid">
                        <Form.Item name="id" label="Mã lỗi" rules={[{ required: true, message: 'Vui lòng nhập mã lỗi!' }]}>
                            <Input disabled={isEditMode} />
                        </Form.Item>
                        <Form.Item name="error_quality_name" label="Tên lỗi công đoạn" rules={[{ required: true, message: 'Vui lòng nhập tên lỗi công đoạn!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="process_id" label="Tên công đoạn" rules={[{ required: true, message: 'Vui lòng nhập tên công đoạn!' }]}>
                            <Select showSearch allowClear placeholder="Chọn công đoạn" options={processOptions} optionFilterProp='label' />
                        </Form.Item>
                        <Form.Item name="description" label="Mô tả" rules={[{ required: true, message: 'Vui lòng nhập mô tả!' }]}>
                            <Input />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </Card>
    );
};

export default ErrorQualityTable;
