import { PlusOutlined, QrcodeOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, InputNumber, Layout, Modal, QRCode, Row, Select, Space, Table, Tabs, Tag, message } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import materialReceiptApi from '../../../../api/materialReceiptApi';
import palletManagementApi from '../../../../api/palletManagementApi';
import warehouseEntryManagementApi from '../../../../api/warehouseEntryManagementApi';
import warehouseLocationManagementApi from '../../../../api/warehouseLocationManagementApi';
import Scanner from '../../../../components/Scanner';
import config from '../../../../config/config';
import './WarehouseMLT.css';
import { columnsEntry, columnsPalletScanner, columnsTop, processOptions } from './tableData';
import dayjs from 'dayjs';
import { ReactToPrint } from 'react-to-print';
import { summaryWarehouseHistory } from '../../../../api/warehouseHistoryApi';
import PreviewPrintTinyMCE from '../../../../components/PreviewPrintTinyMCE';
import { tableHeight } from '../../components/OICalculateTableHeight';

const { Content } = Layout;
const { Option } = Select;

const WarehouseMLTImport = () => {
    const navigate = useNavigate();
    const context = useOutletContext();
    const [formCreatePallet] = Form.useForm();
    const [formScanPallet] = Form.useForm();
    const [selectedProcess, setSelectedProcess] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingToPrintMany, setLoadingToPrintMany] = useState(false);
    const [data, setData] = useState([]);
    const [dataEntry, setDataEntry] = useState([]);
    const [htmlContent, setHtmlContent] = useState('');
    const componentRef1 = React.useRef();
    const [dataPalletScanner, setDataPalletScanner] = useState({ // fields in QR
        warehouse_location_id: null,
        pallet_id: null,
        quantity: 0,
        unit_id: null,
        unit_name: null,
    });
    const [tableDataPalletScanner, setTableDataPalletScanner] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: config.pageSize,
        total: 0,
    });
    const [materialReceiptOptions, setMaterialReceiptOptions] = useState([]);
    const [materialOptions, setMaterialOptions] = useState([]);
    const [suggests, setSuggests] = useState([]);
    const [summary, setSummary] = useState({ import: 0, export: 0 });
    const [scannerKey, setScannerKey] = useState(Date.now());
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const reactToPrintRef = useRef();
    const currentQr = useRef();
    const tableH = useRef(450);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const isScanningRef = useRef(true);
    const isScanningPalletRef = useRef(true);

    const printPallet = (record) => async () => {
        try {
            setLoading(true);

            const response = await palletManagementApi.printPallet(record?.id);
            if (response?.success) {
                await setHtmlContent(response.data);
            }
            currentQr.current = record;
            console.log(currentQr.current);
            setTimeout(() => {
                reactToPrintRef.current.handlePrint();
                setLoading(false);
            }, 300);
        } catch (error) {
            message.error('THAO TÁC THẤT BẠI!');
            setLoading(false);
        }
    }

    const htmlQr = () => {
        return (
            <div style={{ flexDirection: 'column', display: 'flex', gap: 8, alignItems: 'center', border: '1px solid gray', width: '230px', padding: '10px' }}>
                <QRCode value={currentQr.current?.id} size={200} bordered type='canvas' />
                <b>Mã NVL: {currentQr.current?.material_id}</b>
                <b>Lot NO: {currentQr.current?.lot_no}</b>
                <b>Quy cách đóng gói: {currentQr.current?.material_receipt_item?.packing}</b>
                <b>Ngày tạo: {currentQr.current?.created_at ? dayjs(currentQr.current?.created_at).format('DD/MM/YYYY') : "-"}</b>
            </div>
        )
    }

    const columnsBottomImport = [
        { title: "STT", dataIndex: "stt", key: "stt", align: "center", width: '50px', render: (value, record, index) => index + 1 },
        { title: "Mã phiếu nhập", dataIndex: "material_receipt_id", key: "material_receipt_id", align: "center", width: '120px', render: (_, record) => record?.material_receipt?.id },
        { title: "Mã Pallet", dataIndex: "id", key: "id", align: "center", width: '130px' },
        { title: "Mã NVL", dataIndex: "material_id", key: "material_id", align: "center", width: '80px' },
        { title: "Tên NVL", dataIndex: "material_name", key: "material_name", align: "center", width: '220px', render: (_, record) => record?.material?.material_name },
        { title: "Lot No", dataIndex: "lot_no", key: "lot_no", align: "center", width: '100px' },
        { title: "Số lượng", dataIndex: "quantity", key: "quantity", align: "center", width: '80px', render: (value) => Number(value) },
        { title: "ĐVT", dataIndex: "unit_name", key: "unit_name", align: "center", width: '80px', render: (_, record) => record?.unit?.unit_name },
        {
            title: "Trạng thái IQC", dataIndex: "status", key: "status", align: "center", width: '120px', render: (_, record) => {
                switch (record?.material_receipt_item?.quality_check?.result) {
                    case 'OK':
                        return <Tag color='#2d991f'>OK</Tag>
                    case 'NG':
                        return <Tag color='#ff4d4f'>NG</Tag>
                    default:
                        return <Tag color='#ffae00'>Chưa kiểm</Tag>
                }
            }
        },
        { title: "In tem", dataIndex: "warehouse_location_id", key: "warehouse_location_id", align: "center", width: '60px', fixed: 'right', render: (_, record) => <Button icon={<PrinterOutlined />} onClick={printPallet(record)}></Button> }
    ];

    const fetchData = async (filters) => {
        try {
            setLoading(true);
            const response = await palletManagementApi.getPallets({...filters, page, pageSize: 20}); // Thay đổi endpoint theo API của bạn
            setData(response.data.data);
            response.data?.pagination && setPagination(response.data.pagination)
            setLoading(false);
        } catch (error) {
            message.error(error.message);
        }
    };

    const fetchDataEntry = async (filters) => {
        try {
            setLoading(true);
            const response = await warehouseEntryManagementApi.getWarehouseEntries({ ...filters, model: 'pallet' }); // Thay đổi endpoint theo API của bạn
            setDataEntry(response.data.data);
            setLoading(false);
        } catch (error) {
            message.error(error.message);
        }
    };

    const fetchMaterialReceipt = async (params = null) => {
        try {
            params = { receipt_date: dayjs().format('YYYY-MM-DD') };
            const response = await materialReceiptApi.getMaterialReceipts(params);
            await setMaterialReceiptOptions((response.data.data ?? []).map((item) => {
                return { label: item?.id, value: item?.id };
            }));
            // if ((response.data ?? []).length === 0) message.info('Dữ liệu mã phiếu trống!')
        } catch (error) {
            message.error(error.message);
        }
    }

    const fetchSummary = async () => {
        try {
            const response = await summaryWarehouseHistory({ model: 'pallet' });
            if (response?.success) {
                const data = response.data ?? [];
                const ex = data?.find(item => item?.type === 'export')?.sum ?? 0;
                const im = data?.find(item => item?.type === 'import')?.sum ?? 0;
                setSummary({ export: ex, import: im });
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    const fetchMaterialReceiptItem = async (params = null) => {
        try {
            const response = await materialReceiptApi.getMaterialReceiptItems(params);
            await setMaterialOptions((response.data.data ?? []).map((item) => {
                return {
                    label: item?.material?.id,
                    value: item?.material?.id,
                    material_receipt_item_id: item?.id,
                    quantity: '',
                };
            }));
            if ((response.data.data ?? []).length === 0) {
                message.info('Dữ liệu trống!');
                formCreatePallet.resetFields(['material_id', 'quantity']);
            } else {
                const { material, quantity } = response.data.data[0];
                formCreatePallet.setFieldsValue({
                    material_id: material?.id,
                    quantity,
                })
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    useEffect(() => {
        fetchSummary();
        fetchData();
        fetchDataEntry();
    }, []);

    useEffect(() => {
        if (selectedProcess === 2) navigate("/oi/material-warehouse-export");
    }, [selectedProcess]);

    useEffect(() => {
        if (context && context.setTitle) {
            context.setTitle('QUẢN LÝ KHO NVL');
        }
    }, [context]);

    useEffect(() => {
        const { warehouse_location_id, pallet_id, quantity, unit_id, unit_name } = dataPalletScanner;
        formScanPallet.setFieldsValue({ quantity: quantity });
        setTableDataPalletScanner([{
            key: 1,
            warehouse_location_id,
            pallet_id,
            quantity,
            unit_id,
            unit_name
        }]);
    }, [dataPalletScanner]);

    const handleCancel = () => {
        setScannerKey(Date.now());
        setIsModalVisible(false);
        setDataPalletScanner((prevData) => ({
            ...prevData,
            warehouse_location_id: null,
            pallet_id: null,
            quantity: null,
            unit_id: null,
        }))
        setTableDataPalletScanner([]);
        formScanPallet.resetFields();
        isScanningPalletRef.current = true;
    };

    const handleCreateCancle = () => {
        setScannerKey(Date.now());
        setIsCreateModalVisible(false);
        formCreatePallet.resetFields();
        isScanningPalletRef.current = true;
    }

    const handleCreateWarehouseEntry = async () => {
        try {
            const { warehouse_location_id, pallet_id, quantity, unit_id } = dataPalletScanner;
            if (!pallet_id) throw new Error('CHƯA CÓ THÔNG TIN MÃ PALLET!');
            if (!warehouse_location_id) throw new Error('CHƯA CÓ THÔNG TIN VỊ TRÍ!');
            const response = await warehouseEntryManagementApi.createWarehouseEntry({
                warehouse_location_id,
                pallet_id,
                quantity,
                unit_id,
                status: 'test',
            });

            if (response?.success) {
                fetchSummary();
                fetchData();
                fetchDataEntry();
                handleCancel();
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    const handleQrScanner = async (data) => {
        if (data && isScanningRef.current) {
            isScanningRef.current = false;

            setLoading(true);
            try {
                if (!isScanningPalletRef.current) {
                    const resWarehouse = await warehouseLocationManagementApi.getWarehouseLocationById(data);
                    if (resWarehouse?.success) {
                        await setDataPalletScanner(prevState => ({ ...prevState, warehouse_location_id: resWarehouse.data?.id }));
                        isScanningPalletRef.current = true;
                    }
                } else {
                    // Get pallet
                    const resPallet = await palletManagementApi.getPalletById(data);
                    if (resPallet?.success) {
                        const { unit_id, unit, quantity } = resPallet.data;

                        // Suggest
                        const response = await warehouseLocationManagementApi.getWarehouseLocationsSuggest(data);
                        if (response?.success) {
                            setSuggests(response?.data ?? []);
                        }
                        await setDataPalletScanner(prevState => ({ ...prevState, pallet_id: data, unit_id, unit_name: unit?.unit_name, quantity }));
                        isScanningPalletRef.current = false;
                    }
                }
            } catch (error) {
                message.error(error.message);
            } finally {
                setLoading(false);
            }

            setTimeout(() => {
                isScanningRef.current = true;
            }, 2000);
        }
    }

    const handleOkScanner = async () => {
        await handleCreateWarehouseEntry();
    }

    const handleOk = async () => {
        formCreatePallet.validateFields().then(async (values) => {
            const record = materialOptions.find((item) => item.value === values.material_id);
            const response = await palletManagementApi.createPallet({
                ...values,
                material_receipt_item_id: record?.material_receipt_item_id,
            });
            if (response?.success) {
                fetchData();
                fetchDataEntry();
                handleCreateCancle();
            }
        }).catch((error) => console.log(error));
    }

    const handleScanPallet = async () => {
        setScannerKey(Date.now());
        setSuggests([]);
        setIsModalVisible(true);
    }

    const handlePrintManyPallet = async () => {
        try {
            setLoadingToPrintMany(true);
            const response = await palletManagementApi.printManyPallet({ pallet_ids: data.filter((e, key) => selectedRowKeys.includes(key)).map(e => e.id) });
            if (response?.success) {
                await setHtmlContent(response.data);
            }
            setTimeout(() => {
                reactToPrintRef.current.handlePrint();
                setLoadingToPrintMany(false);
            }, 300);
        } catch (error) {
            console.log(error);
            message.error('THAO TÁC THẤT BẠI!');
            setLoadingToPrintMany(false);
        }
    }

    const handleCreatePalletOpen = async () => {
        await fetchMaterialReceipt();
        formCreatePallet.setFieldsValue({ pallet_amount: 1, lot_no: dayjs().format('DD') });
        setIsCreateModalVisible(true)
    }

    const handleMaterialReceiptSelected = async (value) => {
        await fetchMaterialReceiptItem({ material_receipt_id: value });
    }

    const handleMaterialSelected = (value) => {
        const record = materialOptions.find((item) => item.value === value);
        formCreatePallet.setFieldsValue({ ...record });
    }
    useEffect(() => {
        if (htmlContent) {
            // reactToPrintRef.current.handlePrint();
            setSelectedRowKeys([]);
        }
    }, [htmlContent]);

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    useEffect(() => {
        setTimeout(() => {
            let tableHCurrent = tableHeight("table-wh-import", window.innerHeight);
            if (tableHCurrent) {
                tableHCurrent -= 30 + 48 + 64;
                tableH.current = tableHCurrent;
            }
            console.log(window.innerHeight, tableH.current);
        }, 300);
    }, [data]);

    const loadMoreData = async () => {
        if (loading || !hasMore) return;
    
        setLoading(true);
        const response = await palletManagementApi.getPallets({page, pageSize: 20});
        const result = response.data?.data ?? [];
        if (result.length > 0) {
          setData((prevData) => [...prevData, ...result]);
          setPage((prevPage) => prevPage + 1);
        } else {
          setHasMore(false);
        }
        setLoading(false);
    };

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (scrollTop + clientHeight >= scrollHeight - 15) {
            loadMoreData();
        }
    };

    return (
        <Content className="oi-content">
            <Row className="mt-1" gutter={[6, 12]}>
                <Col span={24} className="section-top">
                    <div className="oi-select-row">
                        <div className="oi-select-container" style={{ width: '30%' }}>
                            <div className="oi-select-header">Thao tác</div>
                            <Select
                                optionFilterProp='label'
                                options={processOptions}
                                value={selectedProcess}
                                style={{ width: '100%', height: '-webkit-fill-available' }}
                                onChange={setSelectedProcess}
                                className="oi-select"
                                placeholder="Thao tác"
                            />
                        </div>
                        <div className="oi-statistics-container" style={{ width: '70%' }}>
                            <Table
                                className="custom-table"
                                dataSource={[{ ...summary }]}
                                columns={columnsTop.map(col => ({ ...col, align: 'center' }))}
                                pagination={false}
                                size="small"
                                bordered
                            />
                        </div>
                    </div>
                </Col>
                {/* <Col span={24}>
                    <Table
                        className="custom-table"
                        dataSource={[{ key: 1, ...dataPalletScanner }]}
                        columns={columnsMiddleImport.map(col => ({ ...col, align: 'center' }))}
                        pagination={false}
                        size="small"
                        bordered
                    />
                </Col> */}
                <Col span={12} className="section-button">
                    <Button block type="primary" icon={<PlusOutlined />} onClick={handleCreatePalletOpen}>Tạo Pallet</Button>
                </Col>
                <Col span={12}>
                    {/* Quét QR Pallet để nhập kho */}
                    <Button block type="primary" icon={<QrcodeOutlined />} onClick={handleScanPallet}>Quét QR</Button>
                </Col>
                <Col span={24}>
                    <Card>
                        <Tabs defaultActiveKey="1" type="card" style={{ width: '100%' }} className='custom-oi-tabs'>
                            <TabPane tab="Danh sách Pallets" key="1">
                                {
                                    selectedRowKeys.length > 0 && (
                                        <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '5px' }}>
                                            <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrintManyPallet}>In tem đã chọn</Button>
                                        </div>
                                    )
                                }
                                <Table
                                    className="table-wh-import"
                                    loading={loading}
                                    dataSource={data.map((row, key) => ({ key, ...row }))}
                                    columns={columnsBottomImport.map(col => ({ ...col, align: 'center' }))}
                                    pagination={false}
                                    rowSelection={rowSelection}
                                    size="small"
                                    scroll={{ x: '1080px', y: tableH.current }}
                                    bordered
                                    onScroll={handleScroll}
                                />
                            </TabPane>
                            <TabPane tab="Lịch sử nhập kho" key="2">
                                <Table
                                    loading={loading}
                                    dataSource={dataEntry}
                                    columns={columnsEntry.map(col => ({ ...col, align: 'center' }))}
                                    pagination={false}
                                    size="small"
                                    scroll={{ x: '950px', y: tableH.current }}
                                    bordered
                                />
                            </TabPane>
                        </Tabs>
                    </Card>
                </Col>
            </Row>

            {/* Modal QR Scanner */}
            <Modal
                key={scannerKey}
                centered
                title={dataPalletScanner.pallet_id ? 'Quét QR Vị trí' : 'Quét QR mã Pallet'}
                open={isModalVisible}
                onCancel={handleCancel}
                onOk={handleOkScanner}
                okText="Xác nhận"
                cancelText="Thoát"
                width={500}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    {suggests.length > 0 && (
                        <div className="oi-suggest">
                            <span>Gợi ý vị trí kho:</span>
                            <ul>
                                {suggests.map((item) => (
                                    <li><small>Hàng:{` ${item?.id} `}- Còn trống:{` ${Number(item?.remain).toLocaleString('en-US')} `}(kg)</small></li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <div>
                        {isModalVisible && <Scanner key={scannerKey} isScan={true} onResult={(res) => handleQrScanner(res)} />}
                    </div>
                    <Table
                        className="custom-table"
                        dataSource={tableDataPalletScanner}
                        columns={columnsPalletScanner()}
                        pagination={false}
                        size="small"
                        bordered
                    />
                </Space>
            </Modal>

            {/* Modal create pallet */}
            <Modal
                centered
                title="Tạo pallet"
                open={isCreateModalVisible}
                onCancel={handleCreateCancle}
                onOk={handleOk}
                okText="Xác nhận"
                cancelText="Thoát"
                width={500}
            >
                <Form form={formCreatePallet} layout="vertical" name="form_in_modal">
                    <Row gutter={5}>
                        <Col span={6} md={6} xs={24}>
                            <Form.Item name="pallet_amount" label="Số bản ghi" rules={[{ required: true, message: 'Vui lòng nhập!' }]}>
                                <InputNumber style={{ width: '100%' }} min={1} placeholder="Số bản ghi Pallet cần tạo" />
                            </Form.Item>
                        </Col>
                        <Col span={18} md={18} xs={24}>
                            <Form.Item name="material_receipt_id" label="Mã phiếu" rules={[{ required: true, message: 'Vui lòng chọn mã phiếu!' }]}>
                                <Select showSearch allowClear placeholder="Chọn mã phiếu" options={materialReceiptOptions} optionFilterProp='label' onChange={handleMaterialReceiptSelected} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="material_id" label="Mã NVL" rules={[{ required: true, message: 'Vui lòng chọn mã NVL!' }]}>
                        <Select showSearch allowClear placeholder="Chọn mã NVL" options={materialOptions} optionFilterProp='label' onChange={handleMaterialSelected} />
                    </Form.Item>
                    <Form.Item name="lot_no" label="Lot No" rules={[{ required: true, message: 'Vui lòng nhập Lot No!' }]}>
                        <Input placeholder="Lot No" />
                    </Form.Item>
                    <Form.Item name="quantity" label="Số lượng" rules={[{ required: true, message: 'Vui lòng nhập số lượng!' }]}>
                        <InputNumber style={{ width: '100%' }} min={0} placeholder="Số lượng" />
                    </Form.Item>
                </Form>
            </Modal>
            {htmlContent && <div className="print-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />}
            <div>
                <ReactToPrint
                    ref={reactToPrintRef}
                    trigger={() => <button style={{ display: 'none' }}>Print this out!</button>}
                    content={() => componentRef1.current}
                />
                {/* <PreviewPrintTinyMCE content={htmlContent} setContent={setHtmlContent}/> */}
                {/* {htmlQr()} */}
                <div ref={componentRef1} >
                    {htmlContent && <div className="print-container" dangerouslySetInnerHTML={{ __html: htmlContent }} />}
                </div>
            </div>
        </Content >

    );
};

export default WarehouseMLTImport;
