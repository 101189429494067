// src/pages/UI/TemplateManagement/TemplateManagement.js

import React from 'react';
import { Layout } from 'antd';
import TemplateTable from './TemplateTable';

const { Content } = Layout;

const TemplateManagement = () => {
  return (
    <Layout>
      <Layout>
        <Content className="site-layout-content">
          <TemplateTable />
        </Content>
      </Layout>
    </Layout>
  );
};

export default TemplateManagement;
