// src/pages/UI/ProductManagement/ProductManagementTable.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import productApi from "../../../api/productManagementApi";
import unitApi from '../../../api/unitManagementApi';
import config from '../../../config/config';
import unitManagementApi from '../../../api/unitManagementApi';
import productCategoryManagementApi from '../../../api/productCategoryManagementApi';
import { b64toBlob } from '../../../utils/dataBlob';
import axiosInstance from '../../../api/axiosInstance';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import AuthContext from '../../../context/AuthContext';

const ProductManagementTable = ({ filters }) => {
  const { user } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [units, setUnits] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productAttributes, setProductAttributes] = useState([]);

  useEffect(() => {
    setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
    fetchData({ ...filters, page: 1, pageSize: config.pageSize });
    fetchProductAttribute();
  }, [filters]);

  useEffect(() => {
    (async () => {
      const res1 = await unitManagementApi.getUnits();
      if (res1.success) setUnits(res1.data.data.map(e => ({ ...e, value: e.id, label: e.unit_name })))
      const res2 = await productCategoryManagementApi.getProductCategories();
      if (res2.success) setProductCategories(res2.data.data.map(e => ({ ...e, value: e.id, label: e.product_category_name })))
    })()
  }, []);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await productApi.getProducts(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data.data);
      response.data.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch products');
    }
  };

  const showModal = () => {
    setIsEditMode(false);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setIsEditMode(true);
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      try {
        if (isEditMode) {
          const response = await productApi.updateProduct(editingRecord.id, values); // Thay đổi endpoint theo API của bạn
          if (response.success) {
            fetchData({ ...filters, ...pagination });
          }
        } else {
          const response = await productApi.createProduct(values); // Thay đổi endpoint theo API của bạn
          if (response.success) {
            fetchData({ ...filters, ...pagination });
          }
        }
        setIsModalVisible(false);
        form.resetFields();
      } catch (error) {
        message.error('Failed to save product');
      }
    }).catch((error) => {
      console.log('error', error);
    });
  };
  const handleDelete = async (id) => {
    try {
      const response = await productApi.deleteProduct(id); // Thay đổi endpoint theo API của bạn
      if (response.success) {
        setData((prevData) => prevData.filter(item => item.id !== id));
      }
    } catch (error) {
      message.error('Failed to delete equipment');
    }
  };
  const printRecord = async (record) => {
    const res = await productApi.printProduct(record.id);
    if (res.success) {
      setHtmlContent(res.data);
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`);
        }
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-products', // Đường dẫn API để xử lý upload file
    onChange: handleUploadChange,
    showUploadList: false, // Ẩn danh sách file
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: 'center',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Mã nội bộ',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Tên nội bộ',
      dataIndex: 'product_name',
      key: 'product_name',
      align: 'center',
    },
    {
      title: 'Loại',
      dataIndex: 'product_category_name',
      key: 'product_category_name',
      align: 'center',
      render: (value, record) => record?.product_category?.product_category_name
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_id',
      key: 'unit_id',
      align: 'center',
      render: (value, record) => record?.unit?.unit_name
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {permissions.includes('product.update') && (<Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
          {permissions.includes('product.delete') && (
            <Popconfirm
              title="Bạn có chắc chắn muốn xóa thành phẩm này?"
              onConfirm={() => handleDelete(record.id)}
              okText="Có"
              cancelText="Không"
            >
              <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
            </Popconfirm>
          )}
        </Space>
      ),
      align: 'center',
    },
  ];

  const fetchProductAttribute = async () => {
    const response = await axiosInstance.get('/product-attributes');
    if (response?.success) {
      setProductAttributes(response.data?.data ?? []);
    }
  }

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/products-export');
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      style={{ height: "100%" }}
      title="Quản lý mã nội bộ"
      extra={
        <div className="table-actions">
          {permissions.includes('product.create') && (
            <>
              <Button target="_blank" href={config.apiUrl.replace('/api', '') + '/excel/Products.xlsx'} icon={<DownloadOutlined />}>Tải excel mẫu</Button>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                  {uploading ? 'Đang tải lên' : 'Upload Excel'}
                </Button>
              </Upload>
            </>
          )}
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel}>Xuất file</Button>
          {permissions.includes('product.create') && (<Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm mã nội bộ</Button>)}
          
        </div>
      }
    >
      <Table
        className='table-product'
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            fetchData({ ...filters, page, pageSize });
          }
        }}
        bordered  // Thêm thuộc tính bordered để có viền
        size="small"  // Sử dụng kích thước bảng nhỏ
        scroll={{ y: CalculateTableHeight('table-product') }}
      />
      <div ref={componentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa mã nội bộ" : "Thêm mã nội bộ"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={800}
      >
        <div className="oi-modal-content-scroll">
          <Form form={form} layout="vertical" name="form_in_modal">
            <div className="modal-form-grid">
              <Form.Item name="id" label="Mã nội bộ" rules={[{ required: true, message: 'Vui lòng nhập mã nội bộ!' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="product_name" label="Tên thành phẩm" rules={[{ required: true, message: 'Vui lòng nhập tên thành phẩm!' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="product_category_id" label="Loại thành phẩm" rules={[{ required: true, message: 'Vui lòng chọn loại thành phẩm!' }]}>
                <Select options={productCategories} optionFilterProp='label' showSearch />
              </Form.Item>
              <Form.Item name="unit_id" label="Đơn vị" rules={[{ required: true, message: 'Vui lòng nhập đơn vị!' }]}>
                <Select options={units} optionFilterProp='label' showSearch />
              </Form.Item>

              {
                productAttributes.map((item) => (
                  <Form.Item name={item.id} label={item.name}>
                    <Input placeholder={item.name} />
                  </Form.Item>
                ))
              }
            </div>
          </Form>
        </div>
      </Modal>
    </Card>
  );
};

export default ProductManagementTable;
