import { Button, Card, message, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../api/axiosInstance';
import ExportExcelButton from '../../../../components/ExportExcelButton';
import config from '../../../../config/config';
import { numberFormat } from '../../../../utils/numberFormat';
import dayjs from 'dayjs';
import { b64toBlob } from '../../../../utils/dataBlob';
import { DownloadOutlined } from '@ant-design/icons';

// Giả lập dữ liệu cho biểu đồ
const dataChart = [
  { tag: 'SL đầu ra(KH)', quantity: 350, percent: 800 },
  { tag: 'SL đầu ra(TT)', quantity: 900, percent: 600 },
  { tag: 'Chênh lệch', quantity: 300, percent: 400 },
  { tag: 'Số lượng lỗi', quantity: 450, percent: 380 },
];

const TaktTimeCycleTimeTable = ({ filters }) => {
  const [chart, setChart] = useState([])
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [overall, setOverall] = useState({});
  const [loadingTable1, setLoadingTable1] = useState(false);
  const [loadingTable3, setLoadingTable3] = useState(false);
  const [overallManufactureHistoryMiddleData, setOverallManufactureHistoryMiddleData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [recordData, setRecordData] = useState({});

  useEffect(() => {
    setChart(dataChart);
  }, []);

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      align: 'right',
      key: "stt",
      fixed: 'left',
      width: "50px",
      render: (_, _record, index) => index + 1
    },
    {
      title: "Ngày sản xuất",
      dataIndex: "start_time",
      width: "200px",
    },
    { title: "Máy", dataIndex: "equipment_id", key: "equipment_id", width: "150px" },
    { title: "LSX", dataIndex: "batch_id", key: "batch_id", width: "200px" },
    { title: "LOT", dataIndex: "lot_id", key: "lot_id", width: "250px" },
    { title: "Mã sản phẩm", dataIndex: "product_id", key: "product_id", width: "200px" },
    { title: "Tên sản phẩm", dataIndex: "product_name", key: "product_name", width: "250px" },
    {
      title: "Thời gian bắt đầu",
      dataIndex: "start_time",
      width: "200px",
    },
    {
      title: "Thời gian kết thúc",
      dataIndex: "end_time",
      key: "end_time",
      width: "200px",
    },
    { title: "Số lượng", dataIndex: "quantity", width: "100px" },
    { title: "Cycle time", dataIndex: "cycle_time", key: "cycle_time", width: "100px", render: (_, record) => {
      if (record?.start_time && record?.end_time) {
        return dayjs(record.end_time, 'DD/MM/YYYY HH:mm').diff(dayjs(record.start_time, 'DD/MM/YYYY HH:mm'), 'second');
      }
      return ''; 
    } },
    { title: "Số bao", dataIndex: "bag_number", key: "bag_number", width: "100px" },
    { title: "Takt time", dataIndex: "takt_time", key: "takt_time", width: "100px", fixed: 'right', render: (_, record) => {
      if (record?.start_time && record?.end_time && record?.bag_number) {
        return Number((dayjs(record.end_time, 'DD/MM/YYYY HH:mm').diff(dayjs(record.start_time, 'DD/MM/YYYY HH:mm'), 'second') / Number(record.bag_number)).toFixed(2));
      }
      return ''; 
    } }
  ];

  const fetchData = async (filters) => {
    setLoadingTable3(true);
    try {
      setLoading(true);
      const response = await axiosInstance.get('lot-histories', { params: filters }) // Thay đổi endpoint theo API của bạn
      setData(response.data.data);
      response.data?.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch pallets');
    }
    setLoadingTable3(false);
  };
  const fetchOverallManufactureHistory = async (filters) => {
    setLoadingTable1(true);
    try {
      const response = await axiosInstance.get('ui-overall-lot-histories', { params: filters });
      setOverall(response.data);
    } catch (error) {
      message.error('Failed to fetch pallets');
    }
    setLoadingTable1(false);
  }

  const fetchBatchHistory = async () => {
    setLoading(true);
    const response = await axiosInstance.get('/batch-histories', { params: { ...filters, pageSize: 15 } });
    if (response?.success) {
      const resource = await (response.data?.data ?? []).filter((item) => item?.batch?.production_plan?.status !== 4);
      setOverallManufactureHistoryMiddleData(resource);
    }
    setLoading(false);
  }

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    if (filters?.start_time) filters.start_time = dayjs(filters.start_time).format('YYYY-MM-DD');
    if (filters?.end_time) filters.end_time = dayjs(filters.end_time).format('YYYY-MM-DD');
    fetchData({ ...filters, ...paginate });
    fetchOverallManufactureHistory(filters);
    fetchBatchHistory();
  }, [filters]);

  const getTableHeight = () => {
    const card = document.querySelector('.section-card');
    if (card) {
      const cardHead = card?.querySelector('.ant-card-head');
      const cardBody = card?.querySelector('.ant-card-body');
      const tableTop = card?.querySelector('.table-top');
      const tableMiddle = card?.querySelector('.table-middle');
      const tableBottom = card?.querySelector('.table-bottom');
      let paddingTop = 0;
      let paddingBottom = 0;
      if (cardBody) {
        const style = window.getComputedStyle(cardBody);
        paddingTop = parseFloat(style.paddingTop);
        paddingBottom = parseFloat(style.paddingBottom);
      }
      const header = tableBottom?.querySelector('.ant-table-thead');
      const tableBottomPagination = tableBottom?.querySelector('.ant-table-pagination');
      const tableBody = tableBottom?.querySelector('.ant-table-body');
      const antEmpty = tableBottom?.querySelector('.ant-empty-normal');
      const result = (card?.offsetHeight ?? 0) - (cardHead?.offsetHeight ?? 0) - (tableTop?.offsetHeight ?? 0) - (tableMiddle?.offsetHeight ?? 0) - paddingTop - paddingBottom - (header?.offsetHeight ?? 0) - (tableBottomPagination?.offsetHeight ?? 0) - 48;
      if (tableBody) tableBody?.style.setProperty('height', `${result}px`, 'important');
      if (antEmpty) antEmpty?.style.setProperty('height', `${result - 114}px`);
      console.log(result);
      if (Number(result) > 0) return result;
    }
    return undefined;
  }

  const showBomInfo = async (record) => {
    await setRecordData(record);
    setIsModalVisible(true);
  }

  /**
   * @param record ProductionPlan 
   */
  const sumWeight = (record) => {
    const volume_of_batch = Number(record?.volume_of_batch);
    if (record.bom?.bom_items?.length === 0 || !volume_of_batch) return 0;
    let sum = 0;
    record.bom?.bom_items?.map((item) => {
      sum += Number(item?.unit_weight) * Number(volume_of_batch) * Number(record?.batch_number);
    });
    return sum;
  }

  const percentLoss = (record) => {
    if (record.bom?.bom_items?.length > 0 && record?.quantity > 0) {
      const sumW = sumWeight(record);
      const balance = sumW - Number(record.quantity);
      if (balance > 0 && sumW > 0) return `${numberFormat(balance / sumW * 100)}%`;
    }
    return '';
  }

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/production-plans-export/cycle-time-and-takt-time', {params: filters});
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      className="section-card"
      style={{ height: '100%' }}
      title="Quản lý CT, TT"
      extra={
        <Space>
          {/* <ExportExcelButton data={overallManufactureHistoryMiddleData} filename={'Manufacturing'} sheetname={'Manufacturing'} /> */}
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel} loading={loading}>Xuất file</Button>
        </Space>

      }
    >
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Table
            className='table-bottom'
            loading={loadingTable3}
            columns={columns.map(e => ({ ...e, align: 'center' }))}
            dataSource={data}
            bordered
            pagination={{
              current: pagination.page,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: (page, pageSize) => fetchData({ ...filters, page, pageSize: pageSize }),
            }}
            scroll={{ x: '1600px', y: '33vh' }}
            size='small'
          />
        </Space>
      </div>
    </Card>
  );
};

export default TaktTimeCycleTimeTable;
