import axiosInstance from "./axiosInstance";

const getUnits = (filters) => axiosInstance.get('/units', { params: filters });
const getUnitById = (id) => axiosInstance.get(`/units/${id}`);
const createUnit = (unitData) => axiosInstance.post('/units', unitData);
const updateUnit = (id, unitData) => axiosInstance.put(`/units/${id}`, unitData);
const deleteUnit = (id) => axiosInstance.delete(`/units/${id}`);

export default {
  getUnits,
  getUnitById,
  createUnit,
  updateUnit,
  deleteUnit,
};
