import React, { useState } from 'react';
import { Layout, Tree, DatePicker, Button, Form, Divider, Select, ConfigProvider, Radio, Input } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../../components/Sidebar';

dayjs.locale('vi');

const treeData = [
  {
    title: 'Nhà máy Hoà Bình',
    key: '0-0',
    children: [
      {
        title: 'Cân NVL',
        key: '0-0-0',
      },
      {
        title: 'Trộn',
        key: '0-0-1',
      },
      {
        title: 'Đóng gói',
        key: '0-0-2',
      },
    ],
  },
];
const formFields = [
  // {
  //   type: 'Tree',
  //   label: '',
  //   name: 'equipment_id',
  //   treeData: treeData,
  // },
  {
    type: 'DatePicker',
    label: 'Ngày bắt đầu nhập',
    name: 'start_date',
    placeholder: 'Ngày bắt đầu nhập',
  },
  {
    type: 'DatePicker',
    label: 'Ngày kết thúc nhập',
    name: 'end_date',
    placeholder: 'Ngày kết thúc nhập',
  },
  {
    type: 'Select',
    label: 'Loại công việc bảo trì',
    name: 'type',
    placeholder: 'Chọn loại công việc',
    // mode: 'tags',
    options: [],
  },
  {
    type: 'Input',
    label: 'Đơn vị sử dụng',
    name: 'unit',
    placeholder: 'Nhập đơn vị sử dụng',
  },
  // Add other fields here
];

const EquipmentMaintenancePlanSidebar = ({ onFilter }) => {

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default EquipmentMaintenancePlanSidebar;
