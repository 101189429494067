// src/pages/UI/MaterialManagement/WarehouseSidebar.js
import React, { useEffect, useState } from 'react';
import { Layout, Tree, DatePicker, Input, Button, Form, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/locale/vi_VN';
import { ConfigProvider } from 'antd';
import UISidebar from '../components/Sidebar';
import axiosInstance from '../../../api/axiosInstance';
import config from '../../../config/config';

dayjs.locale('vi');

const WarehouseLocationSidebar = ({onFilter}) => {
  const [warehouseOptions, setWarehouseOptions] = useState([]);

  const formFields = [
    {
      type: 'Select',
      label: 'Kho',
      name: 'warehouse_id',
      placeholder: 'Chọn kho',
      options: warehouseOptions,
    },
    // {
    //   type: 'Input',
    //   label: 'Mã vị trí kho',
    //   name: 'warehouse_id',
    //   placeholder: 'Nhập mã kho',
    // },
    {
      type: 'Input',
      label: 'Mã vị trí',
      name: 'id',
      placeholder: 'Nhập mã vị trí',
    },
  ];
  
  const fetchWarehouse = async () => {
    const response = await axiosInstance.get(config.apiUrl + '/warehouses');
    const result = response.data?.data ?? [];
    setWarehouseOptions(result.map((item) => ({label: item?.id, value: item?.id})));
  }

  useEffect(() => {
    fetchWarehouse();
  }, []);

  return (
    <UISidebar formFields={formFields} onFilter={onFilter}/>
  );
};

export default WarehouseLocationSidebar;
