import React, { useEffect, useRef, useState } from 'react';
import { Layout, DatePicker, Form, Select } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/locale/vi_VN';
import './ProductionPlanSidebar.css';
import UISidebar from '../components/Sidebar';
import equipmentManagementApi from '../../../api/equipmentManagementApi';
import { getProductionPlans } from '../../../api/productionPlanApi';

dayjs.locale('vi');

const { Sider } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const ProductionPlanSidebar = ({ onFilter, filters }) => {
  const [form] = Form.useForm();
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [piOptions, setPiOptions] = useState([]);
  const [params, setParams] = useState(filters);
  const timer = useRef();
  const formFields = [
    {
      type: 'Select',
      label: 'Thiết bị',
      name: 'equipment_id',
      options: equipmentOptions,
      placeholder: 'Chọn thiết bị',
    },
    {
      type: 'AutoComplete',
      label: 'Số PI',
      name: 'pi',
      placeholder: 'Số PI',
      options: piOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchProductionPlan({pi: value, page: 1, pageSize: 10});
        }, 500);
      }
    },
    {
      type: 'Input',
      label: 'Số đơn sản xuất',
      name: 'batch_id',
      placeholder: 'Nhập số đơn sản xuất',
    },
    {
      type: 'DatePicker',
      label: 'Thời gian sản xuất',
      name: 'start_time',
      placeholder: 'Thời gian sản xuất',
    },
    {
      type: 'Input',
      label: 'Mã nội bộ',
      name: 'product_id',
      placeholder: 'Nhập mã nội bộ',
    },
    {
      type: 'Select',
      label: 'Trạng thái',
      name: 'status',
      placeholder: 'Trạng thái',
      value: params?.status ?? 0,
      options: [
        {label: 'Tất cả', value: 'all'},
        {label: 'Đang chờ', value: 0},
        {label: 'Đang thực hiện', value: 1},
        {label: 'Hoàn thành', value: 2},
        {label: 'Đã dừng', value: 3},
        {label: 'Đã hủy', value: 4},
      ]
    },
    // Add other fields here
  ];

  useEffect(() => {
    filters.status = filters?.status ?? 0;
    setParams(filters);
  }, [filters]);

  const fetchEquipment = async () => {
    try {
      const response = await equipmentManagementApi.getEquipments();
      if (response?.success) {
        setEquipmentOptions(response.data.data.map(e => ({ label: e.equipment_name, value: e.id })));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductionPlan = async (params) => {
    const response = await getProductionPlans(params);
    if (response?.success) {
      setPiOptions((response.data?.data ?? []).map((item) => ({label: item?.pi, value: item?.pi})));
    }
  }

  useEffect(() => {
    fetchEquipment();
  }, []);
  return (
    <UISidebar formFields={formFields} onFilter={onFilter} filters={params} />
  );
};

export default ProductionPlanSidebar;
