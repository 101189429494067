// src/pages/UI/ProcessManagement/ProcessManagementTable.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import processApi from "../../../api/processManagementApi";
import config from '../../../config/config';
import { b64toBlob } from '../../../utils/dataBlob';
import axiosInstance from '../../../api/axiosInstance';
import AuthContext from '../../../context/AuthContext';

const ProcessManagementTable = ({ filters }) => {
    const { user } = useContext(AuthContext);
    const [permissions, setPermissions] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [form] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState(null);
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const componentRef = useRef();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: config.pageSize,
        total: 0,
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        const paginate = { ...pagination, page: 1 };
        setPagination(paginate)
        fetchData({ ...filters, ...paginate });
    }, [filters]);

    useEffect(() => {
        setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
    }, []);

    useEffect(() => {
        if (htmlContent) {
            handlePrint();
        }
    }, [htmlContent]);

    const fetchData = async (filters) => {
        try {
            setLoading(true);
            const response = await processApi.getProcesses(filters); // Thay đổi endpoint theo API của bạn
            setData(response.data.data ?? []);
            response.data?.pagination && setPagination(response.data.pagination)
            setLoading(false);
        } catch (error) {
            message.error('Failed to fetch processes');
        }
    };

    const showModal = () => {
        setIsEditMode(false);
        setEditingRecord(null);
        setIsModalVisible(true);
    };

    const showEditModal = (record) => {
        setIsEditMode(true);
        setEditingRecord(record);
        form.setFieldsValue(record);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        form.validateFields().then(async (values) => {
            try {
                if (isEditMode) {
                    const response = await processApi.updateProcess(editingRecord.id, values); // Thay đổi endpoint theo API của bạn
                    if (response.success) {
                        fetchData({ ...filters, ...pagination });
                    } else throw new Error('Action failure');
                } else {
                    const response = await processApi.createProcess(values); // Thay đổi endpoint theo API của bạn
                    console.log()
                    if (response.success) {
                        fetchData({ ...filters, ...pagination });
                    } else throw new Error('Action failure');
                }
                setIsModalVisible(false);
                form.resetFields();
            } catch (error) {
                message.error('Failed to save product');
            }
        }).catch((error) => {
            console.log(error);
        });
    };
    const handleDelete = async (id) => {
        try {
            await processApi.deleteProcess(id); // Thay đổi endpoint theo API của bạn
            fetchData({ ...filters, ...pagination });
            // message.success('Xóa công đoạn thành công');
        } catch (error) {
            message.error('Failed to delete equipment');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    const handleUploadChange = (info) => {
        try {
            if (info.file.status === 'uploading') {
                setUploading(true);
            } else {
                if (info.file.status === 'done') {
                    if (!info.file?.response?.success)
                        throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
                    fetchData({ ...filters, ...pagination });
                    message.success(`${info.file.name} - Upload thành công`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} - Upload thất bại`);
                }
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setUploading(false);
        }
    };

    const uploadProps = {
        name: 'file',
        accept: '.xlsx, .xls',
        action: config.apiUrl + '/import-processes', // Đường dẫn API để xử lý upload file
        onChange: handleUploadChange,
        showUploadList: false, // Ẩn danh sách file
    };

    const columns = [
        {
            title: 'STT',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
            render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
        },
        {
            title: 'Mã công đoạn',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Tên công đoạn',
            dataIndex: 'process_name',
            key: 'name',
            align: 'center',
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {permissions.includes('process.update') && (<Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
                    {permissions.includes('process.delete') && (<Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
                    </Popconfirm>)}
                </Space>
            ),
            align: 'center',
        },
    ];

    const handleExportExcel = async () => {
        setLoading(true);
        const response = await axiosInstance.get(config.apiUrl + '/processes-export');
        if (response?.success) {
            const { data, type, file } = response.data;
            // Download
            if (data && type && file) {
                const blob = b64toBlob(data, type)
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = file
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } else message.error('Data invalid!');
        }
        setLoading(false);
    }

    return (
        <Card
            title="Quản lý công đoạn"
            extra={
                <div className="table-actions">
                    {permissions.includes('process.create') && (<>
                        <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel}>Xuất file</Button>
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                                {uploading ? 'Đang tải lên' : 'Upload Excel'}
                            </Button>
                        </Upload>
                        <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm công đoạn</Button>
                    </>)}
                </div>
            }
        >
            <Table
                loading={loading}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={data.map((record, key) => { return { key, ...record } })}
                pagination={{
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: (page, pageSize) => {
                        setPagination({ ...pagination, page, pageSize });
                        fetchData({ ...filters, page, pageSize });
                    }
                }}
                bordered  // Thêm thuộc tính bordered để có viền
                size="small"  // Sử dụng kích thước bảng nhỏ
            />
            <div ref={componentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
            <Modal
                title={
                    <div className="modal-title">
                        {isEditMode ? "Chỉnh sửa công đoạn" : "Thêm công đoạn"}
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Lưu lại"
                cancelText="Thoát"
                width={800}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <div className="modal-form-grid">
                        <Form.Item name="id" label="Mã công đoạn" rules={[{ required: true, message: 'Vui lòng nhập mã công đoạn!' }]}>
                            <Input disabled={isEditMode} />
                        </Form.Item>
                        <Form.Item name="process_name" label="Tên công đoạn" rules={[{ required: true, message: 'Vui lòng nhập tên công đoạn!' }]}>
                            <Input />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </Card>
    );
};

export default ProcessManagementTable;
