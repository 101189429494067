// src/pages/UI/Manufacturing/ManufacturingHistory.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import ManufacturingSidebar from './ManufacturingSidebar';
import ManufacturingTable from './ManufacturingTable';
import dayjs from 'dayjs';

const { Content } = Layout;

const ManufacturingHistory = () => {
  const [filters, setFilters] = useState({
    start_time: dayjs(),
    end_time: dayjs(),
  });

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  useEffect(()=>{
    console.log(filters);
  }, [filters])
  return (
    <Layout>
      <ManufacturingSidebar filters={filters} onFilter={handleFilter}/>
      <Layout>
        <Content className="site-layout-content">
          <ManufacturingTable filters={filters} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default ManufacturingHistory;
