function collectionHas(a, b) { //helper function (see below)
    for (let i = 0, len = a.length; i < len; i++) {
        if (a[i] == b) return true;
    }
    return false;
}
function findParentBySelector(elm, selector) {
    const all = document.querySelectorAll(selector);
    let cur = elm?.parentNode;
    while (cur && !collectionHas(all, cur)) { //keep going up until you find a match
        cur = cur.parentNode; //go up
    }
    return cur; //will return null if not found
}
const CalculateTableHeight = (tableClassName, sub = 0) => {
    const table = document.querySelector('.' + tableClassName);
    const card = findParentBySelector(table, '.ant-card');
    const cardHead = card?.querySelector('.ant-card-head');
    const cardBody = card?.querySelector('.ant-card-body');
    const header = table?.querySelector('.ant-table-thead');
    const pagination = table?.querySelector('.ant-table-pagination');
    const tableBody = table?.querySelector('.ant-table-body');
    const antEmpty = table?.querySelector('.ant-empty-normal');
    let paddingTop = 0;
    let paddingRight = 0;
    let paddingBottom = 0;
    let paddingLeft = 0;
    if (cardBody) {
        const style = window.getComputedStyle(cardBody);
        paddingTop = parseFloat(style.paddingTop);
        paddingRight = parseFloat(style.paddingRight);
        paddingBottom = parseFloat(style.paddingBottom);
        paddingLeft = parseFloat(style.paddingLeft);
    }
    const result = (card?.offsetHeight ?? 0) - (cardHead?.offsetHeight ?? 0) - (header?.offsetHeight ?? 0) - ((pagination?.offsetHeight ?? 0) * 2) - paddingTop - paddingBottom - sub;
    if (tableBody) tableBody?.style.setProperty('height', `${result}px`, 'important');
    if (antEmpty) antEmpty?.style.setProperty('height', `${result - 114}px`);
    console.log(result);
    return result;
};

export default CalculateTableHeight;