import axiosInstance from "./axiosInstance";

const getErrorEquipments = (filters) => axiosInstance.get('/error-equipments', { params: filters });
const getErrorEquipmentById = (id) => axiosInstance.get(`/error-equipments/${id}`);
const createErrorEquipment = (errorEquipmentData) => axiosInstance.post('/error-equipments', errorEquipmentData);
const updateErrorEquipment = (id, errorEquipmentData) => axiosInstance.put(`/error-equipments/${id}`, errorEquipmentData);
const deleteErrorEquipment = (id) => axiosInstance.delete(`/error-equipments/${id}`);

export default {
  getErrorEquipments,
  getErrorEquipmentById,
  createErrorEquipment,
  updateErrorEquipment,
  deleteErrorEquipment,
};
