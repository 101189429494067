import React, { useState } from 'react';
import { Layout, Tree, DatePicker, Button, Form, Divider, Select, ConfigProvider, Radio, Input } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../../components/Sidebar';

dayjs.locale('vi');

const { Sider } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const formFields = [
  {
    type: 'DatePicker',
    label: 'Ngày bắt đầu',
    name: 'start_date',
    placeholder: 'Ngày bắt đầu',
  },
  {
    type: 'DatePicker',
    label: 'Ngày kết thúc',
    name: 'end_date',
    placeholder: 'Ngày kết thúc',
  },
  {
    type: 'Input',
    label: 'Mã Lot',
    name: 'lot_id',
    placeholder: 'Nhập mã Lot',
  },
  // Add other fields here
];

const IQCSidebar = ({ onFilter }) => {
  const [form] = Form.useForm();

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default IQCSidebar;
