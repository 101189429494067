// src/pages/OI/OIPage.js
import React, { useState } from 'react';
import { Layout } from 'antd';
import OIHeader from './components/OIHeader';
import OIFooter from './components/OIFooter';
import { Outlet } from 'react-router-dom';

const { Header, Content } = Layout;

const OIPage = () => {
  const [title, setTitle] = useState('');
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <OIHeader title={title} />
      <Outlet context={{ setTitle }} />
      <OIFooter />
    </Layout>
  );
};

export default OIPage;
