// src/pages/UI/EquipmentManagement/EquipmentManagement.js

import React, { useState } from 'react';
import { Layout } from 'antd';
import EquipmentManagementTable from './EquipmentManagementTable';
import EquipmentManagementSidebar from './EquipmentManagementSidebar';

const { Content } = Layout;

const EquipmentManagement = () => {
    const [filters, setFilters] = useState({});

    const handleFilter = (filterValues) => {
        console.log(filterValues);
        setFilters(filterValues);
    };
    return (
        <Layout>
            <EquipmentManagementSidebar onFilter={handleFilter} />
            <Layout>
                <Content className="site-layout-content">
                    <div style={{height: "100%"}}>
                        <EquipmentManagementTable filters={filters} />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default EquipmentManagement;
