import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Table, Button, Card, Space, message, Upload, Modal, Form, Input, Descriptions, Badge, InputNumber, Select, Tag, DatePicker } from 'antd';
import './ProductionPlanTable.css';
import config from '../../../config/config';
import dayjs from 'dayjs';
import { EditOutlined, EyeFilled, HolderOutlined, PauseOutlined, PlayCircleOutlined, UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { getProductionPlans, getProductionPlanById } from '../../../api/productionPlanApi';
import { render } from '@testing-library/react';
import axiosInstance from '../../../api/axiosInstance';
import { numberFormat } from '../../../utils/numberFormat';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

const RowContext = createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: 'move',
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const ProductionPlanTable = ({ filters }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState({});
  const [bomItemData, setBomItemData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const tableHeight = useRef('68vh');

  const openModalEdit = (record) => {
    form.setFieldsValue({ ...record, id: record.id, quantity: record.quantity, start_time: dayjs(record?.start_time), status: String(record?.status) });
    setIsModalEditVisible(true);
  };

  const handleView = async (record) => {
    try {
      // const response = await getProductionPlanById(record.id);
      const response = await axiosInstance.get(config.apiUrl + '/boms', { params: { pi: record?.pi, customer_id: record?.customer_id } });
      const resouce = response.data?.data ?? [];
      if (resouce.length > 0) {
        const result = { ...record, bom_items: resouce[0]?.bom_items };
        setRecordData(result);
        setIsModalVisible(true);
      } else message.error(`Không lấy được thông tin BOM: pi(${record?.pi}), customer(${record?.customer_id})`);
    } catch (error) {
      message.error('Failed to fetch record details');
    }
  };

  const handlePause = async (record) => {
    try {
      const response = await axiosInstance.put(`/production-plans/${record.id}`, { status: '3' });
      fetchData({ ...filters, ...pagination });
    } catch (error) {
      message.error('Failed to pause production plan');
    }
  }

  const handleContinue = async (record) => {
    try {
      const response = await axiosInstance.put(`/production-plans/${record.id}`, { status: '0' });
      fetchData({ ...filters, ...pagination });
    } catch (error) {
      message.error('Failed to continue production plan');
    }
  }

  const renderStatus = (value) => {
    switch (value) {
      case 1:
        return <Tag color="#1677ff">Đang thực hiện</Tag>
      case 2:
        return <Tag color="#3a851f">Hoàn thành</Tag>
      case 3:
        return <Tag color="#ff4d4f">Đã dừng</Tag>
      case 4:
        return <Tag color="#59554ee0">Đã hủy</Tag>
      default:
        return <Tag color="#faad14">Đang chờ</Tag>
    }
  }

  const columns = [
    {
      title: <HolderOutlined />,
      key: 'sort',
      align: 'center',
      width: 40,
      render: () => <DragHandle />,
    },
    // { title: 'STT', dataIndex: 'stt', width: '50px', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1 },
    { title: 'Thứ tự SX', dataIndex: 'priority', width: '100px' },
    { title: 'Số đơn sản xuất', dataIndex: 'batch_id', width: '120px' },
    { title: 'Ngày sản xuất', dataIndex: 'start_time', render: (_, item) => dayjs(item.start_time).format('DD/MM/YYYY HH:mm') ?? "", width: '140px' },
    { title: 'Ngày kết thúc', dataIndex: 'end_time', render: (_, item) => dayjs(item.end_time).format('DD/MM/YYYY HH:mm') ?? "", width: '140px' },
    { title: 'Máy', dataIndex: 'equipment_name', render: (_, item) => item.equipment_id ?? "", width: '100px' },
    { title: 'Loại công suất (Kg/h)', dataIndex: 'capacity', width: '180px', render: (value) => Number(Number(value).toFixed(2)).toLocaleString('en-US') },
    { title: 'Thời gian SX (giờ)', dataIndex: 'production_time', width: '150px' },
    { title: 'Số PI', dataIndex: 'pi', width: '150px' },
    { title: 'Mã nội bộ', dataIndex: 'product_id', width: '200px' },
    { title: 'Mã khách hàng', dataIndex: 'customer_id', width: '200px' },
    { title: 'Mã lô', dataIndex: 'batch_code', width: '90px' },
    { title: 'Khối lượng', dataIndex: 'quantity', width: '90px', render: (value) => Number(Number(value).toFixed(2)).toLocaleString('en-US') },
    { title: 'Số mẻ', dataIndex: 'batch_number', width: '70px' },
    { title: 'Khối lượng/mẻ', dataIndex: 'volume_of_batch', width: '120px', render: (value) => Number(Number(value).toFixed(2)).toLocaleString('en-US') },
    { title: 'Loại bao bì', dataIndex: 'packaging_type', width: '300px' },
    { title: 'Lot Pallet', dataIndex: 'lot_pallet', width: '200px' },
    { title: 'Khối lượng bao', dataIndex: 'bag_stacking', width: '120px' },
    { title: 'Xếp Pallet', dataIndex: 'pallet_stacking', width: '100px' },
    { title: 'Màng co, shipping', dataIndex: 'shrink_wrap', width: '200px' },
    { title: 'Trạng thái', dataIndex: 'status', width: '120px', fixed: 'right', render: (value) => renderStatus(value) },
    {
      title: 'Hành động', key: 'action', fixed: 'right', width: '110px',
      render: (text, record) => (
        <Space size={[8, 8]}>
          <Button type={record?.status === 0 ? 'primary' : undefined} size='small' icon={<EditOutlined />} onClick={() => { openModalEdit(record) }} disabled={record?.status !== 0} />
          <Button type="primary" size='small' icon={<EyeFilled />} onClick={() => handleView(record)} />
          {record.status == '1' && <Button type='primary' size='small' icon={<PauseOutlined />} onClick={() => handlePause(record)} />}
          {record.status == '3' && <Button type='primary' size='small' icon={<PlayCircleOutlined />} onClick={() => handleContinue(record)} />}
        </Space>
      ),
      align: 'center',
    }
  ];

  useEffect(() => {
    fetchData({ ...filters, page: 1, pageSize: config.pageSize });
  }, [filters]);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      // filters.status = filters?.status ?? 0;
      if (filters?.status === 'all') filters.status = null;
      const response = await getProductionPlans(filters);
      setData((response.data.data ?? []).map((item) => ({ key: item.id, ...item })));
      response.data?.pagination && setPagination(response.data.pagination);
      setTimeout(() => {
        tableHeight.current = CalculateTableHeight('table-production');
      }, [300]);
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch accounts');
    }
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Thực hiện thất bại`);
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(info.file?.response?.message);
        }
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    action: config.apiUrl + '/import-production-plans',
    name: 'file',
    accept: '.xlsx, .xls',
    showUploadList: false,
    loading: uploading,
    onChange: handleUploadChange,
  };

  const handleSubmitForm = async () => {
    try {
      const values = await form.validateFields();
      if (values?.start_time) values.start_time = dayjs(values.start_time).format('YYYY-MM-DD HH:mm:ss');
      await axiosInstance.put(`/production-plans/${values.id}`, values);
      setIsModalEditVisible(false);
      fetchData({ ...filters, ...pagination });
    } catch (error) {
      console.log(error);
    }
  };

  const onDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id && filters.status === 0) {
      setData((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.key === active?.id);
        const overIndex = prevState.findIndex((record) => record.key === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
      const response = await axiosInstance.post(config.apiUrl + '/swap-production-plans', {ids: [active?.id, over?.id]});
      if (response.success) {
        fetchData({ ...filters, ...pagination });
      }
    }
  };

  return (
    <Card
      style={{ height: "100%" }}
      title="Kế hoạch sản xuất"
      className='table-container'
      extra={
        <Space>
          <Upload {...uploadProps}>
            <Button type='primary' icon={<UploadOutlined />} >Upload excel</Button>
          </Upload>
        </Space>
      }
    >
      {filters.status === 0 ? (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext rtableContext items={data.map((i) => i.id)} strategy={verticalListSortingStrategy}>
            <Table
              rowKey="key"
              components={{
                body: {
                  row: Row,
                },
              }}
              className="table-production"
              loading={loading}
              columns={columns.map(e => ({ ...e, align: 'center' }))}
              dataSource={data}
              rowSelection={false}
              bordered
              pagination={{
                current: pagination.page,
                pageSize: pagination.pageSize,
                total: pagination.total,
                onChange: (page, pageSize) => {
                  fetchData({ ...filters, page, pageSize });
                }
              }}
              scroll={{ x: '2600px', y: tableHeight.current }} //
              size='small'
            />
          </SortableContext>
        </DndContext>
      ) : (
        <Table
          className="table-production"
          loading={loading}
          columns={columns.map(e => ({ ...e, align: 'center' }))}
          dataSource={data}
          rowSelection={false}
          bordered
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) => {
              fetchData({ ...filters, page, pageSize });
            }
          }}
          scroll={{ x: '2550px', y: tableHeight.current }} //
          size='small'
        />
      )}

      {/* Modal */}
      <Modal
        centered
        title={"Thông tin đơn"}
        open={isModalVisible}
        width={1200}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Descriptions title="" bordered size='small'>
          <Descriptions.Item label="Đơn sản xuất">{recordData.batch_id}</Descriptions.Item>
          <Descriptions.Item label="Ngày sản xuất">{recordData.start_time && dayjs(recordData.start_time).format('DD/MM/YYYY HH:mm')}</Descriptions.Item>
          <Descriptions.Item label="Ngày kết thúc">{recordData.end_time && dayjs(recordData.end_time).format('DD/MM/YYYY HH:mm')}</Descriptions.Item>
          <Descriptions.Item label="Máy">{recordData?.equipment?.equipment_name}</Descriptions.Item>
          <Descriptions.Item label="Loại công suất (Kg/h)">{recordData.capacity}</Descriptions.Item>
          <Descriptions.Item label="Thời gian sản xuất">{recordData.production_time}</Descriptions.Item>
          <Descriptions.Item label="Số PI">{recordData.pi}</Descriptions.Item>
          <Descriptions.Item label="Mã nội bộ">{recordData.product_id}</Descriptions.Item>
          <Descriptions.Item label="Mã khách hàng">{recordData.customer_id}</Descriptions.Item>
          <Descriptions.Item label="Mã lô">{recordData.batch_code}</Descriptions.Item>
          <Descriptions.Item label="Khối lượng">{recordData.quantity}</Descriptions.Item>
          <Descriptions.Item label="Số mẻ">{recordData.batch_number}</Descriptions.Item>
          {/* <Descriptions.Item label="Số mẻ">{numberFormat(recordData.quantity / recordData.volume_of_batch)}</Descriptions.Item> */}
          <Descriptions.Item label="Khối lượng/mẻ">{recordData.volume_of_batch}</Descriptions.Item>
          <Descriptions.Item label="Loại bao bì">{recordData.packaging_type}</Descriptions.Item>
          <Descriptions.Item label="Lot Pallet">{recordData.lot_pallet}</Descriptions.Item>
          <Descriptions.Item label="Khối lượng bao">{recordData.bag_stacking}</Descriptions.Item>
          <Descriptions.Item label="Xếp Pallet">{recordData.pallet_stacking}</Descriptions.Item>
          <Descriptions.Item label="Màng co, shipping">{recordData.shrink_wrap}</Descriptions.Item>
        </Descriptions>
        <Table
          style={{ marginTop: '10px' }}
          columns={[
            { title: 'STT', dataIndex: 'stt', width: '50px', align: 'center', render: (_, item, index) => index + 1 },
            { title: 'Mã nguyên liệu', align: 'center', dataIndex: 'component_id' },
            { title: 'Mô tả thành phẩm', align: 'center', dataIndex: 'component_description' },
            { title: 'Tỉ lệ thành phần', align: 'center', dataIndex: 'unit_weight', render: (value) => `${Number(parseFloat(value * 100).toFixed(2))}%` },
            { title: 'Khối lượng thành phần', align: 'center', dataIndex: 'unit_weight', render: (_, item) => (item.unit_weight * recordData.volume_of_batch).toFixed(2) + ' kg' },
          ]}
          // dataSource={recordData?.product?.boms[0]?.bom_items}
          dataSource={recordData?.bom_items}
          rowSelection={false}
          bordered
          pagination={false}
          scroll={{ x: '1000px' }}
          size='small'
        />
      </Modal>

      <Modal
        title="Chỉnh sửa thông tin đơn"
        visible={isModalEditVisible}
        onOk={() => handleSubmitForm()}
        onCancel={() => setIsModalEditVisible(false)}
        okText="Lưu"
        cancelText="Thoát"
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item name="id" style={{ display: 'none' }}></Form.Item>
          <div className="modal-form-grid">
            <Form.Item label="Ngày sản xuất" name="start_time" rules={[{ required: true, message: "Vui lòng nhập ngày sản xuất" }]}>
              <DatePicker format="DD-MM-YYYY HH:mm" placeholder="Ngày sản xuất" showTime allowClear={false} />
            </Form.Item>
            <Form.Item label="Số lượng" name="quantity">
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
            <Form.Item label="Loại bao bì" name="packaging_type" rules={[{ required: true, message: "Vui lòng nhập" }]}>
              <Input placeholder='Loại bao bì' />
            </Form.Item>
            <Form.Item label="Lot pallet" name="lot_pallet" rules={[{ required: true, message: "Vui lòng nhập" }]}>
              <Input placeholder='Lot pallet' />
            </Form.Item>
            <Form.Item label="Màng co, shipping" name="shrink_wrap" rules={[{ required: true, message: "Vui lòng nhập" }]}>
              <Input placeholder='Màng co, shipping' />
            </Form.Item>
            <Form.Item label="Trạng thái" name="status">
              <Select placeholder={'Chọn trạng thái'} allowClear>
                <Select.Option value="0" disabled>Đang chờ</Select.Option>
                <Select.Option value="1" disabled>Đang thực hiện</Select.Option>
                <Select.Option value="2" disabled>Hoàn thành</Select.Option>
                <Select.Option value="3" disabled>Đã dừng</Select.Option>
                <Select.Option value="4">Huỷ</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

export default ProductionPlanTable;
