// src/pages/UI/Manufacturing/ManufacturingHistory.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import EquipmentSidebar from './EquipmentSidebar';
import EquipmentTable from './EquipmentTable';
import EquipmentCharts from './EquipmentCharts';

const { Content } = Layout;

const Equipment = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  return (
    <Layout>
      <EquipmentSidebar onFilter={handleFilter} />
      <Layout>
        <Content className="site-layout-content">
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
            <EquipmentCharts />
            <EquipmentTable />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Equipment;
