import axiosInstance from "./axiosInstance";

const getMaterialExports = (filters) => axiosInstance.get('/material-exports', { params: filters });
const getMaterialExportItems = (filters) => axiosInstance.get('/material-export-items', { params: filters });
const getMaterialExportById = (id) => axiosInstance.get(`/material-exports/${id}`);
const createMaterialExport = (materialExportData) => axiosInstance.post('/material-exports', materialExportData);
const updateMaterialExport = (id, materialExportData) => axiosInstance.put(`/material-exports/${id}`, materialExportData);
const deleteMaterialExport = (id) => axiosInstance.delete(`/material-exports/${id}`);
const deleteMaterialExportItem = (id) => axiosInstance.delete(`/material-export-items/${id}`);

export default {
  getMaterialExports,
  getMaterialExportItems,
  getMaterialExportById,
  createMaterialExport,
  updateMaterialExport,
  deleteMaterialExport,
  deleteMaterialExportItem,
};
