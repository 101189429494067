import axiosInstance from "./axiosInstance";

const getMaterials = (filters) => axiosInstance.get('/materials', { params: filters });
const getMaterialById = (id) => axiosInstance.get(`/materials/${id}`);
const createMaterial = (materialData) => axiosInstance.post('/materials', materialData);
const updateMaterial = (id, materialData) => axiosInstance.put(`/materials/${id}`, materialData);
const deleteMaterial = (id) => axiosInstance.delete(`/materials/${id}`);

export default {
  getMaterials,
  getMaterialById,
  createMaterial,
  updateMaterial,
  deleteMaterial,
};
