import { Card } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';

// Simulating API response with updated data
const dataFake = {
    "2024-07-27": {
        "day": "27/07",
        "plan": 400000,
        "result": 400000
    },
    "2024-07-28": {
        "day": "28/07",
        "plan": 350000,
        "result": 340000
    },
    "2024-07-29": {
        "day": "29/07",
        "plan": 300000,
        "result": 295000
    },
    "2024-07-30": {
        "day": "30/07",
        "plan": 250000,
        "result": 245000
    },
    "2024-07-31": {
        "day": "31/07",
        "plan": 200000,
        "result": 195000
    },
    "2024-08-01": {
        "day": "01/08",
        "plan": 180000,
        "result": 170000
    },
    "2024-08-02": {
        "day": "02/08",
        "plan": 55000,
        "result": 5000
    }
};

const FirstChart = ({ data }) => {
    const [chartOptions, setChartOptions] = useState(null);

    useEffect(() => {
        const categories = [];
        const planData = [];
        const resultData = [];

        Object.keys(data).forEach(key => {
            categories.push(data[key].day);
            planData.push(data[key].plan);
            resultData.push(data[key].result);
        });

        setChartOptions({
            chart: {
                zoomType: 'xy',
                height: 350
            },
            title: {
                text: 'Biểu đồ sản xuất'
            },
            xAxis: {
                categories: categories,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Sản lượng'
                }
            },
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>'
            },
            plotOptions: {
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666'
                    }
                },
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: true
                }
            },
            series: [
                {
                    name: 'Kế hoạch',
                    type: 'area',
                    data: planData,
                    color: 'rgba(124, 181, 236, 0.5)',
                    lineColor: 'rgba(124, 181, 236, 1)'
                },
                {
                    name: 'Kết quả',
                    type: 'line',
                    data: resultData,
                    color: '#FF0000',
                    marker: {
                        enabled: true,
                        radius: 5
                    },
                    lineWidth: 2
                }
            ],
            credits: { enabled: false }
        });
    }, [data]);

    return (
        <Card className="kpi-chart" title="">
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </Card>
    );
};

export default FirstChart;
