import axiosInstance from "./axiosInstance";

const getPallets = (filters) => axiosInstance.get('/pallets', { params: filters });
const getPalletById = (id) => axiosInstance.get(`/pallets/${id}`);
const createPallet = (palletData) => axiosInstance.post('/pallets', palletData);
const updatePallet = (id, palletData) => axiosInstance.put(`/pallets/${id}`, palletData);
const deletePallet = (id) => axiosInstance.delete(`/pallets/${id}`);
const getPalletMaterials = () => axiosInstance.get(`/pallets-materials`);
const printPallet = (id) => axiosInstance.get(`/pallets/${id}/print`);
const printManyPallet = (params) => axiosInstance.get(`/pallets/print/many`, { params });

export default {
  getPallets,
  getPalletById,
  createPallet,
  updatePallet,
  deletePallet,
  printPallet,
  getPalletMaterials,
  printManyPallet,
};
