import React, { useState } from 'react';
import { Layout } from 'antd';
import FinishedProductImportTable from './FinishedProductImportTable';
import FinishedProductImportSidebar from './FinishedProductImportSidebar';
import './FinishedProductImportManagement.css';

const { Content, Sider } = Layout;

const FinishedProductImportManagement = () => {
    const [filters, setFilters] = useState({});

    const handleFilter = (newFilters) => {
        setFilters(newFilters);
    };

    return (
        <Layout>
            <Sider className="hidden-on-mobile" width={250}>
                <FinishedProductImportSidebar onFilter={handleFilter} />
            </Sider>
            <Content className="site-layout-content">
                <FinishedProductImportTable filters={filters} />
            </Content>
        </Layout>
    );
};

export default FinishedProductImportManagement;
