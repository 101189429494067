import React, { useEffect, useState } from 'react';
import { Table, Button, Card, Space, message } from 'antd';
import config from '../../../../config/config';
import materialReceiptApi from '../../../../api/materialReceiptApi';
import lotHistoryApi from '../../../../api/lotHistoryApi';
import dayjs from 'dayjs';
import { numberFormat } from '../../../../utils/numberFormat';
import axiosInstance from '../../../../api/axiosInstance';
import { b64toBlob } from '../../../../utils/dataBlob';
import { DownloadOutlined } from '@ant-design/icons';
function generateDataArray(date) {
  const data = [];
  const formattedDate = new Date(date).toISOString();
  const processNames = [
      'Công đoạn 1', 'Công đoạn 2', 'Công đoạn 3', 'Công đoạn 4',
      'Công đoạn 5', 'Công đoạn 6', 'Công đoạn 7', 'Công đoạn 8',
      'Công đoạn 9', 'Công đoạn 10', 'Công đoạn 11', 'Công đoạn 12',
      'Công đoạn 13', 'Công đoạn 14', 'Công đoạn 15', 'Công đoạn 16',
      'Công đoạn 17', 'Công đoạn 18', 'Công đoạn 19', 'Công đoạn 20'
  ];
  const equipmentIds = ['Máy 01', 'Máy 02', 'Máy 03', 'Máy 04', 'Máy 05'];
  const productIds = ['TP UF1096', 'TP UF1097', 'TP UF1098', 'TP UF1099', 'TP UF1100'];
  const lotIds = ['L01', 'L02', 'L03', 'L04', 'L05', 'L06', 'L07', 'L08', 'L09', 'L10'];
  const inspectors = [
      'Nguyễn Thị A', 'Nguyễn Thị B', 'Nguyễn Thị C', 'Nguyễn Thị D', 'Nguyễn Thị E', 'Nguyễn Thị F'
  ];

  for (let i = 0; i < 20; i++) {
      data.push({
          stt: i + 1,
          checked_date: formattedDate,
          equipment: { process: { process_name: processNames[i % processNames.length] } },
          equipment_id: equipmentIds[i % equipmentIds.length],
          product_id: productIds[i % productIds.length],
          lot_id: lotIds[i % lotIds.length],
          sl_dau_ra_tt: 0,
          sl_dau_ra_ok: 0,
          quantity_ng: Math.floor(Math.random() * 100),
          failure_percentage: 0,
          quality_check: {
              created_at: formattedDate,
              result: 'OK',
              inspector: { name: inspectors[i % inspectors.length] }
          },
          checker_name: inspectors[i % inspectors.length],
      });
  }

  return data;
}

// Example usage:
const date = '2024-08-02T00:00:00Z';
console.log();
const sampleData = generateDataArray(date);
const OQCTable = ({ filters }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const columns = [
    { title: 'STT', dataIndex: 'stt', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1, width: '50px' },
    { title: 'Ngày kiểm tra', dataIndex: 'checked_date', width: '120px', render: (_, record) => record?.quality_check?.created_at ? dayjs(record?.quality_check?.created_at).format('DD/MM/YYYY') : "" },
    { title: 'Công đoạn', dataIndex: 'proccess_id', width: '100px', render: (_, record) => record?.equipment?.process?.process_name, hidden: true },
    { title: 'Máy sản xuất', dataIndex: 'equipment_id', width: '150px' },
    { title: 'Mã sản phẩm', dataIndex: 'product_id', width: '150px' },
    { title: 'Mã Lot', dataIndex: 'lot_id', width: '100px' },
    { title: 'Số lượng đầu ra', dataIndex: 'quantity', width: '120px' },
    { title: 'SL đầu ra OK', dataIndex: 'quantiy_ok', width: '120px', render: (_, record) => getQualityCheck(record) },
    { title: 'Số lượng NG', dataIndex: 'quantity_ng', width: '100px', render: (value) => value ?? 0 },
    { title: 'Tỷ lệ NG', dataIndex: 'failure_percentage', width: '100px', render: (_, record) => {
      if (record?.quantity_ng > 0 && record?.quantity > 0) {
        return numberFormat(record?.quantity_ng / record?.quantity * 100)
      }
      return 0
    } },
    { title: 'Kết quả KT', dataIndex: 'quality_check', width: '100px', render: (value) => value?.result },
    { title: 'Người kiểm tra', dataIndex: 'checker_name', width: '120px', render: (_, record) => record?.quality_check?.inspector?.name }
  ];

  const getQualityCheck = (record, result = 'OK') => {
    if (record?.quality_check?.result === result) {
      return record?.quantity ?? 0;
    }
    return 0;
  }

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      filters.quality_check = true;
      if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
      if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');

      const response = await lotHistoryApi.getLotHistories(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data?.data ?? []);
      response.data?.pagination && setPagination(response.data.pagination);
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/quality-export/oqc', {params: filters});
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      title="Lịch sử kiểm tra OQC"
      extra={
        <Space>
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel} loading={loading}>Xuất file</Button>
        </Space>
      }
    >
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
        <Table
          className="table-mh70"
          loading={loading}
          columns={columns.map(e => ({ ...e, align: 'center' }))}
          dataSource={data}
          bordered
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) => fetchData({ ...filters, page, pageSize }),
          }}
          scroll={{ x: '1330px' }}
          size='small'
        />
      </div>
    </Card>
  );
};

export default OQCTable;
