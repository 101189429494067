// src/pages/UI/CTPlan/CTPlan.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import CTPlanSidebar from '../ProductionPlanSidebar';
import CTPlanTable from './CTTable';

const { Content } = Layout;

const CTPlan = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  useEffect(()=>{
    console.log(filters);
  }, [filters])
  return (
    <Layout>
      <CTPlanSidebar onFilter={handleFilter}/>
      <Layout>
        <Content className="site-layout-content">
          <CTPlanTable />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CTPlan;
