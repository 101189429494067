import { Form, InputNumber, Space, Tag } from "antd";
import { numberFormat } from "../../../../utils/numberFormat";

export const processOptions = [
    { label: 'Nhập kho', value: 1 },
    { label: 'Xuất kho', value: 2 },
];
export const modeCreatePalletOptions = [
    { label: 'Quét QR', value: 'qr' },
    { label: 'Thủ công', value: 'manually' },
];
export const columnsTop = [
    {
        title: "Tổng nhập",
        dataIndex: "import",
        key: "import",
        align: "center",
        render: (value) => numberFormat(value),
    },
    {
        title: "Tổng xuất",
        dataIndex: "export",
        key: "export",
        align: "center",
        render: (value) => numberFormat(value),
    },
];

export const columnsMiddleImport = [
    {
        title: "Mã Pallet",
        dataIndex: "pallet_id",
        key: "pallet_id",
        align: "center",
        width: 160,
    },
    {
        title: "Số lượng(kg)",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
        width: 160,
        render: (value) => numberFormat(value),
    },
    {
        title: "Vị trí",
        dataIndex: "warehouse_location_id",
        key: "warehouse_location_id",
        align: "center",
        width: 160,
    },
];

export const columnsBottomImport = [
    {
        title: "STT",
        dataIndex: "stt",
        key: "stt",
        align: "center",
        render: (value, record, index) => index + 1,
    },
    {
        title: "Mã phiếu nhập",
        dataIndex: "material_receipt_id",
        key: "material_receipt_id",
        align: "center",
        render: (_, record) => record?.material_receipt?.id,
    },
    // {
    //     title: "QR Pallet",
    //     dataIndex: "id",
    //     key: "id",
    //     align: "center",
    //     render: (_, record) => {
    //         const value = {
    //             pallet_id: record?.id,
    //             quantity: Number(record?.quantity),
    //             unit_id: record?.unit_id,
    //             unit_name: record?.unit?.unit_name ?? '',
    //         }
    //         return <QRCode value={JSON.stringify(value)} />
    //     },
    // },
    {
        title: "Mã Pallet",
        dataIndex: "id",
        key: "id",
        align: "center",
    },
    {
        title: "Mã nguyên vật liệu",
        dataIndex: "material_id",
        key: "material_id",
        align: "center",
    },
    {
        title: "Tên vật liệu",
        dataIndex: "material_name",
        key: "material_name",
        align: "center",
        render: (_, record) => record?.material?.material_name,
    },
    {
        title: "Số lượng",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
        render: (value) => Number(value),
    },
    {
        title: "Đơn vị tính",
        dataIndex: "unit_name",
        key: "unit_name",
        align: "center",
        render: (_, record) => record?.unit?.unit_name,
    },
];

export const columnsPalletScanner = (isExport = false) => [
    {
        title: "Mã Pallet",
        dataIndex: "pallet_id",
        key: "pallet_id",
        align: "center",
    },
    {
        title: "Số lượng",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
        width: 150,
        render: (value) => isExport ? <Form.Item name={'quantity'} noStyle>
            <InputNumber max={value} min={0}/>
        </Form.Item> : value,
    },
    {
        title: "ĐVT",
        dataIndex: "unit_name",
        key: "unit_name",
        align: "center",
    },
    {
        title: "Vị trí",
        dataIndex: "warehouse_location_id",
        key: "warehouse_location_id",
        align: "center",
    },
];

export const columnsEntry = [
    {
        title: "STT",
        dataIndex: "stt",
        key: "stt",
        align: "center",
        width: '60px',
        render: (value, record, index) => index + 1,
    },
    {
        title: "Mã Pallet",
        dataIndex: "warehouseentryable_id",
        key: "warehouseentryable_id",
        width: '80px',
        align: "center",
    },
    {
        title: "Mã NVL",
        dataIndex: "material_id",
        key: "material_id",
        align: "center",
        width: '80px',
        render: (_, record) => record?.material?.id,
    },
    {
        title: "Tên NVL",
        dataIndex: "material_name",
        key: "material_name",
        align: "center",
        width: '120px',
        render: (_, record) => record?.material?.material_name,
    },
    {
        title: "Số lượng",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
        width: '80px',
        render: (value) => numberFormat(value),
    },
    {
        title: "ĐVT",
        dataIndex: "unit_id",
        key: "unit_id",
        align: "center",
        width: '70px',
        render: (_, record) => record?.unit?.unit_name,
    },
    {
        title: "Vị trí",
        dataIndex: "warehouse_location_id",
        key: "warehouse_location_id",
        align: "center",
        width: '80px',
    },
    {
        title: "Người nhập",
        dataIndex: "created_by",
        key: "created_by",
        align: "center",
        width: '120px',
        render: (_, record) => record?.created_by?.name,
    },
    {
        title: "Ngày nhập",
        dataIndex: "created_at",
        key: "created_at",
        align: "center",
        width: '120px',
    },
];

// Export
export const columnsBottomExport = [
    {
        title: "STT",
        dataIndex: "stt",
        key: "stt",
        align: "center",
        width: '50px',
        render: (value, record, index) => index + 1,
    },
    {
        title: "Mã NVL",
        dataIndex: "material_id",
        key: "material_id",
        align: "center",
        width: '100px',
    },
    {
        title: "Tên NVL",
        dataIndex: "material_name",
        key: "material_name",
        align: "center",
        width: '120px',
        render: (_, record) => record?.material?.material_name,
    },
    {
        title: "SL cần xuất",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
        width: '120px',
        render: (value) => Number(value),
    },
    {
        title: "Vị trí",
        dataIndex: "location",
        align: "left",
        width: '120px',
        render: (_, record) => {
            const arr = record?.pallets ?? [];
            return arr.map((item) => item?.warehouse_entry?.warehouse_location_id).join(', ');
        },
    },
];

export const columnsExit = [
    {
        title: "STT",
        dataIndex: "stt",
        key: "stt",
        align: "center",
        width: '50px',
        render: (value, record, index) => index + 1,
    },
    {
        title: "Mã Pallet",
        dataIndex: "warehousehistoryable_id",
        key: "warehousehistoryable_id",
        align: "center",
        width: '80px',
    },
    {
        title: "Mã NVL",
        dataIndex: "material_id",
        key: "material_id",
        align: "center",
        width: '120px',
        render: (_, record) => record?.pallet?.material?.id
    },
    {
        title: "Tên NVL",
        dataIndex: "material_name",
        key: "material_name",
        align: "center",
        width: '120px',
        render: (_, record) => record?.pallet?.material?.material_name
    },
    {
        title: "Số lượng",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
        width: '80px',
        render: (value) => Number(value),
    },
    {
        title: "Vị trí",
        dataIndex: "warehouse_location_id",
        key: "warehouse_location_id",
        align: "center",
        width: '80px',
    },
    {
        title: "Thời gian xuất",
        dataIndex: "created_at",
        key: "created_at",
        align: "center",
        width: '120px',
    },
    {
        title: "Người xuất",
        dataIndex: "created_by",
        key: "created_by",
        align: "center",
        width: '80px',
        render: (_, record) => record?.created_by?.name
    },
];