import axiosInstance from "./axiosInstance";

const getWarehouseEntries = (filters) => axiosInstance.get('/warehouse-entries', { params: filters });
const getWarehouseEntriesInventory = (filters) => axiosInstance.get('/warehouse-entries-inventory', { params: filters });
const getWarehouseEntryById = (id) => axiosInstance.get(`/warehouse-entries/${id}`);
const createWarehouseEntry = (warehouseEntryData) => axiosInstance.post('/warehouse-entries', warehouseEntryData);
const createWarehouseEntryLot = (warehouseEntryData) => axiosInstance.post('/warehouse-entries/lot', warehouseEntryData);
const updateWarehouseEntry = (id, warehouseEntryData) => axiosInstance.put(`/warehouse-entries/${id}`, warehouseEntryData);
const deleteWarehouseEntry = (id) => axiosInstance.delete(`/warehouse-entries/${id}`);

export default {
  getWarehouseEntries,
  getWarehouseEntryById,
  createWarehouseEntry,
  createWarehouseEntryLot,
  updateWarehouseEntry,
  deleteWarehouseEntry,
  getWarehouseEntriesInventory,
};
