// src/pages/UI/MaterialManagement/MaterialTable.js
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm, Select, QRCode } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, DownloadOutlined, QrcodeOutlined } from '@ant-design/icons';
import materialApi from '../../../api/materialManagementApi';
import config from '../../../config/config';
import unitManagementApi from '../../../api/unitManagementApi';
import materialCategoryManagementApi from '../../../api/materialCategoryManagementApi';
import axiosInstance from '../../../api/axiosInstance';
import { b64toBlob } from '../../../utils/dataBlob';
import ReactToPrint from 'react-to-print';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import AuthContext from '../../../context/AuthContext';

const initialData = [
  {
    key: '1',
    materialCode: 'M001',
    materialName: 'Nguyên vật liệu A',
    materialCategory: 'Loại 1',
    unitPrice: '50,000',
    stockQuantity: 100,
  },
  // Thêm các dữ liệu mẫu khác theo cấu trúc này
];

const MaterialManagementTable = ({ filters }) => {
  const { user } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [currentQr, setCurrentQr] = useState("");
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const showModal = () => {
    setIsEditMode(false);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setIsEditMode(true);
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const [units, setUnits] = useState([]);
  const [materialCategories, setMaterialCategories] = useState([]);
  const tableHeight = useRef(undefined);

  useEffect(() => {
    setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
  }, []);

  useEffect(() => {
    if (isModalVisible) {
      getUnits();
      getMaterialCategories();
    }
  }, [isModalVisible]);

  useEffect(() => {
    fetchData({ ...filters, page: 1, pageSize: config.pageSize });
  }, [filters]);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await materialApi.getMaterials(filters);
      setData(response.data.data);
      setPagination(response.data.pagination);
      setTimeout(() => {
        tableHeight.current = CalculateTableHeight('table-material');
      }, 300);
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch materials');
    }
  };

  const [loadingMaterialCategories, setLoadingMaterialCategories] = useState(false);
  const getMaterialCategories = async () => {
    setLoadingMaterialCategories(true);
    const res = await materialCategoryManagementApi.getMaterialCategories();
    if (res.success) setMaterialCategories(res.data.data.map(e => ({ ...e, value: e.id, label: e.material_category_name })))
    setLoadingMaterialCategories(false);
  }

  const [loadingUnits, setLoadingUnits] = useState(false);
  const getUnits = async () => {
    setLoadingUnits(true);
    const res = await unitManagementApi.getUnits();
    if (res.success) setUnits(res.data.data.map(e => ({ ...e, value: e.id, label: e.unit_name })))
    setLoadingUnits(false);
  }

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      if (isEditMode) {
        // Cập nhật bản ghi
        const response = await materialApi.updateMaterial(editingRecord.id, values);
        if (response.success) {
          fetchData({ ...filters, ...pagination })
          setIsModalVisible(false);
          form.resetFields();
        }
        // message.success('Cập nhật nguyên vật liệu thành công');
      } else {
        // Thêm bản ghi mới
        const response = await materialApi.createMaterial(values);
        if (response.success) {
          fetchData({ ...filters, ...pagination })
          setIsModalVisible(false);
          form.resetFields();
        }
        // message.success('Thêm nguyên vật liệu mới thành công');
      }
    }).catch((error) => {
      console.log('error', error);
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await materialApi.deleteMaterial(id);
      response.success && fetchData({ ...filters, ...pagination });
      // message.success('Xóa nguyên vật liệu thành công');
    } catch (error) {
      message.error('Failed to delete material');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`);
        }
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-materials', // Đường dẫn API để xử lý upload file
    onChange: handleUploadChange,
    showUploadList: false, // Ẩn danh sách file
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: 70,
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Mã nguyên vật liệu',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Loại',
      dataIndex: 'material_category',
      align: 'center',
      width: 110,
      render: (value) => value?.material_category_name ?? ""
    },
    {
      title: 'MI',
      dataIndex: 'mi',
      align: 'center',
      hidden: true,
    },
    {
      title: 'Tên nguyên vật liệu',
      dataIndex: 'material_name',
      align: 'center',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit',
      align: 'center',
      width: 90,
      render: (value) => value?.unit_name ?? ""
    },
    {
      title: 'Phân loại',
      dataIndex: 'class',
      align: 'center',
      width: 90,
    },
    {
      title: 'Hành động',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {permissions.includes('material.update') && (<Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
          {permissions.includes('material.delete') && (<Popconfirm
            title="Bạn có chắc chắn muốn xóa thiết bị này?"
            onConfirm={() => handleDelete(record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
          </Popconfirm>)}
          <QrcodeOutlined type="primary" onClick={async () => {
            setShowQrModal(true);
            setCurrentQr(record?.id);
          }}>QR</QrcodeOutlined>
        </Space>
      ),
    },
  ];

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/materials-export');
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  const htmlQR = () => {
    return (
      <div style={{ flexDirection: 'column', display: 'flex', gap: 8, alignItems: 'center' }}>
        <QRCode value={currentQr} bordered type='canvas' />
        {currentQr}
      </div>
    )
  }
  const componentRef1 = useRef();
  const reactToPrintRef = useRef();
  const handlePrint = () => {
    reactToPrintRef.current.handlePrint();
  }

  return (
    <Card
      style={{ height: '100%' }}
      title="Quản lý nguyên vật liệu"
      extra={
        <div className="table-actions">
          {permissions.includes('material.create') && (<>
            <Button target="_blank" href={config.apiUrl.replace('/api', '') + '/excel/Materials.xlsx'} icon={<DownloadOutlined />}>Tải excel mẫu</Button>
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                {uploading ? 'Đang tải lên' : 'Upload Excel'}
              </Button>
            </Upload>
          </>)}
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel}>Xuất file</Button>
          {permissions.includes('material.create') && (<Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm nguyên vật liệu</Button>)}
        </div>
      }
    >
      <Table
        className='table-material'
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((e, i) => ({ ...e, key: i }))}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, page, pageSize });
            fetchData({ ...filters, page, pageSize });
          }
        }}
        bordered  // Thêm thuộc tính bordered để có viền
        size="small"
        scroll={{ y: tableHeight.current }}
      />
      <Modal
        title={"QR Code"}
        open={showQrModal}
        onOk={() => {
          setShowQrModal(false);
        }}
        onCancel={() => {
          setShowQrModal(false);
        }}

        okText="OK"
        cancelText="Thoát"
        footer={
          <Space>
            <Button key="back" onClick={() => setShowQrModal(false)}>Thoát</Button>
            <Button key="print" type="primary" onClick={handlePrint}>In</Button>
            {/* <Button key="submit" type="primary" onClick={()=>setShowQrModal(false)}>OK</Button> */}
          </Space>
        }
      >
        {htmlQR()}
      </Modal>
      <div>
        <ReactToPrint
          ref={reactToPrintRef}
          trigger={() => <button style={{ display: 'none' }}>Print this out!</button>}
          content={() => componentRef1.current}
        />
        <div ref={componentRef1} className="print-container">
          {htmlQR()}
        </div>
      </div>
      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa nguyên vật liệu" : "Thêm nguyên vật liệu"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        onClose={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={800}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <div className="modal-form-grid">
            <Form.Item name="id" label="Mã nguyên vật liệu" rules={[{ required: true, message: 'Vui lòng nhập mã nguyên vật liệu!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="material_name" label="Tên nguyên vật liệu" rules={[{ required: true, message: 'Vui lòng nhập tên nguyên vật liệu!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="material_category_id" label="Loại" rules={[{ required: true, message: 'Vui lòng chọn loại!' }]}>
              <Select options={materialCategories} optionFilterProp='label' showSearch loading={loadingMaterialCategories} />
            </Form.Item>
            <Form.Item name="class" label="Phân loại">
              {/* <Input /> */}
              <Select options={[
                { label: 'NHUA', value: 'NHUA' },
                { label: 'ACID', value: 'ACID' },
                { label: 'KEM', value: 'KEM' },
                { label: 'OIL', value: 'OIL' },
                { label: 'BOTDA', value: 'BOTDA' },
                { label: 'WAX', value: 'WAX' },
              ]} placeholder="Vui lòng chọn" />
            </Form.Item>
            <Form.Item name="mi" label="MI">
              <Input />
            </Form.Item>
            <Form.Item name="unit_id" label="Đơn vị" rules={[{ required: true, message: 'Vui lòng chọn đơn vị!' }]}>
              <Select options={units} optionFilterProp='label' showSearch loading={loadingUnits} />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

export default MaterialManagementTable;
