import { BorderOutlined, LineOutlined } from '@ant-design/icons';
import { Column, DualAxes } from '@ant-design/plots';
import { Card, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import dayjs from 'dayjs';

const SecondChart = ({ data }) => {

    const [chartOptions, setChartOptions] = useState(null);

    function convertData(apiData) {
        const result = [];
        const startDate = dayjs().subtract(6, 'days'); // 7 days including today
        const endDate = dayjs();

        // Loop through each day in the range
        for (let date = startDate; date.isBefore(endDate) || date.isSame(endDate); date = date.add(1, 'day')) {
            const dateStr = date.format('YYYY-MM-DD');
            const existingData = apiData.find((data) => data.date === dateStr);
            result.push({
                date: date.format('DD/MM'),
                quantity_ng: Number(existingData?.quantity_ng || 0),
            });
        }

        return result;
    }

    useEffect(() => {
        // Simulating API response with updated data
        // const data = [
        //     { day: "27/07", errors: 10, totalProduction: 40000 },
        //     { day: "28/07", errors: 12, totalProduction: 45000 },
        //     { day: "29/07", errors: 8, totalProduction: 42000 },
        //     { day: "30/07", errors: 15, totalProduction: 50000 },
        //     { day: "31/07", errors: 9, totalProduction: 46000 },
        //     { day: "01/08", errors: 5, totalProduction: 48000 },
        //     { day: "02/08", errors: 1, totalProduction: 55000 },
        // ];
        const result = convertData(data);

        const categories = result.map(item => item.date);
        const errorData = result.map(item => item.quantity_ng);
        const percentageData = result.map(item => item.quantity_ng);
        // const percentageData = result.map(item => (item.errors / item.totalProduction) * 100);

        setChartOptions({
            chart: {
                zoomType: 'xy',
                height: 350
            },
            title: {
                text: 'Biểu đồ chất lượng'
            },
            xAxis: {
                categories: categories,
                crosshair: true
            },
            yAxis: [
                {
                    min: 0,
                    title: {
                        text: 'Số lỗi'
                    }
                },
                {
                    title: {
                        text: 'Lỗi'
                    },
                    opposite: true,
                    labels: {
                        format: '{value}'
                    }
                }
            ],
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>'
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    pointWidth: 30
                },
                line: {
                    dataLabels: {
                        enabled: true,
                        format: '{y:.2f}'
                    },
                    enableMouseTracking: true
                }
            },
            series: [
                {
                    name: 'Số lỗi',
                    type: 'column',
                    data: errorData,
                    color: '#007bff'
                },
                {
                    name: 'Lỗi',
                    type: 'line',
                    yAxis: 1,
                    data: percentageData,
                    color: '#FFA500',
                    marker: {
                        enabled: true,
                        radius: 5
                    },
                    lineWidth: 2,
                    tooltip: {
                        valueSuffix: ''
                    }
                }
            ],
            credits: { enabled: false }
        });
    }, [data]);

    return (
        <Card className="kpi-chart" title="">
            {/* <Column {...config} /> */}
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </Card>
    );
};

export default SecondChart;
