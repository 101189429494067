import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm, Select, InputNumber, Row, Col, Divider, Descriptions, Flex } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, CloseOutlined, EyeOutlined, CopyOutlined, HistoryOutlined } from '@ant-design/icons';
import bomApi from "../../../api/bomManagementApi";
import unitApi from '../../../api/unitManagementApi';
import config from '../../../config/config';
import productManagementApi from '../../../api/productManagementApi';
import materialManagementApi from '../../../api/materialManagementApi';
import customerManagementApi from '../../../api/customerManagementApi';
import "./BOMManagement.css";
import dayjs from 'dayjs';
import { numberFormat } from '../../../utils/numberFormat';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import axiosInstance from '../../../api/axiosInstance';
import AuthContext from '../../../context/AuthContext';

const BOMManagementTable = ({ filters }) => {
  const { user } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);
  const [bom, setBom] = useState([]);
  const [bomLog, setBomLog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [units, setUnits] = useState([]);
  const [products, setProducts] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [bomItems, setBomItems] = useState([]);
  const tableHeight = useRef('71vh');

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  useEffect(() => {
    if (isModalVisible) {
      getProducts();
      getUnits();
      getMaterials();
      getCustomers();
    }
  }, [isModalVisible]);

  useEffect(() => {
    setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
  }, []);

  const [loadingProducts, setLoadingProducts] = useState(false);
  const getProducts = async () => {
    setLoadingProducts(true);
    const products = await productManagementApi.getProducts();
    if (products.success) setProducts(products.data.data.map(e => ({ ...e, value: e.id, label: e.id })));
    setLoadingProducts(false);
  }
  const [loadingUnits, setLoadingUnits] = useState(false);
  const getUnits = async () => {
    setLoadingUnits(true);
    const units = await unitApi.getUnits();
    if (units.success) setUnits(units.data.data.map(e => ({ ...e, value: e.id, label: e.unit_name })));
    setLoadingUnits(false);
  }
  const [loadingMaterials, setLoadingMaterials] = useState(false);
  const getInventory = (data) => {
    if (data?.pallets) {
      const histories = data.pallets?.map((item) => {
        if (item?.warehouse_history) return item.warehouse_history;
      }) ?? [];
      let im = 0;
      let ex = 0;
      histories.map((row) => {
        if (row?.type === 'import') im += Number(row?.quantity);
        else if (row?.type === 'export') ex += Number(row?.quantity);
      });
      return `(${im - ex})`;
    }
    return '';
  }
  const getMaterials = async () => {
    setLoadingMaterials(true);
    const materials = await materialManagementApi.getMaterials({ withs: 'pallets.warehouseHistory' });
    if (materials.success) setMaterials(materials.data.data.map(e => ({ ...e, value: e.id, label: `${e.id} ${getInventory(e)}` })));
    setLoadingMaterials(false);
  }
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const getCustomers = async () => {
    setLoadingCustomers(true);
    const customers = await customerManagementApi.getCustomers();
    if (customers.success) setCustomers(customers.data.data.map(e => ({ ...e, value: e.id, label: e.id })));
    setLoadingCustomers(false);
  }

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await bomApi.getBOMs(filters); // Thay đổi endpoint theo API của bạn
      if (response.success) {
        setData((response.data.data ?? []).map(e => ({ ...e, key: e.id, bomItems: e.bom_items ?? [] })));
        response.data.pagination && setPagination(response.data.pagination)
      }
      setTimeout(() => {
        tableHeight.current = CalculateTableHeight('table-bom');
      }, 300);
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch products');
    }
  };

  const showModal = () => {
    setBomItems([]);
    setIsEditMode(false);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setIsEditMode(true);
    setEditingRecord(record);
    setBomItems(record?.bom_items ?? []);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const onCopy = (record) => {
    const data = { ...record }
    delete data['id'];
    delete data['product_id'];
    form.setFieldsValue(data);
    showModal();
  }

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      const bomItems = [];
      const items = values.bomItems ?? [];
      if (items.length <= 0) {
        message.error('Chưa chọn nguyên vật liệu.')
        return;
      }
      items.forEach(element => {
        const bomItem = { ...element, product_id: values.product_id }
        bomItems.push(bomItem);
      });
      values = { ...values, bomItems }
      try {
        if (isEditMode) {
          const response = await bomApi.updateBOM(editingRecord.id, values);
          if (response.success) {
            fetchData({ ...filters, ...pagination });
            // setData((prevData) => [{ ...values, id: editingRecord.id }, ...prevData.filter(item => item.id !== editingRecord.id)]);
            // message.success('Cập nhật sản phẩm thành công');
            handleCloseModal();
          }
        } else {
          const response = await bomApi.createBOM(values);
          if (response.success) {
            fetchData({ ...filters, ...pagination });
            // setData((prevData) => [{ ...values, id: response.data.id }, ...prevData]);
            // setPagination({ ...pagination, total: pagination.total++ })
            // message.success('Thêm sản phẩm mới thành công');
            handleCloseModal();
          }
        }
      } catch (error) {
        message.error('Failed to save product');
      }
    }).catch((error) => {
      console.log('error', error);
    });
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
    form.resetFields();
  };
  const handleDelete = async (id) => {
    try {
      const response = await bomApi.deleteBOM(id); // Thay đổi endpoint theo API của bạn
      if (response.success) {
        setData((prevData) => prevData.filter(item => item.id !== id));
      }
      // message.success('Xóa sản phẩm thành công');
    } catch (error) {
      message.error('Failed to delete equipment');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} - Upload thất bại`);
        }
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-boms', // Đường dẫn API để xử lý upload file
    onChange: handleUploadChange,
    showUploadList: false, // Ẩn danh sách file
  };

  const columns = [
    {
      title: 'Mã BOM',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'customer_id',
      key: 'customer_id',
      align: 'center',
    },
    {
      title: 'PI',
      dataIndex: 'pi',
      key: 'pi',
      align: 'center',
    },
    {
      title: 'Mã nội bộ',
      dataIndex: 'product_id',
      key: 'product_id',
      align: 'center',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      render: (value) => dayjs(value).format('DD/MM/YYYY')
    },
    {
      title: 'Hành động',
      key: 'action',
      width: 280,
      render: (text, record) => (
        <Space size={[5, 5]}>
          {permissions.includes('bom.create') && (<Button type='primary' size='small' icon={<CopyOutlined />} onClick={() => onCopy(record)} />)}
          {permissions.includes('bom.update') && (<Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
          {permissions.includes('bom.delete') && (<Popconfirm
            title="Bạn có chắc chắn muốn xóa sản phẩm này?"
            onConfirm={() => handleDelete(record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
          </Popconfirm>)}
          <Button size='small' icon={<EyeOutlined />} onClick={() => handleOpenDetailModal(record)} />
          <Button size='small' icon={<HistoryOutlined />} onClick={() => handleHistoryModal(record)} />
        </Space>
      ),
      align: 'center',
    },
  ];
  const expandedRowRender = (bom) => {
    const bomItemsColumns = [
      {
        title: 'Mã nguyên vật liệu',
        dataIndex: 'component_id',
        align: 'center',
      },
      {
        title: 'Mô tả thành phần',
        dataIndex: 'component_description',
        align: 'center',
      },
      {
        title: 'Tỷ lệ (%)',
        dataIndex: 'ratio',
        align: 'center',
      },
    ];
    const items = [
      {
        key: '1',
        label: 'Mã BOM',
        children: bom?.id,
      },
      // {
      //   key: '2',
      //   label: 'Mã sản phẩm',
      //   children: bom?.product_id,
      // },
      {
        key: '3',
        label: 'Mã nội bộ',
        children: bom?.product_id,
      },
      {
        key: '4',
        label: 'Mã PI',
        children: bom?.pi,
      },
      {
        key: '5',
        label: 'Mã khách hàng',
        children: bom?.customer_id,
      },
      {
        key: '6',
        label: 'Số lượng',
        children: bom?.quantity,
      },
      {
        key: '7',
        label: 'Đơn vị',
        children: bom?.unit?.unit_name ?? "",
      },
    ]
    return (
      <Flex vertical gap="small">
        <Descriptions items={items} column={7} layout='vertical' bordered />
        <Table columns={bomItemsColumns} dataSource={bom?.bomItems ?? []} pagination={false} size='small' bordered />
      </Flex>
    );
  };

  const historyContentRender = (method, meta_origin, meta_change) => {
    if (method === 'create') return '';
    const originKeys = Object.keys(meta_origin);
    return originKeys.map((key) => {
      console.log(meta_origin[key]);
      if (!['bom_items', 'updated_at', 'created_at'].includes(key)) {
        return <span>{key}: {meta_origin[key]} {method === 'update' && `=> ${meta_change[key]}`}</span>;
      }
    })
  }

  const getMethod = (method) => {
    switch (method) {
      case 'create':
        return 'Tạo mới'
      case 'delete':
        return 'Xóa bom'
      default:
        return 'Cập nhật'
    }
  }

  const historyRowRender = (bom, bomLog) => {
    const bomLogsColumns = [
      {
        title: 'Ngày cập nhật',
        dataIndex: 'updated_at',
        align: 'center',
        width: 150,
        render: (value) => value ? dayjs(value).format('DD/MM/YYYY HH:mm') : '',
      },
      {
        title: 'Người cập nhật',
        dataIndex: 'user',
        align: 'center',
        width: 150,
        render: (value) => value?.name,
      },
      {
        title: 'Nội dung',
        dataIndex: 'note',
        align: 'left',
        render: (_, record) => {
          const origin = JSON.parse(record?.meta_origin ?? "{}");
          const change = JSON.parse(record?.meta_change ?? "{}")
          return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: 600 }}>{getMethod(record?.method)}</span>
            {historyContentRender(record?.method, origin, change)}
          </div>
        }
      },
    ];
    const items = [
      {
        key: '1',
        label: 'Mã BOM',
        children: bom?.id,
      },
      // {
      //   key: '2',
      //   label: 'Mã sản phẩm',
      //   children: bom?.product_id,
      // },
      {
        key: '3',
        label: 'Mã nội bộ',
        children: bom?.product_id,
      },
      {
        key: '4',
        label: 'Mã PI',
        children: bom?.pi,
      },
      {
        key: '5',
        label: 'Mã khách hàng',
        children: bom?.customer_id,
      },
      {
        key: '6',
        label: 'Số lượng',
        children: bom?.quantity,
      },
      {
        key: '7',
        label: 'Đơn vị',
        children: bom?.unit?.unit_name ?? "",
      },
    ]
    return (
      <Flex vertical gap="small">
        <Descriptions items={items} column={7} layout='vertical' bordered />
        <Table columns={bomLogsColumns} dataSource={bomLog} pagination={false} size='small' bordered />
      </Flex>
    );
  };

  const handleCloseDetailModal = () => {
    setBom();
    setIsOpenDetailModal(false);
  }

  const handleOpenDetailModal = (record) => {
    setBom(record);
    setIsOpenDetailModal(true);
  }

  const handleHistoryModal = async (record) => {
    const response = await axiosInstance.get(config.apiUrl + '/bom-logs', { params: { bom_id: record?.id } });
    if (response?.success) {
      setBom(record);
      setBomLog(response.data?.data);
      setIsOpenHistoryModal(true);
    }
  }

  const handleChangeRatio = () => {
    setBomItems(form.getFieldsValue()?.bomItems ?? []);
  }

  return (
    <Card
      style={{ height: "100%" }}
      title="Quản lý BOM"
      extra={
        <div className="table-actions">
          {/* <Button type="primary">Xuất file</Button> */}
          {permissions.includes('bom.create') && (<>
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                {uploading ? 'Đang tải lên' : 'Upload Excel'}
              </Button>
            </Upload>
            <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm BOM</Button>
          </>)}
        </div>
      }
    >
      <Table
        className="table-bom"
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, page, pageSize });
            fetchData({ ...filters, page, pageSize });
          }
        }}
        bordered  // Thêm thuộc tính bordered để có viền
        size="small"  // Sử dụng kích thước bảng nhỏ
        scroll={{ x: 1000, y: tableHeight.current }}
      />
      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa BOM" : "Thêm BOM"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={1100}
        maskClosable={false}
      >
        <Form form={form} layout="vertical" name="form_in_modal" className='bom-management-form'>
          <div className="modal-form-grid" style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', marginBottom: 16 }}>
            <Form.Item name="id" label="Mã BOM" rules={[{ required: true, message: 'Vui lòng nhập mã BOM!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="product_id" label="Mã nội bộ" rules={[{ required: true, message: 'Vui lòng chọn mã nội bộ!' }]}>
              <Select options={products} showSearch optionFilterProp='label' loading={loadingProducts} />
            </Form.Item>
            <Form.Item name="pi" label="Mã PI" rules={[{ required: true, message: 'Vui lòng nhập mã PI!' }]}>
              <Input disabled={editingRecord} />
            </Form.Item>
            <Form.Item name="customer_id" label="Mã khách hàng" rules={[{ required: true, message: 'Vui lòng nhập mã khách hàng!' }]}>
              <Select options={customers} showSearch optionFilterProp='label' loading={loadingCustomers} disabled={editingRecord} />
            </Form.Item>
            <Form.Item name="quantity" label="Khối lượng 1 mẻ (kg)" rules={[{ required: true, message: 'Vui lòng nhập khối lượng 1 mẻ!' }]}>
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name="unit_id" label="Đơn vị" rules={[{ required: true, message: 'Vui lòng nhập đơn vị!' }]}>
              <Select options={units} optionFilterProp='label' showSearch loading={loadingUnits} />
            </Form.Item>
          </div>
          <Divider />
          <Form.List name="bomItems">
            {(fields, { add, remove }) => (
              <>
                {fields.length ?
                  <Row gutter={8} style={{ marginBottom: 12 }}>
                    <Col span={1} />
                    <Col span={7}>
                      <label for="form_in_modal_bomItems_0_component_description" class="ant-form-item-required" title="Mã nguyên vật liệu">
                        <span style={{ color: 'red', fontSize: 14, fontFamily: 'SimSun', marginInlineEnd: 4 }}>*</span>Mã nguyên vật liệu
                      </label>
                    </Col>
                    <Col span={7}>
                      <label for="form_in_modal_bomItems_0_component_description" class="ant-form-item-required" title="Mô tả thành phần">
                        Mô tả thành phần
                      </label>
                    </Col>
                    <Col span={8}>
                      <label for="form_in_modal_bomItems_0_component_description" class="ant-form-item-required" title="Tỷ lệ (%)">
                        <span style={{ color: 'red', fontSize: 14, fontFamily: 'SimSun', marginInlineEnd: 4 }}>*</span>Tỷ lệ (%)
                      </label>
                    </Col>
                  </Row>
                  :
                  null
                }
                {fields.map(({ key, name, ...restField }, index) => (
                  <Row gutter={8} style={{ marginBottom: 12 }}>
                    <Col span={1} style={{ display: 'flex', alignItems: 'center' }}>{index + 1 + "."}</Col>
                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, 'component_id']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập mã NVL!',
                          },
                        ]}
                        style={{ marginBottom: 0 }}
                      >
                        <Select placeholder="Mã nguyên vật liệu" options={materials} showSearch optionFilterProp='value' loading={loadingMaterials} />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        {...restField}
                        name={[name, 'component_description']}
                        style={{ marginBottom: 0 }}
                      >
                        <Input placeholder="Mô tả thành phần" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'ratio']}
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng nhập tỷ lệ!',
                          },
                        ]}
                        style={{ marginBottom: 0 }}
                      >
                        <InputNumber placeholder="Tỷ lệ (%)" style={{ width: '100%' }} onChange={handleChangeRatio} />
                      </Form.Item>
                    </Col>
                    <Col span={1} style={{ display: 'flex', alignItems: 'center' }}><CloseOutlined onClick={() => { remove(name); handleChangeRatio(); }} /></Col>
                  </Row>
                ))}
                {bomItems.length > 0 && (
                  <Row gutter={8} style={{ marginBottom: 12 }}>
                    <Col span={15}>
                      <div style={{ fontWeight: 600, display: 'flex', justifyContent: 'end', alignItems: 'center', height: '100%' }}>
                        <span>Tổng (%)</span>
                      </div>
                    </Col>
                    <Col span={8}><Input readOnly value={numberFormat(bomItems?.reduce((a, b) => a + Number(b?.ratio), 0))} /></Col>
                    <Col span={1} />
                  </Row>
                )}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Thêm nguyên vật liệu
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
      <Modal
        title={"Chi tiết BOM"}
        open={isOpenDetailModal}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={handleCloseDetailModal}
        cancelText="Đóng"
        width={800}
      >
        {expandedRowRender(bom)}
      </Modal>

      <Modal
        title={"Lịch sử chỉnh sửa BOM"}
        open={isOpenHistoryModal}
        okButtonProps={{ style: { display: 'none' } }}
        onCancel={() => {
          setIsOpenHistoryModal(false);
        }}
        cancelText="Đóng"
        width={800}
      >
        {historyRowRender(bom, bomLog)}
      </Modal>
    </Card>
  );
};

export default BOMManagementTable;
