// src/pages/UI/MaterialManagement/MaterialManagement.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import SemiFinishedProductSidebar from './SemiFinishedProductManagementSidebar';
import SemiFinsishProductTable from './SemiFinishedProductManagementTable';

const { Header, Content } = Layout;

const SemiFinishedProduct = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  return (
    <Layout>
      <SemiFinishedProductSidebar onFilter={handleFilter}/>
      <Layout>
        <Content className="site-layout-content">
          <div style={{height: '100%'}}>
            <SemiFinsishProductTable filters={filters}/>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SemiFinishedProduct;
