import { ArrowDownOutlined, ArrowRightOutlined, ArrowUpOutlined, BarChartOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Card, Col, List, message, Row, Skeleton, Space, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import FirstChart from './charts/FirstChart';
import SecondChart from './charts/SecondChart';
import ThirdChart from './charts/ThirdChart';
import FourthChart from './charts/FourthChart';
import dayjs from 'dayjs';
import axiosInstance from '../../../api/axiosInstance';
import config from '../../../config/config';
const { Text } = Typography;

const KPITable = ({ filters }) => {
  const [dataChart1, setDataChart1] = useState([]);
  const [dataChart2, setDataChart2] = useState([]);
  const [dataChart3, setDataChart3] = useState([]);
  const [dataChart4, setDataChart4] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData(filters);
  }, [filters]);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
      // else filters.start_date = dayjs().startOf('month').format('YYYY-MM-DD');
      if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');
      // else filters.end_date = dayjs().endOf('month').format('YYYY-MM-DD');
      const resKpi = await axiosInstance.get('/kpi', { params: filters });
      if (resKpi?.success) {
        setDataChart1(Object.values(resKpi.data ?? []));
      }

      const response = await axiosInstance.get(config.apiUrl + '/lot-histories-summary', { params: filters }) // Thay đổi endpoint theo API của bạn
      const responseOEE = await axiosInstance.get(config.apiUrl + '/error-equipment-logs/chart/oee', { params: filters });
      const responsePower = await axiosInstance.get(config.apiUrl + '/power');
      if (response?.success) {
        const { dates, products } = response.data;
        console.log(dates, products)
        // Chart2
        setDataChart2(dates || []);

        // Chart3
        setDataChart3(responsePower.data || []);

        // Chart4
        setDataChart4(responseOEE.data || []);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Row gutter={5}>
        <Col span={24}>
          <Row gutter={[5, 5]}>
            <Col span={12}>
              <Skeleton loading={loading} active paragraph={{ rows: 8 }}>
                <FirstChart data={dataChart1} />
              </Skeleton>
            </Col>
            <Col span={12}>
              <Skeleton loading={loading} active paragraph={{ rows: 8 }}>
                <SecondChart data={dataChart2} />
              </Skeleton>
            </Col>
            <Col span={12}>
              <Skeleton loading={loading} active paragraph={{ rows: 8 }}>
                <ThirdChart data={dataChart3} />
              </Skeleton>
            </Col>
            <Col span={12}>
              <Skeleton loading={loading} active paragraph={{ rows: 8 }}>
                <FourthChart data={dataChart4} />
              </Skeleton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Space>
  );
};

export default KPITable;
