import axiosInstance from "./axiosInstance";

const getStandardParameters = (filters) => axiosInstance.get('/standard-parameters', { params: filters });
const getStandardParameterById = (id) => axiosInstance.get(`/standard-parameters/${id}`);
const createStandardParameter = (standardParameterData) => axiosInstance.post('/standard-parameters', standardParameterData);
const updateStandardParameter = (id, standardParameterData) => axiosInstance.put(`/standard-parameters/${id}`, standardParameterData);
const deleteStandardParameter = (id) => axiosInstance.delete(`/standard-parameters/${id}`);

export default {
  getStandardParameters,
  getStandardParameterById,
  createStandardParameter,
  updateStandardParameter,
  deleteStandardParameter,
};
