import { QrcodeOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Layout, Modal, Row, Select, Space, Table, Tabs, message } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import materialExportApi from '../../../../api/materialExportApi';
import materialReceiptApi from '../../../../api/materialReceiptApi';
import palletManagementApi from '../../../../api/palletManagementApi';
import unitManagementApi from '../../../../api/unitManagementApi';
import warehouseEntryManagementApi from '../../../../api/warehouseEntryManagementApi';
import warehouseLocationManagementApi from '../../../../api/warehouseLocationManagementApi';
import Scanner from '../../../../components/Scanner';
import config from '../../../../config/config';
import './WarehouseMLT.css';
import { columnsBottomExport, columnsExit, columnsMiddleImport, columnsPalletScanner, columnsTop, processOptions } from './tableData';
import { createWarehouseHistory, getWarehouseHistories, summaryWarehouseHistory } from '../../../../api/warehouseHistoryApi';
import { tableHeight } from '../../components/OICalculateTableHeight';

const { Content } = Layout;
const { Option } = Select;

const WarehouseMLTExport = () => {
    const navigate = useNavigate();
    const context = useOutletContext();
    const [formScanPallet] = Form.useForm();
    const [selectedProcess, setSelectedProcess] = useState(2);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [summary, setSummary] = useState({ import: 0, export: 0 });
    const [dataExit, setDataExit] = useState([]);
    const [dataPalletScanner, setDataPalletScanner] = useState({ // fields in QR
        warehouse_location_id: null,
        pallet_id: null,
        quantity: 0,
        unit_id: null,
        unit_name: null,
    });
    const [tableDataPalletScanner, setTableDataPalletScanner] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: config.pageSize,
        total: 0,
    });
    const [unitOptions, setUnitOptions] = useState([]);
    const [materialExportOptions, setMaterialExportOptions] = useState([]);
    const [materialExportItems, setMaterialExportItems] = useState([]);
    const [materialExportSelected, setMaterialExportSelected] = useState(null);
    const [scannerKey, setScannerKey] = useState(Date.now());

    const isScanningRef = useRef(true);
    const tableH = useRef(450);

    const fetchData = async (filters) => {
        try {
            setLoading(true);
            const response = await palletManagementApi.getPallets(filters); // Thay đổi endpoint theo API của bạn
            setData(response.data.data);
            response.data?.pagination && setPagination(response.data.pagination)
            setLoading(false);
        } catch (error) {
            message.error(error.message);
        }
    };

    const fetchDataExit = async (filters) => {
        try {
            setLoading(true);
            filters.model = 'pallet';
            const response = await getWarehouseHistories(filters); // Thay đổi endpoint theo API của bạn
            setDataExit(response.data.data);
            setLoading(false);
        } catch (error) {
            message.error(error.message);
        }
    };

    const fetchUnit = async () => {
        try {
            const response = await unitManagementApi.getUnits();
            await setUnitOptions((response.data.data ?? []).map((item) => {
                return { label: item?.unit_name, value: String(item?.id) };
            }));
            if ((response.data.data ?? []).length === 0) message.info('DỮ LIỆU ĐVT TRỐNG!')
        } catch (error) {
            message.error(error.message);
        }
    }

    const fetchMaterialExport = async () => {
        try {
            const response = await materialExportApi.getMaterialExports();
            await setMaterialExportOptions((response.data.data ?? []).map((item) => {
                return { label: item?.id, value: item?.id };
            }));
        } catch (error) {
            message.error(error.message);
        }
    }

    const fetchSummary = async () => {
        try {
            const response = await summaryWarehouseHistory({model: 'pallet'});
            if (response?.success) {
                const data = response.data ?? [];
                const ex = data?.find(item => item?.type === 'export')?.sum ?? 0;
                const im = data?.find(item => item?.type === 'import')?.sum ?? 0;
                setSummary({export: ex, import: im});
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    useEffect(() => {
        fetchSummary();
        fetchMaterialExport();
        fetchData();
        fetchDataExit({ type: 'export' });
        fetchUnit();
    }, []);

    useEffect(() => {
        if (selectedProcess === 1) navigate("/oi/material-warehouse-import");
    }, [selectedProcess]);

    useEffect(() => {
        if (context && context.setTitle) {
            context.setTitle('QUẢN LÝ KHO NVL');
        }
    }, [context]);

    useEffect(() => {
        const { warehouse_location_id, pallet_id, quantity, unit_id, unit_name } = dataPalletScanner;
        formScanPallet.setFieldsValue({ quantity: quantity });
        setTableDataPalletScanner([{
            key: 1,
            warehouse_location_id,
            pallet_id,
            quantity,
            unit_id,
            unit_name
        }]);
    }, [dataPalletScanner]);

    const handleCancel = () => {
        setScannerKey(Date.now());
        setIsModalVisible(false);
        setDataPalletScanner({
            warehouse_location_id: null,
            pallet_id: null,
            quantity: 0,
            unit_id: null,
        });
        setTableDataPalletScanner([]);
        formScanPallet.resetFields();
    };

    const handleCreateWarehouseEntry = async (formValues) => {
        try {
            const { warehouse_location_id, pallet_id, quantity, unit_id } = dataPalletScanner;

            if (!pallet_id) throw new Error('CHƯA CÓ THÔNG TIN MÃ PALLET!');
            if (!warehouse_location_id) throw new Error('CHƯA CÓ THÔNG TIN VỊ TRÍ!');
            if(formValues.quantity <= 0 || formValues.quantity> quantity) throw new Error('SỐ LƯỢNG KHÔNG HỢP LỆ!');
            console.log(dataPalletScanner, materialExportItems);
            const response = await createWarehouseHistory({
                material_export_id: materialExportSelected, // export_id
                warehouse_location_id,
                pallet_id,
                quantity: formValues.quantity,
                unit_id,
                type: 'export',
            });

            if (response?.success) {
                fetchSummary();
                fetchData();
                fetchDataExit({ type: 'export' });
                handleCancel();
            }
        } catch (error) {
            message.error(error.message);
        }
    }

    const handleQrScanner = async (data) => {
        if (data && isScanningRef.current) {
            isScanningRef.current = false;

            setLoading(true);
            try {
                console.log(data);
                const response = await warehouseEntryManagementApi.getWarehouseEntries({ pallet_id: data });
                if (response.data) {
                    const dataWarehouseEntry = response.data?.data ?? [];
                    if (!dataWarehouseEntry[0]?.id) throw new Error('KHÔNG LẤY ĐƯỢC VỊ TRÍ PALLET!');
                    const { warehouse_location_id } = dataWarehouseEntry[0]; // Get quantity and location_id from WarehouseEntry
                    const result = materialExportItems.find((item) => item.material_id === dataWarehouseEntry[0].material.id);
                    if (result?.id) { // Check pallet_id
                        // Get pallet
                        const resPallet = await palletManagementApi.getPalletById(data);
                        if (resPallet?.success) {
                            const {unit_id, unit, quantity} = resPallet.data;
                            setDataPalletScanner(prevState => ({ ...prevState, pallet_id: data, unit_id, unit_name: unit?.unit_name, quantity, warehouse_location_id }));
                        }
                    } else throw new Error('MÃ PALLET KHÔNG HỢP LỆ!');
                }
            } catch (error) {
                message.error(error.message);
                setDataPalletScanner(prevState => ({ ...prevState, pallet_id: null }));
            } finally {
                setLoading(false);
            }

            setTimeout(() => {
                isScanningRef.current = true;
            }, 2000);
        }
    }

    const handleOkScanner = async () => {
        await handleCreateWarehouseEntry(formScanPallet.getFieldsValue(true));
    }

    const handleScanPallet = async () => {
        setScannerKey(Date.now());
        if (materialExportItems.length > 0) setIsModalVisible(true);
        else message.error('VUI LÒNG CHỌN PHIẾU XUẤT!');
    }

    const handleMaterialExportSelected = async (value) => {
        console.log(value);
        setMaterialExportSelected(value);
        const response = await materialExportApi.getMaterialExportItems({ material_export_id: value });
        if (response?.success) {
            setMaterialExportItems(response?.data?.data ?? []);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            let tableHCurrent = tableHeight("table-wh-export", window.innerHeight);
            if (tableHCurrent) {
                tableHCurrent -= 30 + 48 + 64;
                tableH.current = tableHCurrent;
            }
            console.log(window.innerHeight, tableH.current);
        }, 300);
    }, [data]);

    return (
        <Content className="oi-content">
            <Row className="mt-1" gutter={[6, 12]}>
                <Col span={24} className="section-top">
                    <Row gutter={[8, 8]}>
                        <Col span={6} xs={8} md={6}>
                            <div className="oi-select-container" style={{width: '100%'}}>
                                <div className="oi-select-header">Thao tác</div>
                                <Select
                                    optionFilterProp='label'
                                    options={processOptions}
                                    value={selectedProcess}
                                    style={{ width: '100%', height: '-webkit-fill-available' }}
                                    onChange={setSelectedProcess}
                                    className="oi-select"
                                    placeholder="Thao tác"
                                />
                            </div>
                        </Col>
                        <Col span={6} xs={16} md={6}>
                            <div className="oi-select-container" style={{width: '100%'}}>
                                <div className="oi-select-header">Phiếu xuất</div>
                                <Select
                                    showSearch
                                    optionFilterProp='label'
                                    options={materialExportOptions}
                                    style={{ width: '100%', height: '-webkit-fill-available' }}
                                    onChange={handleMaterialExportSelected}
                                    className="oi-select"
                                    placeholder="Phiếu xuất"
                                />
                            </div>
                        </Col>
                        <Col span={12} xs={24} md={12}>
                            <Table
                                className="custom-table"
                                dataSource={[{...summary}]}
                                columns={columnsTop.map(col => ({ ...col, align: 'center' }))}
                                pagination={false}
                                size="small"
                                bordered
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={20} xs={24} md={20} className="section-middle">
                    <Table
                        className="custom-table"
                        dataSource={[{ key: 1, ...dataPalletScanner }]}
                        columns={columnsMiddleImport.map(col => ({ ...col, align: 'center' }))}
                        pagination={false}
                        size="small"
                        bordered
                    />
                </Col>
                <Col span={4} xs={24} md={4}>
                    <Button style={{ width: '100%', height: '100%', fontSize: '16px' }} type="primary" icon={<QrcodeOutlined />} onClick={handleScanPallet}>
                        <span style={{ textWrap: 'balance' }}>Quét QR</span>
                    </Button>
                </Col>
                <Col span={24}>
                    <Card>
                        <Tabs defaultActiveKey="1" type="card" style={{ width: '100%' }} className='custom-oi-tabs'>
                            <TabPane tab="NVL cần xuất" key="1">
                                <Table
                                    className="table-wh-export"
                                    loading={loading}
                                    dataSource={materialExportItems}
                                    columns={columnsBottomExport}
                                    // pagination={{
                                    //     current: pagination.page,
                                    //     pageSize: pagination.pageSize,
                                    //     total: pagination.total,
                                    //     onChange: (page, pageSize) => {
                                    //         setPagination({ ...pagination, page, pageSize });
                                    //         fetchData({ page, pageSize });
                                    //     }
                                    // }}
                                    pagination={false}
                                    size="small"
                                    scroll={{ x: 600, y: tableH.current  }}
                                    bordered
                                />
                            </TabPane>
                            <TabPane tab="Lịch sử xuất kho" key="2">
                                <Table
                                    loading={loading}
                                    dataSource={dataExit}
                                    columns={columnsExit.map(col => ({ ...col, align: 'center' }))}
                                    pagination={false}
                                    size="small"
                                    scroll={{ x: '900px', y: tableH.current  }}
                                    bordered
                                />
                            </TabPane>
                        </Tabs>
                    </Card>
                </Col>
            </Row>

            {/* Modal QR Scanner */}
            <Modal
                key={scannerKey}
                centered
                title={'Quét QR mã Pallet'}
                open={isModalVisible}
                onCancel={handleCancel}
                onOk={handleOkScanner}
                okText="Xác nhận"
                cancelText="Thoát"
                width={500}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div>
                        <Scanner key={scannerKey} isScan={true} onResult={(res) => handleQrScanner(res)} />
                    </div>
                    <Form form={formScanPallet}>
                        <Table
                            className="custom-table"
                            dataSource={tableDataPalletScanner}
                            columns={columnsPalletScanner(true)}
                            pagination={false}
                            size="small"
                            bordered
                        />
                    </Form>
                </Space>
            </Modal>
        </Content>
    );
};

export default WarehouseMLTExport;
