// src/pages/UI/ProductionPlan/ProductionPlan.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import ProductionPlanSidebar from './ProductionPlanSidebar';
import ProductionPlanTable from './ProductionPlanTable';

const { Content } = Layout;

const ProductionPlan = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    console.log(filterValues);
    setFilters(filterValues);
  };

  useEffect(() => {
    handleFilter({status: 0});
  }, []);

  return (
    <Layout>
      <ProductionPlanSidebar onFilter={handleFilter} filters={filters}/>
      <Layout>
        <Content className="site-layout-content">
          <div style={{height:"100%"}}>
            <ProductionPlanTable filters={filters}/>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default ProductionPlan;
