import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Descriptions, Form, message, Modal, Popconfirm, Row, Select, Space, Table, Upload } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import customerManagementApi from '../../../api/customerManagementApi';
import finishedProductImportApi from '../../../api/finishedProductImportApi';
import productManagementApi from '../../../api/productManagementApi';
import unitManagementApi from '../../../api/unitManagementApi';
import config from '../../../config/config';
import { numberFormat } from '../../../utils/numberFormat';

const FinishedProductImportTable = ({ filters }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isViewModalVisible, setIsViewModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [form] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState(null);
    const [data, setData] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: 20,
        total: 0,
    });

    useEffect(() => {
        fetchProduct();
        fetchUnit();
        fetchCustomer();
        fetchData({ ...filters, page: pagination.page, pageSize: pagination.pageSize });
    }, [filters]);

    const fetchData = async (params = {}) => {
        setLoading(true);
        try {
            if (params?.import_date) params.import_date = dayjs(params.import_date).format('YYYY-MM-DD');

            const response = await finishedProductImportApi.getFinishedProductImports(params);
            if (response.success) {
                setData(response.data.data);
                setPagination({
                    ...pagination,
                    total: response.data.pagination.total,
                });
            }
        } catch (error) {
            message.error(error.message);
        }
        setLoading(false);
    };

    const fetchProduct = async (params = null) => {
        try {
            const response = await productManagementApi.getProducts(params);
            await setProductOptions((response.data.data ?? []).map((item) => {
                return { label: <span>{item?.id}<small style={{ color: 'gray' }}>({item?.product_name})</small></span>, value: item?.id };
            }));
            if ((response.data.data ?? []).length === 0) message.info('SẢN PHẨM TRỐNG!');
        } catch (error) {
            message.error(error.message);
        }
    }

    const fetchUnit = async () => {
        try {
            const response = await unitManagementApi.getUnits();
            await setUnitOptions((response.data.data ?? []).map((item) => {
                return { label: item?.unit_name, value: String(item?.id) };
            }));
            if ((response.data.data ?? []).length === 0) message.info('Dữ liệu ĐVT trống!')
        } catch (error) {
            message.error('Failed to fetch units');
        }
    }

    const fetchCustomer = async () => {
        try {
            const response = await customerManagementApi.getCustomers();
            await setCustomerOptions((response.data.data ?? []).map((item) => {
                return { label: item?.customer_name, value: item?.id };
            }));
            if ((response.data.data ?? []).length === 0) message.info('DỮ LIỆU KHÁCH HÀNG TRỐNG')
        } catch (error) {
            message.error(error.message);
        }
    }

    const showModal = async () => {
        setLoading(true);
        setIsEditMode(false);
        setEditingRecord(null);
        form.setFieldsValue({
            import_date: dayjs(),
            productItems: [{ product_id: null, customer_id: null }],
        });
        setIsModalVisible(true);
        setLoading(false);
    };

    const showEditModal = async (record) => {
        setLoading(true);
        const rows = await record?.items?.map((row) => {
            return { product_id: row.product_id, customer_id: row.customer_id };
        });
        setIsEditMode(true);
        setEditingRecord(record);
        form.setFieldsValue({
            productItems: rows
        });
        setIsModalVisible(true);
        setLoading(false);
    };

    const viewModal = async (record) => {
        setLoading(true);
        setEditingRecord(record);
        setIsViewModalVisible(true);
        setLoading(false);
    };

    const handleOk = async () => {
        form.validateFields().then(async (values) => {
            try {
                if (isEditMode) {
                    const response = await finishedProductImportApi.updateFinishedProductImport(
                        editingRecord?.id,
                        {
                            product_items: values.productItems,
                        }
                    );
                    if (response.success) await fetchData(pagination);
                } else {
                    const response = await finishedProductImportApi.createFinishedProductImport({
                        product_items: values.productItems,
                    });
                    if (response.success) {
                        setPagination({ ...pagination, total: pagination.total + 1 });
                        await fetchData(pagination);
                    }
                }
                setIsModalVisible(false);
                form.resetFields();
            } catch (error) {
                message.error('Failed to save');
            }
        }).catch((error) => { console.log(error) });
    };

    const handleDelete = async (id) => {
        try {
            const response = await finishedProductImportApi.deleteFinishedProductImport(id);
            if (response?.success) {
                setPagination({ ...pagination, total: pagination.total - 1 });
                await fetchData(pagination);
            }
        } catch (error) {
            message.error('Failed to delete');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };


    const handleUploadChange = (info) => {
        if (info.file.status === 'uploading') {
            setUploading(true);
        } else {
            setUploading(false);
            if (info.file.status === 'done') {
                message.success(`${info.file.name} - Upload thành công`);
                fetchData({ ...filters, page: pagination.page, pageSize: pagination.pageSize });

            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} - Upload thất bại`);
            }
        }
    };

    const uploadProps = {
        name: 'file',
        accept: '.xlsx, .xls',
        action: config.apiUrl + '/import-material-receipts', // Thay đổi endpoint theo API của bạn
        onChange: handleUploadChange,
        showUploadList: false,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };

    const columns = [
        { title: 'STT', dataIndex: 'no', key: 'no', align: 'center', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1, width: 70 },
        { title: 'Mã phiếu nhập', dataIndex: 'id', key: 'id', align: 'center', width: 120 },
        { title: 'Ngày nhập', dataIndex: 'import_date', key: 'import_date', align: 'center', width: 90 },
        { title: 'Người nhập', dataIndex: 'created_by', key: 'created_by', align: 'center', width: 100, render: (_, record) => record?.user?.name },
        {
            title: 'Hành động', key: 'action', width: 100, render: (text, record) => (
                <Space size="middle">
                    <Button size='small' icon={<EyeOutlined />} onClick={() => viewModal(record)} />
                    <Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />
                    <Popconfirm title="Bạn có chắc chắn muốn xóa?" onConfirm={() => handleDelete(record.id)} okText="Có" cancelText="Không">
                        <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
                    </Popconfirm>
                </Space>
            ), align: 'center'
        },
    ];

    const columnsViewTable = [
        { title: 'STT', dataIndex: 'no', key: 'no', width: '50px', align: 'center', render: (_, record, index) => index + 1 },
        { title: 'Mã sản phẩm', dataIndex: 'product_id', key: 'product_id', align: 'center', width: '100px' },
        { title: 'Tên sản phẩm', dataIndex: 'product_name', key: 'product_name', align: 'center', width: '150px', render: (_, record, index) => record?.product?.product_name },
        { title: 'Số lượng', dataIndex: 'quantity', key: 'quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
        { title: 'ĐVT', dataIndex: 'unit_id', key: 'unit_id', align: 'center', width: '100px', render: (_, record, index) => record?.product?.unit?.unit_name },
        { title: 'Khách hàng', dataIndex: 'customer_name', key: 'customer_name', align: 'center', width: '150px', render: (_, record, index) => record?.customer?.customer_name },
        { title: 'Ghi chú', dataIndex: 'note', key: 'note', width: '150px', align: 'center' },
    ];

    const itemsDescription = [
        { key: '1', label: 'Mã phiếu nhập', children: editingRecord?.id },
        { key: '2', label: 'Ngày nhập', children: editingRecord?.import_date },
        { key: '3', label: 'Người nhập', children: editingRecord?.user?.name },
    ];

    return (
        <Card
            title="Quản lý phiếu nhập kho thành phẩm"
            extra={
                <div className="table-actions">
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                            {uploading ? 'Đang tải lên' : 'Upload Excel'}
                        </Button>
                    </Upload>
                    <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Tạo lệnh nhập</Button>
                </div>
            }
        >
            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: (page, pageSize) => {
                        setPagination({ ...pagination, page, pageSize });
                        fetchData({ ...filters, page, pageSize });
                    },
                }}
                bordered
                size="small"
            />

            <Modal
                title={
                    <div className="modal-title">
                        {isEditMode ? "Sửa phiếu nhập kho" : "Tạo phiếu nhập kho"}
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Lưu lại"
                cancelText="Thoát"
                width={800}
                loading={loading}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <Form.List name="productItems">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row gutter={8} key={key}>
                                        <Col span={11} xs={10} md={11}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'product_id']}
                                                label={key === 0 ? "Mã sản phẩm" : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập mã SP!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Mã sản phẩm" options={productOptions.map(e => (form.getFieldValue('productItems') ?? []).some(o => o?.product_id === e.value) ? ({ ...e, disabled: true }) : e)} showSearch optionFilterProp='label' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={11} xs={10} md={11}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'customer_id']}
                                                label={key === 0 ? "Khách hàng" : ""}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng nhập khách hàng!',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Khách hàng" options={customerOptions} showSearch optionFilterProp='label' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2} xs={4} md={2}>
                                            <Form.Item label={key === 0 ? " " : ""}>
                                                <Button block icon={<CloseOutlined />} disabled={key === 0} onClick={() => remove(name)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Thêm sản phẩm
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>

            {/* View modal */}
            <Modal
                title={
                    <div className="modal-title">
                        <span>Thông tin phiếu nhập</span>
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isViewModalVisible}
                onCancel={() => setIsViewModalVisible(false)}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText="Thoát"
                width={800}
                loading={loading}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Descriptions items={itemsDescription} layout="vertical" bordered />
                    <Table dataSource={editingRecord?.items} columns={columnsViewTable} bordered pagination={false} scroll={{ x: '800px' }} />
                </Space>
            </Modal>
        </Card>
    );
};

export default FinishedProductImportTable;
