import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Space, message } from 'antd';
import config from '../../../../config/config';
import axiosInstance from '../../../../api/axiosInstance';
import dayjs from 'dayjs';
import { DownloadOutlined } from '@ant-design/icons';
import { b64toBlob } from '../../../../utils/dataBlob';
import CalculateTableHeight from '../../../../components/calculateTableHeight';

const EquipmentParametersTable = ({ filters }) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const [data, setData] = useState([]);

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: 50,
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Thiết bị',
      dataIndex: 'equipment',
      width: '140px',
      render: (value) => value?.equipment_name
    },
    {
      title: 'Thời điểm ghi nhận',
      dataIndex: 'created_at',
      width: '140px',
      render: (value) => dayjs(value).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Số LSX',
      dataIndex: 'batch_id',
      width: '80px'
    },
    {
      title: 'Số Lot',
      dataIndex: 'lot_id',
      width: '80px'
    },
    {
      title: 'Thông số',
      dataIndex: 'equipment_parameter',
      width: '180px',
      render: (value) => value?.parameter_name,
    },
    {
      title: 'Tiêu chuẩn',
      dataIndex: 'standard',
      width: '100px'
    },
    {
      title: 'Thực tế',
      dataIndex: 'value',
      width: '120px',
      render: (value) => Number(value),
    },
    {
      title: 'Mức vượt',
      dataIndex: 'excess_level',
      width: '100px'
    },
  ];

  const fetchData = async (filters) => {
    setLoading(true);
    const response = await axiosInstance(config.apiUrl + '/equipment-parameter-logs', { params: filters });
    if (response.success) {
      setData(response.data?.data ?? []);
      response.data?.pagination && setPagination(response.data.pagination)
    }
    setLoading(false);
  }

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/equipment-parameter-logs-export', { params: {...filters, ...pagination} });
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      style={{height: '100%'}}
      title="Thông số thiết bị"
      extra={
        <Space>
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel}>Xuất Excel</Button>
        </Space>
      }
    >
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
        <Table
          className='table-equipment-parameter'
          loading={loading}
          columns={columns.map(e => ({ ...e, align: 'center' }))}
          dataSource={data.map((e, i) => ({ ...e, key: i }))}
          bordered
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, page, pageSize });
              fetchData({ page, pageSize });
            },
          }}
          scroll={{ x: "1340px", y: CalculateTableHeight('table-equipment-parameter', 48) }}
          size="small"
        />
      </div>
    </Card>
  );
};

export default EquipmentParametersTable;
