import axiosInstance from "./axiosInstance";

const getMolds = (filters) => axiosInstance.get('/molds', { params: filters });
const getMoldById = (id) => axiosInstance.get(`/molds/${id}`);
const createMold = (moldData) => axiosInstance.post('/molds', moldData);
const updateMold = (id, moldData) => axiosInstance.put(`/molds/${id}`, moldData);
const deleteMold = (id) => axiosInstance.delete(`/molds/${id}`);

export default {
  getMolds,
  getMoldById,
  createMold,
  updateMold,
  deleteMold,
};
