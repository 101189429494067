// src/pages/UI/ProductManagement/ProductManagementSidebar.js
import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../components/Sidebar';
import axiosInstance from '../../../api/axiosInstance';
import config from '../../../config/config';

dayjs.locale('vi');

const ProductManagementSidebar = ({ onFilter }) => {
  const [productOptions, setProductOptions] = useState([]);
  const [productNameOptions, setProductNameOptions] = useState([]);
  const timer = useRef();

  const formFields = [
    // {
    //   type: 'Input',
    //   label: 'Mã nội bộ',
    //   name: 'id',
    //   placeholder: 'Mã nội bộ',
    // },
    // {
    //   type: 'Input',
    //   label: 'Tên nội bộ',
    //   name: 'product_name',
    //   placeholder: 'Tên nội bộ',
    // }
    {
      type: 'AutoComplete',
      label: 'Mã nội bộ',
      name: 'id',
      placeholder: 'Mã nội bộ',
      options: productOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchProduct({ id: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
    {
      type: 'AutoComplete',
      label: 'Tên nội bộ',
      name: 'product_name',
      placeholder: 'Tên nội bộ',
      options: productNameOptions,
      search: (value) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          fetchProduct({ product_name: value, page: 1, pageSize: 10 });
        }, 500);
      }
    },
  ];

  const fetchProduct = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/products', { params });
    if (response?.success) {
      setProductOptions((response.data?.data ?? []).map((item) => ({ label: item?.id, value: item?.id })));
      setProductNameOptions((response.data?.data ?? []).map((item) => ({ label: item?.product_name, value: item?.product_name })));
    }
  }

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default ProductManagementSidebar;
