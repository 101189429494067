// src/pages/LoginPage.js
import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, Card, message, Row, Col, Upload, Modal } from 'antd';
import AuthContext from '../context/AuthContext';
import './LoginPage.css';
import config from '../config/config';
import { LoginOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { resetPassword } from '../api/resetPasswordApi';
import Scanner from '../components/Scanner';
import axiosInstance from '../api/axiosInstance';

const LoginPage = () => {
  const { login, loginWithQrCode } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [logo, setLogo] = useState();
  const { Meta } = Card;

  const getConfig = async () => {
    let config_local = await localStorage.getItem('company_config');
    if (config_local) {
      const config_company = JSON.parse(config_local);
      if (config_company.company_name) {
        setCompany(config_company.company_name);
        if (config_company.logo) {
          setLogo(`${config.apiUrl.replace('/api', '')}${config_company.logo}`);
        }
      }
    }
  }
  useEffect(() => {
    getConfig();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await login(values.username, values.password);
      // message.success('Đăng nhập thành công!');
    } catch (error) {
      message.error('Đăng nhập thất bại, vui lòng thử lại!');
    } finally {
      setLoading(false);
    }
  };

  const onResetPassword = async (values) => {
    setLoading(true);
    try {
      await resetPassword({email: values.email});
      setIsResetPassword(false);
    } catch (error) {
      message.error('Khôi phục mật khẩu thất bại, vui lòng thử lại!');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleQrScanner = async (token) => {
    setLoading(true);
    try {
      if (token) {
        await loginWithQrCode(token);
        setIsModalVisible(false);
        message.success('Đăng nhập thành công!');
      } else throw new Error('Token not found!');
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="login-container">
      {
        !isResetPassword ? (
          <Card className="login-card">
            {/* { company && (<Meta title="Đăng nhập" style={{marginBottom: '8px'}} />) } */}
            <div className="text-center mt-3">
              <img className="w-25" src={logo} style={{maxHeight: '120px', maxWidth: '250px'}} />
            </div>
            <h3 className="text-center text-primary mb-0" style={{ marginTop: '0px' }}>
              {company ?? 'Đăng nhập'}
            </h3>
            <Form
              name="login"
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                name="username"
                label="Tài khoản"
                rules={[{ required: true, message: 'Vui lòng nhập tài khoản!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Mật khẩu"
                rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button icon={<LoginOutlined />} type="primary" htmlType="submit" loading={loading} block>
                  Đăng nhập
                </Button>
              </Form.Item>
              <Row>
                <Col span={12}>
                  <Button block icon={<QrcodeOutlined />} type="link" onClick={() => setIsModalVisible(true)}>Đăng nhập bằng QR</Button>
                </Col>
                <Col span={12}>
                  <Button block type="link" onClick={() => setIsResetPassword(true)}>Quên mật khẩu?</Button>
                </Col>
              </Row>
            </Form>
          </Card>
        ) : (
          <Card className="login-card">
            <div className="text-center mt-3">
              <img className="w-25" src={logo} style={{maxHeight: '120px', maxWidth: '250px'}} />
            </div>
            <h3 className="text-center text-primary mb-0" style={{ marginTop: '0px' }}>
              Khôi phục mật khẩu
            </h3>
            <Form
              name="reset-password"
              onFinish={onResetPassword}
              layout="vertical"
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Vui lòng nhập email!'}, {type: 'email', message: 'Vui lòng nhập đúng định dạng!'}]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading} block>
                  Khôi phục
                </Button>
              </Form.Item>
              <Button block onClick={() => setIsResetPassword(false)}>Quay lại đăng nhập</Button>
            </Form>
          </Card>
        )
      }

      {/* Modal QR Scanner */}
      <Modal
        centered
        title={"Đăng nhập bằng QR"}
        open={isModalVisible}
        onCancel={handleCancel}
        okButtonProps={{style: { display: 'none' }}}
        cancelText="Thoát"
        width={500}
      >
        {isModalVisible && <Scanner isScan={true} onResult={(res) => handleQrScanner(res)}/>}
      </Modal>
    </div>
  );
};

export default LoginPage;
