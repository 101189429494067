import { Card } from "antd";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import dayjs from "dayjs";
import axiosInstance from "../../../../../api/axiosInstance";
import config from "../../../../../config/config";

function generateFakeData(startDate, days) {
    function randomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function formatDate(date) {
        return date.format('DD-MM');
    }

    const data = [];
    const start = startDate?.subtract(days, 'day');

    for (let i = 0; i <= days; i++) {
        const currentDate = start.add(i, 'day');
        const A = randomInt(50, 100);
        const P = randomInt(50, 100);
        const Q = randomInt(50, 100);
        const entry = {
            date: formatDate(currentDate),
            A: A,
            P: P,
            Q: Q,
            OEE: (A*P*Q)/10000
        };

        data.push(entry);
    }

    return data;
}
const fakeData = generateFakeData(dayjs(), 4);
const OEEChart = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(config.apiUrl + '/error-equipment-logs/chart/oee', {params: {}});
                setData(response.data || []);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const dates = [...new Set(data.map(item => item.date))];
        const A = data.map(item => item.a);
        const P = data.map(item => item.p);
        const Q = data.map(item => item.q);
        const OEE = data.map(item => item.oee);
        const series = [
            {
                name: "A",
                data: A,
            },
            {
                name: "P",
                data: P,
            },
            {
                name: "Q",
                data: Q,
            },
            {
                name: "OEE",
                data: OEE,
            },
        ];
        const options = {
            chart: {
                height: 300,
                type: 'column',
            },

            title: false,

            xAxis: {
                categories: dates,
                crosshair: true,
                accessibility: {
                    enabled: false
                }
            },
            plotOptions: {
                column: {
                    depth: 40,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:0.1f}%'
                    },
                    borderRadius: 0
                }
            },

            yAxis: {
                title: null,
            },
            series: series,
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("ng-rate-chart", options);
    }, [data]);

    return <Card title="OEE"
        styles={{body: {padding: '0px'}}}
        style={
            { padding: '0px' }
        }>
        <div id="ng-rate-chart" />
    </Card>
};

export default OEEChart;