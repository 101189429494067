// src/pages/OI/components/OIHeader.js
import React, { useContext, useState } from 'react';
import { Layout, Button, Dropdown, Space } from 'antd';
import { CloseOutlined, DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import './OIHeader.css';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/AuthContext';
import config from '../../../config/config';

const { Header } = Layout;

const OIHeader = ({ title }) => {
  const navigate = useNavigate();
  const { user, logout } = useContext(AuthContext);
  const [logo, setLogo] = useState(config.logo);

  const userMenu = [
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Đăng xuất',
    },
  ];
  const handleMenuClick = (e) => {
    console.log(e);
    switch (e.key) {
      case 'profile':
        break;
      case 'logout':
        logout()
        break;
      default:
        break;
    }
  };

  const getLogoFromLocalStorage = () => {
    const conf = localStorage.getItem('company_config');
    if (conf) {
      const logoUrl = JSON.parse(conf)?.logo ?? null;
      if (logoUrl && logoUrl?.includes('logo.'))
        setLogo(`${config.apiUrl.replace('/api', '')}${logoUrl}`);
    }
  }

  useState(() => {
    getLogoFromLocalStorage();
  }, []);

  return (
    <Header className="oi-header">
      <div className="oi-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="oi-title">{title}</div>
      <div className="user-section">
        <Dropdown menu={{ items: userMenu, onClick: handleMenuClick, }} className="user-menu">
          <Button>
            {user.name} <DownOutlined />
          </Button>
        </Dropdown>
        <Button icon={<CloseOutlined />} onClick={() => navigate('/')} style={{ marginLeft: '16px' }}/>
      </div>
    </Header>
  );
};

export default OIHeader;
