import React from 'react';
import { Layout, DatePicker, Input, Button, Form, Divider } from 'antd';

const { Sider } = Layout;

const FinishedProductImportSidebar = ({ onFilter }) => {
    const [form] = Form.useForm();

    const handleFinish = (values) => {
        onFilter(values);
    };

    return (
        <Sider width={250} className="site-layout-sider">
            <div className="sidebar-content">
                <Divider>Tìm kiếm</Divider>
                <div className="mb-3">
                    <Form
                        form={form}
                        style={{ margin: '0 15px' }}
                        layout="vertical"
                        onFinish={handleFinish}
                    >
                        <Form.Item label="Mã phiếu nhập" name="id" className="mb-3">
                            <Input placeholder="Mã phiếu nhập" allowClear />
                        </Form.Item>
                        <Form.Item label="Ngày nhập" name="import_date" className="mb-3">
                            <DatePicker style={{ width: '100%' }} placeholder='Ngày nhập' format="DD-MM-YYYY" allowClear />
                        </Form.Item>
                    </Form>
                </div>
                <div className="sidebar-footer">
                    <Button
                        type="primary"
                        style={{ width: '80%' }}
                        onClick={() => form.submit()}
                    >
                        Truy vấn
                    </Button>
                </div>
            </div>
        </Sider>
    );
};

export default FinishedProductImportSidebar;
