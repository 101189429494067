// src/pages/UI/TemplateManagement/TemplateTable.js

import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Card, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import axiosInstance from '../../../api/axiosInstance'; // Import axiosInstance
import TemplateForm from './TemplateForm';
import './TemplateTable.css';
import templateApi from "../../../api/templateManagementApi";
import AuthContext from '../../../context/AuthContext';

const TemplateTable = () => {
  const { user } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
  }, []);

  const fetchData = async () => {
    try {
      const response = await templateApi.getTemplates(); // Thay đổi endpoint theo API của bạn
      setData(response.data);
    } catch (error) {
      message.error('Failed to fetch templates');
    }
  };

  const showModal = () => {
    setIsEditMode(false);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    console.log(record);
    setIsEditMode(true);
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      console.log(values);
      try {
        if (isEditMode) {
          const response = await templateApi.updateTemplate(editingRecord.id, values); // Thay đổi endpoint theo API của bạn
          if (response.success) {
            setData((prevData) => [{ ...values, id: editingRecord.id }, ...prevData.filter(item => item.id !== editingRecord.id)]);
          } else throw new Error('Action failure');
        } else {
          const response = await templateApi.createTemplate(values); // Thay đổi endpoint theo API của bạn
          if (response.success) {
            setData((prevData) => [{ ...values, id: response.data.id }, ...prevData]);
          } else throw new Error('Action failure');
        }
        setIsModalVisible(false);
        form.resetFields();
      } catch (error) {
        message.error('Failed to save template');
      }
    }).catch(error => {
      console.log(error);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const columns = [
    {
      title: 'Tên template',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {permissions.includes('template.update') && (<Button icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
          {/* <Button icon={<DeleteOutlined />} /> */}
        </Space>
      ),
      align: 'center',
    },
  ];

  return (
    <Card
      title="Quản lý Template"
      extra={
        <div className="table-actions">
          {permissions.includes('template.create') && (<Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm template</Button>)}
        </div>
      }
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((record, key) => { return { key, ...record } })}
        pagination={{ pageSize: 20 }}
        bordered  // Thêm thuộc tính bordered để có viền
        size="small"  // Sử dụng kích thước bảng nhỏ
      />

      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa template" : "Thêm template"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={800}
      >
        <TemplateForm form={form} />
      </Modal>
    </Card>
  );
};

export default TemplateTable;
