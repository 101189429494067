import React, { createContext, useContext, useMemo, useState } from 'react';
import { Table, Button, Card, Space, Upload, message } from 'antd';
import './CTPlanTable.css';
import config from '../../../../config/config';
import dayjs from 'dayjs';
import { HolderOutlined, UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
const RowContext = createContext({});

const data = Array(20).fill().map((_, key) => {
  return {
    key: key + 1,
    field1: `23410${Math.floor(Math.random() * 900) + 100}A`,
    field2: ['Đơn hàng nội địa', 'Đơn hàng xuất khẩu'][Math.floor(Math.random() * 2)],
    field3: `KC${Math.floor(Math.random() * 900) + 100}`,
    field4: `${(Math.random() + 1).toString(36).substring(7).toUpperCase()} COMPANY`,
    field5: (Math.random() + 1).toString(36).substring(7).toUpperCase(),
    field6: 'Unit1',
    field7: 'Unit2',
    field8: dayjs().format('DD/MM/YYYY'),
    field9: Math.floor(Math.random() * 900) + 100,
    field10: Math.floor(Math.random() * 900) + 100,
    field11: Math.floor(Math.random() * 900) + 100,
    field12: 'T5',
  }
});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: 'move',
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const CTPlanTable = () => {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = React.useState(data);
  const [uploading, setUploading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const overallManufactureHistoryColumn = [
    {
      title: 'Số lượng đầu ra KH',
      dataIndex: 'sl_dau_ra_kh',
    },
    {
      title: 'Số lượng đầu ra thực tế',
      dataIndex: 'sl_dau_ra_tt',
    },
    {
      title: 'Chênh lệch (TT - KH)',
      dataIndex: 'chenh_lech',
    },
    {
      title: 'Tỷ lệ hoàn thành',
      dataIndex: 'compete_percentage',
    },
    {
      title: 'Số lượng NG',
      dataIndex: 'sl_ng',
    },
  ];
  
  const overallManufactureHistoryData = [
    {
      key: '1',
      sl_dau_ra_kh: '1000',
      sl_dau_ra_tt: '950',
      chenh_lech: '-50',
      compete_percentage: '95%',
      sl_ng: '50',
    },
  ];

  const handleUploadChange = (info) => {
    try {
        if (info.file.status === 'uploading') {
            setUploading(true);
        } else {
            if (info.file.status === 'done') {
                // if (!info.file?.response?.success) 
                //     throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
                // fetchData({ ...filters, ...pagination });
                message.success(`${info.file.name} - Upload thành công`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} - Upload thất bại`);
            }
        }
    } catch (error) {
        message.error(error.message)
    } finally {
        setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: 'http://127.0.0.1:8000/api/import-x', // Đường dẫn API để xử lý upload file
    onChange: handleUploadChange,
    showUploadList: false, // Ẩn danh sách file
  };
  
  const columns = [
    {
      title: <HolderOutlined />,
      key: 'sort',
      align: 'center',
      width: 40,
      render: () => <DragHandle />,
    },
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Số CT',
      dataIndex: 'field1',
      key: 'field1',
    },
    {
      title: 'Loại đơn hàng',
      dataIndex: 'field2',
      key: 'field2',
    },
    {
      title: 'Mã khách hàng',
      dataIndex: 'field3',
      key: 'field3',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'field4',
      key: 'field4',
    },
    {
      title: 'Mã hàng',
      dataIndex: 'field5',
      key: 'field5',
    },
    {
      title: 'Tên hàng',
      dataIndex: 'field6',
      key: 'field6',
    },
    {
      title: 'ĐVT',
      dataIndex: 'field7',
      key: 'field7',
    },
    {
      title: 'ĐVT2',
      dataIndex: 'field8',
      key: 'field8',
    },
    {
      title: 'Ngày giao hàng',
      dataIndex: 'field9',
      key: 'field9',
    },
    {
      title: 'Số lượng',
      dataIndex: 'field9',
      key: 'field9',
    },
    {
      title: 'Số lượng 2',
      dataIndex: 'field9',
      key: 'field10',
    },
    {
      title: 'Mã phôi',
      dataIndex: 'field10',
      key: 'field11',
    },
    {
      title: 'Chế độ chạy',
      dataIndex: 'field11',
      key: 'field12',
    },
  ];

  const navigateToPO = () => {
    navigate('/ui/po-plan');
  }

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.key === active?.id);
        const overIndex = prevState.findIndex((record) => record.key === over?.id);
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  return (
    <Card
      title="Quản lý mã CT"
      extra={
        <Space>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} loading={uploading}>
              {uploading ? 'Đang tải lên' : 'Upload'}
            </Button>
          </Upload>
          <Button>Insert</Button>
          <Button>Duplicate</Button>
          <Button type="primary" onClick={navigateToPO}>Run</Button>
          <Button>Save</Button>
        </Space>
      }
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext rtableContext items={dataSource.map((i) => i.key)} strategy={verticalListSortingStrategy}>
          <Table
            rowKey="key"
            components={{
              body: {
                row: Row,
              },
            }}
            columns={columns.map(e => ({ ...e, align: 'center' }))}
            dataSource={dataSource}
            pagination={false}
            bordered
            size='small'
          />
        </SortableContext>
      </DndContext>
      {/* <Table
        columns={columns.map(e => ({ ...e, align: 'center' }))}
        dataSource={data}
        bordered
        pagination={{ pageSize: 20 }}
        scroll={{ x: true }}
        size='small'
      /> */}
    </Card>
  );
};

export default CTPlanTable;
