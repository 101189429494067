import axiosInstance from "./axiosInstance";

const getTemplates = (filters) => axiosInstance.get('/templates', { params: filters });
const getTemplateById = (id) => axiosInstance.get(`/templates/${id}`);
const createTemplate = (templateData) => axiosInstance.post('/templates', templateData);
const updateTemplate = (id, templateData) => axiosInstance.put(`/templates/${id}`, templateData);
const deleteTemplate = (id) => axiosInstance.delete(`/templates/${id}`);

export default {
  getTemplates,
  getTemplateById,
  createTemplate,
  updateTemplate,
  deleteTemplate,
};
