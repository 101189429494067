import axiosInstance from "./axiosInstance";

const getErrorEquipmentLogs = (filters) => axiosInstance.get('/error-equipment-logs', { params: filters });
const getErrorEquipmentLogById = (id) => axiosInstance.get(`/error-equipment-logs/${id}`);
const createErrorEquipmentLog = (errorEquipmentData) => axiosInstance.post('/error-equipment-logs', errorEquipmentData);
const updateErrorEquipmentLog = (id, errorEquipmentData) => axiosInstance.put(`/error-equipment-logs/${id}`, errorEquipmentData);
const deleteErrorEquipmentLog = (id) => axiosInstance.delete(`/error-equipment-logs/${id}`);
const equipmentErrorFrequency = (filters) => axiosInstance.get('/error-equipment-logs/chart/frequency', {params: filters});

export default {
    getErrorEquipmentLogs,
    getErrorEquipmentLogById,
    createErrorEquipmentLog,
    updateErrorEquipmentLog,
    deleteErrorEquipmentLog,
    equipmentErrorFrequency
};
