import axiosInstance from "./axiosInstance";

const getLots = (filters) => axiosInstance.get('/lots', { params: filters });
const getLotById = (id) => axiosInstance.get(`/lots/${id}`);
const createLot = (data) => axiosInstance.post('/lots', data);
const updateLot = (id, data) => axiosInstance.put(`/lots/${id}`, data);
const deleteLot = (id) => axiosInstance.delete(`/lots/${id}`);

export default {
  getLots,
  getLotById,
  createLot,
  updateLot,
  deleteLot,
};
