// src/pages/UI/ProcessManagement/ProcessManagement.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import ProcessManagementSidebar from './ProcessManagementSidebar';
import ProcessManagementTable from './ProcessManagementTable';

const { Header, Content } = Layout;

const ProcessManagement = () => {
    const [filters, setFilters] = useState({});

    const handleFilter = (filterValues) => {
        setFilters(filterValues);
    };
    // useEffect(()=>{
    //   console.log(filters);
    // }, [filters])
    return (
        <Layout>
            <ProcessManagementSidebar onFilter={handleFilter} />
            <Content className="site-layout-content">
                <ProcessManagementTable filters={filters} />
            </Content>
        </Layout>
    );
};

export default ProcessManagement;
