import axiosInstance from "./axiosInstance";

const getErrorQualities = (filters) => axiosInstance.get('/error-qualities', { params: filters });
const getErrorQualityById = (id) => axiosInstance.get(`/error-qualities/${id}`);
const createErrorQuality = (errorQualityData) => axiosInstance.post('/error-qualities', errorQualityData);
const updateErrorQuality = (id, errorQualityData) => axiosInstance.put(`/error-qualities/${id}`, errorQualityData);
const deleteErrorQuality = (id) => axiosInstance.delete(`/error-qualities/${id}`);

export default {
  getErrorQualities,
  getErrorQualityById,
  createErrorQuality,
  updateErrorQuality,
  deleteErrorQuality,
};
