import axiosInstance from "./axiosInstance";

const getDepartments = (filters) => axiosInstance.get('/departments', { params: filters });
const getDepartmentById = (id) => axiosInstance.get(`/departments/${id}`);
const createDepartment = (departmentData) => axiosInstance.post('/departments', departmentData);
const updateDepartment = (id, departmentData) => axiosInstance.put(`/departments/${id}`, departmentData);
const deleteDepartment = (id) => axiosInstance.delete(`/departments/${id}`);

export default {
  getDepartments,
  getDepartmentById,
  createDepartment,
  updateDepartment,
  deleteDepartment,
};
