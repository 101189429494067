import axiosInstance from "./axiosInstance";

const getRoles = (filters) => axiosInstance.get('/roles', { params: filters });
const getRoleById = (id) => axiosInstance.get(`/roles/${id}`);
const createRole = (roleData) => axiosInstance.post('/roles', roleData);
const updateRole = (id, roleData) => axiosInstance.put(`/roles/${id}`, roleData);
const deleteRole = (id) => axiosInstance.delete(`/roles/${id}`);

export default {
  getRoles,
  getRoleById,
  createRole,
  updateRole,
  deleteRole,
};
