import { Button, Space, Table, Tabs, Tag, message } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import React, { useEffect, useState } from 'react';
import warehouseEntryManagementApi from '../../../../api/warehouseEntryManagementApi';
import { getWarehouseHistories } from '../../../../api/warehouseHistoryApi';
import config from '../../../../config/config';
import dayjs from 'dayjs';
import { DownloadOutlined } from '@ant-design/icons';
import axiosInstance from '../../../../api/axiosInstance';
import { b64toBlob } from '../../../../utils/dataBlob';
import { numberFormat } from '../../../../utils/numberFormat';

const FinishedProductWarehouseTable = ({ filters }) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const [paginationEntry, setPaginationEntry] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const [data, setData] = useState([]);
  const [dataEntry, setDataEntry] = useState([]);

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    const paginateEntry = { ...paginationEntry, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
    fetchWarehouseEntry({ ...filters, ...paginateEntry });
  }, [filters]);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      filters.model = 'lot';
      if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
      if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');

      const response = await getWarehouseHistories(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data.data ?? []);
      response.data?.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchWarehouseEntry = async (filters) => {
    try {
      filters.model = 'lot';
      filters.withs = 'lot.product';
      const response = await warehouseEntryManagementApi.getWarehouseEntriesInventory(filters); // Thay đổi endpoint theo API của bạn
      setDataEntry(response.data ?? []);
      response.data?.pagination && setPaginationEntry(response.data.pagination)
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    { title: 'STT', dataIndex: 'stt', align: 'center', width: '50px', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1 },
    { title: 'Loại', dataIndex: 'type', align: 'center', width: '80px', render: (value) => value === 'export' ? <Tag color="#2DB7F5">XUẤT</Tag> : <Tag color="#F50">NHẬP</Tag> },
    { title: 'Vị trí', dataIndex: 'warehouse_location_id', align: 'center', width: '100px' },
    { title: 'Mã Lot', dataIndex: 'lot', align: 'center', width: '100px', render: (value) => value?.id },
    { title: 'Mã thành phẩm', dataIndex: 'product_id', align: 'center', width: '150px', render: (_, record) => record?.lot?.product?.id },
    { title: 'Tên thành phẩm', dataIndex: 'product_name', align: 'center', width: '150px', render: (_, record) => record?.lot?.product?.product_name },
    { title: 'Số lượng', dataIndex: 'quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Tồn', dataIndex: 'inventory_quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Đơn vị tính', dataIndex: 'unit', align: 'center', width: '100px', render: (value) => value?.unit_name },
    { title: "Mã KH", dataIndex: "customer_id", key: "customer_id", align: "center", width: "100px", render: (_, record) => record?.lot?.batch?.production_plan?.customer_id },
    { title: "PI", dataIndex: "pi", key: "pi", align: "center", width: "100px", render: (_, record) => record?.lot?.batch?.production_plan?.pi },
    { title: 'Thời gian', dataIndex: 'created_at', align: 'center', width: '100px' },
    { title: 'Người tạo', dataIndex: 'created_by', align: 'center', width: '100px', render: (_, record) => record?.created_by?.name }
  ];

  const columnsEntry = [
    { title: 'STT', dataIndex: 'stt', align: 'left', width: '70px', render: (_, record, index) => ((paginationEntry.page - 1) * paginationEntry.pageSize) + index + 1 },
    // { title: 'Vị trí', dataIndex: 'warehouse_location_id', align: 'left', width: '80px' },
    { title: 'Mã thành phẩm', dataIndex: 'product_id', align: 'left', width: '100px' },
    { title: 'Tên thành phẩm', dataIndex: 'product_name', align: 'left', width: '150px' },
    { title: 'Số lượng', dataIndex: 'total_quantity', align: 'left', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Đơn vị tính', dataIndex: 'unit_name', align: 'left', width: '100px' },
  ];

  const handleHistoryExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/warehouse-histories-export', { params: { model: 'lot' } });
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  const handleInventoryExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/warehouse-entries-export', { params: { model: 'lot' } });
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  const getCalculateHeight = () => {
    try {
      const layoutHeight = document.querySelector(".site-layout-content");
      const tabHeight = document.querySelector(`.tab-pane`);
      if (tabHeight && layoutHeight?.offsetHeight) {
        const tabNavHeight = tabHeight?.querySelector(".ant-tabs-nav")?.offsetHeight ?? 0;
        const sectionTopHeight = tabHeight?.querySelector(".section-top")?.offsetHeight ?? 0;
        const tableTab = tabHeight?.querySelector(`.table-tab`);
        const antTableHeader = tableTab?.querySelector(".ant-table-header");
        const antTableBody = tableTab?.querySelector(".ant-table-body");
        const antTableEmpty = tableTab?.querySelector(".ant-empty-normal");
        const result = layoutHeight?.offsetHeight - tabNavHeight - sectionTopHeight - (antTableHeader?.offsetHeight ?? 0) - 32 - 64 - 42 - 32 - 16;
        console.log(result);
        if (antTableBody) antTableBody?.style.setProperty("height", `${result}px`, "important");
        if (antTableEmpty) antTableEmpty?.style.setProperty("height", `${result - 114}px`);
        return result;
      }
      return undefined;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  return (
    <Tabs defaultActiveKey="1" type="card" style={{ width: '100%' }} className='custom-oi-tabs' animated={false} moreIcon={null}>
      <TabPane tab="Lịch sử nhập/xuất kho thành phẩm" key="1" className='tab-pane'>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" icon={<DownloadOutlined />} onClick={handleHistoryExportExcel}>Xuất file lịch sử kho</Button>
          </div>
          <Table
            loading={loading}
            className="table-tab"
            columns={columns}
            dataSource={data.map((e, i) => ({ ...e, key: i }))}
            bordered
            pagination={{
              current: pagination.page,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: (page, pageSize) => {
                setPagination({ ...pagination, page, pageSize });
                fetchData({ ...filters, page, pageSize });
              }
            }}
            scroll={{ x: "1630px", y: getCalculateHeight() }}
            size="small"
          />
        </Space>
      </TabPane>
      <TabPane tab="Tồn kho thành phẩm" key="2">
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" icon={<DownloadOutlined />} onClick={handleInventoryExportExcel}>Xuất file tồn kho</Button>
          </div>
          <Table
            loading={loading}
            columns={columnsEntry}
            dataSource={dataEntry.map((e, key) => ({ ...e, key }))}
            bordered
            pagination={{
              ...paginationEntry,
              onChange: (page, pageSize) => {
                setPagination({ ...paginationEntry, page, pageSize });
                fetchWarehouseEntry({ page, pageSize });
              },
            }}
            scroll={{ x: '500px', y: getCalculateHeight() }}
            size="small"
          />
        </Space>
      </TabPane>
    </Tabs>
  );
};

export default FinishedProductWarehouseTable;
