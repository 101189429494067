// src/pages/UI/POPlan/POPlan.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import POPlanSidebar from '../ProductionPlanSidebar';
import POPlanTable from './POTable';

const { Content } = Layout;

const POPlan = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  useEffect(()=>{
    console.log(filters);
  }, [filters])
  return (
    <Layout>
      <POPlanSidebar onFilter={handleFilter}/>
      <Layout>
        <Content className="site-layout-content">
          <POPlanTable />
        </Content>
      </Layout>
    </Layout>
  );
};

export default POPlan;
