import { CloseOutlined } from '@ant-design/icons';
import { Button, Layout, Space, Table } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config/config';
import SketchfabModel from './Sketchfab/SketchfabModel';

const Simulator = () => {
    const navigate = useNavigate();
    const [logo, setLogo] = useState(config.logo);

    const getLogoFromLocalStorage = () => {
        const conf = localStorage.getItem('company_config');
        if (conf) {
            const logoUrl = JSON.parse(conf)?.logo ?? null;
            if (logoUrl && logoUrl?.includes('logo.'))
                setLogo(`${config.apiUrl.replace('/api', '')}${logoUrl}`);
        }
    }

    useState(() => {
        getLogoFromLocalStorage();
    }, []);

    const layoutStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        position: 'relative', // Add this line
    };

    const tableStyle = {
        position: 'absolute',
        top: '65px',
        right: '0',
        width: '350px',
    };

    const dataSource = [
        {
            key: '1',
            congdong: 'Máy trộn',
            hangmuc: 'Nhiệt độ (tùy NVL)',
            dieukien: '<99 ~ 125°C',
            rowSpan: 2,
        },
        {
            key: '2',
            hangmuc: 'Tốc độ cánh trộn',
            dieukien: '25 ~ 50Hz',
            rowSpan: 0,
        },
        {
            key: '3',
            congdong: 'Máy đùn',
            hangmuc: 'Nhiệt độ',
            dieukien: '±15°C',
            rowSpan: 3,
        },
        {
            key: '4',
            congdong: '',
            hangmuc: 'Áp suất',
            dieukien: '>=-0.08mpa',
            rowSpan: 0,
        },
        {
            key: '5',
            congdong: '',
            hangmuc: 'Bể nước',
            dieukien: '<55°C',
            rowSpan: 0,
        },
    ];

    const columns = [
        {
            title: 'Công đoạn',
            dataIndex: 'congdong',
            key: 'congdong',
            width: '120px',
            render: (text, _, index) => {
                const obj = {
                    children: text,
                    props: {},
                };
                if (dataSource[index].rowSpan) {
                    obj.props.rowSpan = dataSource[index].rowSpan;
                } else {
                    obj.props.rowSpan = 0;
                }
                return obj;
            },
        },
        {
            title: 'Hạng mục',
            dataIndex: 'hangmuc',
            key: 'hangmuc',
            width: '120px',
        },
        {
            title: 'Điều kiện',
            dataIndex: 'dieukien',
            key: 'dieukien',
            width: '100px',
        },
    ];

    return (
        <Layout>
            <Header className="oi-header">
                <div className="oi-logo">
                    <img src={logo} alt="logo" />
                </div>
                <div className="oi-title">Dây chuyền sản xuất</div>
                <Space>
                    <Button className="oi-close-button" icon={<CloseOutlined />} onClick={() => navigate('/')} />
                </Space>
            </Header>
            <Layout style={layoutStyle}>
                {/* <iframe title="MACHINE" width="100%" height="100%" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/adb54161c84340669f2aaf159e9d31c9/embed"> </iframe> */}
                {/* <iframe title="Dây chuyền sản xuất hạt nhựa 02" width="100%" height="100%" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/d65055132c0744afbb088e0930365388/embed"> </iframe> */}
                
                <SketchfabModel modelUid="d65055132c0744afbb088e0930365388" />
                {/* <ExternalObjects objects={externalObjects} onSelect={handleSelectObject} /> */}
                <Table size="small" bordered dataSource={dataSource} columns={columns} style={tableStyle} scroll={{ x: "340px" }} pagination={false} />
            </Layout>
        </Layout>
    )
}

export default Simulator