import React, { useEffect, useState } from 'react';
import { Layout, Table, Space, DatePicker, message, Button, Modal, Input, Form, Radio, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useOutletContext } from 'react-router-dom';
import './OIQualityIQC.css';
import axiosInstance from '../../../../api/axiosInstance';
import config from '../../../../config/config';
import { numberFormat } from '../../../../utils/numberFormat';

const { Content } = Layout;

const OIQualityIQC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const context = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [inspectionType, setInspectionType] = useState('');
  const [criteria, setCriteria] = useState([]);
  const [buttonStatus, setButtonStatus] = useState({});
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [materialReceiptItems, setMaterialReceiptItems] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [dataTop, setDataTop] = useState([]);

  useEffect(() => {
    if (context && context.setTitle) {
      context.setTitle('QUẢN LÝ IQC');
    }
  }, [context]);

  useEffect(() => {
    fetchMaterialReceiptItem();
    fetchMaterialReceiptItemSummary();
  }, [startDate, endDate]);

  const fetchMaterialReceiptItem = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(config.apiUrl + '/material-receipt-items/search', {
        params: {
          start_date: dayjs(startDate).format('YYYY-MM-DD'),
          end_date: dayjs(endDate).format('YYYY-MM-DD'),
        }
      });
      setMaterialReceiptItems(response.data);
    } catch (error) {
      message.error('Failed to fetch criteria');
    } finally {
      setLoading(false);
    }
  };

  const fetchCriteria = async (category) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(config.apiUrl + '/inspection-criteria/search', {
        params: {
          type: 'IQC',
          relationship_id: selectedRow?.material_id,
          // model: 'Material',
          // category: category
        }
      });
      const criteriaData = response.data || [];
      setCriteria(criteriaData);
      const initialButtonStatus = {};
      criteriaData.forEach(item => {
        initialButtonStatus[item.id] = { status: 'OK/NG', bg: 'white', color: 'black' };
      });
      setButtonStatus(initialButtonStatus);
      const initialValues = {};
      criteriaData.forEach(item => {
        initialValues[item.id] = item.acceptablerange === 'OK/NG' ? '' : '';
      });
      form.setFieldsValue(initialValues);
    } catch (error) {
      message.error('Failed to fetch criteria');
    } finally {
      setLoading(false);
    }
  };

  const fetchMaterialReceiptItemSummary = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/material-receipt-items-summary', {
      params: {
        start_date: dayjs(startDate).format('YYYY-MM-DD'),
        end_date: dayjs(endDate).format('YYYY-MM-DD'),
      }
    });
    if (response?.success) {
      const { total, percent_ng, ok, ng } = response.data;
      setDataTop([{ total, percent_ng, ok, ng }]);
    }
  }

  const showModal = (type) => {
    if (selectedRow?.material_id) {
      setInspectionType(type);
      setIsModalVisible(true);
      fetchCriteria(type === 'Kiểm tra chỉ tiêu' ? 'tinh_nang' : 'ngoai_quan');
    } else message.info('Không có phiếu nào để kiểm tra!');
  };

  const handleResult = (result) => {
    form.validateFields().then(async (values) => {
      setLoading(true);
      const formData = Object.entries(values).map(([id, value]) => {
        const criterion = criteria.find(item => item.id === parseInt(id));
        const type = determineTypeInput(criterion.acceptablerange);
        if (type === 1) {
          return {
            inspection_criteria_id: id,
            measurement: value,
            result: buttonStatus[id]?.status
          };
        } else if (type === 2) {
          return {
            inspection_criteria_id: id,
            measurement: value,
            result: value
          };
        } else {
          return {
            inspection_criteria_id: id,
            measurement: value,
            result: ''
          };
        }
      });
      const inputData = {
        material_receipt_item_id: selectedRow?.material_receipt_item_id,
        items: formData,
        result: result
      };
      await axiosInstance.post(config.apiUrl + '/quality-checks', inputData);
      setIsModalVisible(false);
      form.resetFields();
      fetchMaterialReceiptItem();
      setLoading(false);
    }).catch(errorInfo => {
      console.log('Validate Failed:', errorInfo);
      setLoading(false);
    });
  };

  const handleInputChange = (value, id, range) => {
    if (value === '') {
      setButtonStatus(prevStatus => ({
        ...prevStatus,
        [id]: {
          status: 'OK/NG',
          bg: 'white',
          color: 'black'
        }
      }));
    } else {
      const isValid = checkMeasurement(parseFloat(value), range);
      setButtonStatus(prevStatus => ({
        ...prevStatus,
        [id]: {
          status: isValid ? 'OK' : 'NG',
          bg: isValid ? 'green' : 'red',
          color: 'white'
        }
      }));
    }
  };

  const checkMeasurement = (measurement, acceptableRange) => {
    acceptableRange = acceptableRange.trim();
    measurement = parseFloat(measurement);
    acceptableRange = acceptableRange.replace(/≤/g, '<=').replace(/≥/g, '>=');
    const regex = /^(<=|>=|>|<|=|==)?\s*(\d+(\.\d+)?)\s*(\w+)?$/;

    if (regex.test(acceptableRange)) {
      const matches = acceptableRange.match(regex);
      const comparisonType = matches[1];
      const compareValue = parseFloat(matches[2]);

      switch (comparisonType) {
        case '<=':
          return measurement <= compareValue;
        case '>=':
          return measurement >= compareValue;
        case '>':
          return measurement > compareValue;
        case '<':
          return measurement < compareValue;
        case '=':
        case '==':
          return measurement === compareValue;
        default:
          return false;
      }
    }

    return false;
  };

  const determineTypeInput = (acceptablerange) => {
    if (acceptablerange === null) return 3;
    if (acceptablerange === 'OK/NG') return 2;
    return 1;
  };

  const handleChange = (id, value) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const renderInput = (id, description, range) => {
    const type = determineTypeInput(range);

    switch (type) {
      case 1:
        return (
          <Form.Item label={description} key={id} style={{ marginBottom: '12px' }}>
            <Input.Group compact>
              <Form.Item
                name={id}
                noStyle
                rules={[{ required: true, message: 'Dữ liệu không được để trống !' }]}
              >
                <Input
                  style={{ width: 'calc(100% - 80px)' }}
                  placeholder="Nhập giá trị"
                  onChange={(e) => handleInputChange(e.target.value, id, range)}
                />
              </Form.Item>
              <Button style={{ width: '80px', backgroundColor: buttonStatus[id]?.bg || 'white', color: buttonStatus[id]?.color || 'black' }}>
                {buttonStatus[id]?.status || 'OK/NG'}
              </Button>
            </Input.Group>
          </Form.Item>
        );
      case 2:
        return (
          <Form.Item
            label={description}
            key={id}
            name={id}
            style={{ marginBottom: '12px' }}
          >
            <Radio.Group
              style={{ textAlign: 'left' }}
              name={id}
              optionType="button"
              buttonStyle="solid"
              onChange={e => handleChange(id, e.target.value)}
              className={selectedValues[id] === 'NG' ? 'red-radio' : ''}
            >
              <Radio.Button value="OK">OK</Radio.Button>
              <Radio.Button value="NG">NG</Radio.Button>
            </Radio.Group>
          </Form.Item>
        );
      case 3:
        return (
          <Form.Item
            style={{ marginBottom: '12px' }}
            name={id}
            label={description}
            rules={[{ required: true, message: 'Dữ liệu không được để trống !' }]}
            key={id}
          >
            <Input placeholder="Nhập giá trị" />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const columnsTop = [
    { title: 'Công đoạn', dataIndex: 'process', align: 'center', width: '85px', render: () => 'IQC' },
    { title: 'Tổng phiếu', dataIndex: 'total', align: 'center', width: '80px' },
    { title: 'SL kiểm', dataIndex: 'ok', align: 'center', width: '70px' },
    { title: 'NG', dataIndex: 'ng', align: 'center', width: '50px' },
    { title: 'Tỉ lệ NG', dataIndex: 'percent_ng', align: 'center', width: '100px' },
  ];

  const columnsMiddle = [
    { title: 'Mã phiếu', dataIndex: 'material_receipt_id', align: 'center' },
    { title: 'Mã NVL', dataIndex: 'material_id', align: 'center' },
    { title: 'Số lượng', dataIndex: 'quantity', align: 'center', render: (value) => numberFormat(value) },
    { title: 'Kết quả kiểm', dataIndex: 'result', align: 'center' },
  ];

  const columnsBottom = [
    { title: 'Số phiếu', dataIndex: 'material_receipt_id', align: 'center', width: '90px' },
    { title: 'Mã NVL', dataIndex: 'material_id', align: 'center', width: '90px' },
    { title: 'Nhà cung cấp', dataIndex: '', align: 'center', width: '120px', render: (text, record) => record.supplier?.supplier_name },
    { title: 'Số lượng', dataIndex: 'quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
    { title: 'ĐVT', dataIndex: 'unit', align: 'center', width: '80px', render: (text, record) => record.material?.unit?.unit_name },
    { title: 'Kết quả kiểm', dataIndex: 'result', align: 'center', width: '120px', render: (text, record) => record.quality_check?.result ?? 'Chưa kiểm tra' },
  ];


  const handleRowClick = (record) => {
    setSelectedRow({
      material_receipt_item_id: record.id,
      material_receipt_id: record.material_receipt_id,
      material_id: record.material_id,
      quantity: record.quantity,
      result: record?.quality_check?.result ?? 'Chưa kiểm tra'
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const rowClassName = (record) => {
    switch (record?.quality_check?.result) {
      case 'NG':
        return 'table-row-ng';
      default:
        return "";
    }
  }

  return (
    <Content className="oi-content">
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Table
          className="custom-table"
          dataSource={dataTop}
          columns={columnsTop}
          pagination={false}
          size="small"
          bordered
          loading={loading}
          scroll={{ x: 500 }}
        />
        <Row gutter={[8, 8]}>
          <Col span={20} xs={24} md={20}>
            <Table
              locale={{ emptyText: 'Không có dữ liệu' }}
              className="custom-table"
              dataSource={selectedRow ? [selectedRow] : []}
              columns={columnsMiddle}
              pagination={false}
              size="small"
              bordered
              loading={loading}
            />
          </Col>
          <Col span={4} xs={24} md={4}>
            <Button style={{ width: '100%', height: '100%', fontSize: '16px' }} type="primary" onClick={() => showModal('Kiểm tra chỉ tiêu')}>
              <span style={{ textWrap: 'balance' }}>Kiểm tra chỉ tiêu</span>
            </Button>
          </Col>
        </Row>
        <div className="oi-select-row" style={{ gap: '5px' }}>
          <DatePicker defaultValue={dayjs()} format="DD-MM-YYYY" onChange={(date) => { setStartDate(date) }} />
          <DatePicker defaultValue={dayjs()} format="DD-MM-YYYY" onChange={(date) => { setEndDate(date) }} />
        </div>
      </Space>

      <Table
        rowClassName={rowClassName}
        dataSource={materialReceiptItems}
        columns={columnsBottom}
        pagination={false}
        scroll={{ x: '600px', y: 400 }}
        size="small"
        bordered
        loading={loading}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />

      <Modal
        title={`${inspectionType}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={() => { handleResult('OK') }}>
            OK
          </Button>,
          <Button key="save" type="primary" onClick={() => { handleResult('NG') }} danger>
            NG
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Hủy
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          {criteria.length > 0 && criteria.map(item => renderInput(item.id, item.criteria_name, item.acceptablerange))}
        </Form>
      </Modal>
    </Content>
  );
};

export default OIQualityIQC;
