import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Space, message, Tabs, Tag, Checkbox } from 'antd';
import config from '../../../../config/config';
import dayjs from 'dayjs';
import { getWarehouseHistories } from '../../../../api/warehouseHistoryApi';
import TabPane from 'antd/es/tabs/TabPane';
import warehouseEntryManagementApi from '../../../../api/warehouseEntryManagementApi';
import { DownloadOutlined } from '@ant-design/icons';
import axiosInstance from '../../../../api/axiosInstance';
import { b64toBlob, downloadExcel } from '../../../../utils/dataBlob';
import { numberFormat } from '../../../../utils/numberFormat';

const MaterialWarehouseTable = ({ filters }) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const [paginationEntry, setPaginationEntry] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });

  const [data, setData] = useState([]);
  const [dataEntry, setDataEntry] = useState([]);

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    const paginateEntry = { ...paginationEntry, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
    fetchWarehouseEntry({ ...filters, ...paginateEntry });
  }, [filters]);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      filters.model = 'pallet';
      if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
      if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');

      const response = await getWarehouseHistories(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data.data ?? []);
      response.data?.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchWarehouseEntry = async (filters) => {
    try {
      filters.model = 'pallet';
      const response = await warehouseEntryManagementApi.getWarehouseEntriesInventory(filters); // Thay đổi endpoint theo API của bạn
      setDataEntry(response.data ?? []);
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: 'Xác nhận',
      dataIndex: 'confirm',
      key: 'confirm',
      align: 'center',
      width: 70,
      render: (text, record) => (
        <Checkbox
          checked={record?.confirm === 1}
          onChange={(e) => {console.log(e.target.checked); handleStatusChange(record.id, e.target.checked)}}
        />
      ),
    },
    { title: 'STT', dataIndex: 'stt', align: 'center', width: '50px', render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1 },
    { title: 'Loại', dataIndex: 'type', align: 'center', width: '80px', render: (value) => value === 'export' ? <Tag color="#2DB7F5">XUẤT</Tag> : <Tag color="#F50">NHẬP</Tag> },
    { title: 'Vị trí', dataIndex: 'warehouse_location_id', align: 'center', width: '80px' },
    { title: 'Mã Pallet', dataIndex: 'warehousehistoryable_id', align: 'center', width: '150px' },
    { title: 'Tên NVL', dataIndex: 'material_name', align: 'center', width: '150px', render: (_, record) => record?.pallet?.material?.material_name },
    { title: 'Số lượng', dataIndex: 'quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Tồn', dataIndex: 'inventory_quantity', align: 'center', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Đơn vị tính', dataIndex: 'unit_name', align: 'center', width: '100px', render: (_, record) => record?.unit?.unit_name },
    { title: 'Thời gian', dataIndex: 'created_at', align: 'center', width: '100px' },
    { title: 'Người tạo', dataIndex: 'created_by', align: 'center', width: '100px', render: (_, record) => record?.created_by?.name }
  ];

  const columnsEntry = [
    { title: 'STT', dataIndex: 'stt', align: 'left', width: '50px', render: (_, record, index) => index + 1 },
    // { title: 'Vị trí', dataIndex: 'warehouse_location_id', align: 'left', width: '80px' },
    { title: 'Mã NVL', dataIndex: 'material_id', align: 'left', width: '100px' },
    { title: 'Tên NVL', dataIndex: 'material_name', align: 'left', width: '150px' },
    { title: 'Số lượng', dataIndex: 'total_quantity', align: 'left', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Đơn vị tính', dataIndex: 'unit_name', align: 'left', width: '100px' },
  ];

  const handleStatusChange = async (id, checked) => {
    if (checked) {
      setLoading(true);
      const status = checked ? 1 : 0;
      const response = await axiosInstance.put(config.apiUrl + `/warehouse-histories/${id}`, { confirm: status });
      if (response?.success) {
        const newData = data.map(item => {
          if (item.id === id) {
            return { ...item, confirm: status };
          }
          return item;
        });
        setData(newData);
      } else message.error('CẬP NHẬT THẤT BẠI');
      setLoading(false);
    }
  };

  const handleHistoryExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/warehouse-histories-export/entry-production');
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        downloadExcel(data, type, file);
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  const handleWarehouseEntryExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/warehouse-histories-export/entry-summary');
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        downloadExcel(data, type, file);
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  const handleInventorySummaryExportExcel = async () => {
    setLoading(true);
    if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
    if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');
    const response = await axiosInstance.get(config.apiUrl + '/warehouse-histories-export/inventory-summary', { params: filters });
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        downloadExcel(data, type, file);
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  const handleInventoryExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/warehouse-entries-export', { params: { model: 'pallet' } });
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        downloadExcel(data, type, file);
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  const getCalculateHeight = () => {
    try {
      const layoutHeight = document.querySelector(".site-layout-content");
      const tabHeight = document.querySelector(`.tab-pane`);
      if (tabHeight && layoutHeight?.offsetHeight) {
        const tabNavHeight = tabHeight?.querySelector(".ant-tabs-nav")?.offsetHeight ?? 0;
        const sectionTopHeight = tabHeight?.querySelector(".section-top")?.offsetHeight ?? 0;
        const tableTab = tabHeight?.querySelector(`.table-tab`);
        const antTableHeader = tableTab?.querySelector(".ant-table-header");
        const antTableBody = tableTab?.querySelector(".ant-table-body");
        const antTableEmpty = tableTab?.querySelector(".ant-empty-normal");
        const result = layoutHeight?.offsetHeight - tabNavHeight - sectionTopHeight - (antTableHeader?.offsetHeight ?? 0) - 32 - 64 - 42 - 32 - 16;
        console.log(result);
        if (antTableBody) antTableBody?.style.setProperty("height", `${result}px`, "important");
        if (antTableEmpty) antTableEmpty?.style.setProperty("height", `${result - 114}px`);
        return result;
      }
      return undefined;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  return (
    <Tabs defaultActiveKey="1" type="card" style={{ width: '100%' }} className='custom-oi-tabs'
    // tabBarExtraContent={
    //   <Space>
    //     <Button type="primary" icon={<DownloadOutlined />}>
    //       Xuất Excel
    //     </Button>
    //   </Space>
    // }
    >
      <TabPane tab="Lịch sử nhập/xuất kho NVL" key="1" className="tab-pane">
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Space>
              <Button type="primary" icon={<DownloadOutlined />} onClick={handleWarehouseEntryExcel}>Báo cáo nhập kho</Button>
              <Button type="primary" icon={<DownloadOutlined />} onClick={handleHistoryExportExcel}>Báo cáo nhập,xuất SX</Button>
              <Button type="primary" icon={<DownloadOutlined />} onClick={handleInventorySummaryExportExcel}>Báo cáo nhập,xuất,tồn</Button>
            </Space>
          </div>
          <Table
            className='table-tab'
            loading={loading}
            columns={columns}
            dataSource={data.map((e, key) => ({ ...e, key }))}
            bordered
            pagination={{
              current: pagination.page,
              pageSize: pagination.pageSize,
              total: pagination.total,
              onChange: (page, pageSize) => {
                setPagination({ ...pagination, page, pageSize });
                fetchData({ ...filters, page, pageSize });
              }
            }}
            scroll={{ x: '900px', y: getCalculateHeight() }}
            size="small"
          />
        </Space>
      </TabPane>
      <TabPane tab="Tồn kho NVL" key="2">
        <Space direction="vertical" size="middle" style={{ display: 'flex', height: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" icon={<DownloadOutlined />} onClick={handleInventoryExportExcel}>Xuất file tồn kho</Button>
          </div>
          <Table
            className="table-mh70"
            loading={loading}
            columns={columnsEntry}
            dataSource={dataEntry.map((e, key) => ({ ...e, key }))}
            bordered
            pagination={{
              ...paginationEntry,
              onChange: (page, pageSize) => {
                setPagination({ ...paginationEntry, page, pageSize });
                fetchWarehouseEntry({ page, pageSize });
              },
            }}
            scroll={{ x: '600px', y: getCalculateHeight() }}
            size="small"
          />
        </Space>
      </TabPane>
    </Tabs>
  );
};

export default MaterialWarehouseTable;
