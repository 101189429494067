import React, { useEffect, useState } from 'react';
import { Table, Button, Card, Space, message, Tag } from 'antd';
import config from '../../../../config/config';
import dayjs from 'dayjs';
import materialReceiptApi from '../../../../api/materialReceiptApi';
import { numberFormat } from '../../../../utils/numberFormat';
import axiosInstance from '../../../../api/axiosInstance';
import { b64toBlob } from '../../../../utils/dataBlob';
import { DownloadOutlined } from '@ant-design/icons';

const IQCTable = ({ filters }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: config.pageSize,
    total: 0,
  });
  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      width: '50px',
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1
    },
    {
      title: 'Mã NVL',
      dataIndex: 'material_id',
      key: 'material_id',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Tên NVL',
      dataIndex: 'material',
      key: 'material',
      align: 'center',
      width: '150px',
      render: (value) => value?.material_name,
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'left',
      width: '300px',
      render: (value) => value?.supplier_name,
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      width: '100px',
      render: (value) => numberFormat(value),
    },
    {
      title: 'Đơn vị tính',
      dataIndex: 'unit_name',
      key: 'unit_name',
      align: 'center',
      width: '100px',
      render: (_, record) => record?.material?.unit?.unit_name,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: '100px',
      render: (_, record) => record?.material_receipt?.receipt_date,
    },
    {
      title: 'IQC',
      dataIndex: 'iqc',
      key: 'iqc',
      align: 'center',
      width: '100px',
      render: (_, record) => {
        switch (record?.quality_check?.result) {
          case 'OK':
            return <Tag color='#2d991f'>OK</Tag>
          case 'NG':
            return <Tag color='#ff4d4f'>NG</Tag>
          default:
            return <Tag color='#ffae00'>Chưa kiểm</Tag>
        }
      }
    },
  ];

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      filters.quality_check = true;
      if (filters?.start_date) filters.start_date = dayjs(filters.start_date).format('YYYY-MM-DD');
      if (filters?.end_date) filters.end_date = dayjs(filters.end_date).format('YYYY-MM-DD');

      const response = await materialReceiptApi.getMaterialReceiptItems(filters); // Thay đổi endpoint theo API của bạn
      setData(response.data.data ?? []);
      response.data?.pagination && setPagination(response.data.pagination)
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch material-receipts!');
    }
  };

  useEffect(() => {
    const paginate = { ...pagination, page: 1 };
    setPagination(paginate)
    fetchData({ ...filters, ...paginate });
  }, [filters]);

  const handleExportExcel = async () => {
    setLoading(true);
    const response = await axiosInstance.get(config.apiUrl + '/quality-export/iqc', {params: filters});
    if (response?.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        const blob = b64toBlob(data, type)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <Card
      title="Lịch sử kiểm tra IQC"
      extra={
        <Space>
          <Button type="primary" icon={<DownloadOutlined />} onClick={handleExportExcel} loading={loading}>Xuất file</Button>
        </Space>
      }
    >
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
        <Table
          className="table-mh70"
          columns={columns}
          dataSource={data}
          bordered
          pagination={{
            ...pagination,
            onChange: (page, pageSize) => {
              setPagination({ ...pagination, page, pageSize });
              fetchData({ page, pageSize });
            },
          }}
          scroll={{ x: 1000, y: "67vh" }}
          size='small'
        />
      </div>
    </Card>
  );
};

export default IQCTable;
