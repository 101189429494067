// src/pages/UI/AccountManagement/AccountTable.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import axiosInstance from '../../../api/axiosInstance'; // Import axiosInstance
import './AccountTable.css';
import { useReactToPrint } from 'react-to-print';
import accountApi from "../../../api/accountManagementApi";
import departmentApi from "../../../api/departmentManagementApi";
import config from '../../../config/config';
import roleManagementApi from '../../../api/roleManagementApi';
import AuthContext from '../../../context/AuthContext';

const AccountTable = ({ filters }) => {
    const { user } = useContext(AuthContext);
    const [permissions, setPermissions] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [form] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState(null);
    const [data, setData] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const componentRef = useRef();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: config.pageSize,
        total: 0,
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
    }, []);

    useEffect(() => {
        const paginate = { ...pagination, page: 1 };
        setPagination(paginate);
        // fetchDepartment();
        fetchRole();
        fetchData({ ...filters, ...paginate });
    }, [filters]);

    useEffect(() => {
        if (htmlContent) {
            handlePrint();
        }
    }, [htmlContent]);

    const fetchData = async (filters) => {
        try {
            setLoading(true);
            const response = await accountApi.getAccounts(filters); // Thay đổi endpoint theo API của bạn
            const resource = await (response.data.data ?? []).map((record, key) => {
                const department_id = record?.departments[0] ? record?.departments[0]?.id : null
                return { key, department_id, ...record }
            })
            setData(resource);
            response.data?.pagination && setPagination(response.data.pagination)
            setLoading(false);
        } catch (error) {
            message.error('Failed to fetch accounts');
        }
    };

    const fetchDepartment = async () => {
        try {
            const response = await departmentApi.getDepartments();
            await setDepartmentOptions((response.data.data ?? []).map((item) => {
                return { label: item?.name, value: item?.id };
            }));
        } catch (error) {
            message.error('Failed to fetch department');
        }
    }

    const fetchRole = async () => {
        try {
            const response = await roleManagementApi.getRoles();
            await setRoleOptions((response.data.data ?? []).map((item) => {
                return { label: item?.name, value: item?.id };
            }));
        } catch (error) {
            message.error(error.message);
        }
    }

    const showModal = () => {
        setIsEditMode(false);
        setEditingRecord(null);
        setIsModalVisible(true);
    };

    const showEditModal = (record) => {
        setIsEditMode(true);
        setEditingRecord(record);
        form.setFieldsValue(record);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        form.validateFields().then(async (values) => {
            try {
                if (isEditMode) {
                    const response = await accountApi.updateAccount(editingRecord.id, values); // Thay đổi endpoint theo API của bạn
                    if (response.success) {
                        // setData((prevData) => [{ ...values, id: editingRecord.id }, ...prevData.filter(item => item.id !== editingRecord.id)]);
                        await fetchData(pagination);
                    } else throw new Error('Action failure');
                } else {
                    console.log(values);
                    const response = await accountApi.createAccount(values); // Thay đổi endpoint theo API của bạn
                    if (response.success) {
                        // setData((prevData) => [{ ...values, id: response.data.id }, ...prevData]);
                        setPagination({ ...pagination, total: pagination.total++ })
                        await fetchData(pagination);
                    } else throw new Error('Action failure');
                }
                setIsModalVisible(false);
                form.resetFields();
            } catch (error) {
                message.error('Failed to save product');
            }
        }).catch((error) => { console.log(error) });
    };
    const handleDelete = async (id) => {
        try {
            await accountApi.deleteAccount(id); // Thay đổi endpoint theo API của bạn
            setData((prevData) => prevData.filter(item => item.id !== id));
            // message.success('Xóa tài khoản thành công');
        } catch (error) {
            message.error('Failed to delete equipment');
        }
    };
    const printRecord = async (record) => {
        const res = accountApi.getProductById(record.id);
        if (res.success) {
            setHtmlContent(res.data);
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    const handleUploadChange = (info) => {
        try {
            if (info.file.status === 'uploading') {
                setUploading(true);
            } else {
                if (info.file.status === 'done') {
                    if (!info.file?.response?.success)
                        throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`);

                    fetchDepartment();
                    fetchData({ ...filters, ...pagination });
                    message.success(`${info.file.name} - Upload thành công`);
                } else if (info.file.status === 'error') {
                    message.error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`);
                }
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setUploading(false);
        }
    };

    const uploadProps = {
        name: 'file',
        accept: '.xlsx, .xls',
        action: config.apiUrl + '/import-users', // Đường dẫn API để xử lý upload file
        onChange: handleUploadChange,
        showUploadList: false, // Ẩn danh sách file
    };

    const columns = [
        {
            title: 'STT',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
            render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
        },
        {
            title: 'Mã tài khoản',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: 'Tên đăng nhập',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
        },
        {
            title: 'Phòng ban',
            dataIndex: 'department_name',
            key: 'department_name',
            align: 'center',
            render: (value, record) => {
                return record?.departments[0]?.name
            }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {permissions.includes('user.update') && (<Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
                    {permissions.includes('user.delete') && (<Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
                    </Popconfirm>)}
                </Space>
            ),
            align: 'center',
        },
    ];
    return (
        <Card
            title="Quản lý tài khoản"
            extra={
                <div className="table-actions">
                    {permissions.includes('user.create') && (<>
                        <Button target="_blank" href={config.apiUrl.replace('/api', '') + '/excel/Users.xlsx'} icon={<DownloadOutlined />}>Tải excel mẫu</Button>
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                                {uploading ? 'Đang tải lên' : 'Upload Excel'}
                            </Button>
                        </Upload>
                        <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm tài khoản</Button>
                    </>)}
                </div>
            }
        >
            <Table
                loading={loading}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
                pagination={{
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: (page, pageSize) => {
                        setPagination({ ...pagination, page, pageSize });
                        fetchData({ ...filters, page, pageSize });
                    }
                }}
                bordered  // Thêm thuộc tính bordered để có viền
                size="small"  // Sử dụng kích thước bảng nhỏ
            />
            <div ref={componentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
            <Modal
                title={
                    <div className="modal-title">
                        {isEditMode ? "Chỉnh sửa tài khoản" : "Thêm tài khoản"}
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Lưu lại"
                cancelText="Thoát"
                width={800}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <div className="modal-form-grid">
                        {/* <Form.Item name="department_id" label="Phòng ban" rules={[{ required: true, message: 'Vui lòng chọn phòng ban!' }]}>
                            <Select showSearch allowClear placeholder="Chọn phòng ban" options={departmentOptions} optionFilterProp='label' />
                        </Form.Item> */}
                        <Form.Item name="role_id" label="Chức vụ" rules={[{ required: true, message: 'Vui lòng chọn chức vụ!' }]}>
                            <Select showSearch allowClear placeholder="Chọn chức vụ" options={roleOptions} optionFilterProp='label' />
                        </Form.Item>
                        <Form.Item name="name" label="Tên tài khoản" rules={[{ required: true, message: 'Vui lòng nhập tên tài khoản!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Vui lòng nhập Email!' }, { type: 'email', message: 'Email không hợp lệ!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="username" label="Tên đăng nhập" rules={[{ required: true, message: 'Vui lòng nhập tên đăng nhập!' }]}>
                            <Input />
                        </Form.Item>
                        {!isEditMode && (
                            <Form.Item name="password" label="Mật khẩu" rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }, { min: 8, message: 'Mật khẩu ít nhất 8 ký tự!' }]}>
                                <Input.Password />
                            </Form.Item>
                        )}
                    </div>
                </Form>
            </Modal>
        </Card>
    );
};

export default AccountTable;
