// src/pages/UI/PermissionManagement/PermissionManagement.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import PermissionSidebar from './PermissionSidebar';
import PermissionTable from './PermissionTable';

const { Header, Content } = Layout;

const PermissionManagement = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  // useEffect(()=>{
  //   console.log(filters);
  // }, [filters])
  return (
      <Layout>
         <PermissionSidebar onFilter={handleFilter}/>
        <Content className="site-layout-content">
          <PermissionTable filters={filters}/>
        </Content>
      </Layout>
  );
};

export default PermissionManagement;
