import React from 'react';
import { Layout } from 'antd';
import { HomeOutlined, StarOutlined, ToolOutlined, AppstoreOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import './OIFooter.css';

const { Footer } = Layout;

const OIFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { key: '/oi/production', icon: <HomeOutlined />, label: 'Sản xuất' },
    { key: '/oi/quality-dd', icon: <StarOutlined />, label: 'Đầu đơn' },
    { key: '/oi/quality-iqc', icon: <StarOutlined />, label: 'IQC' },
    { key: '/oi/quality-pqc', icon: <StarOutlined />, label: 'PQC' },
    { key: '/oi/quality-oqc', icon: <StarOutlined />, label: 'OQC' },
    { key: '/oi/equipment', icon: <ToolOutlined />, label: 'Thiết bị' },
    { key: '/oi/material-warehouse-import', icon: <AppstoreOutlined />, label: 'Kho NVL' },
    { key: '/oi/semi-finished-product-warehouse', icon: <AppstoreOutlined />, label: 'Kho BTP' },
    { key: '/oi/finished-product-warehouse', icon: <AppstoreOutlined />, label: 'Kho TP' },
  ];

  const handleTabClick = (key) => {
    navigate(key);
  };

  return (
    <Footer className="oi-footer">
      {tabs.map((tab) => (
        <div
          key={tab.key}
          className={`oi-footer-tab ${location.pathname === tab.key ? 'active' : ''}`}
          onClick={() => handleTabClick(tab.key)}
        >
          {tab.icon}
          <div>{tab.label}</div>
        </div>
      ))}
    </Footer>
  );
};

export default OIFooter;
