import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import equipmentParameterLogApi from '../../../../api/equipmentParameterLogApi';
import dayjs from 'dayjs';

const MeasurementTab = ({ equipmentId }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const overSpecColumns = [
    {
      title: 'Thời điểm ghi nhận',
      dataIndex: 'created_at',
      width: '140px',
      render: (value) => dayjs(value).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Số LSX',
      dataIndex: 'batch_id',
      width: '80px'
    },
    {
      title: 'Số Lot',
      dataIndex: 'lot_id',
      width: '80px'
    },
    {
      title: 'Thông số',
      dataIndex: 'equipment_parameter_id',
      width: '180px',
      render: (_, record) => record?.equipment_parameter?.parameter_name ?? record?.equipment_parameter_id,
    },
    {
      title: 'Tiêu chuẩn',
      dataIndex: 'standard',
      width: '100px',
      render: (_, record) => record?.standard_parameter?.standard ? Number(record?.standard_parameter?.standard) : '-',
    },
    {
      title: 'Thực tế',
      dataIndex: 'value',
      width: '120px',
      render: (value) => Number(value),
    },
    {
      title: 'Mức vượt',
      dataIndex: 'excess_level',
      width: '100px'
    },
  ];

  useEffect(() => {
    fetchEquipmentParameterLog(equipmentId);
  }, [equipmentId]);

  const fetchEquipmentParameterLog = async (equipment_id) => {
    if (equipment_id) {
      setLoading(true);
      const response = await equipmentParameterLogApi.getEquipmentParameterLogs({ equipment_id, page: 1, pageSize: 50 });
      if (response?.success) {
        setData(response.data?.data ?? []);
      }
      setLoading(false);
    }
  }

  const rowClassName = (record) => {
    if (record?.status) return '';
    // return 'highlighted-cell';
    return '';
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      if (equipmentId) {
        const response = await equipmentParameterLogApi.getEquipmentParameterLogs({ equipment_id: equipmentId, page: 1, pageSize: 50 });
        if (response?.success) {
          setData(response.data?.data ?? []);
        }
      }
    }, 10000);
    return () => clearInterval(interval);
  });

  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
      <Table
        loading={loading}
        dataSource={data}
        columns={overSpecColumns.map(e => ({ ...e, align: 'center' }))}
        pagination={false}
        size="small"
        bordered
        scroll={{ x: 1200, y: '50vh' }}
        rowClassName={rowClassName}
      />
    </div>
  );
};

export default MeasurementTab;
