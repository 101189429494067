// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';

const AuthContext = createContext();
let logoutFunction = null;

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                // const response = await axiosInstance.get('/auth/check'); // Thay đổi endpoint theo API của bạn
                const storedUser = localStorage.getItem('user');
                if (storedUser) {
                    setUser(JSON.parse(storedUser));
                }
            } catch (error) {
                setUser(null);
            } finally {
                setLoading(false);
            }
        };
        checkAuth();
    }, []);

    const login = async (username, password) => {
        try {
            const response = await axiosInstance.post('/login', { username, password }); // Thay đổi endpoint theo API của bạn
            if(response.success){
                localStorage.setItem('token', response.data.access_token); // Lưu token vào localStorage
                setUser(response.data.user);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                navigate('/');
            }
        } catch (error) {
            throw error;
        }
    };

    const logout = async (isManual = true) => {
        try {
            if (isManual) {
                await axiosInstance.post('/logout');
            }
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            setUser(null);
            navigate('/login');
        } catch (error) {
            throw error;
        }
    };

    const loginWithQrCode = async (token) => {
        try {
            const response = await axiosInstance.post(`/login-qr-code/${token}`); // Thay đổi endpoint theo API của bạn
            if(response.success){
                localStorage.setItem('token', response.data.access_token); // Lưu token vào localStorage
                setUser(response.data.user);
                localStorage.setItem('user', JSON.stringify(response.data.user));
                navigate('/');
            }
        } catch (error) {
            throw error;
        }
    };

    logoutFunction = () => logout(false);

    return (
        <AuthContext.Provider value={{ user, loading, login, logout, loginWithQrCode }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
export const getLogout = () => logoutFunction;