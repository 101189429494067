// src/api/axiosInstance.js

import axios from "axios";
import config from "../config/config";
import { message } from "antd";
import { getLogout } from "../context/AuthContext";

const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  timeout: config.timeout,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["Accept"] = "application/json";
      if (config.headers["Content-Type"] !== "multipart/form-data") config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (
      response.data.message !== "" &&
      response.data.message !== "success" &&
      response.data.message !== "failed"
    ) {
      if (response.data.success) {
        message.success({ content: response.data.message });
      } else {
        message.error({ content: response.data.message });
      }
    }
    return response.data;
  },
  (error) => {
    switch (error.response?.status) {
      case 401:
        // Handle unauthorized access, e.g., redirect to login page
        // You can dispatch a logout action or perform any other required operations here
        const logout = getLogout();
        if (logout) {
          logout(false); // Log out without making another request to the server
        }
        break;

      default:
        break;
    }
    const defaultResponse = {
      data: [],
      success: false,
      message: "Có lỗi xảy ra. Vui lòng thử lại sau.",
    };
    if (error?.response?.data?.message) {
      message.error({ content: error.response.data.message });
    } else {
      message.error({ content: defaultResponse.message });
    }

    return Promise.resolve(defaultResponse);
  }
);

export default axiosInstance;
