import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import EquipmentMaintenancePlanSidebar from './EquipmentMaintenancePlanSidebar';
import EquipmentMaintenancePlanTable from './EquipmentMaintenancePlanTable';

const { Content } = Layout;

const EquipmentMaintenancePlan = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  return (
    <Layout>
      <EquipmentMaintenancePlanSidebar onFilter={handleFilter} />
      <Layout>
        <Content className="site-layout-content">
          <div style={{height: '100%'}}>
            <EquipmentMaintenancePlanTable filters={filters}/>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default EquipmentMaintenancePlan;
