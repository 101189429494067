import logo from "../assets/img/logo.png"
const config = {
  baseUrl: 'http://localhost:3000',
  apiUrl: 'https://usback.ors.vn/api',
  // apiUrl: 'http://127.0.0.1:8000/api',
  pageSize: 15,
  tinymceApiKey: '6sh37o8jrh5q14t59jxkl6wzb35xj7v24plwljnj8j78i0t7',
  timeout: 30000,
  logo: logo,
  company_name: 'Công ty cổ phần tập đoàn Ouransoft'
  // Thêm các thông tin cấu hình khác tại đây
};

export default config;