import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import EquipmentMaintenanceHistorySidebar from './EquipmentMaintenanceHistorySidebar';
import EquipmentMaintenanceHistoryTable from './EquipmentMaintenanceHistoryTable';

const { Content } = Layout;

const EquipmentMaintenanceHistory = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  return (
    <Layout>
      <EquipmentMaintenanceHistorySidebar onFilter={handleFilter} />
      <Layout>
        <Content className="site-layout-content">
          <div style={{height: '100%'}}>
            <EquipmentMaintenanceHistoryTable filters={filters}/>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default EquipmentMaintenanceHistory;
