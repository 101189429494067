// src/pages/UI/Supplier/SupplierSidebar.js
import React, { useRef, useState } from 'react';
import { Layout, Tree, DatePicker, Input, Button, Form, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/locale/vi_VN';
import { ConfigProvider } from 'antd';
import './SupplierSidebar.css';
import UISidebar from '../components/Sidebar';
import axiosInstance from '../../../api/axiosInstance';
import config from '../../../config/config';

dayjs.locale('vi');

const { Sider } = Layout;
const SupplierSidebar = ({ onFilter }) => {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerNameOptions, setCustomerNameOptions] = useState([]);
  const timer = useRef();

  const formFields = [
    {
      type: 'Input',
      label: 'Mã nhà cung cấp',
      name: 'id',
      placeholder: 'Mã nhà cung cấp',
    },
    {
      type: 'Input',
      label: 'Tên nhà cung cấp',
      name: 'supplier_name',
      placeholder: 'Tên nhà cung cấp',
    },
    // {
    //   type: 'AutoComplete',
    //   label: 'Mã khách hàng',
    //   name: 'id',
    //   placeholder: 'Mã khách hàng',
    //   options: customerOptions,
    //   search: (value) => {
    //     clearTimeout(timer.current);
    //     timer.current = setTimeout(() => {
    //       fetchCustomer({ id: value, page: 1, pageSize: 10 });
    //     }, 500);
    //   }
    // },
    // {
    //   type: 'AutoComplete',
    //   label: 'Tên khách hàng',
    //   name: 'customer_name',
    //   placeholder: 'Tên khách hàng',
    //   options: customerNameOptions,
    //   search: (value) => {
    //     clearTimeout(timer.current);
    //     timer.current = setTimeout(() => {
    //       fetchCustomer({ customer_name: value, page: 1, pageSize: 10 });
    //     }, 500);
    //   }
    // },
  ];

  const fetchCustomer = async (params) => {
    const response = await axiosInstance.get(config.apiUrl + '/customers', { params });
    if (response?.success) {
      setCustomerOptions((response.data?.data ?? []).map((item) => ({ label: item?.id, value: item?.id })));
      setCustomerNameOptions((response.data?.data ?? []).map((item) => ({ label: item?.customer_name, value: item?.customer_name })));
    }
  }

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default SupplierSidebar;
