import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import dayjs from 'dayjs';
import TaktTimeCycleTimeChart from './TaktTimeCycleTimeCharts';
import TaktTimeCycleTimeSidebar from './TarkTimeCycleTimeSidebar';
import TaktTimeCycleTimeTable from './TaktTimeCycleTimeTable';

const { Content } = Layout;

const TackTimeCycleTime = () => {
  const [filters, setFilters] = useState({
    start_time: dayjs().subtract(7, 'day'),
    end_time: dayjs(),
  });

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  useEffect(()=>{
    console.log(filters);
  }, [filters])
  return (
    <Layout>
      <TaktTimeCycleTimeSidebar filters={filters} onFilter={handleFilter}/>
      <Layout>
        <Content className="site-layout-content">
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
          <TaktTimeCycleTimeChart filters={filters} />
          <TaktTimeCycleTimeTable filters={filters} />
        </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default TackTimeCycleTime;
