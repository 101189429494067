// src/pages/UI/Manufacturing/ManufacturingSidebar.js
import React, { useEffect, useState } from 'react';
import { Layout, Tree, DatePicker, Button, Form, Divider, Select, ConfigProvider, Input, Radio, AutoComplete } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/locale/vi_VN';
import './Sidebar.css';
import { Link, useLocation } from 'react-router-dom';

dayjs.locale('vi');

const { Sider } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const UISidebar = ({ formFields = [], onFilter = null, filters = null }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const dateType = Form.useWatch('dateType', form);

  const getMachinesByCheckedKeys = (treeData, checkedKeys) => {
    const machines = [];
    const traverse = (nodes, level = 1) => {
      nodes.forEach((node) => {
        const isChecked = checkedKeys.includes(node.key);
        const hasChildren = node.children && node.children.length > 0;

        if (isChecked && level === 3) {
          machines.push(node);
        }
        if (hasChildren) {
          traverse(node.children, level + 1);
        }
      });
    };
    traverse(treeData);
    return machines.map(e => e.key);
  };

  const onChangeDate = (field, value) => {
    if (field.name === 'start_date' || field.name === 'end_date') {

    }
  }

  const renderFormItem = (field) => {
    var inputNode = null;
    switch (field.type) {
      case 'RangePicker':
        inputNode = (
          <>
            <Form.Item name={'dateType'} className='mb-1'>
              <Radio.Group
                options={[
                  { value: 'day', label: "Ngày" },
                  { value: 'week', label: "Tuần" },
                  { value: 'month', label: "Tháng" },
                ]}
                value={dateType}
                style={{ marginBottom: 0 }}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
            <Form.Item
              key={field.name}
              label={field.label}
              name={field.name}
              className={field.className ?? 'mb-3'}
            >
              <RangePicker
                picker={dateType}
              />
            </Form.Item>
          </>
        );
        break;
      case 'DatePicker':
        inputNode = (
          <DatePicker
            allowClear={true}
            placeholder={field.placeholder}
            style={{ width: '100%' }}
            picker={dateType}
            defaultValue={field.value ? dayjs(field.value) : null}
            onChange={value => value && value.isValid() && onChangeDate(field, value)}
          />
        );
        break;
      case 'Input':
        inputNode = (
          <Input
            allowClear
            placeholder={field.placeholder}
          />
        );
        break;
      case 'Select':
        inputNode = (
          <Select
            allowClear
            mode={field.mode}
            value={field.value}
            style={{ width: '100%' }}
            placeholder={field.placeholder}
            options={field.options}
          />
        );
        break;
      case 'Tree':
        inputNode = (
          <Tree
            placeholder={field.placeholder}
            checkable
            selectable={false}
            defaultExpandAll
            treeData={field.treeData}
            defaultExpandParent
            className="ant-tree"
            onCheck={(checkedKeys) => {
              const machines = getMachinesByCheckedKeys(field.treeData, checkedKeys);
              form.setFieldsValue({
                [field.name]: machines,
              });
            }}
          />
        );
        break;
      case 'Button':
        const currentPath = location.pathname;
        inputNode = (
          <Link to={field.path}>
            <Button style={{ width: '100%' }} type={currentPath === field.path ? 'primary' : undefined}>{field.name}</Button>
          </Link>
        );
        break;
      case 'AutoComplete':
        inputNode = (
          <AutoComplete 
            style={{ width: '100%' }} 
            allowClear
            placeholder={field.placeholder}
            options={field.options}
            onSearch={field.search}
          />
        );
        break;
      default:
        inputNode = null;
        break;
    }
    return (
      <Form.Item
        key={field.name}
        label={field.label}
        name={field.name !== 'dateRange' ? field.name : ""}
        className={field.className ?? 'mb-3'}
        noStyle={!inputNode ? true : false}
      >
        {inputNode}
      </Form.Item>
    )
  };

  function removeNullValues(obj) {
    const newObj = { ...obj };
    Object.keys(newObj).forEach(key => {
      if (newObj[key] === null || newObj[key] === "") {
        delete newObj[key];
      }
    });
    return newObj;
  }

  const handleFinish = (values) => {
    console.log(values);
    onFilter(removeNullValues(values));
  };

  useEffect(() => {
    form.setFieldsValue(filters);
  }, [filters]);

  return (
    <ConfigProvider locale={viVN}>
      <Sider width={270} className="site-layout-sider">
        <div className="sidebar-content">
          <Divider>Truy vấn</Divider>
          <Form
            form={form}
            style={{ margin: '0 15px' }}
            layout="vertical"
            onFinish={handleFinish}
            initialValues={{
              dateType: 'day',
              ...filters
            }}
          >
            {formFields.map((field) => renderFormItem(field))}
            <Form.Item hidden noStyle>
              <Button htmlType='submit'></Button>
            </Form.Item>
          </Form>
          <div className="sidebar-footer">
            <Button
              type="primary"
              style={{ width: '80%' }}
              onClick={() => form.submit()}
            >
              Tìm kiếm
            </Button>
          </div>
        </div>
      </Sider>
    </ConfigProvider>
  );
};

export default UISidebar;
