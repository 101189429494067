// src/pages/UI/MaterialManagement/MoldSidebar.js
import React, { useState } from 'react';
import { Layout, Tree, DatePicker, Input, Button, Form, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import viVN from 'antd/locale/vi_VN';
import { ConfigProvider } from 'antd';
import UISidebar from '../components/Sidebar';

dayjs.locale('vi');

const { Sider } = Layout;

const treeData = [
  {
    title: 'Loại nguyên vật liệu A',
    key: '0-0',
  },
  {
    title: 'Loại nguyên vật liệu B',
    key: '0-1',
  },
];

const MoldSidebar = ({onFilter}) => {
  const [pickerType, setPickerType] = useState('date');

  const formFields = [
    {
      type: 'Input',
      label: 'Mã khuôn',
      name: 'id',
      placeholder: 'Nhập mã khuôn',
    },
    {
      type: 'Input',
      label: 'Tên khuôn',
      name: 'mold_name',
      placeholder: 'Nhập tên khuôn',
    },
    {
      type: 'Input',
      label: 'Mã thiết bị',
      name: 'equipment_id',
      placeholder: 'Nhập mã thiết bị',
    },
  ];

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} treeData={treeData}/>
  );
};

export default MoldSidebar;
