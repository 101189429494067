// src/pages/UI/UIPage.js
import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import './UIPage.css';
import './components/Header'
import UIHeader from './components/Header';

const UIPage = () => {
    return (
        <Layout>
            <UIHeader />
            <Outlet />
        </Layout>
    );
};

export default UIPage;
