// src/pages/UI/Manufacturing/ManufacturingHistory.js
import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import IQCSidebar from './IQCSidebar';
import IQCTable from './IQCTable';

const { Content } = Layout;

const IQC = () => {
  const [filters, setFilters] = useState({});

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
  };
  useEffect(() => {
    console.log(filters);
  }, [filters])
  return (
    <Layout>
      <IQCSidebar onFilter={handleFilter} />
      <Layout>
        <Content className="site-layout-content">
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column', gap: 8 }}>
            <IQCTable filters={filters} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default IQC;
