import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import MainLayout from './layouts/MainLayout';
import DashboardPage from './pages/Dashboard/DashboardPage';
import UIPage from './pages/UI/UIPage';
import OIPage from './pages/OI/OIPage';
import LoginPage from './pages/LoginPage';
import ProductManagement from './pages/UI/ProductManagement/ProductManagement';
import MaterialManagement from './pages/UI/MaterialManagement/MaterialManagement';
import EquipmentManagement from './pages/UI/EquipmentManagement/EquipmentManagement';
import AccountManagement from './pages/UI/AccountManagement/AccountManagement';
import ManufacturingHistory from './pages/UI/Manufacturing/ManufacturingHistory/ManufacturingHistory';
import TemplateManagement from './pages/UI/TemplateManagement/TemplateManagement';
import DepartmentManagement from './pages/UI/DepartmentManagement/DepartmentManagement';
import ProcessManegement from './pages/UI/ProcessManagement/ProcessManagement';
import UnitManegement from './pages/UI/UnitManagement/UnitManagement';
import ErrorQualityManagement from './pages/UI/ErrorQuantityManagement/ErrorQualityManagement';
import Mold from './pages/UI/MoldManagement/MoldManagement';
import SemiFinishedProduct from './pages/UI/SemiFinishedProductManagement/SemiFinishedProductManagement';
import ErrorEquipmentManagement from './pages/UI/ErrorEquipmentManagement/ErrorEquipmentManagement';
import EquipmentParameterManagement from './pages/UI/EquipmentParameterManagement/EquipmentParameterManagement';
import StandardParameterManagement from './pages/UI/StandardParameterManagement/StandardParameterManagement';
import OIMainContent from './pages/OI/Equipment/OIMainContent';
import PQC from './pages/UI/QualityControl/PQC/PQC';
import OIQualityPQC from './pages/OI/Quality/QualityPQC/OIQualityPQC';
import OIQualityIQC from './pages/OI/Quality/QualityIQC/OIQualityIQC';
import OIQualityOQC from './pages/OI/Quality/QualityOQC/OIQualityOQC';
import WarehouseManagement from './pages/UI/WarehouseManagement/WarehouseManagement';
import WarehouseLocationManagement from './pages/UI/WarehouseLocationManagement/WarehouseLocationManagement';
import FinishedProductWarehouse from './pages/UI/Warehouse/FinishedProductWarehouse/FinishedProductWarehouse';
import ConfigManagement from './pages/UI/ConfigManagement/ConfigManagement';
import { getCompanyConfig } from './api/companyConfigApi';
import OIManufacture from './pages/OI/Manufacture/OIManufacture';
import MaterialReceiptManagement from './pages/UI/MaterialReceiptManagement/MaterialReceiptManagement';
import CustomerManagement from './pages/UI/CustomerManagement/CustomerManagement';
import Equipment from './pages/UI/Equipment/Equipment';
import BOMManagement from './pages/UI/BOMManagement/BOMManagement';
import PermissionManagement from './pages/UI/PermissionManagement/PermissionManagement';
import RoleManagement from './pages/UI/RoleManagement/RoleManagement';
import CTPlan from './pages/UI/ProductionPlan/CT/CTPlan';
import POPlan from './pages/UI/ProductionPlan/PO/POPlan';
import ProductionPlan from './pages/UI/ProductionPlan/ProductionPlan';
import DBWarehouse from './pages/Dashboard/DBWarehouse';
import EquipmentPerformance from './pages/UI/Equipment/EquipmentPerformance/EquipmentPerformance';
import EquipmentParameters from './pages/UI/Equipment/EquipmentParameters/EquipmentParameters';
import WarehouseMLTImport from './pages/OI/Warehouse/WarehouseMLT/WarehouseMLTImport';
import MaterialExportManagement from './pages/UI/MaterialExportManagement/MaterialExportManagement';
import IQC from './pages/UI/QualityControl/IQC/IQC';
import OQC from './pages/UI/QualityControl/OQC/OQC';
import MaterialWarehouse from './pages/UI/Warehouse/MaterialWarehouse/MaterialWarehouse';
import OISemiFinishedProductWarehouse from './pages/OI/Warehouse/OISemiFinishedProductWarehouse/OISemiFinishedProductWarehouse';
import OIFinishedProductWarehouse from './pages/OI/Warehouse/FinishedProductWarehouse/OIFinishedProductWarehouse';
import WarehouseMLTExport from './pages/OI/Warehouse/WarehouseMLT/WarehouseMTLExport';
import SparepartWarehouse from './pages/UI/Warehouse/SparepartWarehouse/SparepartWarehouse';
import EquipmentMaintenancePlan from './pages/UI/Equipment/EquipmentMaintenancePlan/EquipmentMaintenancePlan';
import EquipmentMaintenanceHistory from './pages/UI/Equipment/EquipmentMaintenanceHistory/EquipmentMaintenanceHistory';
import OIQualityDD from './pages/OI/Quality/QualityDD/OIQualityDD';
import OIFinishedProductWarehouseExport from './pages/OI/Warehouse/FinishedProductWarehouse/OIFinishedProductWarehouseExport';
import FinishedProductImportManagement from './pages/UI/FinishedProductImportManagement/FinishedProductImportManagement';
import FinishedProductExportManagement from './pages/UI/FinishedProductExportManagement/FinishedProductExportManagement';
import OISemiFinishedProductWarehouseExport from './pages/OI/Warehouse/OISemiFinishedProductWarehouse/OISemiFinishedProductWarehouseExport';
import KPIManagement from './pages/UI/KPI/KPIManagement';
import Simulator from './pages/Dashboard/Simulator';
import InspectionCriteriaManagement from './pages/UI/InspectionCriteriaManagement/InspectionCriteriaManagement';
import SupplierManagement from './pages/UI/SupplierManagement/SupplierManagement';
import TackTimeCycleTime from './pages/UI/Manufacturing/CycleTime/TaktTimeCycleTime';
import EnginePowerManagement from './pages/UI/EnginePowerManagement/EnginePowerManagement';
import axiosInstance from './api/axiosInstance';
import config from './config/config';

const uiRoutes = [
  { path: 'manufacturing-history', auth: '', element: <ManufacturingHistory /> },
  { path: 'manufacturing-history/cycle-time', auth: '', element: <TackTimeCycleTime /> },
  { path: 'iqc-quality', auth: '', element: <IQC /> },
  { path: 'pqc-quality', auth: '', element: <PQC /> },
  { path: 'oqc-quality', auth: '', element: <OQC /> },
  { path: 'finished-product-warehouse', auth: '', element: <FinishedProductWarehouse /> },
  { path: 'material-warehouse', auth: '', element: <MaterialWarehouse /> },
  { path: 'sparepart-warehouse', auth: '', element: <SparepartWarehouse /> },
  { path: 'equipment-performance', auth: '', element: <EquipmentPerformance /> },
  { path: 'equipment-parameters', auth: '', element: <EquipmentParameters /> },
  { path: 'equipment-maintenance-plan', auth: '', element: <EquipmentMaintenancePlan /> },
  { path: 'equipment-maintenance-history', auth: '', element: <EquipmentMaintenanceHistory /> },
  //MasterData
  { path: 'product-management', auth: 'product.search', element: <ProductManagement /> },
  { path: 'material-management', auth: 'material.search', element: <MaterialManagement /> },
  { path: 'equipment-management', auth: 'equipment.search', element: <EquipmentManagement /> },
  { path: 'account-management', auth: 'user.search', element: <AccountManagement /> },
  { path: 'template-management', auth: 'template.search', element: <TemplateManagement /> },
  { path: 'department-management', auth: 'department.search', element: <DepartmentManagement /> },
  { path: 'warehouse-management', auth: 'warehouse.search', element: <WarehouseManagement /> },
  { path: 'warehouse-location-management', auth: 'warehouse_location.search', element: <WarehouseLocationManagement /> },
  { path: 'process-management', auth: 'process.search', element: <ProcessManegement /> },
  { path: 'unit-management', auth: 'unit.search', element: <UnitManegement /> },
  { path: 'error-quality-management', auth: 'error_quality.search', element: <ErrorQualityManagement /> },
  { path: 'semi-finished-products-management', auth: 'material.search', element: <SemiFinishedProduct /> },
  { path: 'error-equipment-management', auth: 'error_equipment.search', element: <ErrorEquipmentManagement /> },
  { path: 'mold-management', auth: 'mold.search', element: <Mold /> },
  { path: 'equipment-parameter-management', auth: 'equipment_parameter.search', element: <EquipmentParameterManagement /> },
  { path: 'standard-parameter-management', auth: 'standard_parameter.search', element: <StandardParameterManagement /> },
  { path: 'material-receipt-management', auth: 'material_receipt.search', element: <MaterialReceiptManagement /> },
  { path: 'material-export-management', auth: 'material_export.search', element: <MaterialExportManagement /> },
  { path: 'customer-management', auth: 'customer.search', element: <CustomerManagement /> },
  { path: 'bom-management', auth: 'bom.search', element: <BOMManagement /> },
  { path: 'permission-management', auth: 'permission.search', element: <PermissionManagement /> },
  { path: 'role-management', auth: 'role.search', element: <RoleManagement /> },
  { path: 'inspection-criteria-management', auth: 'inspection_criteria.search', element: <InspectionCriteriaManagement /> },
  { path: 'supplier-management', auth: 'supplier.search', element: <SupplierManagement /> },
  { path: 'engine-power-management', auth: 'engine_power.search', element: <EnginePowerManagement /> },
  //ProductionPlan
  { path: 'ct-plan', auth: '', element: <CTPlan /> },
  { path: 'po-plan', auth: '', element: <POPlan /> },
  { path: 'production-plan', auth: '', element: <ProductionPlan /> },
  { path: 'kpi', auth: '', element: <KPIManagement /> },
  //FinishedProductImport
  { path: 'finished-product-import-management', auth: '', element: <FinishedProductImportManagement /> },
  { path: 'finished-product-export-management', auth: '', element: <FinishedProductExportManagement /> },
];

const oiRoutes = [
  { path: 'production', element: <OIManufacture /> },
  { path: 'quality-dd', element: <OIQualityDD /> },
  { path: 'quality-iqc', element: <OIQualityIQC /> },
  { path: 'quality-pqc', element: <OIQualityPQC /> },
  { path: 'quality-oqc', element: <OIQualityOQC /> },
  { path: 'equipment', element: <OIMainContent /> },
  { path: 'material-warehouse-import', element: <WarehouseMLTImport /> },
  { path: 'material-warehouse-export', element: <WarehouseMLTExport /> },
  { path: 'semi-finished-product-warehouse', element: <OISemiFinishedProductWarehouse /> },
  { path: 'semi-finished-product-warehouse-export', element: <OISemiFinishedProductWarehouseExport /> },
  { path: 'finished-product-warehouse', element: <OIFinishedProductWarehouse /> },
  { path: 'finished-product-warehouse-export', element: <OIFinishedProductWarehouseExport /> },
];

const devRoutes = [
  { path: 'config', element: <ConfigManagement /> },
];

const dashboardRoutes = [
  { path: 'warehouse', element: <DBWarehouse /> },
  { path: 'simulator', element: <Simulator /> },
];

const App = () => {
  const [permissions, setPermissions] = useState([]);
  useEffect(() => {
    fetchCompanyConfig();
  }, []);

  const fetchCompanyConfig = async () => {
    try {
      const response = await getCompanyConfig();
      if (response.success) {
        const result = response.data;
        updateCSSVariable('--primary-background', result?.background ?? '#1677ff');
        updateCSSVariable('--secondary-color', result?.color ?? '#2ecc71');
        updateCSSVariable('--font-size', result?.font ?? '16px');

        await localStorage.setItem('company_config', JSON.stringify(result));
      } else throw new Error('Data config not found!');

      // Get Profile
      let getUser = JSON.parse(localStorage.getItem('user') || '{}');
      const resProfile = await axiosInstance.get(config.apiUrl + '/profile');
      if (resProfile.success) {
        getUser = {...getUser, role: resProfile.data?.role};
        await localStorage.setItem('user', JSON.stringify(getUser));
      } else throw new Error('Unauthenticated');
      
      if (getUser.role?.permissions) {
        setPermissions([...getUser.role.permissions?.map(({slug}) => slug)]);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const updateCSSVariable = (variable, value) => {
    document.documentElement.style.setProperty(variable, value);
  };

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<PrivateRoute><MainLayout /></PrivateRoute>}>
            <Route index element={<DashboardPage />} />
            <Route path="ui/*" element={<UIPage />}>
              {uiRoutes.map(route => (
                <Route key={route.path} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="oi/*" element={<OIPage />}>
              {oiRoutes.map(route => (
                <Route key={route.path} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="dashboard/*" element={<DBWarehouse />}>
              {oiRoutes.map(route => (
                <Route key={route.path} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="dev/*" element={<UIPage />}>
              {devRoutes.map(route => (
                <Route key={route.path} path={route.path} element={route.element} />
              ))}
            </Route>
            <Route path="simulator" element={<Simulator />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
