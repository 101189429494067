import React, { useEffect, useState } from 'react';
import { Layout, Tabs, Select, message, Table, Card } from 'antd';
import './OIMainContent.css';
import ErrorSelectionTab from './tabs/ErrorSelectionTab';
import MeasurementTab from './tabs/MeasurementTab';
import processApi from "../../../api/processManagementApi";
import equipmentApi from '../../../api/equipmentManagementApi';
import { useOutletContext } from 'react-router-dom';
import MaintenanceTab from './tabs/MaintenanceTab';
import axiosInstance from '../../../api/axiosInstance';
import config from '../../../config/config';
import equipmentParameterLogApi from '../../../api/equipmentParameterLogApi';

const { Content } = Layout;
const { TabPane } = Tabs;

const OIMainContent = () => {
  const context = useOutletContext();
  const [selectedProcess, setSelectedProcess] = useState();
  const [selectedEquipment, setSelectedEquipment] = useState();
  const [loading, setLoading] = useState(false);
  const [processOptions, setProcessOptions] = useState([]);
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [dataTop, setDataTop] = useState([{ total_end_time: 0, total_stop_times: 0, stop_have_plan: 0, stop_no_plan: 0 }]);

  const columnsTop = [
    {
      title: 'Tổng TG dừng',
      dataIndex: 'total_end_time',
      width: '120px',
      render: (value) => Math.abs(value)
    },
    {
      title: 'Tổng số lần dừng',
      dataIndex: 'total_stop_times',
      width: '140px'
    },
    {
      title: 'Tỷ lệ dừng máy có KH',
      dataIndex: 'stop_have_plan',
      width: '200px'
    },
    {
      title: 'Tỷ lệ dừng máy không KH',
      dataIndex: 'stop_no_plan',
      width: '200px'
    },
  ];

  const fetchProcess = async () => {
    try {
      setLoading(true);
      const response = await processApi.getProcesses();
      const resource = response.data.data ?? [];
      setProcessOptions(resource.map((item) => ({ label: item?.process_name, value: item?.id })));
    } catch (error) {
      message.error('Failed to fetch processes');
    } finally {
      setLoading(false);
    }
  };

  const fetchEquipment = async () => {
    try {
      setLoading(true);
      const response = await equipmentApi.getEquipments();
      const resource = response.data.data ?? [];
      setEquipmentOptions(resource.map((item) => ({ label: item?.equipment_name, value: item?.id })));
      if (resource.length > 0) {
        setSelectedEquipment(resource[0]?.id)
      }
    } catch (error) {
      message.error('Failed to fetch equipments');
    } finally {
      setLoading(false);
    }
  };

  const fetchErrorSummary = async (equipment_id) => {
    if (equipment_id) {
      const response = await axiosInstance.get(config.apiUrl + '/error-equipment-logs-summary', { params: { equipment_id } });
      if (response?.success) {
        const { total_stop_time, total_stops } = response.data;
        setDataTop([{ total_end_time: total_stop_time ?? 0, total_stop_times: total_stops ?? 0 }]);
      }
    }
  }

  useEffect(() => {
    if (context && context.setTitle) {
      context.setTitle('QUẢN LÝ THIẾT BỊ');
    }
  }, [context]);

  useEffect(() => {
    fetchProcess();
    fetchEquipment();
  }, []);

  useEffect(() => {
    fetchErrorSummary(selectedEquipment);
  }, [selectedEquipment]);

  const handleProcessChange = (value) => {
    setSelectedProcess(value);
    setSelectedEquipment(undefined);
    fetchEquipment(value);
  };

  return (
    <Content className="oi-content">
      <div className="oi-select-row">
        <div className="oi-select-container">
          <div className="oi-select-header">Chọn máy</div>
          <Select
            allowClear
            showSearch
            optionFilterProp='label'
            options={equipmentOptions}
            value={selectedEquipment}
            style={{ width: '100%', height: '-webkit-fill-available' }}
            onChange={setSelectedEquipment}
            className="oi-select"
            placeholder="Chọn máy"
            loading={loading}
          />
        </div>
        <div className="oi-statistics-container">
          <Table
            className="custom-table"
            dataSource={dataTop}
            columns={columnsTop.map(col => ({ ...col, align: 'center' }))}
            pagination={false}
            size="small"
            bordered
            scroll={{ x: '800px' }}
          />
        </div>
      </div>
      <Card>
        <Tabs defaultActiveKey="1" type="card" style={{ width: '100%' }} className='custom-oi-tabs' animated={false} moreIcon={null}>
          <TabPane tab="Thông số" key="1">
            <MeasurementTab equipmentId={selectedEquipment} />
          </TabPane>
          <TabPane tab="Sự cố" key="2">
            <ErrorSelectionTab equipmentId={selectedEquipment} />
          </TabPane>
          <TabPane tab="Bảo dưỡng" key="3">
            <MaintenanceTab equipmentId={selectedEquipment} />
          </TabPane>
        </Tabs>
      </Card>
    </Content>
  );
};

export default OIMainContent;
