import React, { useEffect, useRef, useState } from 'react';
import { Layout, Select, message, Table, Card, Space, Button, Radio, Form, Modal, Input, InputNumber } from 'antd';
import './OIQualityOQC.css';
import processApi from "../../../../api/processManagementApi";
import equipmentApi from '../../../../api/equipmentManagementApi';
import { PrinterOutlined, QrcodeOutlined } from '@ant-design/icons';
import { useOutletContext } from 'react-router-dom';
import axiosInstance from '../../../../api/axiosInstance';
import config from '../../../../config/config';
import Scanner from '../../../../components/Scanner';
import lotApi from '../../../../api/lotApi';
import lotHistoryApi from '../../../../api/lotHistoryApi';
import { numberFormat } from '../../../../utils/numberFormat';
import { render } from '@testing-library/react';
import dayjs from 'dayjs';

const { Content } = Layout;

const OIQualityOQC = () => {
  const context = useOutletContext();
  const [selectedMachine, setSelectedMachine] = useState();
  const [loading, setLoading] = useState(false);
  const [dataTop, setDataTop] = useState([{ total: 0, percent_ng: 0, lot_ok: 0 }]);
  const [dataMiddle, setDataMiddle] = useState({});
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [lotHistory, setLotHistory] = useState([]);
  const [inspectionType, setInspectionType] = useState('');
  const [criteria, setCriteria] = useState([]);
  const [buttonStatus, setButtonStatus] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [form] = Form.useForm();
  const [formInput] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalScannerVisible, setIsModalScannerVisible] = useState(false);
  const [isInputModalVisible, setIsInputModalVisible] = useState(false);
  const [scannerKey, setScannerKey] = useState(Date.now());
  const isScanningRef = useRef(true);

  const columnsTop = [
    { title: 'Tổng thực tế', dataIndex: 'total', width: '120px', render: (value) => numberFormat(value) },
    { title: 'Tổng Lot OK', dataIndex: 'lot_ok', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Tổng KG', dataIndex: 'total', width: '100px', render: (value) => numberFormat(value) },
    { title: 'Tỉ lệ NG', dataIndex: 'percent_ng', width: '100px' },
  ];

  const columnsMiddle = [
    { title: 'Số lot', dataIndex: 'lot_id', width: '90px' },
    { title: 'Mã sản phẩm', dataIndex: 'product_id', width: '140px' },
    { title: 'Mã PI', dataIndex: 'pi', width: '80px', render: (_, record) => record?.production_plan?.pi },
    { title: 'Số lượng (Bao)', dataIndex: 'bag_stacking', width: '120px', render: (_, record) => {
      if (record?.lot_history?.production_plan) return record.lot_history.production_plan?.bag_stacking;
      return '';
    } },
    { title: 'Số lượng (KG)', dataIndex: 'quantity', width: '120px', render: (value) => numberFormat(value || 0) },
    { title: 'SL. NG', dataIndex: 'quantity_ng', width: '100px', render: (value) => numberFormat(value || 0) },
    { title: 'Tỷ lệ NG', dataIndex: 'status', width: '100px' }
  ];

  const columnsHistory = [
    { title: 'Số lot', dataIndex: 'lot_id', width: '90px', render: (_, record) => record?.lot_history?.lot_id },
    { title: 'Mã PI', dataIndex: 'pi', width: '80px', render: (_, record) => record?.lot_history?.production_plan?.pi },
    { title: 'Mã nội bộ', dataIndex: 'product_id', width: '90px', render: (_, record) => record?.lot_history?.product_id },
    { title: 'Mã khách hàng', dataIndex: 'customer_id', width: '130px', render: (_, record) => record?.lot_history?.production_plan?.customer_id },
    { title: 'SL. SX', dataIndex: 'quantity', width: '90px', render: (_, record) => record?.lot_history?.quantity },
    { title: 'SL. NG', dataIndex: 'quantity_ng', width: '90px', render: (_, record) => record?.lot_history?.quantity_ng },
    { title: 'Kết quả kiểm tra', dataIndex: 'result', width: '140px', render: (_, record) => record?.result ?? 'Chưa kiểm' },
  ];

  const fetchEquipment = async () => {
    try {
      const response = await equipmentApi.getEquipments();
      if (response?.success) {
        const equipments = response.data?.data ?? []
        setEquipmentOptions(equipments.map((item) => {
          return { label: item?.equipment_name, value: item?.id };
        }));

        if (equipments.length > 0) {
          setSelectedMachine(equipments[0]?.id)
          fetchLotHistory({ equipment_id: equipments[0]?.id })
          fetchLotHistorySummary(equipments[0]?.id);
        } else fetchLotHistory();
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchLotHistory = async (params = null) => {
    try {
      const response = await axiosInstance.get('/log-oqc', { params });
      if (response?.success) {
        const result = response.data ?? []
        setLotHistory(result);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchLotHistorySummary = async (id) => {
    const today = dayjs().format('YYYY-MM-DD');
    const response = await lotHistoryApi.getLotHistoriesSummary({ equipment_id: id, start_date: today, end_date: today });
    if (response?.success) {
      const { total, percent_ng, lot_ok } = response.data;
      setDataTop([{ total, percent_ng, lot_ok }]);
    }
  }

  useEffect(() => {
    if (context && context.setTitle) {
      context.setTitle('QUẢN LÝ OQC');
    }
  }, [context]);

  const handleInputChange = (value, id, range) => {
    if (value === '') {
      setButtonStatus(prevStatus => ({
        ...prevStatus,
        [id]: {
          status: 'OK/NG',
          bg: 'white',
          color: 'black'
        }
      }));
    } else {
      const isValid = checkMeasurement(parseFloat(value), range);
      setButtonStatus(prevStatus => ({
        ...prevStatus,
        [id]: {
          status: isValid ? 'OK' : 'NG',
          bg: isValid ? 'green' : 'red',
          color: 'white'
        }
      }));
    }
  };
  useEffect(() => {
    fetchEquipment();
  }, []);

  const determineTypeInput = (acceptablerange) => {
    if (acceptablerange === null) return 3;
    if (acceptablerange === 'OK/NG') return 2;
    return 1;
  };

  const renderInput = (id, description, range) => {
    const type = determineTypeInput(range);

    switch (type) {
      case 1:
        return (
          <Form.Item label={description} key={id} style={{ marginBottom: '12px' }}>
            <Input.Group compact>
              <Form.Item
                name={id}
                noStyle
                rules={[{ required: true, message: 'Dữ liệu không được để trống !' }]}
              >
                <Input
                  style={{ width: 'calc(100% - 80px)' }}
                  placeholder="Nhập giá trị"
                  onChange={(e) => handleInputChange(e.target.value, id, range)}
                />
              </Form.Item>
              <Button style={{ width: '80px', backgroundColor: buttonStatus[id]?.bg || 'white', color: buttonStatus[id]?.color || 'black' }}>
                {buttonStatus[id]?.status || 'OK/NG'}
              </Button>
            </Input.Group>
          </Form.Item>
        );
      case 2:
        return (
          <Form.Item
            label={description}
            key={id}
            name={id}
            style={{ marginBottom: '12px' }}
          >
            <Radio.Group
              style={{ textAlign: 'left' }}
              name={id}
              optionType="button"
              buttonStyle="solid"
              onChange={e => handleChange(id, e.target.value)}
              className={selectedValues[id] === 'NG' ? 'red-radio' : ''}
            >
              <Radio.Button value="OK">OK</Radio.Button>
              <Radio.Button value="NG">NG</Radio.Button>
            </Radio.Group>
          </Form.Item>
        );
      case 3:
        return (
          <Form.Item
            style={{ marginBottom: '12px' }}
            name={id}
            label={description}
            rules={[{ required: true, message: 'Dữ liệu không được để trống !' }]}
            key={id}
          >
            <Input placeholder="Nhập giá trị" />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const handleResult = (result) => {
    try {
      setLoading(true);
      if (!dataMiddle?.id) throw new Error('KHÔNG TÌM THẤY ID LỊCH SỬ LOT!');
      form.validateFields().then(async (values) => {
        const formData = Object.entries(values).map(([id, value]) => {
          const criterion = criteria.find(item => item.id === parseInt(id));
          const type = determineTypeInput(criterion.acceptablerange);
          if (type === 1) {
            return {
              inspection_criteria_id: id,
              measurement: value,
              result: buttonStatus[id]?.status
            };
          } else if (type === 2) {
            return {
              inspection_criteria_id: id,
              measurement: value,
              result: value
            };
          } else {
            return {
              inspection_criteria_id: id,
              measurement: value,
              result: ''
            };
          }
        });
        const inputData = {
          lot_history_id: dataMiddle.id,
          items: formData,
          result: result,
          check_type: 'OQC'
        };
        await axiosInstance.post(config.apiUrl + '/quality-checks', inputData);
        setIsModalVisible(false);
        form.resetFields();
        await fetchLotHistory({ equipment_id: selectedMachine });
        setDataMiddle({});
      }).catch(errorInfo => {
        console.log('Validate Failed:', errorInfo);
      });
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const checkMeasurement = (measurement, acceptableRange) => {
    acceptableRange = acceptableRange.trim();
    measurement = parseFloat(measurement);
    acceptableRange = acceptableRange.replace(/≤/g, '<=').replace(/≥/g, '>=');
    const regex = /^(<=|>=|>|<|=|==)?\s*(\d+(\.\d+)?)\s*(\w+)?$/;

    if (regex.test(acceptableRange)) {
      const matches = acceptableRange.match(regex);
      const comparisonType = matches[1];
      const compareValue = parseFloat(matches[2]);

      switch (comparisonType) {
        case '<=':
          return measurement <= compareValue;
        case '>=':
          return measurement >= compareValue;
        case '>':
          return measurement > compareValue;
        case '<':
          return measurement < compareValue;
        case '=':
        case '==':
          return measurement === compareValue;
        default:
          return false;
      }
    }

    return false;
  };

  const handleChange = (id, value) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [id]: value,
    }));
  };

  const fetchCriteria = async (category) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(config.apiUrl + '/inspection-criteria/search', {
        params: {
          type: 'OQC',
          // relationship_id: dataMiddle.product_id,
          // model: 'Product',
          // category: category
        }
      });
      const criteriaData = response.data || [];
      setCriteria(criteriaData);
      const initialButtonStatus = {};
      criteriaData.forEach(item => {
        initialButtonStatus[item.id] = { status: 'OK/NG', bg: 'white', color: 'black' };
      });
      setButtonStatus(initialButtonStatus);
      const initialValues = {};
      criteriaData.forEach(item => {
        initialValues[item.id] = item.acceptablerange === 'OK/NG' ? '' : '';
      });
      form.setFieldsValue(initialValues);
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const showModal = (type) => {
    console.log(dataMiddle);
    if (dataMiddle?.id) {
      if (dataMiddle?.quality_check?.result || dataMiddle?.result) {
        message.info(`LOT ${dataMiddle?.lot_id || dataMiddle?.lot_history?.lot_id} ĐÃ KIỂM TRA '${dataMiddle?.quality_check?.result || dataMiddle?.result}'`);
      } else {
        setInspectionType(type);
        setIsModalVisible(true);
        fetchCriteria(type === 'Kiểm tra chỉ tiêu' ? 'tinh_nang' : 'ngoai_quan');
      }
    } else message.info('CHƯA QUÉT MÃ LOT!');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleQrScanner = async (res) => {
    if (res && selectedMachine && isScanningRef.current) {
      isScanningRef.current = false;

      // Get lot
      const response = await lotApi.getLotById(res);
      if (response?.success) {
        // Get lot history
        const history = await lotHistoryApi.getLotHistories({
          lot_id: response.data.id,
          equipment_id: selectedMachine,
        });

        if (history?.success) {
          let result = history.data?.data ?? [];
          if (result.length > 0) setDataMiddle(result[0]);
          else message.info('Không tìm thấy lịch sử Lot');
          setScannerKey(Date.now());
          setIsModalScannerVisible(false);
        }
      }

      setTimeout(() => {
        isScanningRef.current = true;
      }, 2000);
    }
  }

  const rowClassName = (record) => {
    switch (record.quality_check?.result) {
      case 'NG':
        return 'table-row-ng';
      default:
        return "";
    }
  }

  const handleOpenScanner = () => {
    setScannerKey(Date.now());
    if (selectedMachine) setIsModalScannerVisible(true);
    else message.info('VUI LÒNG CHỌN THIẾT BỊ!');
  }

  const handleMachineChange = async (value) => {
    setLoading(true);
    setSelectedMachine(value);
    setDataMiddle({});
    await fetchLotHistory({ equipment_id: value });
    setLoading(false);
  }

  const handleRowClick = (record) => {
    setDataMiddle(record);
  };

  const handleUpdateNg = () => {
    formInput.validateFields().then(async (values) => {
      const response = await lotHistoryApi.updateNgLotHistory(dataMiddle?.id, values);
      if (response?.success) {
        setDataMiddle({});
        await fetchLotHistory({ equipment_id: selectedMachine });
        setIsInputModalVisible(false);
      }
    }).catch(errorInfo => {
      console.log('Validate Failed:', errorInfo);
    });
  }

  const handleOpenUpdateNg = () => {
    if (dataMiddle?.id) setIsInputModalVisible(true);
    else message.info('VUI LÒNG CHỌN LOT TRƯỚC KHI NHẬP SL.NG!');
  }

  return (
    <Content className="oi-content">
      <div className="oi-select-row">
        <div className="oi-select-container">
          <div className="oi-select-header">Máy</div>
          <Select
            showSearch
            optionFilterProp='label'
            options={equipmentOptions}
            value={selectedMachine}
            style={{ width: '100%', height: '-webkit-fill-available' }}
            onChange={handleMachineChange}
            className="oi-select"
            placeholder="Chọn máy"
          />
        </div>
        <div className="oi-statistics-container">
          <Table
            className="custom-table"
            locale={{ emptyText: 'Không có dữ liệu' }}
            dataSource={dataTop}
            columns={columnsTop.map(col => ({ ...col, align: 'center' }))}
            pagination={false}
            size="small"
            bordered
            scroll={{ x: '420px' }}
          />
        </div>
      </div>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Table
          locale={{ emptyText: 'Không có dữ liệu' }}
          dataSource={Object.keys(dataMiddle).length > 0 ? [dataMiddle] : []}
          columns={columnsMiddle.map(e => ({ ...e, align: 'center' }))}
          pagination={false}
          scroll={{ x: '680px' }}
          size='small'
          bordered
        />

        <div className="oi-quality-row-button-pqc">
          <Button block type="primary" onClick={handleOpenScanner}><QrcodeOutlined style={{ fontSize: 20 }} /></Button>
          <Button style={{ width: '100%', height: '100%' }} type="primary" onClick={() => showModal('Kiểm tra chỉ tiêu')}>Kiểm tra chỉ tiêu</Button>
          <Button block type="primary"><PrinterOutlined style={{ fontSize: 20 }} /></Button>
          <Button block type="primary" danger onClick={handleOpenUpdateNg}>Nhập SL NG</Button>
        </div>

        <Table
          loading={loading}
          rowClassName={rowClassName}
          dataSource={lotHistory}
          columns={columnsHistory.map(e => ({ ...e, align: 'center' }))}
          pagination={false}
          scroll={{ x: '660px', y: 400 }}
          size='small'
          bordered
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </Space>
      <Modal
        title={`${inspectionType}`}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={() => { handleResult('OK') }}>
            OK
          </Button>,
          <Button key="save" type="primary" onClick={() => { handleResult('NG') }} danger>
            NG
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Hủy
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          {criteria.length > 0 && criteria.map(item => renderInput(item.id, item?.criteria_name ?? item.description, item.acceptablerange))}
        </Form>
      </Modal>
      <Modal
        key={scannerKey}
        centered
        title={"Quét số lot"}
        open={isModalScannerVisible}
        onCancel={() => {
          setScannerKey(Date.now());
          setIsModalScannerVisible(false);
        }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Thoát"
        width={500}
      >
        {isModalScannerVisible && <Scanner key={scannerKey} isScan={true} onResult={(res) => handleQrScanner(res)} />}
      </Modal>

      {/* Input quantity */}
      <Modal
        centered
        title={"Nhập số lượng"}
        open={isInputModalVisible}
        onCancel={() => setIsInputModalVisible(false)}
        onOk={handleUpdateNg}
        okText="Xác nhận"
        cancelText="Thoát"
        width={500}
      >
        <Form form={formInput} layout="vertical" name="form_in_modal">
          <Form.Item name="quantity_ng" label="Số lượng NG" rules={[{ required: true, message: 'Vui lòng nhập số lượng!' }]}>
            <InputNumber style={{ width: '100%' }} min={0} placeholder="Số lượng NG" />
          </Form.Item>
        </Form>
      </Modal>
    </Content>
  );
};

export default OIQualityOQC;
