import { DatePicker, Form, Layout, Select } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import React, { useEffect, useState } from 'react';
import supplierApi from '../../../../api/supplierApi';
import UISidebar from '../../components/Sidebar';

dayjs.locale('vi');

const { Sider } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const IQCSidebar = ({ onFilter }) => {
  const [form] = Form.useForm();
  const [supplierOptions, setSupplierOptions] = useState([]);

  const formFields = [
    {
      type: 'DatePicker',
      label: 'Ngày bắt đầu',
      name: 'start_date',
      placeholder: 'Ngày bắt đầu',
    },
    {
      type: 'DatePicker',
      label: 'Ngày kết thúc',
      name: 'end_date',
      placeholder: 'Ngày kết thúc',
    },
    {
      type: 'Input',
      label: 'Mã NVL',
      name: 'material_id',
      placeholder: 'Nhập mã NVL',
    },
    {
      type: 'Select',
      label: 'Nhà cung cấp',
      name: 'supplier_id',
      placeholder: 'Chọn nhà cung cấp',
      options: supplierOptions,
    },
    // Add other fields here
  ];

  const fetchSupplier = async () => {
    const response = await supplierApi.getSuppliers();
    if (response?.success) {
      setSupplierOptions((response.data?.data ?? []).map((item) => {
        return { label: item?.supplier_name, value: item?.id };
      }));
    }
  }

  useEffect(() => {
    fetchSupplier();
  }, []);

  return (
    <UISidebar formFields={formFields} onFilter={onFilter} />
  );
};

export default IQCSidebar;
