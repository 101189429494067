import axiosInstance from "./axiosInstance";

const getInventories = (filters) => axiosInstance.get('/warehouses', { params: filters });
const getWarehouseById = (id) => axiosInstance.get(`/warehouses/${id}`);
const createWarehouse = (warehouseData) => axiosInstance.post('/warehouses', warehouseData);
const updateWarehouse = (id, warehouseData) => axiosInstance.put(`/warehouses/${id}`, warehouseData);
const deleteWarehouse = (id) => axiosInstance.delete(`/warehouses/${id}`);

export default {
  getInventories,
  getWarehouseById,
  createWarehouse,
  updateWarehouse,
  deleteWarehouse,
};
