// src/pages/UI/ProcessManagement/ProcessSidebar.js
import React, { useState } from 'react';
import { Layout, Tree, DatePicker, Input, Button, Form, Divider } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import UISidebar from '../components/Sidebar';

dayjs.locale('vi');

const { Sider } = Layout;

const treeData = [
    {
        title: 'Loại sản phẩm A',
        key: '0-0',
    },
    {
        title: 'Loại sản phẩm B',
        key: '0-1',
    },
];

const formFields = [
    {
        type: 'Input',
        label: 'Mã công đoạn',
        name: 'id',
        placeholder: 'Mã công đoạn',
    },
    {
        type: 'Input',
        label: 'Tên công đoạn',
        name: 'process_name',
        placeholder: 'Tên công đoạn',
    },
    // {
    //     type: 'DatePicker',
    //     label: 'Thời gian tạo',
    //     name: 'start_date',
    //     placeholder: 'Ngày bắt đầu',
    //     className: 'mb-1'
    // },
    // {
    //     type: 'DatePicker',
    //     label: '',
    //     name: 'end_date',
    //     placeholder: 'Ngày kết thúc',
    // },
    // Add other fields here
];

const ProcessManagementSidebar = ({ onFilter }) => {
    return (
        <UISidebar formFields={formFields} onFilter={onFilter} treeData={treeData} />
    );
};

export default ProcessManagementSidebar;
