// src/pages/UI/SemiFinishedProductManagement/SemiFinishedProductTable.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import semiFinishedProductApi from '../../../api/semiFinishedProductManagementApi';
import config from '../../../config/config';
import unitManagementApi from '../../../api/unitManagementApi';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import materialCategoryManagementApi from '../../../api/materialCategoryManagementApi';

const initialData = [
  {
    key: '1',
    materialCode: 'M001',
    materialName: 'bán thành phẩm A',
    materialCategory: 'Loại 1',
    unitPrice: '50,000',
    stockQuantity: 100,
  },
  // Thêm các dữ liệu mẫu khác theo cấu trúc này
];

const SemiFinishedProductTable = ({ filters }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [editingRecord, setEditingRecord] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 20,
    total: 0,
  });
  const [materialCategories, setMaterialCategories] = useState([]);
  const [classOptions, setClassOptions] = useState(['NHUA', 'ACID', 'KEM', 'OIL', 'BOTDA', 'WAX'].map((item) => {
    return {label: item, value: item}
  }));

  const showModal = () => {
    setIsEditMode(false);
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const showEditModal = (record) => {
    setIsEditMode(true);
    setEditingRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  useEffect(() => {
    fetchData({ ...filters, page: 1, pageSize: config.pageSize });
  }, [filters]);

  const fetchData = async (filters) => {
    try {
      setLoading(true);
      const response = await semiFinishedProductApi.getSemiFinishedProducts(filters);
      setData(response.data.data);
      setPagination(response.data.pagination);
      setLoading(false);
    } catch (error) {
      message.error('Failed to fetch semi-finished-products');
    }
  };

  useEffect(()=>{
    getUnits();
    getMaterialCategories();
  }, [])

  const [units, setUnits] = useState([])
  const [loadingUnits, setLoadingUnits] = useState(false);
  const getUnits = async () => {
    setLoadingUnits(true);
    const res = await unitManagementApi.getUnits();
    if (res.success) setUnits(res.data.data.map(e => ({ ...e, value: e.id, label: e.unit_name })))
      setLoadingUnits(false);
  }

  const handleOk = async () => {
    form.validateFields().then(async (values) => {
      if (isEditMode) {
        // Cập nhật bản ghi
        const response = await semiFinishedProductApi.updateSemiFinishedProduct(editingRecord.id, values);
        if (response.success) {
          fetchData({...filters, ...pagination});
          setIsModalVisible(false);
          form.resetFields();
        }
        // message.success('Cập nhật bán thành phẩm thành công');
      } else {
        // Thêm bản ghi mới
        const response = await semiFinishedProductApi.createSemiFinishedProduct({...values, type: 2});
        if (response.success) {
          fetchData({...filters, ...pagination});
          setIsModalVisible(false);
          form.resetFields();
        }
        // message.success('Thêm bán thành phẩm mới thành công');
      }
    }).catch((error) => {
      console.log('error', error);
    });
  };

  const handleDelete = async (id) => {
    try {
      const response = await semiFinishedProductApi.deleteSemiFinishedProduct(id);
      response.success && fetchData({...filters, ...pagination});
      // message.success('Xóa bán thành phẩm thành công');
    } catch (error) {
      message.error('Failed to delete material');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleUploadChange = (info) => {
    try {
      if (info.file.status === 'uploading') {
        setUploading(true);
      } else {
        if (info.file.status === 'done') {
          if (!info.file?.response?.success)
            throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`)
          fetchData({ ...filters, ...pagination });
          message.success(`${info.file.name} - Upload thành công`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} - Upload thất bại`);
        }
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      setUploading(false);
    }
  };

  const uploadProps = {
    name: 'file',
    accept: '.xlsx, .xls',
    action: config.apiUrl + '/import-semi-finished-products', // Đường dẫn API để xử lý upload file
    onChange: handleUploadChange,
    showUploadList: false, // Ẩn danh sách file
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      width: 70,
      render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
    },
    {
      title: 'Mã BTP',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Tên BTP',
      dataIndex: 'material_name',
      align: 'center',
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit',
      align: 'center',
      render: (value) => value?.unit_name
    },
    {
      title: 'Hành động',
      key: 'action',
      align: 'center',
      width: 120,
      render: (text, record) => (
        <Space size="middle">
          <Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />
          <Popconfirm
            title="Bạn có chắc chắn muốn xóa bản ghi này?"
            onConfirm={() => handleDelete(record.id)}
            okText="Có"
            cancelText="Không"
          >
            <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const [loadingMaterialCategories, setLoadingMaterialCategories] = useState(false);
  const getMaterialCategories = async () => {
    setLoadingMaterialCategories(true);
    const res = await materialCategoryManagementApi.getMaterialCategories();
    if (res.success) setMaterialCategories(res.data.data.map(e => ({ ...e, value: e.id, label: e.material_category_name })))
    setLoadingMaterialCategories(false);
  }

  return (
    <Card
      style={{height: '100%'}}
      title="Quản lý BTP"
      extra={
        <div className="table-actions">
          <Button type="primary">Xuất file</Button>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
              {uploading ? 'Đang tải lên' : 'Upload Excel'}
            </Button>
          </Upload>
          <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm bán thành phẩm</Button>
        </div>
      }
    >
      <Table
        className='table-semi-fd'
        loading={loading}
        // rowSelection={rowSelection}
        columns={columns}
        dataSource={data.map((e, i) => ({ ...e, key: i }))}
        pagination={{
          current: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          onChange: (page, pageSize) => {
            setPagination({ ...pagination, page, pageSize });
            fetchData({ ...filters, page, pageSize });
          }
        }}
        bordered  // Thêm thuộc tính bordered để có viền
        scroll={{y: CalculateTableHeight('table-semi-fd')}}
      />

      <Modal
        title={
          <div className="modal-title">
            {isEditMode ? "Chỉnh sửa bán thành phẩm" : "Thêm bán thành phẩm"}
            <div className="modal-title-divider"></div>
          </div>
        }
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Lưu lại"
        cancelText="Thoát"
        width={800}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <div className="modal-form-grid">
            <Form.Item name="id" label="Mã bán thành phẩm" rules={[{ required: true, message: 'Vui lòng nhập mã bán thành phẩm!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="material_name" label="Tên bán thành phẩm" rules={[{ required: true, message: 'Vui lòng nhập tên bán thành phẩm!' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="unit_id" label="Đơn vị" rules={[{ required: true, message: 'Vui lòng chọn đơn vị!' }]}>
              <Select showSearch placeholder="Chọn công đoạn" options={units} optionFilterProp='label' loading={loadingUnits}/>
            </Form.Item>
            <Form.Item name="material_category_id" label="Loại" rules={[{ required: true, message: 'Vui lòng chọn loại!' }]}>
              <Select options={materialCategories} optionFilterProp='label' showSearch loading={loadingMaterialCategories} />
            </Form.Item>
            <Form.Item name="class" label="Phân loại" rules={[{ required: true, message: 'Vui lòng chọn phân loại!' }]}>
              <Select showSearch placeholder="Phân loại" options={classOptions} optionFilterProp='label'/>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </Card>
  );
};

export default SemiFinishedProductTable;
