import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Button, Space, Modal, Form, Input, Card, Upload, message, Popconfirm, Select, } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import axiosInstance from '../../../api/axiosInstance'; // Import axiosInstance
import './EquipmentParameterTable.css';
import { useReactToPrint } from 'react-to-print';
import equipmentParameterApi from "../../../api/equipmentParameterManagementApi";
import equipmentApi from "../../../api/equipmentManagementApi";
import config from '../../../config/config';
import AuthContext from '../../../context/AuthContext';

const EquipmentParameterTable = ({ filters }) => {
    const { user } = useContext(AuthContext);
    const [permissions, setPermissions] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [form] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState(null);
    const [data, setData] = useState([]);
    const [equipmentOptions, setEquipmentOptions] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const componentRef = useRef();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        pageSize: config.pageSize,
        total: 0,
    });

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        const paginate = { ...pagination, page: 1 };
        setPagination(paginate)
        fetchEquipment();
        fetchData({ ...filters, ...paginate });
    }, [filters]);

    useEffect(() => {
        if (htmlContent) {
            handlePrint();
        }
    }, [htmlContent]);

    useEffect(() => {
        setPermissions([...user?.role?.permissions?.map(({ slug }) => slug)]);
    }, []);

    const fetchData = async (filters) => {
        try {
            setLoading(true);
            const response = await equipmentParameterApi.getEquipmentParameters(filters); // Thay đổi endpoint theo API của bạn
            setData(response.data.data ?? []);
            response.data?.pagination && setPagination(response.data.pagination)
            setLoading(false);
        } catch (error) {
            message.error('Failed to fetch accounts');
        }
    };

    const fetchEquipment = async () => {
        try {
            const response = await equipmentApi.getEquipments();
            setEquipmentOptions((response.data.data ?? []).map((item) => {
                return { label: item?.equipment_name, value: item?.id };
            }));
            if ((response.data.data ?? []).length === 0) message.info('Dữ liệu thiết bị trống!')
        } catch (error) {
            message.error('Failed to fetch equipments');
        }
    }

    const showModal = () => {
        setIsEditMode(false);
        setEditingRecord(null);
        setIsModalVisible(true);
    };

    const showEditModal = (record) => {
        setIsEditMode(true);
        setEditingRecord(record);
        form.setFieldsValue(record);
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        form.validateFields().then(async (values) => {
            try {
                if (isEditMode) {
                    const response = await equipmentParameterApi.updateEquipmentParameter(editingRecord.id, values); // Thay đổi endpoint theo API của bạn
                    if (response.success) {
                        // setData((prevData) => [{ ...values, id: editingRecord.id }, ...prevData.filter(item => item.id !== editingRecord.id)]);
                        await fetchData(pagination);
                    } else throw new Error('Action failure');
                } else {
                    const response = await equipmentParameterApi.createEquipmentParameter(values); // Thay đổi endpoint theo API của bạn
                    if (response.success) {
                        // setData((prevData) => [{ ...values, id: response.data.id }, ...prevData]);
                        setPagination({ ...pagination, total: pagination.total++ });
                        await fetchData(pagination);
                    } else throw new Error('Action failure');
                }
                setIsModalVisible(false);
                form.resetFields();
            } catch (error) {
                message.error('Failed to save product');
            }
        }).catch((error) => { console.log(error) });
    };
    const handleDelete = async (id) => {
        try {
            await equipmentParameterApi.deleteEquipmentParameter(id); // Thay đổi endpoint theo API của bạn
            setData((prevData) => prevData.filter(item => item.id !== id));
            message.success('Xóa thông số thiết bị thành công');
        } catch (error) {
            message.error('Failed to delete equipment');
        }
    };
    const printRecord = async (record) => {
        const res = equipmentParameterApi.getEquipmentParameterById(record.id);
        if (res.success) {
            setHtmlContent(res.data);
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    const handleUploadChange = (info) => {
        try {
            if (info.file.status === 'uploading') {
                setUploading(true);
            } else {
                if (info.file.status === 'done') {
                    if (!info.file?.response?.success)
                        throw new Error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`);
                    fetchData({ ...filters, ...pagination });
                    message.success(`${info.file.name} - Upload thành công`);
                } else if (info.file.status === 'error') {
                    message.error(info.file?.response?.message ?? `${info.file.name} - Upload thất bại`);
                }
            }
        } catch (error) {
            message.error(error.message)
        } finally {
            setUploading(false);
        }
    };

    const uploadProps = {
        name: 'file',
        accept: '.xlsx, .xls',
        action: config.apiUrl + '/import-equipment-parameters', // Đường dẫn API để xử lý upload file
        onChange: handleUploadChange,
        showUploadList: false, // Ẩn danh sách file
    };

    const columns = [
        {
            title: 'STT',
            dataIndex: 'no',
            key: 'no',
            align: 'center',
            render: (_, record, index) => ((pagination.page - 1) * pagination.pageSize) + index + 1,
        },
        {
            title: 'Mã thông số',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Tên thiết bị',
            dataIndex: 'equipment_name',
            key: 'equipment_name',
            align: 'center',
            render: (value, record) => {
                return record.equipment?.equipment_name
            }
        },
        {
            title: 'Tên thông số',
            dataIndex: 'parameter_name',
            key: 'parameter_name',
            align: 'center',
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {permissions.includes('equipment_parameter.update') && (<Button type='primary' size='small' icon={<EditOutlined />} onClick={() => showEditModal(record)} />)}
                    {permissions.includes('equipment_parameter.delete') && (<Popconfirm
                        title="Bạn có chắc chắn muốn xóa?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button type='primary' size='small' icon={<DeleteOutlined />} danger />
                    </Popconfirm>)}
                </Space>
            ),
            align: 'center',
        },
    ];
    return (
        <Card
            title="Quản lý thông số thiết bị"
            extra={
                <div className="table-actions">
                    {permissions.includes('equipment_parameter.create') && (<>
                        <Button target="_blank" href={config.apiUrl.replace('/api', '') + '/excel/EquipmentParameters.xlsx'} icon={<DownloadOutlined />}>Tải excel mẫu</Button>
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />} type="primary" loading={uploading} style={{ margin: '0 8px' }}>
                                {uploading ? 'Đang tải lên' : 'Upload Excel'}
                            </Button>
                        </Upload>
                        <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>Thêm thông số thiết bị</Button>
                    </>)}
                </div>
            }
        >
            <Table
                loading={loading}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={data.map((record, key) => { return { key, ...record } })}
                pagination={{
                    current: pagination.page,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    onChange: (page, pageSize) => {
                        setPagination({ ...pagination, page, pageSize });
                        fetchData({ ...filters, page, pageSize });
                    }
                }}
                bordered  // Thêm thuộc tính bordered để có viền
                size="small"  // Sử dụng kích thước bảng nhỏ
            />
            <div ref={componentRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
            <Modal
                title={
                    <div className="modal-title">
                        {isEditMode ? "Chỉnh sửa thông số thiết bị" : "Thêm thông số thiết bị"}
                        <div className="modal-title-divider"></div>
                    </div>
                }
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Lưu lại"
                cancelText="Thoát"
                width={800}
            >
                <Form form={form} layout="vertical" name="form_in_modal">
                    <div className="modal-form-grid">
                        <Form.Item name="id" label="Mã thông số" rules={[{ required: true, message: 'Vui lòng nhập mã thông số!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="parameter_name" label="Tên thông số thiết bị" rules={[{ required: true, message: 'Vui lòng nhập tên thông số thiết bị!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="equipment_id" label="Tên thiết bị" rules={[{ required: true, message: 'Vui lòng chọn thiết bị!' }]}>
                            <Select showSearch allowClear placeholder="Chọn thiết bị" options={equipmentOptions} optionLabelProp='label' />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </Card>
    );
};

export default EquipmentParameterTable;
